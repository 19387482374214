var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "comment-content",
        class: {
          "comment-hidden": _vm.item.hidden,
          "comment-deleted": _vm.item.state.value === "deleted",
        },
        style: { backgroundColor: _vm.item.color },
        attrs: { id: "comment-" + _vm.to_html_id(_vm.item.line_ref) },
      },
      [
        _c(
          "div",
          { staticClass: "comment-title", on: { dblclick: _vm.toggleFolded } },
          [
            _c("span", {
              staticClass: "fa comment-fold-toggle",
              class: {
                "fa-caret-down": !_vm.folded,
                "fa-caret-right": _vm.folded,
              },
              on: {
                click: function ($event) {
                  return _vm.toggleFolded(_vm.item)
                },
              },
            }),
            _vm._v(
              " " +
                _vm._s(_vm.item.line_ref) +
                "." +
                _vm._s(_vm.item.number) +
                ": " +
                _vm._s(_vm.item.type.label) +
                " "
            ),
            _vm.item.origin
              ? _c("span", [
                  _vm._v(
                    " - " +
                      _vm._s(_vm.$gettext("Comment from")) +
                      " " +
                      _vm._s(_vm.item.origin.label)
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.folded,
                expression: "!folded",
              },
            ],
          },
          [
            _c("div", {
              staticClass: "comment-text clearfix",
              domProps: { innerHTML: _vm._s(_vm.item.content) },
            }),
            _vm._v(" "),
            _vm._l(_vm.item.attachments, function (attachFile) {
              return _c(
                "div",
                {
                  key: attachFile.uuid,
                  staticClass: "comment-attachment-item",
                },
                [
                  _c("span", { staticClass: "glyph fa fa-paperclip" }),
                  _vm._v(" "),
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: attachFile.url } },
                    [_vm._v(_vm._s(attachFile.filename))]
                  ),
                ]
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "comment-footer clearfix" },
              [
                _vm._l(_vm.item.info, function (info, index) {
                  return _c("i", { staticClass: "comment-footer-info" }, [
                    index != 0 ? _c("br") : _vm._e(),
                    _vm._v(_vm._s(info)),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "comment-actions hidden-print" },
                  [
                    _vm.item.state.value === "deleted" &&
                    !_vm.item.readonly &&
                    !_vm.$parent.$parent.readonly
                      ? [
                          _c("span", { staticClass: "glyph fa fa-trash" }),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.restore(_vm.item)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$gettext("Restore")))]
                          ),
                        ]
                      : [
                          !_vm.item.hidden && !_vm.item.readonly
                            ? [
                                _vm.item.state.value === "draft"
                                  ? _c("span", [
                                      _c("span", {
                                        staticClass: "glyph fa fa-pencil",
                                      }),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.edit(_vm.item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$gettext("Edit")))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "glyph fa fa-trash",
                                      }),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.remove(_vm.item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$gettext("Delete")))]
                                      ),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "glyph fa fa-check",
                                      }),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.validate(_vm.item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$gettext("Validate"))
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _c("span", [
                                      _c("span", {
                                        staticClass: "glyph fa fa-lock",
                                      }),
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.unlock(_vm.item)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.$gettext("Unlock")))]
                                      ),
                                    ]),
                              ]
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
              ],
              2
            ),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }