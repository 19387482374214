var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row display-flex hidden-xs" }, [
    _c("div", { staticClass: "col-sm-3 footer-column" }, [_c("teams")], 1),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-6 separator-left footer-column" },
      [_c("recents")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-sm-3 separator-left footer-column" },
      [_c("links")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }