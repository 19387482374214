<template>
  <li>
    <div :class="{folder: hasChilds, 'drag': dragOn, 'node-item-section': node.section}" @click.stop="doClick(node, $event)" @dblclick="hasChilds && toggleExpanded(node)" class="node-item"
       @dragenter="doDragEnter"
         @dragleave="doDragLeave"
         @dragover="e => {e.preventDefault()}"
         @drop="doDrop" :title="node.text">
      <span :class="hasChilds ? expanded ? 'glyphicon glyphicon-chevron-down' : 'glyphicon glyphicon-chevron-right' : ''" @click.stop="hasChilds && toggleExpanded(node, $event)" class="treeview-toggle"
            v-if="!always_expanded && !loading"></span>
      <span v-if="always_expanded && !loading" class="treeview-toggle glyphicon"></span>
      <span v-if="loading" class="lds-ring"><div></div><div></div><div></div><div></div></span>
      <span v-if="treeview.checkable" :class="checked ? 'img-checked' : checked ===  undefined ? 'img-indeterminate' : 'img-unchecked'" @click.stop="toggleChecked(node, $event)"
            @dblclick.stop="" class="img"/><span v-if="node.badge" class="badge pull-right">{{node.badge}}</span><span :class="{active: selected}" class="title">{{node.text}}</span> <small class="node-text-info" v-if="node.info">{{node.info}}</small>
    </div>
    <div v-if="adding" class="new-folder-item">
      <input id="new-folder" type="text" v-model="editText" @keydown.enter.stop="commitEditing" @keydown.esc.stop="cancelEditing"><button @click.prevent.stop="commitEditing">{{$gettext('OK')}}</button><button @click.prevent.stop="cancelEditing">{{$gettext('Cancel')}}</button>
    </div>
    <transition name="slide">
      <ul v-if="expanded || always_expanded">
        <treeview-node :depth="depth+1" :key="child.id"
          :node="child"
          class="item"
          v-for="child in node.childs"
          v-on:emitNodeChecked="emitNodeChecked"
          v-on:emitNodeExpanded="emitNodeExpanded"
          v-on:emitNodeSelected="emitNodeSelected"
          v-on:emitNodeClicked="emitNodeClicked"
        />
      </ul>
    </transition>
  </li>
</template>

<script>
  import './images/unchecked.png'
  import './images/checked.png'
  import './images/indeterminate.png'
  import message from "toastr";

  export default {
    name: 'treeview-node',
    props: {
      model: Object,
      always_expanded: {
        type: Boolean,
        required: false,
        default: false
      },
      node: {
        type: Object,
        required: true
      },
      depth: Number
      // parentNode: Object
    },
    data () {
      return {
        treeview: this.$parent.treeview || this.$parent,
        checked: false,
        expanded: false,
        selected: false,
        dragOn: false,
        loading: false,
        editText: 'New folder'
      }
    },
    computed: {
      hasChilds () {
        return this.node.childs === true || (this.node.childs && this.node.childs.length > 0)
      },
      adding () {
         return this.selected && this.treeview.adding
      }
    },
    watch: {
      checked () {
        if (this.node && this.node.state)
          this.node.state.checked = this.checked
      },
      expanded () {
        this.node.state.expanded = this.expanded
      },
      selected () {
        this.node.state.selected = this.selected
      },
      adding () {
        if (this.adding) {
          this.$nextTick(() => {
            const input = document.getElementById('new-folder')
            input.select()
            input.scrollIntoView({behavior: 'smooth'})
          })
        }
      }
    },
    methods: {
      commitEditing() {
        if (this.treeview.adding) {
          if (this.editText) {
            this.treeview.$emit('newFolder', {create: true, node: this, parent_id: this.node.id, text: this.editText})
            this.resetEditing()
          } else {
            message.info(this.$gettext('Enter the folder name'))
          }
        }
      },
      cancelEditing () {
        this.treeview.$emit('newFolder', {create: false})
        this.resetEditing()
      },
      resetEditing() {
        this.treeview.adding = false
        this.editText = 'New folder'
      },
      toggleExpanded (node) {
        this.expanded = !this.expanded
        if (this.expanded && this.node.childs === true) {
          this.treeview.loadNode(this)
        }
        this.node.state.expanded = this.expanded
        this.$nextTick(() => {
          this.$emit('emitNodeExpanded', node, this.expanded)
        })
      },
      doClick (node) {
        if (!node.section) {
          this.toggleSelected(node)
          this.$emit('emitNodeClicked', node)
        }
      },
      doDragEnter (ev) {
        this.dragOn = true
        if (ev.relatedTarget.classList.contains('treeview-toggle') && !this.expanded) {
          this.toggleExpanded(this.node)
        }
      },
      doDragLeave (ev) {
        if (ev.currentTarget.contains(ev.relatedTarget)) {
          return;
        }
        this.dragOn = false
      },
      doDrop (ev) {
        ev.preventDefault()
        this.dragOn = false
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[i].kind === 'file') {
              let file = ev.dataTransfer.items[i].getAsFile();
              console.log('... file[' + i + '].name = ' + file.name);
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.files.length; i++) {
            console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
          }
        }
      },
      toggleSelected (node) {
        if (this.treeview.selectable) {
          this.selected = !this.treeview.multiselectable || !this.selected
          this.node.state.selected = this.selected
          if (this.treeview.adding) {
            this.cancelEditing()
          }
          this.$emit('emitNodeSelected', node)
        }
      },
      toggleChecked (node) {
        this.checked = !this.checked
        this.node.state.checked = this.checked
        this.$nextTick(() => {
          this.callNodesChecked(this.checked)
          this.$emit('emitNodeChecked', node)
        })
      },
      emitNodeClicked (nodeClicked) { // redirect the event toward the Tree component
        this.$emit('emitNodeClicked', nodeClicked)
      },
      emitNodeSelected (nodeSelected) { // redirect the event toward the Tree component
        this.$emit('emitNodeSelected', nodeSelected)
      },
      emitNodeExpanded (node, state) { // redirect the event toward the Tree component
        this.$emit('emitNodeExpanded', node, state)
      },
      emitNodeChecked (nodeChecked) { // redirect the event toward the Tree component
        this.$emit('emitNodeChecked', nodeChecked)
      },
      setNodeState (node, state, event) {
        if (!node.state) node.state = {checked: false, expanded: false, selected: false}
        node.state[event] = state
      },
      recCallNodes (state, event, nodes) {
        nodes.forEach(node => {
          this.setNodeState(node, state, event)
          if (node.childs && Array.isArray(node.childs)) {
              this.recCallNodes(state, event, node.childs)
            }
          })
      },
      callNodesChecked (state, propagate = true) {
        this.checked = state
        for (let i = 0; i < this.$children.length; i++) {
          this.$children[i].callNodesChecked(state, false)
        }
        if (this.$children.length === 0 && this.node.childs && Array.isArray(this.node.childs) && this.node.childs.length > 0) {
          this.recCallNodes(state, 'checked', this.node.childs)
        }
        if (propagate) {
          let $parent = this.$parent
          while ($parent && $parent.$options.name === this.$options.name) {
            const all = $parent.$children.every(el => el.checked === state)
            if (all) {
              $parent.checked = state
            } else {
              $parent.checked = undefined
            }
            $parent = $parent.$parent
          }
        }
      },
      callNodesDeselect () {
        this.selected = false
        this.node.state.selected = this.selected
        for (let i = 0; i < this.$children.length; i++) {
          this.$children[i].callNodesDeselect()
        }
        if (this.$children.length === 0 && this.node.childs && Array.isArray(this.node.childs) && this.node.childs.length > 0) {
          this.recCallNodes(false, 'selected', this.node.childs)
        }
      },
      callSpecificChild (arrIds, fname, args) {
        for (let i = 0; i < this.$children.length; i++) {
          let currentNodeId = this.$children[i].$props.node.id
          if (arrIds.find(x => x === currentNodeId)) {
            this.$children[i][fname](args)
            return false
          }
        }
      },
      callNodeChecked (args) {
        const arrIds = args.arrIds
        const value = args.value
        if (arrIds[arrIds.length - 1] === this.node.id) {
          this.checked = value
          this.callNodesChecked(this.checked)
        } else {
          this.expanded = true
          this.$nextTick(() => {
            this.callSpecificChild(arrIds, 'callNodeChecked', args)
          })
        }
      },
      callNodeSelected (args) {
        const arrIds = args.arrIds
        const value = args.value
        if (arrIds[arrIds.length - 1] === this.node.id) {
          this.selected = value
        } else {
          this.expanded = true
          this.$nextTick(() => {
            this.callSpecificChild(arrIds, 'callNodeSelected', args)
          })
        }
      },
      callNodeExpanded (args) {
        const arrIds = args.arrIds
        const value = args.value
        if (value === false && this.expanded === false) return
        if (value && this.node.childs === true) {
          this.treeview.loadNode(this)
        }
        if (arrIds[arrIds.length - 1] !== this.node.id) {
          this.expanded = true
          this.$nextTick(() => {
            this.callSpecificChild(arrIds, 'callNodeExpanded', args)
          })
        } else {
          this.expanded = value
        }
      },
      updateChilds (data, selectNodeId=null) {
        this.loading = false
        this.node.childs = data
        this.node.childs.forEach(child => {
          if (!child.state) {
            child.state = {checked: false, expanded: false, selected: false}
          }
        })
        if (this.checked) {
          this.$nextTick(() => {
            this.callNodesChecked(true)
            this.$emit('emitNodeChecked', this.node)
          })
        }
        if (selectNodeId !== null) {
          this.treeview.selectNode(selectNodeId)
        }
        this.$forceUpdate()
      },
    },
    mounted () {
      if (this.node.state) {
        this.checked = this.node.state.checked
        this.expanded = this.node.state.expanded
        this.selected = this.node.state.selected
      } else {
        this.node.state = {checked: false, expanded: false, selected: false}
      }
    }
  }
</script>

<style lang="scss" scoped>
  .item {
    cursor: pointer;
    line-height: 24px;
  }

  ul {
    padding-left: 2px;
    line-height: 1.5em;
    list-style-type: none;
  }

  li {
    margin-left: 8px;
    margin-right: 4px;
  }

  ul.treeview > li.item {
    margin-left: -17px;
  }

  .node-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .title.active {
    color: #1b7eac;
    font-weight: bold;
  }

  .node-item:hover .title {
    color: #1b8dbb;
  }

  .node-item.node-item-section:hover .title {
    color: unset;
    cursor: default;
  }

  .treeview-toggle {
    width: 17px;
    display: inline-block;
    color: #888;
  }

  .treeview span {
    vertical-align: middle;
  }

  .img {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 8px;
  }

  .img-checked {
    background-image: url('images/checkedlight.png');
  }

  .img-indeterminate {
    background-image: url('images/indeterminatelight.png');
  }

  .img-unchecked {
    background-image: url('images/uncheckedlight.png');
  }

  .slide-enter-active {
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
  .drag {
    border: 1px dotted #777;
    margin: -1px;
  }

  .node-text-info {
    font-size: 10px;
    font-style: italic;
    color: #777;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin-left: -2px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    margin: 2px;
    border: 2px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #aaa transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .badge {
    margin-left: 4px;
    margin-top: 8px;
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 8px;
  }

  li {
    margin-right: 0;
  }
  .new-folder-item {
    width: 70%;
  }
</style>

