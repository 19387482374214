var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-tags-input", {
    attrs: {
      id: "inputKeywords",
      tags: _vm.value,
      "autocomplete-items": _vm.autocompleteItems,
      "add-only-from-autocomplete": _vm.strict,
      placeholder: _vm.placeholder,
      autocomplete: "off",
      "allow-edit-tags": !_vm.strict,
    },
    on: { "tags-changed": _vm.updateTags },
    model: {
      value: _vm.tag,
      callback: function ($$v) {
        _vm.tag = $$v
      },
      expression: "tag",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }