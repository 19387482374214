var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "panel",
    {
      attrs: {
        title: this.$pgettext("breadcrumb", "My last-viewed documents"),
        "icon-class": "fa fa-clock-o",
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function () {
            return [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.load()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-refresh" }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Refresh"))),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("table", { staticClass: "table table-striped" }, [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("#")]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$gettext("Document")))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.$gettext("Last access")))]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function (item, index) {
            return _c("tr", { key: index }, [
              _c("td", [_vm._v(_vm._s(index + 1))]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "a",
                  {
                    attrs: { href: item.url },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openItem(item, $event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ]),
              _vm._v(" "),
              _c("td", [
                _vm._v(_vm._s(new Date(item.last_access).toLocaleDateString())),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }