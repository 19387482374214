<template>
  <div class="modal fade" id="comment-editor" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ title }}</h2>
        </div>
        <div class="modal-body">
          <form id="comment-editor-form" autocomplete="off" novalidate>
            <div class="row">
              <div class="col-md-12 comment-content">
                <textarea id="id_content" class="form-control"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <label>{{$gettext('Attachments')}}</label>
                <div class="comment-attachments dropzone">
                  <ul class="dz-server-files">
                    <li v-for="att in attachments">
                      <a :data-id="att.uuid" :data-size="att.size" :data-content-type="att.content_type" :href="att.url">{{ att.filename }}</a>
                    </li>
                  </ul>
                  <div class="dz-template">
                    <div class="dz-preview dz-file-preview">
                      <div class="dz-image"><img data-dz-thumbnail/></div>
                      <div class="dz-details">
                        <div class="dz-size"><span data-dz-size></span></div>
                        <div class="dz-filename"><a data-dz-name></a></div>
                      </div>
                      <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                      <div class="dz-error-message"><span data-dz-errormessage></span></div>
                      <div class="dz-success-mark">
                        <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                          <title>Check</title>
                          <defs></defs>
                          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                            <path
                              d="M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
                              id="Oval-2" stroke-opacity="0.198794158" stroke="#747474" fill-opacity="0.816519475" fill="#888"
                              sketch:type="MSShapeGroup"></path>
                          </g>
                        </svg>
                      </div>
                      <div class="dz-error-mark">
                        <svg width="54px" height="54px" viewBox="0 0 54 54" version="1.1" xmlns="http://www.w3.org/2000/svg"
                             xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
                          <title>Error</title>
                          <defs></defs>
                          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                            <g id="Check-+-Oval-2" sketch:type="MSLayerGroup" stroke="#747474" stroke-opacity="0.198794158"
                               fill="#d76156" fill-opacity="0.7">
                              <path
                                d="M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z"
                                id="Oval-2" sketch:type="MSShapeGroup"></path>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 setting-field">
                <div class="form-group" :class="{'has-error': errors && errors.type }">
                  <label for="comment_type">{{$gettext('Type')}} *</label>
                  <select id="comment_type" class="form-control" v-model="type" @change="doChangeType">
                    <option disabled value="">{{$gettext('Select a type')}}</option>
                    <option v-for="item in types" :key="item.id" :data-hide-origin="item.hide_origin" :value="item.id">{{item.label}}</option>
                  </select>
                  <span v-if="errors && errors.type" class="help-block">{{ errors.type[0] }}</span>
                </div>
              </div>
              <div class="col-md-4 setting-field">
                <div class="form-group" :class="{'has-error': errors && errors.origin }">
                  <label for="comment_origin">{{$gettext('Origin')}} *</label>
                  <select id="comment_origin" class="form-control" v-model="origin">
                    <option disabled value="">{{$gettext('Select an origin')}}</option>
                    <optgroup :label="label" v-for="(items, label) in userOrigins">
                      <option v-for="item in items" :key="item.id" :value="item.id">{{item.label}}</option>
                    </optgroup>
                  </select>
                  <span v-if="errors && errors.origin" class="help-block">{{ errors.origin[0] }}</span>
                </div>
              </div>
              <div class="col-md-4 setting-field">
                <div class="checkbox" v-show="!forceHideOrigin">
                  <label>
                    <input type="checkbox" v-model="showOrigin"> {{$gettext('Show origin')}}
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <div class="checkbox pull-left modal-default-checkbox">
            <label>
              <input type="checkbox" v-model="keepDefault"> {{ $gettext('Per default') }}
            </label>
          </div>
          <button type="button" class="btn btn-primary" @click.prevent="submit()">{{ commentId ? $gettext('Save') : $gettext('Add') }}
          </button>
          <button data-dismiss="modal" data-action="cancel" class="btn btn-secondary"> {{ $gettext('Cancel') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import $ from 'jquery'
  import 'summernote'
  import 'summernote/dist/lang/summernote-fr-FR'
  import 'summernote/dist/summernote.css'
  import urljoin from 'url-join'
  import Dropzone from 'dropzone'
  import 'dropzone/dist/dropzone.css'
  import DRTFullscreen from './drt-full-screen'
  import message from 'toastr'
  import groupBy from 'lodash.groupby'
  import { messageBox } from './utils/dialogs'

  let commentEditor = {
    name: 'comment-editor',
    props: {
      tools: Object,
      lineRef: String,
      commentId: String
    },
    data: function () {
      return {
        keepDefault: false,
        types: [],
        userOrigins: {},
        type: null,
        origin: null,
        attachments: [],
        state: 'draft',
        content: '',
        reference: this.lineRef,
        showOrigin: true,
        errors: null,
        forceHideOrigin: false
      }
    },
    computed: {
      isNewComment () {
        return !this.commentId
      },
      title () {
        return this.isNewComment ? this.$gettext('Add a comment on reference line') + ' ' + this.reference : this.$gettext('Edit comment') + ' ' + this.reference
      }
    },
    watch: {
      '$route.path': function () {
        this.close()
      }
    },
    methods: {
      doChangeType(e) {
        let $displayOrigin = document.getElementById('comment_type')
        this.forceHideOrigin = $displayOrigin.options[$displayOrigin.selectedIndex].dataset['hideOrigin'] || false
      },
      storePreferences() {
        window.localStorage.commentEditorPreferences = JSON.stringify({
          'showOrigin': this.showOrigin, 'origin': this.origin, 'type': this.type
        })
      },
      loadPreferences() {
        let preferences = JSON.parse(window.localStorage.commentEditorPreferences || '{}')
        if (preferences.showOrigin !== undefined) {
          this.showOrigin = preferences.showOrigin
          this.origin = preferences.origin
          this.type = preferences.type
        }
      },
      submit () {
        if (this.keepDefault) {
          this.storePreferences()
        }
        let data = {
          origin: this.origin,
          type: this.type,
          line_ref: this.lineRef,
          attachments: this.attachments,
          content: this.content,
          show_origin: this.showOrigin && !this.forceHideOrigin,
          document: this.tools.document
        }
        let method = this.$root.$restAPI.post
        let url = 'comments/'
        if (this.commentId) {
          method = this.$root.$restAPI.put
          url = `comments/${this.commentId}/`
        }
        this.errors = null
        method(url, data).then(response => {
          this.tools.$emit('commentRefresh', response.data.id)
          this.close()
        }).catch(error => {
          if (error.response.status === 400) {
            this.errors = error.response.data
          } else {
            message.error(error.message)
          }
        })
      },
      close () {
        $(this.$el).modal('hide')
      },
      loadFormEditor () {
        this.loadPreferences()
        $(this.$el).modal('show')
        if (!this.commentId) {
          Vue.nextTick(() => {
            this.initHtmlEditor()
          });
        } else {
          this.$restAPI.get(`/comments/${this.commentId}/`)
            .then(response => {
              this.origin = response.data.origin
              this.type = response.data.type
              this.showOrigin = response.data.show_origin
              this.state = response.data.state
              this.attachments = response.data.attachments
              this.content = response.data.content
              this.reference = `${response.data.line_ref}.${response.data.number}`
              Vue.nextTick(() => {
                this.initHtmlEditor()
              })
            })
            .catch(error => {
              message.error(error.message)
            })
        }
      },
      initHtmlEditor () {

        let $dropzone = document.querySelector('.comment-attachments.dropzone')
        let vm = this
        // Overwrite confirm method to display a messageBox instead of the simple javascript modal.
        Dropzone.confirm = function (question, accepted, rejected) {
		      messageBox(vm.$gettext('Confirmation'), question, {'Y': vm.$gettext('Yes'), 'N': vm.$gettext('No')}).then( action => {
            if (action === 'Y') {
              return accepted()
            } else {
              return rejected()
            }
          })
        }
        this.$dropzone = new Dropzone($dropzone, {
          url: this.$restAPI.defaults.baseURL + 'upload/',
          params: { 'uuid': this.commentId },
          addRemoveLinks: true,
          createImageThumbnails: false,
          dictDefaultMessage: this.$gettext('Drop files here or click to upload.'),
          dictRemoveFile: this.$gettext('Remove'),
          dictRemoveFileConfirmation: this.$gettext('Are you sure you want to remove this file?'),  // Message to display in the confirm modal.
          headers: { 'X-CSRFToken': document.querySelector('meta[name="csrf-token"]').getAttribute('content') },
          init: function () {
            let $serverFiles = this.element.querySelector('.dz-server-files')
            let $files = $serverFiles.querySelectorAll('a')
            $files.forEach(item => {
              let mockFile = { name: item.textContent, size: item.getAttribute('data-size'), id: item.getAttribute('data-id'), url: item.href, contentType: item.getAttribute('data-content-type') }
              this.emit('addedfile', mockFile)
              if (this.options.createImageThumbnails && mockFile.contentType.match(/image.*/)) {
                this.emit('thumbnail', mockFile, mockFile.url)
              }
              this.emit('complete', mockFile)
            })
            //$serverFiles.remove()

            this.on('success', function(file, serverResponse) {
              file.id = serverResponse.uuid
              file.url = vm.$restAPI.defaults.baseURL + `comments/download/${file.id}/`
              let $el = file.previewElement.querySelector('a[data-dz-name]')
              $el.setAttribute('href', file.url)
              $el.setAttribute('data-id', file.id)
              vm.attachments.push({ filename: file.name, url: file.url, uuid: file.id })
            })
          },
          addedfile: function (file) {
            // if (this.element === this.previewsContainer) {
            //   this.element.classList.add('dz-started')
            // }

            if (this.previewsContainer) {
              file.previewElement = Dropzone.createElement(this.options.previewTemplate.trim())
              file.previewTemplate = file.previewElement // Backwards compatibility

              this.previewsContainer.appendChild(file.previewElement)
              for (let node of file.previewElement.querySelectorAll('[data-dz-name]')) {
                node.draggable = false
                node.textContent = file.name
                node.href = file.url
                node.dataset.id = file.id
                node.title = file.name
              }
              for (let node of file.previewElement.querySelectorAll('[data-dz-size]')) {
                node.innerHTML = this.filesize(file.size)
              }

              if (this.options.addRemoveLinks) {
                file._removeLink = Dropzone.createElement(`<a class="dz-remove" data-dz-remove>${this.options.dictRemoveFile}</a>`)
                file.previewElement.appendChild(file._removeLink)
              }

              let removeFile = f => {
                vm.attachments = vm.attachments.filter(e => e.uuid !== f.id)
                return this.removeFile(f)
              }

              let removeFileEvent = e => {
                e.preventDefault()
                e.stopPropagation()
                if (file.status === Dropzone.UPLOADING) {
                  return Dropzone.confirm(this.options.dictCancelUploadConfirmation, () => this.removeFile(file))
                } else {
                  if (this.options.dictRemoveFileConfirmation) {
                    return Dropzone.confirm(this.options.dictRemoveFileConfirmation, () => removeFile(file))
                  } else {
                    return removeFile(file)
                  }
                }
              }
              for (let removeLink of file.previewElement.querySelectorAll('[data-dz-remove]')) {
                removeLink.addEventListener('click', removeFileEvent)
              }
            }
          },
          previewTemplate: $dropzone.querySelector('.dz-template').innerHTML
        })
        let lang = document.querySelector('html').attributes['lang'].value
        let $summernote = $('#id_content')
        let apiBaseUrl = this.$restAPI.defaults.baseURL
        let apiUploadFile = this.$restAPI.post
        $summernote.summernote({
          modules: {
            ...$.summernote.options.modules,
            'fullscreen': DRTFullscreen
          },
          dialogsInBody: true,
          disableDragAndDrop: true,
          lang: `${lang.toLowerCase()}-${lang.toUpperCase()}`,
          placeholder: this.$gettext('Write your comment...'),
          toolbar: [
            ['style', ['bold', 'italic', 'underline', 'clear']],
            ['font', ['strikethrough', 'superscript', 'subscript']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table', 'link', 'picture']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['fullscreen']
          ],
          height: 300,
          disableResizeEditor: true,
          callbacks: {
            onImageUpload: function (files) {
              let data = new window.FormData()
              data.append('file', files[0])
              apiUploadFile('upload/', data).then(response => {
                $summernote.summernote('insertImage', apiBaseUrl + 'comments/download/' + response.data.uuid, function ($image) {
                  $image.attr('data-uuid', response.data.uuid)
                })
              })
            },
            onFocus: function () {
              $('body').addClass('modal-open')
            },
            onInit: function () {
              $summernote.summernote("code", vm.content);
            },
            onChange: function () {
              vm.content = $summernote.summernote('code');
            },
            onBlur: function () {
              vm.content = $summernote.summernote('code');
            }
          }
        })
        $('.note-statusbar').hide()
        $(this.$el).on('shown.bs.modal', () => {
          $summernote.summernote('focus')
        })
        $(this.$el).on('hide.bs.modal', () => {
          $summernote.summernote('disable')
        })
        $(this.$el).on('hidden.bs.modal', () => {
          this.$destroy()
        })
        this.doChangeType()
      },
      loadUserOrigins() {
        this.$root.$restAPI.get('/comments/user_origins/').then(response => {
          this.userOrigins = groupBy(response.data, 'category')
        })
      },
      loadTypes() {
        this.$root.$restAPI.get('/comments/types/').then(response => {
          this.types = response.data
        })
      }
    },
    mounted () {
      this.loadUserOrigins()
      this.loadTypes()
      this.loadFormEditor()
    }
  }

  export function openCommentEditor (root, lineRef, commentId = null) {
    let editor = document.getElementById('comment-editor')
    if (editor === null) {
      editor = document.createElement('div')
      let body = document.querySelector('body')
      body.appendChild(editor)
    }
    let tools = root
    new Vue({
      name: 'comment-editor',
      parent: root,
      components: { commentEditor },
      render: function (h) {
        return h('comment-editor', { props: { lineRef, commentId, tools } })
      },
      mounted () {
        $(this.$el).modal({ backdrop: 'static', show: false })
      }
    }).$mount(editor)
  }

  export default commentEditor
</script>

<style>
  .note-popover {
    z-index: 2100;
  }

  .modal-dialog.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100% !important;
    margin: 0;
  }

  .note-editable {
    -webkit-user-select: initial;
    user-select: initial;
  }
</style>

<style scoped>
  .modal {
    text-align: center;
    padding: 0 !important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    width: 840px;
  }

  .modal-header {
    background: #f5f5f5;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    height: 56px;
    margin: 0;
    overflow: hidden;
    padding: 15px 20px;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modal-header h2 {
    color: #333;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.5;
    margin: 0;
    overflow: hidden;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .modal-footer {
    background: #f5f5f5;
    box-sizing: border-box;
    border-top: 1px solid #ccc;
    clear: both;
    height: 51px;
    overflow: hidden;
    padding: 7px 10px;
    text-align: right;
    white-space: nowrap;
    width: 100%;
  }

  /*.modal-footer .btn {*/
  /*  background: #113850;*/
  /*  border-color: transparent;*/
  /*  color: #fff;*/
  /*  font-weight: bold;*/
  /*}*/

  /*.modal-footer a {*/
  /*  cursor: pointer;*/
  /*  font-size: 14px;*/
  /*  display: inline-block;*/
  /*  padding: 0 10px;*/
  /*  vertical-align: baseline;*/
  /*  color: #1b7eac;*/
  /*  line-height: 34px;*/
  /*}*/

  .modal-default-checkbox {
    margin-left: 8px;
    color: #444;
  }

  form label {
    word-wrap: break-word;
    color: #707070;
    font-weight: normal;
    font-size: 12px;
  }
</style>
