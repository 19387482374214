var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "title" }, [
        _c("i", { staticClass: "fa fa-history" }),
        _vm._v(" " + _vm._s(_vm.$gettext("My last-viewed documents"))),
      ]),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "ul",
            _vm._l(_vm.items, function (item) {
              return _c("li", { key: item.document_id }, [
                _c(
                  "a",
                  {
                    attrs: { href: item.url },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openItem(item, $event)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.title))]
                ),
              ])
            }),
            0
          )
        : _c("spinner"),
      _vm._v(" "),
      _vm.failed
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.$gettext("Loading failed.")) + " ("),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.load(true)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Retry")))]
            ),
            _vm._v(")"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }