<template>

  <table class="table table-condensed table-striped table-hover">
    <thead>
      <slot name="thead"></slot>
    </thead>
    <tbody @scroll="handleElementScroll" :style="{'height': bodyHeight + 'px' }">
      <slot name="tbody"></slot>
    </tbody>
  </table>

</template>

<script>
  export default {
    name: 'infinite-table',
    props: {
      loading: { type: Boolean },
      offset: { type: Number, default: 20 },
      onTopScrollsToBottom: { type: Boolean, default: true },
      bodyHeight: { type: Number, default: 100 }
    },
    data () {
      return {
        target: null,
        scrollPosition: this.offset,
        lastDirection: ''
      }
    },
    methods: {
      handleElementScroll: function (e) {
        if (!this.target) this.target = e.target
        let currentScrollPosition = e.target.scrollTop

        if (currentScrollPosition > this.scrollPosition && currentScrollPosition + e.target.offsetHeight >= e.target.scrollHeight - this.offset) {
          this.emitEvents('bottom')
        } else if (currentScrollPosition < this.scrollPosition && currentScrollPosition <= this.offset) {
          this.emitEvents('top')
        }
        this.scrollPosition = currentScrollPosition
      },
      emitEvents (name) {
        if (!this.loading) {
          this.lastDirection = name
          this.$emit(name)
        }
      }
    },
    watch: {
      loading () {
        this.$nextTick(() => {
          if (this.onTopScrollsToBottom && !this.loading && this.lastDirection === 'top') {
            this.target.scrollTop = this.target.scrollHeight - this.target.offsetHeight - this.offset - 2
          }
          if (!this.loading && this.lastDirection === 'bottom') {
            this.target.scrollTop = this.offset + 2
          }
        })
      }
    },
    mounted () {
      // this.$el.querySelector('tbody').scrollTop = this.offset + 2
    }
  }
</script>

<style scoped>

thead>tr {
  position: relative;
  display: block;
}

tbody {
  display: block;
  overflow: auto;
}
</style>
