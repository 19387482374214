import $ from "jquery"

let elMore = $('.more-link')
let elLess = $('.less-link')

if (elLess.length > 0) {
  elLess.click(function (event) {
    event.preventDefault() /* prevent the a from changing the url */
    $('.more-text').css('display', 'none') /* hide the .more-text span */
    $('.more-link').css('display', 'inline-block')
    $('.less-link').css('display', 'none')
  })
}

if (elMore.length > 0) {
  elMore.click(function (event) {
    event.preventDefault() /* prevent the a from changing the url */
    $('.more-text').css('display', 'block') /* show the .more-text span */
    $('.more-link').css('display', 'none')
    $('.less-link').css('display', 'block')
  })
}
