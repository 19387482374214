<template>
  <panel class="result" :title="title" icon-class="fa fa-book" ref="result">
    <vue-tags-input class="result-tags"
                    v-model="tag" :allow-edit-tags="true"
                    :validation="validation"
                    :tags="tags"
                    :placeholder="$gettext('Add criteria')"
                    @tags-changed="tagChanged"
                    @before-deleting-tag="doBeforeDeletingTag"
                    @before-editing-tag="doBeforeEditingTag"
                    @before-saving-tag="doBeforeSavingTag"
                    @before-adding-tag="doBeforeAddingTag" v-focus>
      <template #tag-left="props" class="my-tag-left">
        <span v-if="props.tag.selection" class="glyphicon glyphicon-check"></span>
        <span class="glyphicon glyphicon-search" v-else-if="props.tag.query"></span><span v-if="props.tag.mode == 2"> {{ $gettext('Phrase prefix')|upper }}: </span>
        <span v-else-if="props.tag.filter"><template v-if="props.tag.label">{{ props.tag.label }}: </template></span>
      </template>
    </vue-tags-input>
    <div v-if="!this.error && notFound" class="alert alert-info" role="alert">
      {{$gettext('No result found.')}}
    </div>
    <div v-if="this.error" class="alert alert-warning" role="alert">
      {{this.error}}
    </div>
    <div class="row">
      <div v-show="!notFound && !error && params.size" class="result-count col-sm-2">
        <span><strong>{{ ((params.page - 1) * params.size) + 1 }}</strong> - <strong>{{ Math.min(((params.page - 1) * params.size) + params.size, total) }}</strong> {{ $gettext('of') }} <strong>{{ total }}</strong> <span class="result-refresh-action hidden-print" @click="refreshResults()" :title="$gettext('Refresh')"><span class="glyphicon glyphicon-refresh"></span></span></span>
      </div>
      <div v-show="!notFound && !error" class="col-sm-10 col-xs-12 result-toolbar hidden-print">
        <select class="form-control result-page-size input-sm custom-control"
                :title="$gettext('Rows per page')"
                v-model="perPage">
          <option v-for="size in page_sizes" :value="size" :key="size">{{ getPageSizeCaption(size) }}</option>
        </select>
        <select v-show="!isCommentOverview" class="form-control input-sm custom-control" v-model="excerpts">
          <option value="no">{{$gettext('No excerpts')}}</option>
          <option value="short">{{$gettext('Short excerpts')}}</option>
          <option value="medium">{{$gettext('Medium excerpts')}}</option>
          <option value="long">{{$gettext('Long excerpts')}}</option>
          <option value="all">{{$gettext('All excerpts')}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="table-responsive">
        <table v-if="items.length > 0" class="table table-condensed">
          <thead>
            <tr>
              <th class="result-checkbox"><input @click='selectAll()' type="checkbox" v-model="isAllSelected"/></th>
              <th class="result-content"><a @click.prevent="changeOrdering('title')">{{$gettext('Title')}}&nbsp;<i v-if="sortedColumn==='title'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a> / <a @click.prevent="changeOrdering('monograph_number')">{{$gettext('Monograph number(s)')}}&nbsp;<i v-if="sortedColumn==='monograph_number'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a> / <a @click.prevent="changeOrdering('reference')">{{$gettext('Reference')}}&nbsp;<i v-if="sortedColumn==='reference'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a></th>
              <th v-if="isCommentOverview" class="result-number">{{$gettext('Comments')}}</th>
              <th v-if="isCommentOverview" class="result-number">{{$gettext('Files')}}</th>
              <th v-if="isCommentOverview" class="result-number">{{$gettext('My comments')}}</th>
              <th v-if="isCommentOverview" class="result-number">{{$gettext('My files')}}</th>
              <th v-if="isCommentOverview" class="result-date">{{$gettext('Last comment')}}</th>
              <th class="result-date"><a @click.prevent="changeOrdering('posting_date')">{{$gettext('Date posted')}}&nbsp;<i v-if="sortedColumn==='posting_date'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a></th>
              <th class="result-date result-deadline"><a @click.prevent="changeOrdering('comment_deadline')">{{$gettext('Commenting deadline')}}&nbsp;<i v-if="sortedColumn==='comment_deadline'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a></th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in items" :key="index" class="result-item">
          <tr>
            <td class="result-checkbox"><input :checked="itemSelected(item)" @click.stop="selectItem(item, $event)" type="checkbox"/></td>
            <td class="result-content">
              <div class="result-item-title">
                <i class="fa" :class="getFileIcon(item)"></i><span class="result-item-title-text"><a :href="item.url" v-html="item.title" @click.stop="openItem(item, index, $event)"></a></span>
                <small class="result-score" v-if="false">{{ item.score }}</small>
              </div>
              <div class="result-item-subtitle">
                <span class="badge badge-light" v-for="num in item.monograph_numbers">{{ num }}</span><span class="result-item-reference">{{ item.reference }}</span><span v-if="item.is_restricted" class="badge badge-restricted">Restricted</span>
              </div>
            </td>
            <td v-if="isCommentOverview" class="result-number">{{ item.comment_overview && item.comment_overview.comments }}</td>
            <td v-if="isCommentOverview" class="result-number">{{ item.comment_overview && item.comment_overview.attachments }}</td>
            <td v-if="isCommentOverview" class="result-number">{{ item.comment_overview && item.comment_overview.user_comments }}</td>
            <td v-if="isCommentOverview" class="result-number">{{ item.comment_overview && item.comment_overview.user_attachments }}</td>
            <td v-if="isCommentOverview" class="result-date">{{ item.comment_overview && item.comment_overview.last_updated|date }}</td>
            <td class="result-date">{{ item.posting_date|date }}</td>
            <td class="result-date result-deadline deadline" v-html="htmlDeadline(item.comment_deadline)"></td>
          </tr>
          <tr v-if="!isCommentOverview" class="result-item-extra">
            <td class="result-checkbox"></td>
            <td :colspan="isCommentOverview ? 8 : 3">
              <div class="result-item-extra-content">
                <ul class="result-item-folders">
                  <li v-for="folder in item.folders" class="result-item-folder"><a :href="folder.url + '?select=' + item.id" :title="folder.path">{{folder.path.slice(1)}}</a></li>
                </ul>
                <ul class="result-item-meetings">
                  <li v-for="meeting in getSortedMeetings(item)" class="result-item-meeting" :data-id="meeting.id" :data-url="meeting.url">{{meeting.date|date}}: <span class="meeting-title">{{meeting.title}}</span></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr class="row-preview" v-if="item.highlight['attachment.content']">
            <td class="result-checkbox"></td>
            <td class="result-highlight" :colspan="isCommentOverview ? 8 : 3">
              <div class="result-preview" v-if="item.highlight['attachment.content'] && !Array.isArray(item.highlight['attachment.content'])">
                <p v-html="item.highlight['attachment.content']"></p>
              </div>
              <div class="result-preview" v-if="item.highlight['attachment.content'] && Array.isArray(item.highlight['attachment.content']) && item.highlight['attachment.content'].length > 0">
                <div :key="index" v-for="(content, index) in item.highlight['attachment.content']">
                  <template v-if="content === '...'">{{ $gettext('and more...') }}</template>
                  <p v-else v-html="content"></p>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="loading" v-if="isLoading">

        </div>
        </div>
      </div>
    </div>
    <paginator v-if="numPages > 1" :params="params" :num_page="numPages" :callback="paginationChanged"></paginator>

    <template #menu>
      <li><a href="#" @click.prevent="saveQuery()">{{ $gettext('Save query as...') }}</a></li>
      <li><a href="#" @click="exportPage()">{{ $gettext('Export results...') }}</a></li>
      <li><a href="#" @click="printDocuments()">{{ $gettext('Print documents...') }}</a></li>
      <li><a href="#" @click="downloadDocuments()">{{ $gettext('Download documents...') }}</a></li>
      <li><a href="#" @click="getLink()">{{ $gettext('Get link...') }}</a></li>
    </template>
    <template #actions>
      <router-link :to="{ name: 'home' }" :title="$gettext('Search')"><i class="fa fa-search"></i></router-link>
    </template>
  </panel>
</template>

<script>
  import Paginator from './components/paginator'
  import Panel from './components/panel'
  import { createTag, VueTagsInput } from '@johmun/vue-tags-input'
  import message from 'toastr'
  import {inputBox, inputRadiosBox, messageBox} from './utils/dialogs'
  import { $pgettext } from './helpers/i18n'
  import { getFontAwesomeIconFromMIME } from './utils/mimes'

  const commandRegex = /^:(\w+)\s?(.*)$/i
  const UNLIMITED = 99999
  const UNLIMITED_DATE = new Date(2999, 0 ,1, 0, 0, 0, 0)

  export default {
    name: 'result.vue',
    components: { VueTagsInput, Panel, Paginator },
    props: {
      page_sizes: {
        type: Array,
        default: () => [50, 100, 250, 500]
      },
      url: {
        type: String,
        default: '/documents/search'
      },
      title: {
        type: String,
        default: $pgettext('Default result title', 'Results')
      }
    },
    filters: {
      date: function (str, empty='--', unlimited='') {
        if (!str) { return empty }
        let dt = new Date(str)
        if (dt >= UNLIMITED_DATE) return unlimited
        return ((dt.getDate() < 10) ? '0' : '') + dt.getDate() + '/' + ((dt.getMonth() < 9) ? '0' : '') + (dt.getMonth() + 1) + '/' + dt.getFullYear()
      },
      none: function (str) {
        if (!str) { return '-'}
        return str
      }
    },
    data: function () {
      return {
        items: [],
        groupCodes: {},
        perPage: 50,
        numPages: 1,
        total: 0,
        params: { page: 1, size: this.perPage },
        tag: '',
        tags: [],
        currentIndex: -1,
        notFound: false,
        validation: [],
        excerpts: 'medium',
        sortedColumn: null,
        sortedOrder: 'asc',
        selection: new Set(),
        isAllSelected: false,
        error: null,
        isLoading: false,
        direction: null
      }
    },
    computed: {
      isCommentOverview () {
        return this.$route.query.overview
      }
    },
    watch: {
      '$route.query': function () {
        this.doQueryChanged()
      },
      perPage () {
        this.params.page = 1
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, ...{ size: this.perPage } } }).catch(()=>{})
      },
      excerpts () {
        this.$router.replace({ name: this.$route.name, query: { ...this.$route.query, ...{ excerpts: this.excerpts } } }).catch(()=>{})
      }
    },
    methods: {
      getSortedMeetings(item) {
        return [...item.meetings].sort((a, b) => new Date(a.date) - new Date(b.date))
      },
      selectItem(item, event) {
        if (event.target.checked) {
          this.selection.add(item.id)
        } else {
          this.selection.delete(item.id)
        }
        this.isAllSelected = (this.selection.size === this.items.length)
        this.$forceUpdate()
      },
      itemSelected(item) {
        return this.selection.has(item.id)
      },
      selectAll () {
        this.isAllSelected = !this.isAllSelected
        let selection = this.selection
        if (this.isAllSelected) {
          this.items.forEach(function (item) {
            selection.add(item.id)
          })
        } else {
          this.items.forEach(function (item) {
            selection.delete(item.id)
          })
        }
        this.selection = selection
        this.$forceUpdate()
      },
      htmlDeadline: function (str) {
        if (!str) { return '<span class="deadline-nocomment">--</span>'}
        let dt = new Date(str)
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        if (dt >= UNLIMITED_DATE) {
          str = ''
        } else {
          str = ((dt.getDate() < 10) ? '0' : '') + dt.getDate() + '/' + ((dt.getMonth() < 9) ? '0' : '') + (dt.getMonth() + 1) + '/' + dt.getFullYear()
        }
        let cl = 'deadline-open'
        let title = this.$gettext('Open for comments')
        if (dt < today) {
          cl = 'deadline-closed'
          title =  this.$gettext('Closed for comments')
        }
        return `<span class="${cl}" title="${title}">${str}</span>`
      },
      changeOrdering (columnName) {
        if (this.sortedColumn === columnName) {
          this.sortedOrder = this.sortedOrder === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortedColumn = columnName
          this.sortedOrder = 'asc'
        }
        let ordering = (this.sortedOrder === 'desc' ? '-' : '') + this.sortedColumn
        this.params.page = 1
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, ...{ ordering }, ...this.params }}).catch(()=>{})
      },
      getPageSizeCaption (size) {
        return size < UNLIMITED ? `${size} ` + this.$gettext('rows') : this.$gettext('Unlimited')
      },
      paginationChanged () {
        this.tag = ''
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, ...this.params } }).catch(()=>{})
      },
      getFileIcon (item) {
        return getFontAwesomeIconFromMIME(item.content_type)
      },
      doQueryChanged () {
        if (this.direction === 'previous') {
          // Load previous page on open last result
          this.load(false, 'last')
        } else if (this.direction === 'next') {
          // Load next page on open first result
          this.load(false, 'first')
        } else {
          // Refresh
          this.load(true)
        }
        this.direction = null
      },
      load (refresh = false, open=null) {
        this.isLoading = true
        this.notFound = false
        this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.title }]
        this.queryToTags(this.$route.query)
        this.error = null
        if (!refresh || this.$root.viewerIsClosed()) {
          this.$restAPI.get(`${this.url}/`, { params: this.$route.query }).then(response => {
            if (response.data.parameters && response.data.parameters['groups']) {
              this.groupCodes = response.data.parameters['groups']
            }
            this.updateTags(this.$route.query)
            this.items = response.data.results
            if (this.items.length === 0) {
              this.notFound = true
            }
            this.numPages = response.data.num_pages
            this.total = response.data.count
            if (this.$route.query.size) {
              this.params.size = parseInt(this.$route.query.size)
            } else {
              this.params.size = this.perPage
            }
            this.params.page = response.data.page
            this.$root.loadDocumentItems(this.items.map(item => item.id), response.data.count, this.params.size * (response.data.page - 1), open)
          }).catch(error => {
            this.error = error.response.data.detail
          }).then(() => {
            this.isLoading = false;
          })
        }
      },
      refreshResults () {
        this.load(true)
      },
      tagChanged (newTags) {
        if (newTags.length === 0) {
          this.$root.clearFilters()
          this.$router.push({ name: 'home', query: {} })
        }
        this.tags = newTags
      },
      doBeforeDeletingTag (item) {
        let query = {}
        if (item.tag.query) {
          query['q'] = undefined
        }
        if (item.tag.filter) {
          query[item.tag.name] = undefined
          if (item.tag.name === 'posted') {
            this.$root.checkPostedValues(query['posted'])
          }
          if (item.tag.name === 'forComment') {
            this.$root.checkForCommentValues(query['forComment'])
          }
        }
        query['page'] = 1
        // if (item.tag.selection) {
        //   query['s'] = undefined
        // }
        // if (item.tag.chapter) {
        //   query['chapter'] = undefined
        // }
        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {
          item.deleteTag()
        })
      },
      doBeforeEditingTag (item) {
        if (!item.tag.readonly) {
          item.editTag()
        }
      },
      doBeforeSavingTag (item) {
        this.updateQueryFromTag(item.tag)
        item.saveTag()
      },
      doBeforeAddingTag (item) {
        let value = item.tag.text.trim()
        let matches = commandRegex.exec(value)
        let continueProcess = true
        let searchReplace = false

        if (matches) {
          continueProcess = false
          let command = matches[1].toLowerCase()
          let args = matches[2]
          if (command === 'selection') {
            let selections = this.$root.treeview.getCheckedNodes('id')
            if (selections.length > 0) {
              item.tag.text = this.$gettext('FOR SELECTION')
              item.tag.selection = true
              item.tag.classes = 'ti-selection'
              item.tag.readonly = true
              item.addTag()
              this.updateQueryFromTag(item.tag)
            } else {
              this.tag = ''
              message.info(`${this.$gettext('No items selected in the table of contents')}`)
            }
          } else if (command === 'search') {
            value = args
            continueProcess = true
            searchReplace = true
          } else if (command === 'query') {
            // TODO: this.manageQuery()
            this.tag = ''
          } else if (command === 'save') {
            this.saveQuery(args)
            this.tag = ''
          } else if (command === 'help') {
            // TODO: this.openAdvancedSearchInfo()
            this.tag = ''
          } else {
            this.tag = ''
            message.warning(`${command}: ${this.$gettext('"Unknown command')}`)
          }
        }
        if (continueProcess) {
          let tagQuery = this.tags.find(item => item.query === true)
          if (!tagQuery) {
            item.tag.text = value
            item.tag.query = true
            item.tag.classes = 'ti-query'
            item.addTag()
            this.updateQueryFromTag(item.tag)
          } else {
            if (searchReplace) {
              tagQuery.text = value
            } else {
              tagQuery.text += ' ' + value
            }
            this.updateQueryFromTag(tagQuery)
          }

          this.tag = ''
        }
      },
      updateQueryFromTag (tag) {
        let query = {}
        if (tag.query) {
          query.q = tag.text
        }
        if (tag.filter) {
          query[tag.name] = tag.text
        }
        query['page'] = 1
        // if (tag.selection && this.$root.treeview) {
        //   let selection = this.$root.treeview.getCheckedNodes('id').join(',')
        //   if (selection) query.s = selection
        // }
        // this.searchMode = tag.selection ? 'selection' : 'all'

        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {})
      },
      updateTags (query, currentPath = null) {
        if (query.accessGroup) {
          let label = this.$gettext('Access group')
          let accessGroupValues = query.accessGroup.split(',')
          accessGroupValues = accessGroupValues.map(item => this.groupCodes[item]).join(', ')
          this.tags.push(createTag({text: accessGroupValues, name: 'accessGroup', label, filter: true, readonly: true, classes: 'ti-filter'}, []))
        }
        if (query.authorGroup) {
          let label = this.$gettext('Author group')
          let authorGroupValues = query.authorGroup.split(',')
          authorGroupValues = authorGroupValues.map(item => this.groupCodes[item]).join(', ')
          this.tags.push(createTag({text: authorGroupValues, name: 'authorGroup', label, filter: true, readonly: true, classes: 'ti-filter'}, []))
        }
        // if (query.posted) {
        //   this.$root.checkPostedValues(query['posted'])
        // }
        // if (query.forComment) {
        //   this.$root.checkForCommentValues(query['forComment'])
        // }
      },
      queryToTags (query, currentPath = null) {
        this.tags = []
        // if (query.chapter) {
        //   let text = this.$gettext('SELECTED CHAPTER')
        //   if (currentPath) {
        //     text = this.$root.treeview.getPathname(currentPath)
        //   }
        //   this.tags.push(createTag({text: text, chapter: true, readonly: true, classes: 'ti-selection'}, []))
        // }
        if (query.q) {
          this.tags.push(createTag({ text: query.q, query: true, classes: 'ti-query', mode: parseInt(query.qm || '1') }, []))
        }
        if (query.title) {
          this.tags.push(createTag({ text: query.title, name: 'title', label: 'Title', filter: true, readonly: true, classes: 'ti-filter' }, []))
        }
        if (query.monoNumbers) {
          let label = this.$gettext('Mono/GM number')
          let monoNumbers = query.monoNumbers.split(',').join(', ')
          this.tags.push(createTag({ text: monoNumbers, name: 'monoNumbers', label, filter: true, readonly: true,classes: 'ti-filter' }, []))
        }
        if (query.posted) {
          let postedValues = query.posted.split(',')
          postedValues = postedValues.map(item=>item.length > 4 ? item.slice(-2) + '/' + item.slice(0,4) : item)
          postedValues = postedValues.join(', ')
          let label = this.$gettext('Posted')
          this.tags.push(createTag({ text: postedValues, name: 'posted', label, filter: true, readonly: true, classes: 'ti-filter' }, []))
        }
        if (query.reference) {
          let referenceValues = query.reference.split(',')
          referenceValues = referenceValues.map(item=>'"' + item + '"').join(', ')
          let label = this.$gettext('PA/PH reference')
          this.tags.push(createTag({ text: referenceValues, name: 'reference', label, filter: true, readonly: true, classes: 'ti-filter' }, []))
        }
        if (query.tags) {
          let tagsValues = query.tags.split(',')
          tagsValues = tagsValues.map(item=>'"' + item + '"').join(', ')
          let label = this.$gettext('Keywords/section')
          this.tags.push(createTag({ text: tagsValues, name: 'tags', label, filter: true, readonly: true, classes: 'ti-filter' }, []))
        }
        if (query.allRestricted) {
          this.tags.push(createTag({ text: this.$gettext('All restricted'), name: 'allRestricted', filter: true, readonly: true, classes: 'ti-option' }, []))
        }

        if (!query.meetingFor || !query.meetings) {
          if (query.meetingFor && !query.meetingFor.includes(',')) {
            let value = query.meetingFor.length > 4 ? query.meetingFor.slice(-2) + '/' + query.meetingFor.slice(0,4) : query.meetingFor
            this.tags.push(createTag({ text: value, name: 'meetings', label: 'Meetings', filter: true, classes: 'ti-filter' }, []))
          }
          if (query.meetings && !String(query.meetings).includes(',')) {
            this.tags.push(createTag({ text: query._display || query.meetings, name: 'meetings', label: 'Meetings', filter: true, classes: 'ti-filter' }, []))
          }
        }
        if (query.all === '1') {
          this.tags.push(createTag({ text: 'ALL', name: 'all', label: 'Documents', filter: true, classes: 'ti-filter' }, []))
        }
        if (query.forComment) {
          let forCommentValues = query.forComment.split(',')
          let labels = {
            open: this.$gettext('Yes, still open'),
            closed: this.$gettext('Yes, previously open'),
            no: this.$gettext('No')
          }
          // this.tags.push(createTag({ text: value, name: 'forComment', label: 'For comment', filter: true, classes: 'ti-filter' }, []))
          forCommentValues = forCommentValues.map(item=>labels[item]).join(', ')
          let label = this.$gettext('For comment')
          this.tags.push(createTag({ text: forCommentValues, name: 'forComment', label, filter: true, readonly: true, classes: 'ti-filter' }, []))
        }
      },
      saveQuery (defaultName = '') {
        inputBox(this.$gettext('Save query'), this.$gettext('Enter a name for this query.'), [this.$gettext('Save'), this.$gettext('Cancel')], defaultName, (action, value) => {
          return new Promise((resolve, reject) => {
            if (action === 'ok') {
              let data = {
                'name': value,
                'data': this.$route.query
              }
              this.$restAPI.post('/user/query/', data, { headers: { 'content-type': 'application/json' } }).then(response => {
                if (response.status === 200) {
                  resolve(response.data)
                } else {
                  reject({ message: response.data, close: response.status !== 400 })
                }
              }).catch(error => {
                reject({ data: error.response, close: true })
              })
            } else {
              resolve(null)
            }
          })
        }).then(response => {
          if (response.action === 'ok') {
            message.success(this.$gettext('Query saved'))
          }
        }).catch(error => {
          message.error(error.statusText)
        })
      },
      openItem (item, index, e) {
        this.$root.openDocument(item.id)
        e.preventDefault()
      },
      exportPage () {
        let data = {'ids': this.items.map(item => item.id), 'comment_overview': !!this.isCommentOverview}
        let filename = "results.xlsx"
        let successText = this.$gettext('Export results downloaded')
        const config = {responseType: 'blob'}
        this.$http.post('/export/documents/xlsx/', data, config).then(response => {
          if (response && response.status === 200) {
            let blobUrl = URL.createObjectURL(response.data);
            let link = document.createElement("a")
            link.href = blobUrl
            link.download = filename
            document.body.appendChild(link)
            link.click()
            link.remove()
            message.success(successText)
          }
        }).catch(error => {
          message.error(error.status)
        })
      },
      printDocuments () {
        this.uploadDocuments(
          '/export/documents/pdf/', 'documents.pdf', 'application/pdf',
          this.$gettext('Download PDF with documents to print'),
          this.$gettext('PDF document downloaded'),
          this.$gettext('Only PDF and HTML documents are processed'))
      },
      downloadDocuments () {
        this.uploadDocuments(
          '/export/documents/download/', 'documents.zip', 'application/zip',
          this.$gettext('Download archive with documents'), this.$gettext('Archive downloaded'))
      },
      uploadDocuments(url, filename, mime_type, title = '', successText = 'Operation succeeded', helpText = '') {
        let selection = [...this.items].filter(item => this.itemSelected(item)).map(item => item.id)
        let warn_limit = this.$ngettext('(limited to 50 documents)')
        let radios = [
          { 'disabled': (selection.length===0), 'value': 'selection',
            'content': this.$gettext('download selected documents') + ' ' + warn_limit},
          { 'value': 'page', 'content': this.$gettext('download documents in current page')+ ' ' + warn_limit}
        ]
        inputRadiosBox(title, radios,
          helpText, [this.$gettext('Download'), this.$gettext('Cancel')], (action, value) => {
            return new Promise((resolve, reject) => {
              if (action === 'ok') {
                let data = {'ids': selection}
                if (value === 'page') {
                  data['ids'] = this.items.map(item => item.id)
                }
                const config = {responseType: 'blob'}
                this.$http.post(url, data, config).then(response => {
                  if (response.status === 200) {
                    resolve(response)
                  } else {
                    reject({message: response.data, close: response.status !== 400})
                  }
                }).catch(error => {
                  reject({data: error.response, close: true})
                })
              } else {
                resolve(null)
              }
            }).then(response => {
              if (response && response.status === 200) {
                if ( response.headers['x-message-no-file'] ) {
                  let errorText = this.$gettext('No uploaded file.')
                  if ( response.headers['x-message']) {
                    errorText = response.headers['x-message'] + '\n<br>\n<br>\n' + errorText
                  }
                  message.error(errorText)
                } else {
                  let blobUrl = URL.createObjectURL(response.data);
                  let link = document.createElement("a")
                  link.href = blobUrl
                  link.download = filename
                  document.body.appendChild(link)
                  link.click()
                  link.remove()
                  if ( response.headers['x-message']) {
                    successText = response.headers['x-message'] + '\n<br>\n<br>\n' + successText
                  }
                  message.success(successText)
                }
             }
            }).catch(error => {
              message.error(error.status)
            })
          })
      },
      getLink () {
        let btnText = this.$gettext('Copy to clipboard')
        let message = `<div class="input-group">
            <input type="text" id="cc-url" class="form-control" placeholder="url" value="${document.URL}" aria-describedby="basic-addon2">
            <span class="input-group-btn"><button id="clipboard-copy" class="btn btn-default"><i class="fa fa-copy"></i> ${btnText}</button></span>
          </div>`
        $(document).on('click', '#clipboard-copy', () => {
          $("#cc-url").select()
          document.execCommand("copy")
        })
        messageBox (this.$gettext('Get link'), message, ['Cancel'])
      }
    },
    beforeDestroy () {
      this.$root.clearDocumentItems()
      this.$root.$off('document_changed')
      this.$root.$off('document_deleted')
      this.$root.$off('folderFilterChecked')
      this.$root.$off('agendaFilterChecked')
      this.$root.$off('postedFilterChecked')
      this.$root.$off('commentFilterChecked')
      this.$root.$off('openFirstDocumentFromNextPage')
      this.$root.$off('openLastDocumentFromPreviousPage')
    },
    mounted () {
      this.load()
      this.$root.$on('document_changed', id => {
        this.refreshResults()
      })
      this.$root.$on('document_deleted', id => {
        this.refreshResults()
      })
      this.$root.$on('folderFilterChecked', node => {
        let treeFolderFilter = this.$root.treeFolderFilter.getCheckedNodes('id').join(',')
        let query = {'folders': treeFolderFilter, 'page': 1}
        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {})
      })
      this.$root.$on('agendaFilterChecked', node => {
        let treeAgendaFilter = this.$root.treeAgendaFilter.getCheckedNodes('data')
        let meetingFor = []
        let meetings = []
        let query = {}
        treeAgendaFilter.forEach(item => {
          if (item) {
            if (item.id) {
              meetings.push(item.id)
            } else {
              let value = String(item.year)
              if (item.month) value += String(item.month).padStart(2, "0");
              meetingFor.push(value)
            }
          }
        })
        query['meetingFor'] = meetingFor.join(',')
        query['meetings'] = meetings.join(',')
        query['page'] = 1

        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {})
      })
      this.$root.$on('postedFilterChecked', node => {
        let treePostedFilter = this.$root.treePostedFilter.getCheckedNodes('data')
        let postingDates = []

        treePostedFilter.forEach(item => {
          if (item) {
            let filter = String(item.year)
            if (item.month) filter += String(item.month).padStart(2, "0");
            postingDates.push(filter)
          }
        })
        let query = { 'posted': postingDates.join(','), 'page': 1 }
        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {})
      })
      this.$root.$on('commentFilterChecked', node => {
        let treeCommentFilter = this.$root.treeCommentFilter.getCheckedNodes('action')
        let query = { 'forComment': treeCommentFilter.join(','), 'page': 1}
        this.$router.push({ name: 'results', query: { ...this.$route.query, ...query } }, () => {})
      })
      this.$root.$on('openFirstDocumentFromNextPage', () => {
        this.params.page = this.params.page + 1
        this.direction = 'next'
      })
      this.$root.$on('openLastDocumentFromPreviousPage', () => {
        this.direction = 'previous'
        this.params.page = this.params.page - 1
      })
    }
  }
</script>

<style lang="scss">
  .result-tags {
    margin-bottom: 8px;

    .ti-tag:not(.ti-deletion-mark) {
      background-color: #123751 !important;
    }

    .ti-input {
      border: none !important;
      border-bottom: 1px solid #ccc !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ti-query:not(.ti-deletion-mark) {
      background-color: #1b7eac !important;
    }

    .ti-option:not(.ti-deletion-mark) {
      background-color: #ac491b !important;
    }

    .my-tag-left {
      cursor: default !important;
    }

    .ti-selection:not(.ti-deletion-mark) {
      background-color: green !important;
      cursor: default !important;
    }

    .ti-autocomplete {
      width: unset !important;
      color: white;
    }
  }

  @media print {
    .result-tags {
      /*.ti-input {*/
      /*border: none !important;*/
      /*}*/

      .ti-new-tag-input-wrapper,
      .ti-actions {
        display: none !important;
      }
    }
    .dropdown-menu {
      display: none !important;
    }
  }
</style>

<style lang="scss" scoped>

  .table > thead > tr > th {
    vertical-align: middle;
  }

  .table > tbody > tr > td {
    border: none;
  }

  .table tbody:nth-of-type(odd) {
    background-color: #f5f5f5;
  }

  .result-checkbox {
    vertical-align: middle;
    width: 1px;
  }

  .result-date {
    width: 35px;
    text-align: center;
    vertical-align: middle;
  }
  .result-number {
    width: 1px;
    text-align: center;
    vertical-align: middle;
  }

  .result {
    padding-bottom: 60px;
  }

  .result table {
    margin-top: 4px;
  }

  .result-header {
    margin-bottom: 36px;
  }

  .row-number {
    vertical-align: middle;
    width: 40px;
    color: #666;
    font-size: 0.8em;
    display: none;
  }

  .result-text-dots {
    margin-right: 20px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 4px;
    vertical-align: baseline;
    color: #113850;
  }

  .result-preview {
    font-size: 0.9em;
    text-align: justify;
    user-select: text;
    border-left: 2px solid rgba(220, 220, 220, 0.6);
    padding-left: 8px;
    padding-right: 8px;
    color: rgba(0, 0, 0, 0.5);

    & p::before,
    & p::after {
      content: '...';
      color: #aaa;
      margin-right: 4px;
      margin-left: 4px;
    }

    .more-excerpts {
      font-style: italic;
      color: #aaa;
      user-select: None;
      font-size: 0.9rem;
    }
  }

  .results-selection {
    font-size: 12px;
    margin-bottom: 12px;

    & > a {
      margin-right: 16px;
    }
  }

  .result-field {
    white-space: nowrap;
    display: inline-block;
    user-select: text;
  }

  .result h1 {
    font-size: 1.2em;
    margin-top: 8px;
    font-weight: bold;
    color: #21455c;
    display: inline-block;
    user-select: text;

    i {
      margin-right: 4px;
    }
  }

  .result-fields .result-field-label {
    padding-left: 16px;
    padding-right: 4px;
    font-weight: normal;
    font-style: italic;
    color: #666;
  }

  .result-field-content-label {
    padding-right: 4px;
    font-weight: normal;
    color: #aaa;
  }

  .result-fields {
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 4px;
    float: right;
  }

  .result-count {
    vertical-align: middle;
    line-height: 28px;
    font-size: 12px;
    white-space: nowrap;
  }

  .result-score {
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    color: #666;
    margin-left: 8px;
    margin-right: 8px;
  }

  .no-result {
    margin-top: 30px;
    font-size: 1.2em;
  }

  .result-page-size option[value=""][disabled] {
    display: none;
  }

  .search-section {
    margin-bottom: 10px;
    margin-top: 16px;
  }

  .search-buttons {
    margin-top: 30px;

    .btn {
      margin-right: 5px;
    }
  }

  .custom-control {
    width: auto;
    float: right;
    margin-left: 10px;
    margin-bottom: 4px;
    border-color: #ccc;
    background-color: #f9f9f9;
    box-shadow: none;
    border-radius: 2px;
  }

  .search-scope {
    margin-bottom: 20px;
  }

  .search-extra-fields {
    margin-top: 20px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAADCAYAAABS3WWCAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NEQ5RDgxQzc2RjQ5MTFFMjhEMUNENzFGRUMwRjhBRTciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NEQ5RDgxQzg2RjQ5MTFFMjhEMUNENzFGRUMwRjhBRTciPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0RDlEODFDNTZGNDkxMUUyOEQxQ0Q3MUZFQzBGOEFFNyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0RDlEODFDNjZGNDkxMUUyOEQxQ0Q3MUZFQzBGOEFFNyIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PvXFWFAAAAAYSURBVHjaYvj//z8D0/Pnz/8zgFgAAQYAS5UJscReGMIAAAAASUVORK5CYII=) repeat-x scroll 0 10px;
    font-size: 12px;
    line-height: 21px;
    opacity: 0.75;
    color: #888;

    .search-extra-fields-label {
      cursor: pointer;
      font-weight: bold;
      background-color: #fff;
      padding-right: 8px;
      text-transform: uppercase;

      &:hover {
        opacity: 1;
        color: #0b73cb;
      }
    }
  }

  .search-extra-fields-panel {
    margin-top: 8px;
  }

  .form-group label {
    opacity: 0.75;
    font-size: 0.85em;
  }

  .search-menu {
    margin-top: 25px;
    margin-bottom: 5px;
    float: right !important;
  }

  .shortcut {
    float: right !important;
    color: #666;
  }

  .custom-control-label {
    color: #888;
    line-height: 28px;
    font-size: 12px;
    float: right !important;
    margin-top: 0;
  }

  .help-label {
    display: inline-block;
    color: #888;
    font-size: 0.7em;
    padding-left: 8px;
    padding-right: 8px;
    font-style: italic;
    cursor: default;
  }

  .form-group {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
  }

  .btn.btn-tool {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 2px;
    margin-left: 4px;
    color: #64696e;
  }

  .result-sorting-buttons {
    white-space: nowrap;
    display: inline-block;
    float: right;
    overflow-y: hidden;
    height: 36px;
  }

  .search-full-text-bottom {
    font-size: 0.85em;
    margin-top: -8px;
    margin-bottom: 4px;
  }

  .search-full-text-bottom input[type=radio] {
    margin-top: 2px;
  }

  #id_search.search-string-active {
    background-color: #f6f6f6;
  }

  .has-value {
    color: #1b7eac;
  }

  .vue-tags-input.result-tags {
    max-width: 100%;
  }

  .my-tag-left {
    padding-right: 4px;
  }

  .result-item.result-item-active {
    background-color: #1b7eac14;
    border-left: 3px solid #1b7eac;
  }

  /*.result-item input[type="checkbox"] {*/
  /*  margin-right: 8px;*/
  /*  vertical-align: text-top;*/
  /*}*/

  .result-toolbar {
    .input-sm {
      height: 28px;
      line-height: 28px;
      padding: 4px;
    }

    .btn-sm {
      padding: 4px;
    }
  }

  .result-refresh-action {
    color: #888;
    margin-left: 8px;
    cursor: pointer;

    &:hover {
      color: #1b7eac;
    }
  }

  .posted-column {
    width: 80px;
    text-align: center;
  }

  .deadline-column {
    width: 80px;
    text-align: center;
  }

  .result-item-footer {
    padding: 4px 4px 4px 24px;
  }

  .result-item-subtitle {
    .badge {
      margin-right: 4px;

      border-radius: 4px;
      padding: 3px;
      font-size: 8px;
    }
    .badge-light {
      background-color: #aaa;
    }
    .badge-restricted {
      background-color: #a92a2b;
    }
    .result-item-reference {
      font-size: 11px;
      vertical-align: middle;
    }
    span {
      margin-right: 8px;
    }
  }

  .result-item-folder:before {
    content:"\f114";
    font-family: "FontAwesome";
    padding-right: 4px;
  }

  .result-item-meeting {

  }

  .meeting-title {
    color: #777;
  }

  .result-item-meeting:before {
    content:"\f133";
    font-family: "FontAwesome";
    padding-right: 4px;
  }
 .result-item-folders {
    flex: 2;
  }
  .result-item-meetings {
    flex: 1;
  }

  .result-item-extra {
    ul {
      margin: 0;
      list-style-type: none;
      padding-left: 8px;
      overflow-x: hidden;
      font-size: 0.93rem;
    }
    td {
      padding: 0;
    }
  }

  .result-item-extra-content {
    display: flex;
    flex: 66% 0;
    flex-wrap: wrap;
    white-space: nowrap;
  }
  .result-content {
    max-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .result-item-title {
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    display: inline;

    i {
      margin-right: 8px;
      font-weight: normal;
      color: #444;
    }
    .result-item-title-text {
      white-space: normal;
    }
    a {
      max-width: 150px;
      text-overflow: ellipsis;
    }
  }

  .result-count {
    vertical-align: middle;
    line-height: 28px;
    font-size: 12px;
    white-space: nowrap;
  }

  .result-toolbar .btn {
    float: right !important;
    background-color: #aaa;
    margin-left: 4px;
  }

  .result-toolbar {
    .input-sm {
      height: 28px;
      line-height: 28px;
      padding: 4px;
    }

    .btn-sm {
      padding: 4px;
    }
  }

  .result-sorting-buttons {
    white-space: nowrap;
    display: inline-block;
    float: right;
    overflow-y: hidden;
    height: 36px;
  }

  /* Temporary fix for EDQM Bootstrap, to be removed */
  ul.dropdown-menu {
    border: 1px solid #1b7eac;
  }

  a {
    cursor: pointer;
  }

  /* end fix */

  .loading {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 20vmin;
    background-image: url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 40 40">\
<style> /* ...YO DAWG... */ circle { animation: 4s a infinite linear, 3s o infinite linear; }\
@keyframes a {from{stroke-dasharray:100 0}50%25{stroke-dasharray:0 100}to{stroke-dasharray:100 0}}\
@keyframes o {from{stroke-dashoffset:75}to{stroke-dashoffset:375}}\
<%2Fstyle><circle r="15.9154943092" stroke-width="7" fill="none" stroke="rgb(192, 192, 192, 0.4)"/>\
</svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20vmin;
  }
</style>

<style lang="scss">

  .result {
    .deadline-open:after {
      content: "\F044";
      font-family: "FontAwesome";
      padding-left: 4px;
      // color: #777;
      font-size: 14px;
    }

    .deadline-closed {
      color: red;
    }

    .deadline-open {
      color: green;
    }

    .deadline-closed:after {
      content: "\F023";
      font-family: "FontAwesome";
      padding-left: 4px;
      // color: #777;
      font-size: 14px;
    }
  }
</style>
