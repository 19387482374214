<template>
    <div class="row display-flex hidden-xs">
      <div class="col-sm-3 footer-column">
        <teams></teams>
      </div>
      <div class="col-sm-6 separator-left footer-column">
        <recents></recents>
      </div>
      <div class="col-sm-3 separator-left footer-column">
        <links></links>
      </div>
    </div>
</template>

<script>
  import Teams from './components/teams'
  import Recents from './components/recents'
  import Links from './components/links'

  export default {
    name: 'footer',
    components: { Links, Recents, Teams }
  }
</script>

<style scoped>

  .footer-column {

  }

  .separator-left {
    border-left: solid 1px #ccc;
  }

</style>
