import Vue from 'vue'
import VueRouter from 'vue-router'

import Container from './container.vue'
import Home from './home.vue'
import Result from './result.vue'
import MessagePanel from './messagePanel.vue'
import NewDocumentWizard from './document-wizard.vue'
import { $pgettext } from './helpers/i18n'
import FolderView from './folders'
import CalendarView from './calendar'
import ProfileView from './profile'
import RecentDocument from './recentDocument'

Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  // base: document.querySelector('meta[name="base-url"]').getAttribute('content', '/app'),
  routes: [
    {
      name: 'profile',
      path: '/profile/',
      component: ProfileView,
    },
    {
      name: 'new-document',
      path: '/documents/new-document/',
      component: NewDocumentWizard,
    },
    // {
    //   name: 'view-document',
    //   path: '/documents/view/*',
    //   component: Viewer,
    // },
    {
      path: '/agendas/view/',
      component: CalendarView,
      name: 'view-agenda',
    },
    {
      name: 'app',
      path: '/',
      component: Container,
      children: [
        {
          path: '/home/',
          component: Home,
          name: 'home'
        },
        {
          path: '/search/',
          component: Result,
          name: 'results',
        },
        {
          path: '/folders/*/',
          component: FolderView,
          name: 'folders',
        },
        {
          path: '/documents/recent/',
          component: RecentDocument,
          name: 'recent',
        },
        {
          path: '/documents/favourite/',
          component: MessagePanel,
          props: { url: '/user/favorites/', title: $pgettext('breadcrumb', 'My favorite documents') },
          name: 'favorite',
        }
      ]
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return new Promise(resolve => {
        this.app.$root.$once('triggerScroll', () => {
          resolve(savedPosition)
        })
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
