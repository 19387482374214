<template>
  <div class="extranet-agenda-panel">
    <panel :title="$gettext('My forthcoming events')" icon-class="fa fa-calendar">
      <div v-if="feedLinkVisible" class="feed-link"><input :value="feedLinkURL" readonly>
        <button class="btn btn-default" @click="showFeedLink(false)"><i class="glyphicon glyphicon-remove"></i></button>
      </div>
      <div class="agenda-content">
        <div class="degrad-white-50"></div>
        <div class="scroll-container">
          <div v-if="Object.keys(this.meetings).length === 0" class="no-item">{{$gettext('No events')}}</div>
          <timeline :data="meetings" @click="openAgenda"></timeline>
        </div>
        <router-link :to="{ name: 'view-agenda' }" class="pull-right agenda-show-link">{{$gettext('My calendar')}} <i class="fa fa-arrow-circle-right"></i></router-link>
        <div class="clearfix"></div>
      </div>
      <template #menu>
        <li>
          <router-link :to="{ name: 'view-agenda' }"><i class="fa fa-calendar"></i> {{$gettext('My calendar')}}</router-link>
        </li>
<!--        <li role="separator" class="divider"></li>-->
<!--        <li><a href="" @click.prevent="showFeedLink(true)"><i class="fa fa-rss"></i> {{$gettext('Share link (ics) ...')}}</a></li>-->
        <li role="separator" class="divider"></li>
        <li><a href="#" @click.prevent="loadData()"><i class="fa fa-refresh"></i> {{ $gettext('Refresh') }}</a></li>
      </template>
    </panel>
  </div>
</template>

<script>
  import Vue from 'vue';
  import Timeline from './timeline'
  import Panel from './panel'
  import 'malihu-custom-scrollbar-plugin'
  import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
  import $ from 'jquery'
  import {openAgendaDialog} from './agenda-dialog'

  export default {
    name: 'agenda',
    components: { Panel, Timeline },
    data: function () {
      return {
        feedLinkVisible: false,
        meetings: {},
        loading: false,
      }
    },
    computed: {
      feedLinkURL () {
        let baseURL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
        return baseURL + '/feeds/agenda.ics'
      },
    },
    methods: {
      openAgenda (item) {
        openAgendaDialog(this, item)
      },
      loadData () {
        return this.$http.get('/agenda/next/').then(response => {
          this.meetings = response.data
        }).catch(error => {
          console.error(error);
        })
      },
      showFeedLink (visible) {
        this.feedLinkVisible = visible
        if (visible) {
          Vue.nextTick(() => {
            let $el = document.querySelector('.feed-link input');
            $el.select();
            $el.focus();
          })
        }
      }
    },
    mounted () {
      $('.scroll-container').mCustomScrollbar({
        theme: 'minimal-dark',
        mouseWheel: { preventDefault: true },
      })
      this.loadData().then(() => {
        // Vue.nextTick(() => {
        //   $('.scroll-container').mCustomScrollbar('scrollTo', $('li.timeline-item:nth-child(2)'))
        // })
      })
      this._interval = window.setInterval(() => this.loadData(), 900000);  // 15 minutes
    },
    destroyed () {
      window.clearInterval(this._interval)
      $('.scroll-container').mCustomScrollbar("destroy");
    }
  }
</script>

<style lang="scss" scoped>
  .tab-content ul {
    list-style-type: square;
  }


  .feed-link {
    margin-bottom: 8px;

    input {
      width: calc(100% - 36px);
      height: 32px;
      margin-right: 2px;
    }

    button {
      min-height: 30px;
      min-width: 30px;
      max-height: 30px;
      max-width: 30px;
      padding: 4px 5px;
      background-color: rgba(240, 240, 240, 0.9);
      border-color: rgba(240, 240, 240, 0.9);
      color: #777;
      margin-top: -3px;

      &:active {
        background-color: #ccc;
        border-color: #ccc;
      }
    }
  }

  .scroll-container {
    max-height: 600px;
    overflow-y: hidden;
    margin-top: 20px;
    margin-bottom: 8px;
    overflow-x: auto;
  }

  .degrad-white-50 {
    background: linear-gradient(0deg, #fff 25%, rgba(0, 0, 0, 0));
    bottom: 30px;
    height: 60px;
    left: 0;
    position: absolute;
    right: 28px;
    z-index: 200;
  }

  .agenda-show-link {
    font-size: 16px;
    cursor: pointer;
  }

  .agenda-show-link:hover {
    text-decoration: none;
  }

  .no-item {
    font-style: italic;
    color: #ccc;
    height: 80px;
    font-size: 1.2em;
  }
</style>
