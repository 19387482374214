var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "panel",
    {
      ref: "result",
      staticClass: "result",
      attrs: { title: _vm.title, "icon-class": "fa fa-book" },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function () {
            return [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.saveQuery()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Save query as...")))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.exportPage()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Export results...")))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.printDocuments()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Print documents...")))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.downloadDocuments()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Download documents...")))]
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        return _vm.getLink()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Get link...")))]
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "home" },
                    title: _vm.$gettext("Search"),
                  },
                },
                [_c("i", { staticClass: "fa fa-search" })]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("vue-tags-input", {
        directives: [{ name: "focus", rawName: "v-focus" }],
        staticClass: "result-tags",
        attrs: {
          "allow-edit-tags": true,
          validation: _vm.validation,
          tags: _vm.tags,
          placeholder: _vm.$gettext("Add criteria"),
        },
        on: {
          "tags-changed": _vm.tagChanged,
          "before-deleting-tag": _vm.doBeforeDeletingTag,
          "before-editing-tag": _vm.doBeforeEditingTag,
          "before-saving-tag": _vm.doBeforeSavingTag,
          "before-adding-tag": _vm.doBeforeAddingTag,
        },
        scopedSlots: _vm._u([
          {
            key: "tag-left",
            fn: function (props) {
              return [
                props.tag.selection
                  ? _c("span", { staticClass: "glyphicon glyphicon-check" })
                  : props.tag.query
                  ? _c("span", { staticClass: "glyphicon glyphicon-search" })
                  : _vm._e(),
                props.tag.mode == 2
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("upper")(_vm.$gettext("Phrase prefix"))
                          ) +
                          ": "
                      ),
                    ])
                  : props.tag.filter
                  ? _c(
                      "span",
                      [
                        props.tag.label
                          ? [_vm._v(_vm._s(props.tag.label) + ": ")]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tag,
          callback: function ($$v) {
            _vm.tag = $$v
          },
          expression: "tag",
        },
      }),
      _vm._v(" "),
      !this.error && _vm.notFound
        ? _c(
            "div",
            { staticClass: "alert alert-info", attrs: { role: "alert" } },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$gettext("No result found.")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      this.error
        ? _c(
            "div",
            { staticClass: "alert alert-warning", attrs: { role: "alert" } },
            [_vm._v("\n    " + _vm._s(this.error) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.notFound && !_vm.error && _vm.params.size,
                expression: "!notFound && !error && params.size",
              },
            ],
            staticClass: "result-count col-sm-2",
          },
          [
            _c("span", [
              _c("strong", [
                _vm._v(_vm._s((_vm.params.page - 1) * _vm.params.size + 1)),
              ]),
              _vm._v(" - "),
              _c("strong", [
                _vm._v(
                  _vm._s(
                    Math.min(
                      (_vm.params.page - 1) * _vm.params.size + _vm.params.size,
                      _vm.total
                    )
                  )
                ),
              ]),
              _vm._v(" " + _vm._s(_vm.$gettext("of")) + " "),
              _c("strong", [_vm._v(_vm._s(_vm.total))]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "result-refresh-action hidden-print",
                  attrs: { title: _vm.$gettext("Refresh") },
                  on: {
                    click: function ($event) {
                      return _vm.refreshResults()
                    },
                  },
                },
                [_c("span", { staticClass: "glyphicon glyphicon-refresh" })]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.notFound && !_vm.error,
                expression: "!notFound && !error",
              },
            ],
            staticClass: "col-sm-10 col-xs-12 result-toolbar hidden-print",
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.perPage,
                    expression: "perPage",
                  },
                ],
                staticClass:
                  "form-control result-page-size input-sm custom-control",
                attrs: { title: _vm.$gettext("Rows per page") },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.perPage = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              _vm._l(_vm.page_sizes, function (size) {
                return _c("option", { key: size, domProps: { value: size } }, [
                  _vm._v(_vm._s(_vm.getPageSizeCaption(size))),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isCommentOverview,
                    expression: "!isCommentOverview",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.excerpts,
                    expression: "excerpts",
                  },
                ],
                staticClass: "form-control input-sm custom-control",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.excerpts = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "no" } }, [
                  _vm._v(_vm._s(_vm.$gettext("No excerpts"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "short" } }, [
                  _vm._v(_vm._s(_vm.$gettext("Short excerpts"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "medium" } }, [
                  _vm._v(_vm._s(_vm.$gettext("Medium excerpts"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "long" } }, [
                  _vm._v(_vm._s(_vm.$gettext("Long excerpts"))),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "all" } }, [
                  _vm._v(_vm._s(_vm.$gettext("All excerpts"))),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _vm.items.length > 0
              ? _c(
                  "table",
                  { staticClass: "table table-condensed" },
                  [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "result-checkbox" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isAllSelected,
                                expression: "isAllSelected",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.isAllSelected)
                                ? _vm._i(_vm.isAllSelected, null) > -1
                                : _vm.isAllSelected,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectAll()
                              },
                              change: function ($event) {
                                var $$a = _vm.isAllSelected,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.isAllSelected = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.isAllSelected = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.isAllSelected = $$c
                                }
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("th", { staticClass: "result-content" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeOrdering("title")
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$gettext("Title")) + " "),
                              _vm.sortedColumn === "title"
                                ? _c("i", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-sort-amount-desc":
                                        _vm.sortedOrder === "desc",
                                      "fa-sort-amount-asc":
                                        _vm.sortedOrder === "asc",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" / "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeOrdering("monograph_number")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$gettext("Monograph number(s)")) +
                                  " "
                              ),
                              _vm.sortedColumn === "monograph_number"
                                ? _c("i", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-sort-amount-desc":
                                        _vm.sortedOrder === "desc",
                                      "fa-sort-amount-asc":
                                        _vm.sortedOrder === "asc",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" / "),
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeOrdering("reference")
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$gettext("Reference")) + " "),
                              _vm.sortedColumn === "reference"
                                ? _c("i", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-sort-amount-desc":
                                        _vm.sortedOrder === "desc",
                                      "fa-sort-amount-asc":
                                        _vm.sortedOrder === "asc",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.isCommentOverview
                          ? _c("th", { staticClass: "result-number" }, [
                              _vm._v(_vm._s(_vm.$gettext("Comments"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCommentOverview
                          ? _c("th", { staticClass: "result-number" }, [
                              _vm._v(_vm._s(_vm.$gettext("Files"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCommentOverview
                          ? _c("th", { staticClass: "result-number" }, [
                              _vm._v(_vm._s(_vm.$gettext("My comments"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCommentOverview
                          ? _c("th", { staticClass: "result-number" }, [
                              _vm._v(_vm._s(_vm.$gettext("My files"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isCommentOverview
                          ? _c("th", { staticClass: "result-date" }, [
                              _vm._v(_vm._s(_vm.$gettext("Last comment"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", { staticClass: "result-date" }, [
                          _c(
                            "a",
                            {
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.changeOrdering("posting_date")
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$gettext("Date posted")) + " "),
                              _vm.sortedColumn === "posting_date"
                                ? _c("i", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-sort-amount-desc":
                                        _vm.sortedOrder === "desc",
                                      "fa-sort-amount-asc":
                                        _vm.sortedOrder === "asc",
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "th",
                          { staticClass: "result-date result-deadline" },
                          [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.changeOrdering(
                                      "comment_deadline"
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$gettext("Commenting deadline")) +
                                    " "
                                ),
                                _vm.sortedColumn === "comment_deadline"
                                  ? _c("i", {
                                      staticClass: "fa",
                                      class: {
                                        "fa-sort-amount-desc":
                                          _vm.sortedOrder === "desc",
                                        "fa-sort-amount-asc":
                                          _vm.sortedOrder === "asc",
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "tbody",
                        { key: index, staticClass: "result-item" },
                        [
                          _c("tr", [
                            _c("td", { staticClass: "result-checkbox" }, [
                              _c("input", {
                                attrs: { type: "checkbox" },
                                domProps: { checked: _vm.itemSelected(item) },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.selectItem(item, $event)
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "result-content" }, [
                              _c("div", { staticClass: "result-item-title" }, [
                                _c("i", {
                                  staticClass: "fa",
                                  class: _vm.getFileIcon(item),
                                }),
                                _c(
                                  "span",
                                  { staticClass: "result-item-title-text" },
                                  [
                                    _c("a", {
                                      attrs: { href: item.url },
                                      domProps: {
                                        innerHTML: _vm._s(item.title),
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.openItem(
                                            item,
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                false
                                  ? _c(
                                      "small",
                                      { staticClass: "result-score" },
                                      [_vm._v(_vm._s(item.score))]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "result-item-subtitle" },
                                [
                                  _vm._l(
                                    item.monograph_numbers,
                                    function (num) {
                                      return _c(
                                        "span",
                                        { staticClass: "badge badge-light" },
                                        [_vm._v(_vm._s(num))]
                                      )
                                    }
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "result-item-reference" },
                                    [_vm._v(_vm._s(item.reference))]
                                  ),
                                  item.is_restricted
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "badge badge-restricted",
                                        },
                                        [_vm._v("Restricted")]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.isCommentOverview
                              ? _c("td", { staticClass: "result-number" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.comment_overview &&
                                        item.comment_overview.comments
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCommentOverview
                              ? _c("td", { staticClass: "result-number" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.comment_overview &&
                                        item.comment_overview.attachments
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCommentOverview
                              ? _c("td", { staticClass: "result-number" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.comment_overview &&
                                        item.comment_overview.user_comments
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCommentOverview
                              ? _c("td", { staticClass: "result-number" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.comment_overview &&
                                        item.comment_overview.user_attachments
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isCommentOverview
                              ? _c("td", { staticClass: "result-date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        item.comment_overview &&
                                          item.comment_overview.last_updated
                                      )
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("td", { staticClass: "result-date" }, [
                              _vm._v(_vm._s(_vm._f("date")(item.posting_date))),
                            ]),
                            _vm._v(" "),
                            _c("td", {
                              staticClass:
                                "result-date result-deadline deadline",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.htmlDeadline(item.comment_deadline)
                                ),
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          !_vm.isCommentOverview
                            ? _c("tr", { staticClass: "result-item-extra" }, [
                                _c("td", { staticClass: "result-checkbox" }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    attrs: {
                                      colspan: _vm.isCommentOverview ? 8 : 3,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "result-item-extra-content",
                                      },
                                      [
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "result-item-folders",
                                          },
                                          _vm._l(
                                            item.folders,
                                            function (folder) {
                                              return _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "result-item-folder",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          folder.url +
                                                          "?select=" +
                                                          item.id,
                                                        title: folder.path,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          folder.path.slice(1)
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "result-item-meetings",
                                          },
                                          _vm._l(
                                            _vm.getSortedMeetings(item),
                                            function (meeting) {
                                              return _c(
                                                "li",
                                                {
                                                  staticClass:
                                                    "result-item-meeting",
                                                  attrs: {
                                                    "data-id": meeting.id,
                                                    "data-url": meeting.url,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("date")(
                                                        meeting.date
                                                      )
                                                    ) + ": "
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "meeting-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(meeting.title)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.highlight["attachment.content"]
                            ? _c("tr", { staticClass: "row-preview" }, [
                                _c("td", { staticClass: "result-checkbox" }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "result-highlight",
                                    attrs: {
                                      colspan: _vm.isCommentOverview ? 8 : 3,
                                    },
                                  },
                                  [
                                    item.highlight["attachment.content"] &&
                                    !Array.isArray(
                                      item.highlight["attachment.content"]
                                    )
                                      ? _c(
                                          "div",
                                          { staticClass: "result-preview" },
                                          [
                                            _c("p", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  item.highlight[
                                                    "attachment.content"
                                                  ]
                                                ),
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.highlight["attachment.content"] &&
                                    Array.isArray(
                                      item.highlight["attachment.content"]
                                    ) &&
                                    item.highlight["attachment.content"]
                                      .length > 0
                                      ? _c(
                                          "div",
                                          { staticClass: "result-preview" },
                                          _vm._l(
                                            item.highlight[
                                              "attachment.content"
                                            ],
                                            function (content, index) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                [
                                                  content === "..."
                                                    ? [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$gettext(
                                                              "and more..."
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    : _c("p", {
                                                        domProps: {
                                                          innerHTML:
                                                            _vm._s(content),
                                                        },
                                                      }),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isLoading ? _c("div", { staticClass: "loading" }) : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.numPages > 1
        ? _c("paginator", {
            attrs: {
              params: _vm.params,
              num_page: _vm.numPages,
              callback: _vm.paginationChanged,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }