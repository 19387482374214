<template>
  <div>
    <h2>{{ $gettext('My account') }}</h2>
    <br>
    <ul class="nav nav-tabs" role="tablist">
      <li role="presentation" class="active"><a href="#profile" aria-controls="profile" role="tab" data-toggle="tab">{{ $gettext('Profile') }}</a></li>
      <li role="presentation"><a href="#notification" aria-controls="notification" role="tab" data-toggle="tab">{{ $gettext('Notifications') }}</a></li>
    </ul>

    <div class="tab-content">
      <div role="tabpanel" class="tab-pane active" id="profile">

        <form class="form-horizontal">
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('First name') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.first_name }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Last name') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.last_name }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Organisation') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.organization }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Street') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.street }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Postal code') }}</label>
            <div class="col-sm-2">
              <p class="form-control-static">{{ profile.zip }}</p>
            </div>
            <label class="col-sm-2 control-label">{{ $gettext('City') }}</label>
            <div class="col-sm-6">
              <p class="form-control-static">{{ profile.city }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Country') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static" v-if="profile.country">{{ profile.country.name }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Phone') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.phone }}</p>
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">{{ $gettext('Email') }}</label>
            <div class="col-sm-10">
              <p class="form-control-static">{{ profile.email }}</p>
            </div>
          </div>
          <div class="checkbox">
            <label>
              <input type="checkbox" v-model="profile.is_public">
              {{ $gettext('Make my contact information visible to members of my groups') }}
            </label>
            <p class="help-block">{{ $gettext('By checking this box, the following contact information, where provided, will be visible to other members of your groups (excluding private groups): first name, last name, organisation, country, phone number, email address.') }}</p>
          </div>
          <br>
          <button class="btn btn-primary" @click.prevent="updateProfile">{{ $gettext('Apply') }}</button>
        </form>
      </div>
      <div role="tabpanel" class="tab-pane" id="notification">
        <label>{{ $gettext('For new documents with a personal access') }}</label>
        <div class="row">
          <div class="col-sm-3"></div>
          <div class="col-sm-8" v-if="profile.document_personal_subscriptions">
            <label class="checkbox-inline"><bootstrap-toggle v-model="profile.document_personal_subscriptions.each_update"/> {{ $gettext('Each update') }}  </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="profile.document_personal_subscriptions.once_per_day"/> {{ $gettext('Once per day') }} </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="profile.document_personal_subscriptions.once_per_week"/> {{ $gettext('Once per week') }} </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="profile.document_personal_subscriptions.once_per_month"/> {{ $gettext('Once per month') }} </label>
          </div>
          <div class="col-sm-1"></div>
          <hr>
        </div>
        <label>{{ $gettext('For new documents for my groups') }}</label>
        <div class="row" v-for="g in groups_subscriptions">
          <div class="col-sm-3">
            <span :title="g.group_name">{{g.group_code}}</span></div>
          <div class="col-sm-8">
            <label class="checkbox-inline"><bootstrap-toggle v-model="g.each_update"/> {{ $gettext('Each update') }}  </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="g.once_per_day"/> {{ $gettext('Once per day') }} </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="g.once_per_week"/> {{ $gettext('Once per week') }} </label>
            <label class="checkbox-inline"><bootstrap-toggle v-model="g.once_per_month"/> {{ $gettext('Once per month') }} </label>
          </div>
          <div class="col-sm-1">
            <button @click="removeGroupSubscription($event, g.group_id)" class="btn btn-default trash-btn-xs" type="button"
                      v-if="g.custom">
                <span aria-hidden="true" class="glyphicon glyphicon-trash"></span>
              </button>
          </div>
          <hr>
        </div>
        <template v-if="$root.can_subscribe_all_groups()">
          <br>
          <button class="btn btn-primary" @click.prevent="openGroupSelection">{{ $gettext('Add group') }}</button>
        </template>
        <br>
        <button class="btn btn-primary" @click.prevent="updateSubscriptions">{{ $gettext('Apply') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import message from 'toastr'
  import BootstrapToggle from 'vue-bootstrap-toggle'
  import {openGroupSelector} from "./group-selector";

  export default {
    name: "profile",
    components: { BootstrapToggle },
    data () {
      return {
        profile: {},
      }
    },
    methods: {
      load () {
        this.$restAPI.get('user/').then(response => {
          this.profile = response.data
        })
      },
      addGroupSubscriptions (selection) {
        selection.forEach(item => {
          let index = this.profile.document_groups_subscriptions.findIndex(grp => grp.group_id == item.id)
          if (index === -1) {
            this.profile.document_groups_subscriptions.push({
              each_update: false,
              group_id: item.id,
              group_name: item.name,
              group_code: item.code,
              once_per_day: true,
              once_per_month: false,
              once_per_week: false,
              custom: true
            })
          }
        })
      },
      removeGroupSubscription ({target}, group_id) {
        let index = this.profile.document_groups_subscriptions.findIndex(item => item.group_id == group_id)
        this.profile.document_groups_subscriptions.splice(index,1)
      },
      updateProfile() {
        let data = {
          'is_public': this.profile.is_public
        }
        this.$restAPI.patch('user/', data).then(response => {
          this.profile = response.data
          message.success(this.$gettext('Your profile has been updated.'))
        }).catch(error => {
          message.error(this.$gettext('Your profile cannot be updated.'))
        })
      },
      openGroupSelection() {
        let initial_selection = new Set()
        this.profile.document_groups_subscriptions.forEach(item => {
          initial_selection.add(item.group_id)
        })
        openGroupSelector(this.$root, initial_selection, true, false, true).then(selection => {
          this.addGroupSubscriptions(selection)
        })
      },
      validateSubscriptions() {
        let errors = []

        if (this.$root.is_edqm_ui())
          return errors

        let subscriptions = this.profile.document_groups_subscriptions.concat(this.profile.document_personal_subscriptions)
        subscriptions.forEach(item => {
          if (!(item.each_update || item.once_per_day || item.once_per_week || item.once_per_month)) {
            errors.push(item)
          }
        })
        return errors
      },
      updateSubscriptions() {
        // Validate subscriptions: at least one should be active for external users
        let errors = this.validateSubscriptions()
        if (errors.length){
          message.error(this.$gettext('You must choose at least one of the following notification types: each update, daily, weekly or monthly for each notification'))
          return
        }

        let data = {
          'document_groups_subscriptions': this.profile.document_groups_subscriptions,
          'document_personal_subscriptions': this.profile.document_personal_subscriptions
        }
        this.$restAPI.patch('user/', data).then(response => {
          this.profile = response.data
          message.success(this.$gettext('Your notifications preferences have been updated.'))
        }).catch(error => {
          message.error(this.$gettext('Your notifications preferences cannot be updated.'))
        })
      }
    },
    computed: {
      groups_subscriptions () {
        if (this.profile && this.profile.document_groups_subscriptions ) {
          return this.profile.document_groups_subscriptions.sort((g1,g2) => {
            let order = 0
            if ( g1.custom && g2.custom) {
              order = (g1.group_code > g2.group_code) ? 1 : -1
            } else if (g1.custom)  {
              order = 1
            } else if (g2.custom)  {
              order = -1
            } else {
              order = (g1.group_code > g2.group_code) ? 1 : -1
            }
            return order
          })
        } else {
          return this.profile.document_groups_subscriptions
        }
      }
    },
    mounted () {
      this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.$pgettext('breadcrumb', 'My account') }]
      this.load()
    }
  }
</script>

<style>
  .toggle .btn {
    min-width: 1px;
  }
  .toggle label {
    margin-right: 20px;
  }
  .toggle-handle.btn.btn-default {
    background-color: #fff;
    border-color: #fff;
  }
  .trash-btn-xs {
    min-width: 0;
  }
</style>

<style scoped>
  hr {
    width: 100%;
  }
  label.checkbox-inline {
    padding: 0;
    width: 23%;
  }
</style>
