var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "group-selector",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$gettext("Select group")))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.search_all_group,
                      expression: "search_all_group",
                    },
                  ],
                  staticClass: "row",
                },
                [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filter_all_groups,
                            expression: "filter_all_groups",
                          },
                        ],
                        attrs: { id: "all_groups", type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.filter_all_groups)
                            ? _vm._i(_vm.filter_all_groups, null) > -1
                            : _vm.filter_all_groups,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.filter_all_groups,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filter_all_groups = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filter_all_groups = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filter_all_groups = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: "all_groups" } }, [
                        _vm._v(_vm._s(_vm.$gettext("Show all groups"))),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "search_code" } }, [
                      _vm._v(_vm._s(_vm.$gettext("Group code"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter_code,
                          expression: "filter_code",
                        },
                        { name: "clear", rawName: "v-clear" },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "search_code",
                        placeholder: "",
                      },
                      domProps: { value: _vm.filter_code },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.load(true)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.filter_code = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "search_name" } }, [
                      _vm._v(_vm._s(_vm.$gettext("Group name"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter_name,
                          expression: "filter_name",
                        },
                        { name: "clear", rawName: "v-clear" },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "search_name",
                        placeholder: "",
                      },
                      domProps: { value: _vm.filter_name },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.load(true)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.filter_name = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.clearFilters },
                      },
                      [_vm._v(_vm._s(_vm.$gettext("Clear")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-default",
                        attrs: { type: "button" },
                        on: { click: _vm.searchGroups },
                      },
                      [_vm._v(_vm._s(_vm.$gettext("Search")))]
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-striped table-groups" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [
                      _c("label", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          on: {
                            change: function ($event) {
                              return _vm.selectAllItems($event)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "column-short-text" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeOrdering("code")
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.$gettext("Group code")) + " "),
                          _vm.sortedColumn === "code"
                            ? _c("i", {
                                staticClass: "fa",
                                class: {
                                  "fa-sort-amount-desc":
                                    _vm.sortedOrder === "desc",
                                  "fa-sort-amount-asc":
                                    _vm.sortedOrder === "asc",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("th", { staticClass: "column-text" }, [
                      _c(
                        "a",
                        {
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.changeOrdering("name")
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.$gettext("Group name")) + " "),
                          _vm.sortedColumn === "name"
                            ? _c("i", {
                                staticClass: "fa",
                                class: {
                                  "fa-sort-amount-desc":
                                    _vm.sortedOrder === "desc",
                                  "fa-sort-amount-asc":
                                    _vm.sortedOrder === "asc",
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.groups, function (item) {
                    return _c("tr", [
                      _c("td", [
                        _c("label", [
                          _c("input", {
                            attrs: { id: "gr_" + item.id, type: "checkbox" },
                            domProps: { checked: _vm.itemSelected(item) },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.selectItem(item)
                              },
                            },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "column-short-text" }, [
                        _c(
                          "label",
                          { attrs: { for: "gr_" + item.id } },
                          [
                            item.deprecated
                              ? [_c("s", [_vm._v(_vm._s(item.code))])]
                              : [_vm._v(_vm._s(item.code))],
                          ],
                          2
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "column-text" }, [
                        _c(
                          "label",
                          { attrs: { for: "gr_" + item.id } },
                          [
                            item.deprecated
                              ? [_c("s", [_vm._v(_vm._s(item.name))])]
                              : [_vm._v(_vm._s(item.name))],
                          ],
                          2
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.numPages > 1
                ? _c("paginator", {
                    attrs: { params: _vm.params, num_page: _vm.numPages },
                    on: {
                      change: function ($event) {
                        return _vm.load()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$gettext("OK")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }