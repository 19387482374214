var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "title" }, [
        _c("i", { staticClass: "fa fa-users" }),
        _vm._v(" " + _vm._s(_vm.$gettext("My groups"))),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "footer-team-list" },
        _vm._l(_vm.items, function (item) {
          return _c("li", { attrs: { title: item.name } }, [
            _c(
              "a",
              {
                class: { private: !item.visible },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.open(item)
                  },
                },
              },
              [_vm._v(_vm._s(item.code))]
            ),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.moreItems
        ? _c(
            "a",
            {
              staticClass: "more-items",
              on: {
                click: function ($event) {
                  return _vm.loadMore()
                },
              },
            },
            [
              _vm._v(
                _vm._s(_vm.moreItemsCount) +
                  " " +
                  _vm._s(_vm.$gettext("more group(s)..."))
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loaded ? _c("spinner") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }