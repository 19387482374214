import $ from 'jquery'
import axios from 'axios'

export function messageBox (title, message, actions, keyboard = true) {
  return new Promise((resolve, reject) => {
    let div = document.createElement('div')
    let buttons = ''
    let index = 0
    for (let id in actions) {
      if (actions.hasOwnProperty(id)) {
        buttons += `<button id="dialogbox_action_${id}" data-action="${id}" type="button" class="${'btn ' + (index === 0 ? 'btn-primary' : 'btn-secondary')}">
  ${actions[id]}
</button>`
      }
      index++
    }
    div.innerHTML = `<div class="modal outer" tabindex="-1" role="dialog" id="dialogbox">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">${title}</h2>
          </div>
          <div class="modal-body">
            <p>${message}</p>
          </div>
          <div class="modal-footer">
            ${buttons}
          </div>
        </div>
      </div>
    </div>`
    document.querySelector('body').appendChild(div)
    let $dialogBox = $('#dialogbox')
    $dialogBox.on('hidden.bs.modal', e => {
      $dialogBox.parent().remove()
    })
    $dialogBox.find('button[data-action]').click(ev => {
      $dialogBox.modal('hide')
      resolve(ev.target.getAttribute('data-action'))
    })
    console.log(keyboard)
    $dialogBox.modal({ backdrop: 'static', show: true, keyboard: keyboard })
  })
}

export function inputBox (title, message, btnLabels = ['OK', 'Cancel'], defaultValue = '', callback = null) {
  return new Promise((resolve, reject) => {
    let div = document.createElement('div')
    div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="inputbox">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">${title}</h2>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="input-box-value" class="control-label">${message}</label>
              <input type="text" class="form-control" id="input-box-value" value="${defaultValue}"/>
              <p class="help-block"/p>
            </div>
          </div>
          <div class="modal-footer">
            <button id="inputbox_action_ok" data-action="ok" type="button" class="btn btn-primary" disabled>${btnLabels[0]}</button>
            <button id="inputbox_action_cancel" data-action="cancel" type="button" class="btn btn-secondary">${btnLabels[1]}</button>
          </div>
        </div>
      </div>
    </div>`
    document.querySelector('body').appendChild(div)
    let $dialogBox = $('#inputbox')

    let $input = document.getElementById('input-box-value')
    let $btnOk = document.getElementById('inputbox_action_ok')
    $input.addEventListener('keyup', function (event) {
      if (event.keyCode === 13) {
        event.preventDefault()
        $btnOk.click()
      }
      $btnOk.disabled = $input.value === ''
    })
    $dialogBox.on('shown.bs.modal', e => {
      $input.focus()
    })
    $dialogBox.on('hidden.bs.modal', e => {
      $dialogBox.parent().remove()
    })
    $dialogBox.find('button[data-action]').click(ev => {
      let value = $input.value
      let action = ev.target.getAttribute('data-action')
      if (callback) {
        callback(action, value).then(response => {
          $dialogBox.modal('hide')
          resolve({action, value, 'response': response || {}})
        }).catch(error => {
          if (!error.close) {
            document.querySelector('#inputbox p.help-block').textContent = error.message
            document.querySelector('#inputbox .form-group').classList.add('has-error')
          } else {
            $dialogBox.modal('hide')
            reject(error.data)
          }
        })
      } else {
        $dialogBox.modal('hide')
        resolve({action, value})
      }
    })
    $dialogBox.modal('show')
  })
}

export function inputRadiosBox (title, items, helpText, btnLabels = ['OK', 'Cancel'], callback = null) {
  return new Promise((resolve, reject) => {
    let div = document.createElement('div')
    let radio_html = ''
    items.forEach(function (item) {
	    radio_html += `
	      <div class="radio${(item.disabled)?" disabled":""}">
	          <label for="input-radio-${item.value}">
	              <input type="radio" ${(item.disabled)?"disabled":""} name="input-radio" id="input-radio-${item.value}" value="${item.value}"> ${item.content}
	          </label>
	      </div>
	    `
    });
    div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="inputbox">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">${title}</h2>
          </div>
          <div class="modal-body">
            <div class="form-group">
              ${radio_html}
              <p class="help-block">${helpText}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button id="inputbox_action_ok" data-action="ok" type="button" class="btn btn-primary" disabled>${btnLabels[0]}</button>
            <button id="inputbox_action_cancel" data-action="cancel" type="button" class="btn btn-secondary">${btnLabels[1]}</button>
          </div>
        </div>
      </div>
    </div>`
    document.querySelector('body').appendChild(div)
    let $dialogBox = $('#inputbox')

    let $input = $('input[name="input-radio"]')
    let $btnOk = $('#inputbox_action_ok')
    $input.on('change', function (event) {
      $btnOk.prop('disabled', $input.filter(':checked').length === 0)
    })
    $dialogBox.on('shown.bs.modal', e => {
      $input.focus()
    })
    $dialogBox.on('hidden.bs.modal', e => {
      $dialogBox.parent().remove()
    })
    $dialogBox.find('button[data-action]').click(ev => {
      let value = $input.filter(":checked").val()
      let action = ev.target.getAttribute('data-action')
      if (callback) {
        callback(action, value).then(response => {
          $dialogBox.modal('hide')
          resolve({action, value, 'response': response || {}})
        }).catch(error => {
          if (!error.close) {
            document.querySelector('#inputbox p.help-block').textContent = error.message
            document.querySelector('#inputbox .form-group').classList.add('has-error')
          } else {
            $dialogBox.modal('hide')
            reject(error.data)
          }
        })
      } else {
        $dialogBox.modal('hide')
        resolve({action, value})
      }
    })
    $dialogBox.modal('show')
  })
}

/**
 * @param  {String} backgroundColor  Background color.
 * @param  {String} lightColor  Use color when color background is dark.
 * @param  {String} darkColor  Use color when color background is light.
 * @return {String}
 */
export function colorFromBackground (backgroundColor, lightColor = '#ffffff', darkColor = '#000000') {
  let color = parseInt(backgroundColor.substr(1), 16)
  if (0.3 * (color >> 16) + 0.59 * (color >> 8 & 0xFF) + 0.11 * (color & 0xFF) <= 128) {
    return lightColor
  } else {
    return darkColor
  }
}

export function truncateString (str, strLgth = 30, custChar = '...') {
  const truncStr = str.substring(0, strLgth)
  return str.length > strLgth ? `${truncStr}${custChar}` : str
}


export function ajaxMessageBox (title, url, actions, width, id) {
  let style = ''
  if (width !== null) {
    style = 'style="width: ' + width + '"'
  }

  let customId = 'dialogbox'
  if (id !== null) {
    customId = id
  }

  return new Promise((resolve, reject) => {
    let div = document.createElement('div')

    // Add buttons
    let buttons = ''
    for (let id in actions) {
      if (actions.hasOwnProperty(id)) {
        buttons += `<button id="dialogbox_action_${id}" data-action="${id}" type="button" class="btn btn-default">
  ${actions[id]}
</button>`
      }
    }

    // Get content
    axios.get(url)
      .then(response => {
        let message = response.data

        // Fill modal
        div.innerHTML = `<div class="modal" tabindex="-1" role="dialog" id="` + customId + `">
              <div class="modal-dialog" role="document" ` + style + `>
                <div class="modal-content">
                  <div class="modal-header">
                    <h2 class="modal-title">${title}</h2>
                  </div>
                  <div class="modal-body">` + message + `</div>
                  <div class="modal-footer">
                    ${buttons}
                  </div>
                </div>
              </div>
            </div>`

        document.querySelector('body').appendChild(div)
        let $dialogBox = $('#dialogbox')
        $dialogBox.on('hidden.bs.modal', e => {
          $dialogBox.parent().remove()
        })
        $dialogBox.find('button[data-action]').click(ev => {
          let action = ev.target.getAttribute('data-action')
          if (action === 'confirm') {
            let data = new window.FormData()
            this.$http.post(url, data).then(response => {
              if (response.status === 200) {
                resolve(ev.target.getAttribute('data-action'))
                $dialogBox.modal('hide')
              }
            }).catch(error => {
              reject(error)
            })
          } else {
            resolve(ev.target.getAttribute('data-action'))
            $dialogBox.modal('hide')
          }
        })
        $dialogBox.modal('show')
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
