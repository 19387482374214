<template>
  <div>
    <span class="title"><i class="fa fa-history"></i> {{$gettext('My last-viewed documents')}}</span>
    <ul v-if="loaded">
      <li v-for="item in items" :key="item.document_id"><a :href="item.url" @click.stop="openItem(item, $event)">{{ item.title }}</a></li>
    </ul>
    <spinner v-else></spinner>
    <span v-if="failed" class="error">{{$gettext('Loading failed.')}} (<a href="#" @click="load(true)">{{$gettext('Retry')}}</a>)</span>
  </div>
</template>

<script>
  import Spinner from './spinner'
  import message from 'toastr'

  export default {
    name: 'recents',
    components: { Spinner },
    data: function () {
      return {
        items: [],
        loaded: false,
        failed: false
      }
    },
    methods: {
      reset () {
        this.loaded = false
        this.failed = false
        this.items = []
      },
      doLoadFailed () {
        this.failed = true
        this.items = []


      },
      load (reset = false) {
        if (reset) this.reset()
        this.$restAPI.get('/documents/recent/?size=10').then(response => {
          this.items = response.data.results
        }).catch(this.doLoadFailed).then(() => { this.loaded = true })
      },
      openItem (item, e) {
        this.$root.openDocument(item.id)
        e.preventDefault()
      }
    },
    mounted () {
      this.load()
      this.$root.$on('document_opened', document => {
        this.load()
      })
      this.$root.$on('document_deleted', () => {
        this.load()
      })
    }
  }
</script>

<style scoped lang="scss">
  a {
    color: white;
  }

  .title {
    font-family: Open Sans Condensed, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    i {
      padding-right: 4px;
    }
  }

  ul {
    margin-top: 8px;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }

  li {
    font-size: 12px;

  }

  .error {
    font-style: italic;
    color: #ccc;
  }
</style>
