var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h2", [_vm._v(_vm._s(_vm.$gettext("My account")))]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("ul", { staticClass: "nav nav-tabs", attrs: { role: "tablist" } }, [
      _c("li", { staticClass: "active", attrs: { role: "presentation" } }, [
        _c(
          "a",
          {
            attrs: {
              href: "#profile",
              "aria-controls": "profile",
              role: "tab",
              "data-toggle": "tab",
            },
          },
          [_vm._v(_vm._s(_vm.$gettext("Profile")))]
        ),
      ]),
      _vm._v(" "),
      _c("li", { attrs: { role: "presentation" } }, [
        _c(
          "a",
          {
            attrs: {
              href: "#notification",
              "aria-controls": "notification",
              role: "tab",
              "data-toggle": "tab",
            },
          },
          [_vm._v(_vm._s(_vm.$gettext("Notifications")))]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        {
          staticClass: "tab-pane active",
          attrs: { role: "tabpanel", id: "profile" },
        },
        [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("First name"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.first_name)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Last name"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.last_name)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Organisation"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.organization)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Street"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.street)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Postal code"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.zip)),
                ]),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("City"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.city)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Country"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _vm.profile.country
                  ? _c("p", { staticClass: "form-control-static" }, [
                      _vm._v(_vm._s(_vm.profile.country.name)),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Phone"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.phone)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v(_vm._s(_vm.$gettext("Email"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _c("p", { staticClass: "form-control-static" }, [
                  _vm._v(_vm._s(_vm.profile.email)),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.profile.is_public,
                      expression: "profile.is_public",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.profile.is_public)
                      ? _vm._i(_vm.profile.is_public, null) > -1
                      : _vm.profile.is_public,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.profile.is_public,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.profile,
                              "is_public",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.profile,
                              "is_public",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.profile, "is_public", $$c)
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$gettext(
                        "Make my contact information visible to members of my groups"
                      )
                    ) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "help-block" }, [
                _vm._v(
                  _vm._s(
                    _vm.$gettext(
                      "By checking this box, the following contact information, where provided, will be visible to other members of your groups (excluding private groups): first name, last name, organisation, country, phone number, email address."
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.updateProfile.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Apply")))]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tab-pane",
          attrs: { role: "tabpanel", id: "notification" },
        },
        [
          _c("label", [
            _vm._v(
              _vm._s(_vm.$gettext("For new documents with a personal access"))
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-3" }),
            _vm._v(" "),
            _vm.profile.document_personal_subscriptions
              ? _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "label",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("bootstrap-toggle", {
                        model: {
                          value:
                            _vm.profile.document_personal_subscriptions
                              .each_update,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.profile.document_personal_subscriptions,
                              "each_update",
                              $$v
                            )
                          },
                          expression:
                            "profile.document_personal_subscriptions.each_update",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$gettext("Each update")) + "  "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("bootstrap-toggle", {
                        model: {
                          value:
                            _vm.profile.document_personal_subscriptions
                              .once_per_day,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.profile.document_personal_subscriptions,
                              "once_per_day",
                              $$v
                            )
                          },
                          expression:
                            "profile.document_personal_subscriptions.once_per_day",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$gettext("Once per day")) + " "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("bootstrap-toggle", {
                        model: {
                          value:
                            _vm.profile.document_personal_subscriptions
                              .once_per_week,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.profile.document_personal_subscriptions,
                              "once_per_week",
                              $$v
                            )
                          },
                          expression:
                            "profile.document_personal_subscriptions.once_per_week",
                        },
                      }),
                      _vm._v(" " + _vm._s(_vm.$gettext("Once per week")) + " "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "checkbox-inline" },
                    [
                      _c("bootstrap-toggle", {
                        model: {
                          value:
                            _vm.profile.document_personal_subscriptions
                              .once_per_month,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.profile.document_personal_subscriptions,
                              "once_per_month",
                              $$v
                            )
                          },
                          expression:
                            "profile.document_personal_subscriptions.once_per_month",
                        },
                      }),
                      _vm._v(
                        " " + _vm._s(_vm.$gettext("Once per month")) + " "
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-1" }),
            _vm._v(" "),
            _c("hr"),
          ]),
          _vm._v(" "),
          _c("label", [
            _vm._v(_vm._s(_vm.$gettext("For new documents for my groups"))),
          ]),
          _vm._v(" "),
          _vm._l(_vm.groups_subscriptions, function (g) {
            return _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("span", { attrs: { title: g.group_name } }, [
                  _vm._v(_vm._s(g.group_code)),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-8" }, [
                _c(
                  "label",
                  { staticClass: "checkbox-inline" },
                  [
                    _c("bootstrap-toggle", {
                      model: {
                        value: g.each_update,
                        callback: function ($$v) {
                          _vm.$set(g, "each_update", $$v)
                        },
                        expression: "g.each_update",
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Each update")) + "  "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "checkbox-inline" },
                  [
                    _c("bootstrap-toggle", {
                      model: {
                        value: g.once_per_day,
                        callback: function ($$v) {
                          _vm.$set(g, "once_per_day", $$v)
                        },
                        expression: "g.once_per_day",
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Once per day")) + " "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "checkbox-inline" },
                  [
                    _c("bootstrap-toggle", {
                      model: {
                        value: g.once_per_week,
                        callback: function ($$v) {
                          _vm.$set(g, "once_per_week", $$v)
                        },
                        expression: "g.once_per_week",
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Once per week")) + " "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "checkbox-inline" },
                  [
                    _c("bootstrap-toggle", {
                      model: {
                        value: g.once_per_month,
                        callback: function ($$v) {
                          _vm.$set(g, "once_per_month", $$v)
                        },
                        expression: "g.once_per_month",
                      },
                    }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Once per month")) + " "),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-1" }, [
                g.custom
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-default trash-btn-xs",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.removeGroupSubscription(
                              $event,
                              g.group_id
                            )
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "glyphicon glyphicon-trash",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr"),
            ])
          }),
          _vm._v(" "),
          _vm.$root.can_subscribe_all_groups()
            ? [
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.openGroupSelection.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$gettext("Add group")))]
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.updateSubscriptions.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$gettext("Apply")))]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }