<template>
  <div class="row">
    <div class="col-md-8">
       <search></search>
    </div>
    <div class="col-md-4">
      <agenda></agenda>
    </div>
  </div>
</template>

<script>
  import Agenda from './components/agendas'
  import Search from './search'

  /**
   * home component description
   * @vuedoc
   * @exports Home
   */
  export default {
    name: 'home',
    components: { Agenda, Search },
    methods: {

    },
    mounted () {
      this.$root.breadcrumb = [{name: this.$pgettext('breadcrumb', 'Home')}]
    }
  }
</script>

<style lang="scss" scoped>
/*.search-filters {*/
/*  margin-top: 30px;*/
/*  display: inline-block;*/
/*}*/

</style>
