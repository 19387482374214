<template>
  <div class="modal fade" id="query-editor" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col-sm-6">
            <h2>{{$gettext('List of queries')}}</h2>
          </div>
          <div class="col-sm-6">
            <input id="query-editor-filter" tabindex="1" class="form-control input-sm pull-right" autocomplete="off" :placeholder="$gettext('Filter')" name="filter" v-model="filter" v-clear/>
          </div>
        </div>
        <div class="modal-body">
          <table class="table table-condensed table-striped">
            <tbody>
              <tr v-for="(q, index) in filteringQueryList">
                <td></td>
<!--                <td @click="toggleFavorite(q)" :title="$gettext('Favorite')"><span class="glyphicon" :class="q.favorite ? 'glyphicon-star' : 'glyphicon-star-empty'" ></span></td>-->
                <template v-if="editingQuery !== q">
                  <td @dblclick="editQuery(q)">{{ q.name }}</td>
                  <td class="clearfix">
                    <div class="btn-group btn-group-sm pull-right" role="group" aria-label="...">
                      <button :disabled="editingQuery" :title="$gettext('Edit')" @click="editQuery(q)" class="btn btn-secondary" tabindex="-1"><span class="glyphicon glyphicon-pencil"></span></button>
                      <button class="btn btn-primary" :disabled="editingQuery" @click="runQuery(q)" :title="$gettext('Run')" :tabindex="index + 10" ><span class="glyphicon glyphicon-play"></span></button>
                    </div>
                  </td>
                </template>
                <template v-else>
                  <td><input @keyup.enter="renameQuery(q)" @keyup.esc.stop="cancelEditingQuery()" autocomplete="off" class="input-sm form-control" id="input-query-name" v-model="editingQueryName"/></td>
                  <td class="clearfix">
                    <div class="btn-group btn-group-sm pull-right" role="group" aria-label="...">
                      <button class="btn btn-primary" :disabled="editingQueryName === ''"  @click="renameQuery(q)" :title="$gettext('Validate')" tabindex="-1"><span class="glyphicon glyphicon glyphicon-ok"></span></button>
                      <button class="btn btn-secondary" @click="cancelEditingQuery()" :title="$gettext('Cancel')"><span class="glyphicon glyphicon-remove" tabindex="-1"></span></button>
                      <button class="btn btn-danger" data-action="delete" @click="deleteQuery(q)" :title="$gettext('Delete')" tabindex="-1"><span class="glyphicon glyphicon-trash"></span></button>
                    </div>
                  </td>
                </template>
              </tr>
            </tbody>
            <tfoot>

            </tfoot>
          </table>
        </div>
        <div class="modal-footer">
          <span class="pull-left no-execute-panel">
            <input type="checkbox" v-model="viewBefore" tabindex="-1"> {{$gettext('Edit the query before running it')}}
          </span>
          <button data-dismiss="modal" data-action="cancel" :disabled="editingQuery" tabindex="-1" class="btn btn-default">{{$gettext('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import Vue from 'vue'
  import message from 'toastr'
  import {messageBox} from './utils/dialogs'
  import Fuse from 'fuse.js'

  const queryManager = {
    name: 'queryManager',
    data: function () {
      return {
        editingQuery: null,
        editingQueryName: null,
        viewBefore: false,
        filter: '',
        queries: []
      }
    },
    computed: {
      filteringQueryList () {
        return this.$fuse && this.filter !== '' ? this.$fuse.search(this.filter) : this.queries
      }
    },
    methods: {
      renameQuery (query) {
        if (query.name === this.editingQueryName) {
          return this.cancelEditingQuery()
        }
        this.$root.$restAPI.patch(`/user/query/${query.id}/`, {'name': this.editingQueryName}).then(response => {
          Vue.set(query, 'name', response.data.name)
          this.$root.$emit('manageQuery', 'rename', query, this.editingQueryName)
          this.editingQuery = null
        }).catch(error => {
          message.error(error.response.data.message)
        })
      },
      cancelEditingQuery () {
        this.editingQuery = null
      },
      editQuery (query) {
        this.editingQuery = query
        this.editingQueryName = query.name
        Vue.nextTick(() => {
          let $input = document.getElementById('input-query-name')
          $input.select()
          $input.focus()
        })
      },
      runQuery (query) {
        this.$root.$emit('manageQuery', 'run', query, this.viewBefore)
        this.close()
      },
      deleteQuery (query) {
        messageBox(this.$gettext('Delete query'), this.$gettext('Are you sure you want to delete this query ?'), { 'delete': this.$gettext('Delete'), 'cancel': this.$gettext('Cancel') })
          .then((action) => {
            if (action === 'delete') {
              this.$root.$restAPI.delete(`/user/query/${query.id}/`).then(response => {
                if (response.status === 204) {
                  this.editingQuery = null
                  this.load()
                  this.$root.$emit('manageQuery', 'delete', query)
                }
              }).catch(error => {
                message.error(error.response.data.message)
              })
            }
          })
      },
      toggleFavorite (query) {
        let favorite = !(query.favorite || false)
        this.$root.$restAPI.patch(`/user/query/${query.id}/`, {'favorite': favorite}).then(response => {
          Vue.set(query, 'favorite', favorite)
          this.$root.$emit('manageQuery', 'favorite', query, favorite)
        }).catch(error => {
          message.error(error.response.data.message)
        })
      },
      close () {
        $(this.$el).modal('hide')
      },
      load() {
        this.$root.$restAPI.get(`/user/query/`).then(response => {
          this.queries = response.data
          this.$fuse = new Fuse(this.queries, {
            keys: ['name'],
            threshold: 0.5
          })
        }).catch(error => {
          message.error(error.response.data.message)
        })
      }
    },
    mounted () {
      this.load()
    }
  }

  export function openQueryEditor (root, queries) {
    let editor = document.getElementById('query-editor')
    if (editor === null) {
      editor = document.createElement('div')
      let body = document.querySelector('body')
      body.appendChild(editor)
    }
    // return new Promise((resolve, reject) => {})
    new Vue({
      name: 'query-editor',
      parent: root,
      components: {queryManager},
      render: function (h) {
        return h('query-manager', {props: {}})  // queries
      },
      mounted () {
        $(this.$el).modal({backdrop: 'static', show: true})
        $(this.$el).on('hidden.bs.modal', () => {
          this.$destroy()
        })
        $(this.$el).on('shown.bs.modal', () => {
          document.getElementById('query-editor-filter').focus()
        })
      }
    }).$mount(editor)
  }

  export default queryManager
</script>

<style scoped>
  .modal-header {
    padding: 15px 0 15px 0;
  }

  .modal-header h2 {
    display: inline-block;
  }

  .modal-header input {
    width: 200px;
    float: right;
  }

  table td {
    line-height: 28px;
  }

  table td:last-child {
    white-space: nowrap;
    width: 1px;
  }

  table .btn-group {
    width: max-content;
  }

  table thead {
    display: block;
  }

  table tr {
    height: 40px;
    vertical-align: middle;
  }

  table tr td {
    vertical-align: middle;
  }

  table tr:first-child td {
    border-top: none;
  }

  table {
    display: block;
    border: 1px solid #ccc;
    padding: 4px;
    table-layout: auto;
  }

  table tbody {
    display: block;
    height: 200px;
    overflow-y: auto;
  }

  table tbody tr:first-child td:nth-child(1) {
    width: 20px;
  }

  table tbody tr:first-child td:nth-child(2) {
    width: 100%;
  }

  table tbody tr:first-child td:nth-child(3) {
    width: 100px;
    display: inline-block;
  }

  td .glyphicon.glyphicon-star-empty {
    color: #ccc;
    line-height: 28px;
    font-size: 15px;
  }

  td .glyphicon.glyphicon-star {
    color: #777;
    line-height: 28px;
    font-size: 15px;
  }

  .no-execute-panel {
    line-height: 33px;
    font-size: 12px;
    color: #777;
    vertical-align: middle;
    margin-left: 4px;
  }

  .no-execute-panel input[type="checkbox"] {
    vertical-align: middle;
    margin: 0 5px 0 0;
  }
  table .btn {
    margin-top: 1px;
    margin-bottom: 0;
    height: 34px;
    min-width: 32px;
    max-width: 32px;
    margin-left: 1px;
  }
</style>
