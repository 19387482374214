var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row display-flex application-body" }, [
    _c(
      "div",
      { staticClass: "col-md-3 hidden-xs" },
      [
        _c(
          "panel",
          {
            attrs: {
              title: _vm.$gettext("Filters"),
              "icon-class": "fa fa-filter",
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function () {
                  return [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.collapseAllFilters()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$gettext("Collapse all")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resetAllFilters()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$gettext("Reset all filters")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", {
                      staticClass: "divider",
                      attrs: { role: "separator" },
                    }),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.refreshFilters.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-refresh" }),
                          _vm._v(" " + _vm._s(_vm.$gettext("Refresh"))),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "div",
              { staticClass: "panel-container" },
              [
                _c("treeview", {
                  ref: "treeview-agenda",
                  attrs: {
                    data: [
                      {
                        id: "0",
                        text: _vm.$gettext("Meetings"),
                        section: true,
                        childs: true,
                      },
                    ],
                    onclick: _vm.doAgendaClick,
                    onload: _vm.$root.loadAgendaFilters,
                    selectable: false,
                  },
                  on: { "checked-nodes-changed": _vm.onAgendaFilterChecked },
                }),
                _vm._v(" "),
                _c("treeview", {
                  ref: "treeview-posted",
                  attrs: {
                    onclick: _vm.doPostedClick,
                    onload: _vm.$root.loadDatePostedFilters,
                    data: [
                      {
                        id: "0",
                        text: _vm.$gettext("Date posted"),
                        section: true,
                        childs: true,
                      },
                    ],
                    selectable: false,
                  },
                  on: { "checked-nodes-changed": _vm.onPostedFilterChecked },
                }),
                _vm._v(" "),
                _c("treeview", {
                  ref: "treeview-comment",
                  attrs: {
                    onclick: _vm.doForCommentClick,
                    onload: _vm.$root.loadCommentFilters,
                    data: [
                      {
                        id: "0",
                        text: _vm.$gettext("For comment"),
                        section: true,
                        childs: true,
                      },
                    ],
                    selectable: false,
                  },
                  on: { "checked-nodes-changed": _vm.onCommentFilterChecked },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "panel",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.detached,
                expression: "!detached",
              },
            ],
            attrs: {
              title: _vm.$gettext("Folders"),
              "icon-class": {
                fa: true,
                "fa-folder-open": _vm.folderPanelOpen,
                "fa-folder": !_vm.folderPanelOpen,
              },
            },
            on: {
              dblclick: function ($event) {
                return _vm.toggleFolderPanel()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "menu",
                fn: function () {
                  return [
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.collapseAllFolders()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Collapse all")))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.resetAllFolders()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Reset all filters")))]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.selectAllFolders()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$gettext("Select all")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", {
                      staticClass: "divider",
                      attrs: { role: "separator" },
                    }),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$root.loadCollections.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-refresh" }),
                          _vm._v(" " + _vm._s(_vm.$gettext("Refresh"))),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", {
                      staticClass: "divider",
                      attrs: { role: "separator" },
                    }),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.createPanel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$gettext("Detach")) +
                              " "
                          ),
                          _c("small", [_vm._v("(Experimental)")]),
                        ]
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isFolderOpen,
                    expression: "isFolderOpen",
                  },
                ],
                staticClass: "panel-folder panel-container",
              },
              [
                _c(
                  "div",
                  { staticClass: "panel-folder panel-container-content" },
                  [
                    _c("treeview", {
                      ref: "folders",
                      attrs: {
                        onload: _vm.$root.loadCollections,
                        onclick: _vm.doFolderClick,
                        selectable: false,
                      },
                      on: {
                        "checked-nodes-changed": _vm.onFolderFilterChecked,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("favourites"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-9 col-xs-12" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }