var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: { "field-edit": _vm.edit } },
    [
      !_vm.edit
        ? [_vm._v(_vm._s(_vm.current))]
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newValue,
                expression: "newValue",
              },
            ],
            domProps: { value: _vm.newValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.newValue = $event.target.value
                },
                function ($event) {
                  return _vm.updateValue()
                },
              ],
            },
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }