var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "panel",
    {
      attrs: {
        title: _vm.$gettext("My favourites"),
        "icon-class": "fa fa-star",
      },
      scopedSlots: _vm._u([
        {
          key: "menu",
          fn: function () {
            return [
              _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.load()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-refresh" }),
                    _vm._v(" " + _vm._s(_vm.$gettext("Refresh"))),
                  ]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.loaded
        ? _c(
            "ul",
            [
              _vm.items.length === 0
                ? _c("li", { staticClass: "no-item" }, [
                    _vm._v(_vm._s(_vm.$gettext("No favourites"))),
                  ])
                : _c(
                    "draggable",
                    {
                      on: { change: _vm.doChange },
                      model: {
                        value: _vm.items,
                        callback: function ($$v) {
                          _vm.items = $$v
                        },
                        expression: "items",
                      },
                    },
                    [
                      _c(
                        "transition-group",
                        _vm._l(_vm.items, function (item) {
                          return _c("li", { key: item.document_id }, [
                            _c("i", { staticClass: "fa fa-file" }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                attrs: { href: item.url },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.openItem(item, $event)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                          ])
                        }),
                        0
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _c("spinner"),
      _vm._v(" "),
      _vm.failed
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.$gettext("Loading failed.")) + " ("),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.load(true)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Retry")))]
            ),
            _vm._v(")"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }