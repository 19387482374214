<template>
  <vue-cal class="agenda"
           :locale="$language.current"
           :time-from="6 * 60"
           :time-to="23 * 60"
           :time-step="60"
           :disable-views="['day']"
           events-count-on-year-view
           :selectedDate="selectedDate"
           :on-event-click="onEventClick"
           @view-change="onViewChanged"
           :events="events">
  </vue-cal>
</template>

<script>
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/i18n/fr'
  import 'vue-cal/dist/vuecal.css'
  import { openAgendaDialog } from './components/agenda-dialog'
  import dateFormat from 'dateformat'

  export default {
    name: 'calendar',
    components: { VueCal },
    data () {
      return {
        events: []
      }
    },
    computed: {
      selectedDate () {
        let date = this.$route.query.date
        if (date) {
          return new Date(date)
        }
        return ''
      }
    },
    methods: {
      onViewChanged(ev) {
        this.load(ev.startDate, ev.endDate)
      },
      onEventClick (event, ev) {
        if (ev.target.tagName !== 'A') {
          openAgendaDialog(this.$root, event)
        }
      },
      load (dateStart, dateEnd) {
        let params = {}
        if (dateStart && dateEnd) {
          params = {dateStart: dateFormat(dateStart, 'yyyy-mm-dd'), dateEnd: dateFormat(dateEnd, 'yyyy-mm-dd')}
        } else if (this.selectedDate) {
          let dt = this.selectedDate
          let currentWeekDay = dt.getDay()
          let lessDays = currentWeekDay == 0 ? 6 : currentWeekDay - 1
          dateStart = new Date(new Date(dt).setDate(dt.getDate() - lessDays))
          dateEnd = new Date(new Date(dateStart).setDate(dateStart.getDate() + 6))
          params = {dateStart: dateFormat(dateStart, 'yyyy-mm-dd'), dateEnd: dateFormat(dateEnd, 'yyyy-mm-dd')}
        }
        this.$restAPI.get('/user/meetings/', {params} ).then(response => {
          this.events = response.data.map(item => {
            return {
              'class': 'meeting',
              'start': item.start_datetime,
              'end': item.end_datetime,
              'title': item.subject,
              'content': item.summary,
              'url': item.url
            }
          })
        })
      }
    },
    mounted () {
      this.load()
      this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.$pgettext('breadcrumb', 'My calendar') }]
    }
  }
</script>

<style>
  .vuecal__menu button {
    border-bottom-color: #fff;
    color: #fff;
  }

  .vuecal__menu button.active {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .vuecal__title-bar {
    background-color: rgba(21, 123, 168, 0.29);
  }

  .vuecal__cell.today, .vuecal__cell.current {
    background-color: rgba(240, 240, 255, 0.4);
  }

  .vuecal:not(.vuecal--day-view) .vuecal__cell.selected {
    background-color: rgba(225, 225, 225, 0.78);
  }

  .vuecal__cell.selected:before {
    border-color: rgba(27, 126, 172, 0.5);
  }

  .vuecal__event.meeting {
    background-color: rgba(253, 156, 66, 0.9);
    border: 1px solid rgb(233, 136, 46);
    color: #fff;
    margin-bottom: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .vuecal__cell-events-count {
    min-width: 4px;
    height: 16px;
    padding: 2px 4px;
  }
  .vuecal__menu, .vuecal__cell-events-count {
    background-color: #1b7eac;
  }
  .vuecal__cell-events {
    cursor: pointer;
  }

  .vuecal__event-time {
    display: none;
  }

</style>

<style scoped>
  .agenda {
    min-height: 600px;
    margin-top: 8px;
    margin-bottom: 80px;
  }
</style>
