var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog", id: "team-dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h2", { staticClass: "modal-title" }, [
                _vm._v(_vm._s(_vm.$gettext("Access for"))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter,
                    expression: "filter",
                  },
                ],
                staticClass: "form-control pull-right",
                attrs: {
                  tabindex: "1",
                  autocomplete: "off",
                  placeholder: _vm.$gettext("Filter"),
                  name: "filter",
                },
                domProps: { value: _vm.filter },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.filter = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("div", { staticClass: "table-top-panel" }, [
                _c("label", { staticClass: "radio-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "inlineRadioOptions",
                      id: "inlineRadio1",
                    },
                    domProps: { value: 1, checked: _vm._q(_vm.mode, 1) },
                    on: {
                      change: function ($event) {
                        _vm.mode = 1
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$gettext("My groups")) + "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "inlineRadioOptions",
                      id: "inlineRadio2",
                    },
                    domProps: { value: 2, checked: _vm._q(_vm.mode, 2) },
                    on: {
                      change: function ($event) {
                        _vm.mode = 2
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$gettext("All groups")) + "\n          "
                  ),
                ]),
                _vm._v(" "),
                _c("label", { staticClass: "radio-inline" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "inlineRadioOptions",
                      id: "inlineRadio3",
                    },
                    domProps: { value: 3, checked: _vm._q(_vm.mode, 3) },
                    on: {
                      change: function ($event) {
                        _vm.mode = 3
                      },
                    },
                  }),
                  _vm._v(" " + _vm._s(_vm.$gettext("Users")) + "\n          "),
                ]),
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "table-col-1" }, [
                      _c("input", {
                        attrs: { id: "select-all", type: "checkbox" },
                        on: {
                          change: function ($event) {
                            return _vm.toggleAllItemSelection($event)
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.mode !== 3
                      ? _c("th", { staticClass: "table-col-3" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleOrderBy("code")
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$gettext("Code")) + " "),
                              _c("i", {
                                staticClass: "fa",
                                class: _vm.getSortOrderIconClass("code"),
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", { staticClass: "table-col-2" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggleOrderBy(
                                _vm.mode === 3 ? "display_name" : "name"
                              )
                            },
                          },
                        },
                        [
                          _vm._v(_vm._s(_vm.$gettext("Name")) + " "),
                          _c("i", {
                            staticClass: "fa",
                            class: _vm.getSortOrderIconClass("name"),
                          }),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.mode === 3 || _vm.mode === 4
                      ? _c("th", { staticClass: "table-col-4" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleOrderBy("email")
                                },
                              },
                            },
                            [
                              _vm._v(_vm._s(_vm.$gettext("Email")) + " "),
                              _c("i", {
                                staticClass: "fa",
                                class: _vm.getSortOrderIconClass("email"),
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "tr",
                      {
                        key: item.code,
                        attrs: { "data-user-id": item.code },
                        on: {
                          dblclick: function ($event) {
                            return _vm.rowSelect(item)
                          },
                        },
                      },
                      [
                        _c("td", { staticClass: "table-col-1" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: { checked: _vm.hasItemSelected(item) },
                            on: {
                              change: function ($event) {
                                return _vm.toggleItemSelection(item, $event)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.mode !== 3
                          ? _c("td", { staticClass: "table-col-3" }, [
                              _vm._v(_vm._s(item.code)),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { staticClass: "table-col-2" }, [
                          _vm._v(_vm._s(item.name || item.display_name)),
                        ]),
                        _vm._v(" "),
                        _vm.mode === 3 || _vm.mode === 4
                          ? _c("td", { staticClass: "table-col-4" }, [
                              _vm._v(_vm._s(item.email)),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.items.length === 0
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-info",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$gettext("No result found.")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.numPages > 1
                ? _c("paginator", {
                    attrs: { params: _vm.params, num_page: _vm.numPages },
                    on: {
                      change: function ($event) {
                        return _vm.loadData()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$gettext("OK")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }