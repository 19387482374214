var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "comment-editor",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: {
                  id: "comment-editor-form",
                  autocomplete: "off",
                  novalidate: "",
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("label", [_vm._v(_vm._s(_vm.$gettext("Attachments")))]),
                    _vm._v(" "),
                    _c("div", { staticClass: "comment-attachments dropzone" }, [
                      _c(
                        "ul",
                        { staticClass: "dz-server-files" },
                        _vm._l(_vm.attachments, function (att) {
                          return _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  "data-id": att.uuid,
                                  "data-size": att.size,
                                  "data-content-type": att.content_type,
                                  href: att.url,
                                },
                              },
                              [_vm._v(_vm._s(att.filename))]
                            ),
                          ])
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "dz-template" }, [
                        _c(
                          "div",
                          { staticClass: "dz-preview dz-file-preview" },
                          [
                            _vm._m(1),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _vm._m(3),
                            _vm._v(" "),
                            _vm._m(4),
                            _vm._v(" "),
                            _c("div", { staticClass: "dz-success-mark" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "54px",
                                    height: "54px",
                                    viewBox: "0 0 54 54",
                                    version: "1.1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    "xmlns:sketch":
                                      "http://www.bohemiancoding.com/sketch/ns",
                                  },
                                },
                                [
                                  _c("title", [_vm._v("Check")]),
                                  _vm._v(" "),
                                  _c("defs"),
                                  _vm._v(" "),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Page-1",
                                        stroke: "none",
                                        "stroke-width": "1",
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        "sketch:type": "MSPage",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d: "M23.5,31.8431458 L17.5852419,25.9283877 C16.0248253,24.3679711 13.4910294,24.366835 11.9289322,25.9289322 C10.3700136,27.4878508 10.3665912,30.0234455 11.9283877,31.5852419 L20.4147581,40.0716123 C20.5133999,40.1702541 20.6159315,40.2626649 20.7218615,40.3488435 C22.2835669,41.8725651 24.794234,41.8626202 26.3461564,40.3106978 L43.3106978,23.3461564 C44.8771021,21.7797521 44.8758057,19.2483887 43.3137085,17.6862915 C41.7547899,16.1273729 39.2176035,16.1255422 37.6538436,17.6893022 L23.5,31.8431458 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z",
                                          id: "Oval-2",
                                          "stroke-opacity": "0.198794158",
                                          stroke: "#747474",
                                          "fill-opacity": "0.816519475",
                                          fill: "#888",
                                          "sketch:type": "MSShapeGroup",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "dz-error-mark" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "54px",
                                    height: "54px",
                                    viewBox: "0 0 54 54",
                                    version: "1.1",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    "xmlns:sketch":
                                      "http://www.bohemiancoding.com/sketch/ns",
                                  },
                                },
                                [
                                  _c("title", [_vm._v("Error")]),
                                  _vm._v(" "),
                                  _c("defs"),
                                  _vm._v(" "),
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Page-1",
                                        stroke: "none",
                                        "stroke-width": "1",
                                        fill: "none",
                                        "fill-rule": "evenodd",
                                        "sketch:type": "MSPage",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "Check-+-Oval-2",
                                            "sketch:type": "MSLayerGroup",
                                            stroke: "#747474",
                                            "stroke-opacity": "0.198794158",
                                            fill: "#d76156",
                                            "fill-opacity": "0.7",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z",
                                              id: "Oval-2",
                                              "sketch:type": "MSShapeGroup",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 setting-field" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": _vm.errors && _vm.errors.type },
                      },
                      [
                        _c("label", { attrs: { for: "comment_type" } }, [
                          _vm._v(_vm._s(_vm.$gettext("Type")) + " *"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.type,
                                expression: "type",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "comment_type" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.type = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.doChangeType,
                              ],
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v(_vm._s(_vm.$gettext("Select a type")))]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.types, function (item) {
                              return _c(
                                "option",
                                {
                                  key: item.id,
                                  attrs: {
                                    "data-hide-origin": item.hide_origin,
                                  },
                                  domProps: { value: item.id },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.type
                          ? _c("span", { staticClass: "help-block" }, [
                              _vm._v(_vm._s(_vm.errors.type[0])),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 setting-field" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group",
                        class: { "has-error": _vm.errors && _vm.errors.origin },
                      },
                      [
                        _c("label", { attrs: { for: "comment_origin" } }, [
                          _vm._v(_vm._s(_vm.$gettext("Origin")) + " *"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.origin,
                                expression: "origin",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { id: "comment_origin" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.origin = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v(_vm._s(_vm.$gettext("Select an origin")))]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.userOrigins, function (items, label) {
                              return _c(
                                "optgroup",
                                { attrs: { label: label } },
                                _vm._l(items, function (item) {
                                  return _c(
                                    "option",
                                    {
                                      key: item.id,
                                      domProps: { value: item.id },
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  )
                                }),
                                0
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.errors && _vm.errors.origin
                          ? _c("span", { staticClass: "help-block" }, [
                              _vm._v(_vm._s(_vm.errors.origin[0])),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-4 setting-field" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.forceHideOrigin,
                            expression: "!forceHideOrigin",
                          },
                        ],
                        staticClass: "checkbox",
                      },
                      [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.showOrigin,
                                expression: "showOrigin",
                              },
                            ],
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.showOrigin)
                                ? _vm._i(_vm.showOrigin, null) > -1
                                : _vm.showOrigin,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.showOrigin,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.showOrigin = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.showOrigin = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.showOrigin = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " " +
                              _vm._s(_vm.$gettext("Show origin")) +
                              "\n                "
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "div",
              { staticClass: "checkbox pull-left modal-default-checkbox" },
              [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.keepDefault,
                        expression: "keepDefault",
                      },
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.keepDefault)
                        ? _vm._i(_vm.keepDefault, null) > -1
                        : _vm.keepDefault,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.keepDefault,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.keepDefault = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.keepDefault = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.keepDefault = $$c
                        }
                      },
                    },
                  }),
                  _vm._v(
                    " " + _vm._s(_vm.$gettext("Per default")) + "\n          "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.submit()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.commentId ? _vm.$gettext("Save") : _vm.$gettext("Add")
                  ) + "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { "data-dismiss": "modal", "data-action": "cancel" },
              },
              [_vm._v(" " + _vm._s(_vm.$gettext("Cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-12 comment-content" }, [
        _c("textarea", {
          staticClass: "form-control",
          attrs: { id: "id_content" },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dz-image" }, [
      _c("img", { attrs: { "data-dz-thumbnail": "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dz-details" }, [
      _c("div", { staticClass: "dz-size" }, [
        _c("span", { attrs: { "data-dz-size": "" } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dz-filename" }, [
        _c("a", { attrs: { "data-dz-name": "" } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dz-progress" }, [
      _c("span", {
        staticClass: "dz-upload",
        attrs: { "data-dz-uploadprogress": "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "dz-error-message" }, [
      _c("span", { attrs: { "data-dz-errormessage": "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }