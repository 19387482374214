import { render, staticRenderFns } from "./agenda-dialog.vue?vue&type=template&id=e5d92504&scoped=true"
import script from "./agenda-dialog.vue?vue&type=script&lang=js"
export * from "./agenda-dialog.vue?vue&type=script&lang=js"
import style0 from "./agenda-dialog.vue?vue&type=style&index=0&id=e5d92504&prod&lang=scss&scoped=true"
import style1 from "./agenda-dialog.vue?vue&type=style&index=1&id=e5d92504&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5d92504",
  null
  
)

export default component.exports