<template>
  <div class="modal fade" id="group-selector" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col-sm-12">
            <h2>{{$gettext('Select group')}}</h2>
          </div>
        </div>
        <div class="modal-body">
          <div class="row" v-show="search_all_group">
            <div class="col-sm-6">
              <div class="form-group">
                <input id="all_groups" type="checkbox" v-model="filter_all_groups">
                <label for="all_groups">{{$gettext('Show all groups')}}</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label for="search_code">{{$gettext('Group code')}}</label>
                <input type="text" class="form-control" id="search_code" @keyup.enter="load(true)" placeholder="" v-model="filter_code" v-clear>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="search_name">{{$gettext('Group name')}}</label>
                <input type="text" class="form-control" id="search_name" @keyup.enter="load(true)" placeholder="" v-model="filter_name" v-clear>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <button class="btn btn-default" @click="clearFilters" type="button">{{$gettext('Clear')}}</button>
                <button class="btn btn-default" @click="searchGroups" type="button">{{$gettext('Search')}}</button>
              </div>
            </div>
          </div>
          <table class="table table-striped table-groups">
            <thead>
              <tr>
                <th><label><input type="checkbox" @change="selectAllItems($event)"></label></th>
                <th class="column-short-text"><a @click.prevent="changeOrdering('code')">{{$gettext('Group code')}}&nbsp;<i v-if="sortedColumn==='code'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a></th>
                <th class="column-text"><a @click.prevent="changeOrdering('name')">{{$gettext('Group name')}}&nbsp;<i v-if="sortedColumn==='name'" class="fa" :class="{'fa-sort-amount-desc': sortedOrder==='desc', 'fa-sort-amount-asc': sortedOrder==='asc'}"></i></a></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in groups">
                <td><label><input :id="'gr_'+item.id" type="checkbox" :checked="itemSelected(item)" @click.stop="selectItem(item)"></label></td>
                <td class="column-short-text"><label :for="'gr_'+item.id"><template v-if="item.deprecated"><s>{{ item.code }}</s></template><template v-else>{{ item.code }}</template></label></td>
                <td class="column-text"><label :for="'gr_'+item.id"><template v-if="item.deprecated"><s>{{ item.name }}</s></template><template v-else>{{ item.name }}</template></label></td>
              </tr>
            </tbody>
          </table>
          <paginator v-if="numPages > 1" :params="params" :num_page="numPages" @change="load()"></paginator>
        </div>
        <div class="modal-footer">
          <button data-dismiss="modal" @click="submit" tabindex="-1" class="btn btn-primary">{{$gettext('OK')}}</button>
          <button data-dismiss="modal" @click="cancel" tabindex="-1" class="btn btn-secondary">{{$gettext('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import Vue from 'vue'
  import InfiniteTable from './components/infinite-table'
  import debounce from 'lodash.debounce'
  import Paginator from './components/paginator'

  const FILTER_DELAY = 300

  const groupSelector = {
    name: 'groupSelector',
    components: { InfiniteTable, Paginator },
    props : {
      initialSelection: Set,
      initialAllGroup: false,
      onlyActive: false
    },
    data: function () {
      return {
        groups: [],
        search_all_group: false,
        numPages: 1,
        total: 0,
        params: { page: 1 },
        loading: false,
        filter_all_groups: this.initialAllGroup,
        filter_code: '',
        filter_name: '',
        forward: true,
        selection: new Set(this.initialSelection),
        selectedItems: {},
        inverseSelection: false,
        sortedColumn: null,
        sortedOrder: 'asc',
      }
    },
    watch: {
      filter_all_groups: debounce(function () {
        this.load(true)
      }, FILTER_DELAY),
      inverseSelection () {
        // this.selection.clear()
      }
    },
    computed: {
      pageSize () {
        return Math.trunc(this.total / this.numPages)
      },
      recordNumber () {
        return this.pageSize * (this.params.page - 1) + this.currentIndex + 1
      }
    },
    methods: {
      clearFilters () {
        this.filter_code = ''
        this.filter_name = ''
      },
      searchGroups () {
        this.load(true)
      },
      changeOrdering (columnName) {
        if (this.sortedColumn === columnName) {
          this.sortedOrder = this.sortedOrder === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortedColumn = columnName
          this.sortedOrder = 'asc'
        }
        this.load(true)
      },
      selectAllItems(e) {
        if (e.target.checked) {
          this.groups.forEach(item => this.selectItem(item))
        } else {
          this.groups.forEach(item => this.unSelectItem(item))
        }
      },
      selectItem(item) {
        if (!this.selection.has(item.id)) {
          this.selection.add(item.id)
          this.selectedItems[item.id] = item
        }
        this.$forceUpdate()
      },
      unSelectItem(item) {
        if (this.selection.has(item.id)) {
          this.selection.delete(item.id)
          delete this.selectedItems[item.id]
        }
        this.$forceUpdate()
      },
      itemSelected(item) {
        let inSelection = this.selection.has(item.id)
        return this.inverseSelection ? !inSelection : inSelection
      },
      close () {
        $(this.$el).modal('hide')
      },
      load(resetPage=false, noEmitLoading=false) {
        if (resetPage) this.params.page = 1
        let params = {
          page: this.params.page,
          size: 10
        }
        if (this.filter_code) {
          params['code__icontains'] = this.filter_code
        }
        if (this.filter_name) {
          params['name__icontains'] = this.filter_name
        }
        params['all_groups'] = this.filter_all_groups ? '1' : '0'
        if (this.sortedColumn) {
          params['ordering'] = (this.sortedOrder === 'desc' ? '-' : '') + this.sortedColumn
        }
        if (this.onlyActive) {
          params['active'] = '1'
        }
        if (!noEmitLoading) {
          this.loading=true
        }
        this.$restAPI.get('/groups/', {params}).then(response => {
          this.groups = response.data.results
          this.numPages = response.data.num_pages
          this.total = response.data.count
          this.search_all_group = response.data.all_groups_option
          // this.focus()
        }).catch(error => { console.error(error)}).then(() => this.loading=false)
      },
      submit () {

      },
      cancel () {
        this.selection.clear()
      },
      nextPage () {
        this.params.page++
        this.forward=true
        this.load()
      },
      prevPage () {
        this.params.page--
        this.forward=false
        this.direction='next'
        if (this.params.page < 1) {
          this.params.page = 1;
          return
        }
        this.load()
      },
      focus() {
        this.$nextTick(() => {
        let $input = document.querySelector('#group-selector #search_code')
        if ($input) {
          $input.focus()
        }
      })
      }
    },
    mounted () {
      this.load()
    }
  }

  export function openGroupSelector (root, initialSelection, initialAllGroup = false, ids_only = true, onlyActive=false) {
    return new Promise(function(resolve, reject) {
      let editor = document.getElementById('group-selector')
      if (editor === null) {
        editor = document.createElement('div')
        let body = document.querySelector('body')
        body.appendChild(editor)
      }
      new Vue({
        name: 'group-selector',
        parent: root,
        components: { groupSelector },
        render: function (h) {
          return h('group-selector', { props: {initialSelection: new Set(initialSelection), initialAllGroup: initialAllGroup, onlyActive}, ref: 'component' })
        },
        mounted () {
          $(this.$el).modal({ backdrop: 'static', show: true })
          $(this.$el).on('hidden.bs.modal', () => {
            if (ids_only) {
              resolve(Array.from(this.$refs['component'].selection))
            } else {
              resolve(Object.values(this.$refs['component'].selectedItems))
            }
            this.$destroy()
          })
          $(this.$el).on('shown.bs.modal', () => {

          })
        }
      }).$mount(editor)
    })
  }

  export default groupSelector
</script>

<style scoped>
  .modal-header {
    padding: 15px 0 15px 0;
  }

  .modal-header h2 {
    display: inline-block;
  }

  .modal-header input {
    width: 200px;
    float: right;
  }
  .modal-dialog {
    min-width: 50vw;
  }
  .column-text {
    width: 80%;
    text-align: left;
  }
  .column-short-text {
    width: 20%;
    text-align: left;
  }
  .modal-body {
    height: 600px;
  }
  .table-groups label {
    font-weight: normal;
  }
</style>
