var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alert alert-warning", attrs: { role: "alert" } },
    [_vm._v("Not implemented yet")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }