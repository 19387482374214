var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "treeview" },
    [
      _vm._l(_vm.nodes, function (node) {
        return _c("treeview-node", {
          key: node.id,
          staticClass: "item",
          attrs: { always_expanded: _vm.expanded, depth: 1, node: node },
          on: {
            emitNodeChecked: _vm.onNodeChecked,
            emitNodeExpanded: _vm.onNodeExpanded,
            emitNodeClicked: _vm.onNodeClicked,
            emitNodeSelected: _vm.onNodeSelected,
          },
        })
      }),
      _vm._v(" "),
      !_vm.nodes || _vm.nodes.length === 0
        ? _c("li", { staticClass: "no-item" }, [
            _vm._v(_vm._s(_vm.$gettext("Empty"))),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }