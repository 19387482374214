<template>
  <form-wizard :subtitle="subtitle" :title="title" color="#E7A418" errorColor="#e74c3c" ref="wizard"
               @on-complete="doComplete"
               @on-loading="doLoading"
               @on-validate="doValidate"
               @on-error="handleErrorMessage"
  >
    <tab-content v-if="!editMode" :title="$pgettext('Wizard tab', 'Files')" :before-change="validateStep1">
      <label>{{$gettext('Choose your file upload type')}} <span class="help-inline">{{ $gettext('(maximum file size 10 MB)')}}</span></label>
      <div class="radio">
        <label>
          <input type="radio" name="optionsRadios" id="optionsRadios1" :value="false" v-model="documentForComment">
          {{$gettext('Document')}}
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" name="optionsRadios" id="optionsRadios2" :value="true" v-model="documentForComment">
          {{$gettext('Document for comment (You may post an HTML document or a Word document to be converted to HTML)')}}
        </label>
      </div>
      <br>
      <div class="form-group" :class="{'has-error': errors.dropzone}">
        <label for="dropzone" class="control-label">{{$gettext('Upload file(s)')}}</label>
        <vue-dropzone ref="myVueDropzone" id="dropzone"
                      :options="dropzoneOptions"
                      @vdropzone-processing="doFileProcessing"
                      @vdropzone-removed-file="doFileRemoved"
                      @vdropzone-complete="doFileUploaded">
        </vue-dropzone>
        <span v-if="errors.dropzone" class="help-block">{{ errors.dropzone }}</span>
      </div>
      <br>
      <button class="btn btn-secondary convert-to-html" @click="convertToHTML()">{{$gettext('Convert to HTML')}}</button>
      <ul>
        <li v-for="item in convertFiles" v-if="item">
          <span>{{item.origin}} => </span>
          <span v-if="!item.available">{{$gettext('Converting...')}}</span>
          <a v-if="item.available" target="_blank" :href="item.url">{{item.filename}}</a>
        </li>
      </ul>
      <br>
    </tab-content>
    <tab-content :title="$pgettext('Wizard tab', 'Metadata')" :before-change="validateStep2">
      <div v-if="metadata.length > 2" class="metadata-file-selector">
        <select class="selectpicker show-tick" id="wizard-select-file" data-style="btn-primary" v-model="currentMetadataFile">
          <option :value="index" v-for="(meta, index) in metadata">{{ meta.filename }}</option>
        </select>
      </div>
      <form class="form-horizontal">
        <div class="metadata-page" v-for="(meta, index) in metadata" :class="{active: currentMetadataFile === index}">
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputEnglishTitle' + index" class="control-label">{{$gettext('English title')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].englishTitle" ></label>
            </div>
            <div class="col-sm-9">
              <input :id="'inputEnglishTitle' + index" :readonly="sameAsDefault[index].englishTitle" class="form-control" type="text" v-model="sameAsDefault[index].englishTitle ? getDefaultValue('englishTitle') : meta.englishTitle">
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputFrenchTitle' + index" class="control-label">{{$gettext('French title')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].frenchTitle" ></label>
            </div>
            <div class="col-sm-9">
              <input :id="'inputFrenchTitle' + index" :readonly="sameAsDefault[index].frenchTitle" class="form-control" type="text" v-model="sameAsDefault[index].frenchTitle ? getDefaultValue('frenchTitle') : meta.frenchTitle">
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputLatinTitle' + index" class="control-label">{{$gettext('Latin title')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].latinTitle" ></label>
            </div>
            <div class="col-sm-9">
              <input :id="'inputLatinTitle' + index" :readonly="sameAsDefault[index].latinTitle" class="form-control" type="text" v-model="sameAsDefault[index].latinTitle ? getDefaultValue('latinTitle') : meta.latinTitle">
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputKeywords' + index" class="control-label">{{$gettext('Keywords / Topic')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].keywords"></label>
            </div>
            <div class="col-sm-9">
              <select2 :id="'inputKeywords' + index" :readonly="sameAsDefault[index].keywords" :options="editMode ? keywords : metadata[0].keywords" :settings="getSelect2Settings('/autocomplete/keywords', true)" class="form-control" multiple="multiple" v-model="sameAsDefault[index].keywords ? getDefaultValue('keywords') : meta.keywords"></select2>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputMonoNumbers' + index" class="control-label">{{$gettext('Monograph number(s)')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].monoNumbers" ></label>
            </div>
            <div class="col-sm-9">
              <select2 :id="'inputMonoNumbers' + index" :readonly="sameAsDefault[index].monoNumbers" :options="editMode ? monoNumbers : metadata[0].monoNumbers" :settings="getMonoNumberSettings()" class="form-control" multiple="multiple" v-model="sameAsDefault[index].monoNumbers ? getDefaultValue('monoNumbers') : meta.monoNumbers"></select2>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputRestricted' + index" class="control-label">{{$gettext('Restricted document')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].restricted" ></label>
            </div>
            <div class="col-sm-9">
              <div class="checkbox alert alert-info">
                <label>
                  <input type="checkbox" :id="'inputRestricted' + index" :disabled="sameAsDefault[index].restricted" v-model="sameAsDefault[index].restricted ? getDefaultValue('restricted') : meta.restricted">
                  <span><i class="fa fa-lock text-danger"></i>{{$gettext(' Tick this box if your document must not be visible to EDQM users, unless they are given access at the next step under Access rights.')}}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputAgenda' + index" class="control-label">{{$gettext('Meeting(s)')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].meetings" ></label>
            </div>
            <div class="col-sm-9 ">
              <div class="input-group">
                <select2 :id="'inputAgenda' + index" :readonly="sameAsDefault[index].meetings" :type="Number" :options="meetings" :settings="getSelect2Settings('/autocomplete/agenda/')" @select="onSelectMeeting" class="form-control" multiple="multiple" v-model="sameAsDefault[index].meetings ? getDefaultValue('meetings') : meta.meetings"></select2>
                <span class="input-group-btn">
                  <button :title="$gettext('Search for all meetings, past and future')" :disabled="sameAsDefault[index].meetings" @click="openAgendaSelection" class="btn btn-default" type="button">...</button>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :class="{'pad-top-0': index !== 0 && metadata.length !== 2}" :for="'inputFolder' + index" class="control-label">{{$gettext('Folder(s)')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].collections" ></label>
            </div>
            <div class="col-sm-9">
              <div class="input-group">
                <select2 :id="'inputFolder' + index" :readonly="sameAsDefault[index].collections" :type="Number" :options="collections" :settings="getSelect2Settings('/autocomplete/collections/')" @select="onSelectCollection" class="form-control" multiple="multiple" v-model="sameAsDefault[index].collections ? getDefaultValue('collections') : meta.collections"></select2>
                <span class="input-group-btn">
                  <button class="btn btn-default" :disabled="sameAsDefault[index].collections" type="button" @click="openFolderSelection">...</button>
                </span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-3 wizard-meta-label">
              <label :for="'inputNewFilename' + index" class="control-label">{{$gettext('Filename')}}</label>
              <label class="same-label" v-if="index !== 0 && metadata.length !== 2" >{{$gettext('Using the default')}} <input type="checkbox" v-model="sameAsDefault[index].newFilename" ></label>
            </div>
            <div class="col-sm-9">
              <input type="text" class="form-control" :readonly="sameAsDefault[index].newFilename" :id="'inputNewFilename' + index" v-model="sameAsDefault[index].newFilename ? getDefaultValue('newFilename') : meta.newFilename" :disabled="editMode">
            </div>
          </div>
        </div>
      </form>
    </tab-content>
    <tab-content :title="$pgettext('Wizard tab', 'Access rights')" :before-change="validateStep3">
      <p v-if="documentForComment">{{$pgettext('Wizard rights', 'Assign rights and deadlines for comments')}}</p>
      <p v-else>{{$pgettext('Wizard rights', 'Assign rights and deadlines for document that is not for comment')}}</p>
      <table v-if="permissions.length > 0" class="table table-striped table-condensed access-rights">
        <thead>
        <tr>
          <th class="column-resource">{{$gettext('User or Group')}}</th>
          <th v-if="documentForComment" class="column-for-comment">{{$gettext('Document for comment')}}</th>
          <th v-if="documentForComment" class="column-comment-until">{{$gettext('Comment until')}}</th>
          <th v-if="documentForComment" class="column-others-visible">{{$gettext('Comments of others visible')}}</th>
          <th class="column-doc-until">{{$gettext('Document visible until')}}</th>
          <th class="column-after-close" v-if="documentForComment">{{$gettext('Comments visible after closing date')}}</th>
          <th v-if="documentForComment" class="column-visible-until">{{$gettext('Comments visible until')}}</th>
          <th class="column-action">{{$gettext('Action')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="perm in permissions">
          <td class="column-resource">
            <i class="fa" :class="{'fa-user': perm.type === 'user', 'fa-users': perm.type === 'group'}"></i> {{perm.name}}
          </td>
          <td v-if="documentForComment" class="column-checkbox">
            <input type="checkbox" v-model="perm.documentForComment" @change="changeDocumentForCommentState(perm)">
          </td>
          <td v-if="documentForComment" class="column-date">
            <datepicker :disabled="!perm.documentForComment" placeholder="__/__/____" v-model="perm.commentUntil"></datepicker>
          </td>
          <td v-if="documentForComment" class="column-checkbox">
            <input type="checkbox" :disabled="!perm.documentForComment" v-model="perm.commentOthersVisible">
          </td>
          <td class="column-date">
            <datepicker placeholder="__/__/____" v-model="perm.documentVisibleUntil"></datepicker>
          </td>
          <td v-if="documentForComment" class="column-checkbox">
            <input type="checkbox" :disabled="!perm.documentForComment" v-model="perm.commentVisibleAfterClose">
          </td>
          <td v-if="documentForComment" class="column-date">
            <datepicker :disabled="!perm.documentForComment" placeholder="__/__/____" v-model="perm.commentVisibleUntil"></datepicker>
          </td>
          <td class="column-action">
            <button @click="removePermission(perm)"><span class="fa fa-trash"></span></button>
          </td>
        </tr>
        </tbody>
      </table>
      <div v-else class="alert alert-warning" role="alert">
        {{$gettext('No access rights defined.')}}
      </div>
      <div>
        <button @click="addUserPermission" class="btn btn-primary"><i class="fa fa-user"></i> {{$gettext('Set users')}}</button>
        <button @click="addGroupPermission" class="btn btn-primary"><i class="fa fa-users"></i> {{$gettext('Set groups')}}</button>
      </div>
      <div v-if="documentForComment">
        <h5>{{$gettext('Receive notifications when comments are made')}}</h5>
        <div class="row"><div class="col-md-6">{{ $gettext('Email') }}<select2 :options="userForCommentSubscriptions" v-model="selectedUserForCommentSubscriptions"></select2></div><div class="col-md-4">{{ $gettext('Frequency') }}<select class="form-control" v-model="selectedFrequencyForCommentSubscriptions">
          <option value="each">{{ getFrequencyDisplayName('each') }}</option>
          <option value="day">{{ getFrequencyDisplayName('day') }}</option>
          <option value="week">{{ getFrequencyDisplayName('week') }}</option>
          <option value="month">{{ getFrequencyDisplayName('month') }}</option>
        </select></div><div class="col-md-2"><button :disabled="isAddNotificationDisabled" @click="addNotification" class="btn btn-primary btn-add-notification">{{ $gettext('Add notification') }}</button></div></div>
        <div class="row">
          <div class="col-md-6 notification-comment-item" v-for="item in comment_subscriptions" :key="item.email + item.frequency">
            <span>{{ getNotificationNameByEmail(item.email) }}: {{getFrequencyDisplayName(item.frequency)}}</span><button class="btn btn-cancel" @click="removeNotification(item)" :title="$gettext('Remove')"><i class="fa fa-close"></i></button>
          </div>
        </div>
      </div>
    </tab-content>
    <tab-content :title="$pgettext('Wizard tab', 'Notifications')">
      <form>
        <div class="form-group">
          <label for="exampleInputEmail1">{{$gettext('Add a message to the notification')}}</label>
          <p class="help-block">{{$gettext('Warning: if you modify/delete the message below, it will also be modified/deleted for those users who have not yet received a notification.')}}</p>
          <textarea class="form-control" id="exampleInputEmail1" placeholder="" v-model="notificationMessage"></textarea>
        </div>
        <br>
        <label>{{$gettext('Notifications')}}</label>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="notificationEnabled">{{$gettext('Send a notification to recipients')}} <span v-if="this.editMode"></span>
          </label>
        </div>
        <div class="notification-users">
          <span v-for="perm in permissions">
            <input type="checkbox" :id="perm.name" :disabled="!notificationEnabled" :value="perm.send_notification" v-model="perm.send_notification">
            <label :for="perm.name">{{perm.name}}</label>
          </span>
        </div>
        <div class="checkbox">
          <label>
            <input :disabled="!notificationEnabled" type="checkbox" v-model="notificationForced">{{$gettext("Urgent document? Send immediate notification to recipients. (Note that this will override the recipients' preferences for notifications.)")}}
          </label>
        </div>
      </form>
    </tab-content>
    <tab-content :title="$pgettext('Wizard tab', 'Publication')">
      <h3>{{$gettext('Document(s)')}}</h3>
      <table class="summary-metadata" v-for="(doc, index) in documents">
        <tr>
          <th><strong>{{$gettext('Document')}} {{ index + 1 }}</strong></th>
          <td></td>
        </tr>
        <tr>
          <th>{{ $gettext('English title')}}</th>
          <td>{{getMetadataValue(index + 1 , 'englishTitle') }}</td>
        </tr>
        <tr>
          <th>{{ $gettext('French title')}}</th>
          <td>{{getMetadataValue(index + 1 , 'frenchTitle') }}</td>
        </tr>
        <tr>
          <th>{{ $gettext('Latin title')}}</th>
          <td>{{getMetadataValue(index + 1 , 'latinTitle') }}</td>
        </tr>
        <tr>
          <th>{{ $gettext('Keywords / Topic')}}</th>
          <td>
            <span :key="keyword" class="choice-badge" v-for="keyword in getMetadataValue(index + 1 , 'keywords', [])">
              {{ keyword }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $gettext('Monograph number(s)')}}</th>
          <td>
            <span :key="mono_number" class="choice-badge" v-for="mono_number in getMetadataValue(index + 1 , 'monoNumbers', [])">
              {{ mono_number }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $gettext('Restricted document')}}</th>
          <td>
            <span v-if="getMetadataValue(index + 1 , 'restricted')"><i class="fa fa-lock"></i> {{ $gettext('yes')}}</span>
            <span v-else><i class="fa fa-unlock"></i> {{ $gettext('no')}}</span>
          </td>
        </tr>
        <tr>
          <th>{{ $gettext('Meetings')}}</th>
          <td>
            <span class="choice-badge" v-for="meeting_id in getMetadataValue(index + 1 , 'meetings', [])" :key="meeting_id">
              {{ getMeetingName(meeting_id) }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $gettext('Folders')}}</th>
          <td>
            <span class="choice-badge" v-for="collection_id in getMetadataValue(index + 1 , 'collections', [])" :key="collection_id">
              {{ getCollectionName(collection_id) }}
            </span>
          </td>
        </tr>
        <tr>
          <th>{{ $gettext('Filename')}}</th>
          <td>{{getMetadataValue(index + 1 , 'newFilename') }}</td>
        </tr>
      </table>
      <h3>{{$gettext('Access rights')}}</h3>
      <table v-if="permissions.length > 0" class="table table-striped table-condensed">
        <thead>
        <tr>
          <th>{{$gettext('User or Group')}}</th>
          <th v-if="documentForComment">{{$gettext('Document for comment')}}</th>
          <th v-if="documentForComment">{{$gettext('Comment until')}}</th>
          <th v-if="documentForComment">{{$gettext('Comments of others visible')}}</th>
          <th>{{$gettext('Document visible until')}}</th>
          <th v-if="documentForComment">{{$gettext('Comments visible after closing date')}}</th>
          <th v-if="documentForComment">{{$gettext('Comments visible until')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="perm in permissions">
          <td>{{perm.name}}</td>
          <td v-if="documentForComment">{{perm.documentForComment ? $gettext('yes') : $gettext('no')}}</td>
          <td v-if="documentForComment">{{ perm.commentUntil }}</td>
          <td v-if="documentForComment">{{ perm.commentOthersVisible ? $gettext('yes') : $gettext('no') }}</td>
          <td>{{ perm.documentVisibleUntil }}</td>
          <td v-if="documentForComment">{{ perm.commentVisibleAfterClose ? $gettext('yes') : $gettext('no')}}</td>
          <td v-if="documentForComment">{{ perm.commentVisibleUntil }}</td>
        </tr>
        </tbody>
      </table>
      <p v-else>{{$gettext('No access rights defined.')}}</p>
      <div v-if="documentForComment">
        <h3>{{$gettext('Receive notifications when comments are made')}}</h3>
        <div class="row">
          <div class="col-md-6" v-for="item in comment_subscriptions" :key="item.email + item.frequency">
            <span>{{ getNotificationNameByEmail(item.email) }}: {{getFrequencyDisplayName(item.frequency)}}</span>
          </div>
        </div>
      </div>
      <h3>{{$gettext('Notification')}}</h3>
      <p>{{$gettext('Message')}}</p>
      <p>{{ notificationMessage }}</p>
      <p>{{$gettext('Send notification')}} {{ notificationEnabled ? $gettext('yes') : $gettext('no') }}</p>
      <p>{{$gettext('Send immediately')}} {{ notificationForced ? $gettext('yes') : $gettext('no') }}</p>

    </tab-content>
    <template #prev="props">
      <wizard-button class="btn btn-default wizard-btn-prev">{{$pgettext('Wizard button', 'Back')}}</wizard-button>
    </template>
    <template #next="props">
      <wizard-button class="btn btn-default wizard-btn-next">{{$pgettext('Wizard button', 'Next')}}</wizard-button>
    </template>
    <template #finish="props">
      <wizard-button class="btn btn-primary wizard-btn-finish">{{$pgettext('Wizard button', 'Publish')}}</wizard-button>
    </template>
  </form-wizard>
</template>

<script>
  import Vue from 'vue'
  import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import vueDropzone from './components/vue-dropzone'
  import message from 'toastr'
  import Mustache from 'mustache'
  import path from 'path';
  import InputTags from './components/input-tags'
  import Select2 from './components/select2'
  import Datepicker from './components/datepicker'
  import { accessMembersSelector, MY_GROUPS, ALL_USERS } from './components/access-members-selector'
  import urljoin from 'url-join'
  import dateFormat from 'dateformat'
  import { openCollectionSelector } from './collection-selector'
  import { openAgendaSelector } from './agenda-selector'

  const numberRegex = new RegExp('^[0-9]+$');

  function pad (number, length) {

    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }

    return str;
  }

  export default {
    name: 'document-wizard',
    components: { FormWizard, TabContent, vueDropzone, InputTags, Select2, Datepicker, WizardButton },
    props: {
      documentId: {
        type: Number
      }
    },
    data: function () {
      return {
        errors: {},
        currentMetadataFile: 1,
        permissions: [],
        documentForComment: false,
        defaultPermission: {
          type: null,
          name: null,
          commentUntil: null,
          documentForComment: this.documentForComment,
          documentVisibleUntil: null,
          commentOthersVisible: true,
          commentVisibleAfterClose: true,
          commentVisibleUntil: null,
          send_notification: true
        },
        convertFiles: [],
        metadata: [{
          frenchTitle: null,
          latinTitle: null,
          englishTitle: '{{name}}',
          newFilename: '{{filename}}',
          collections: [],
          meetings: [],
          filename: this.$gettext('Defaults (For all documents)'),
          keywords: [],
          monoNumbers: [],
          author: null,
          authorGroup: null,
          restricted: false,
        }],
        sameAsDefault: [this.initSameAsDefault(false)],
        dropzoneOptions: {
          url: this.$restAPI.defaults.baseURL + 'upload/',
          addRemoveLinks: true,
          dictDefaultMessage: this.$gettext('Drop files here or click to upload.'),
          dictRemoveFile: this.$gettext('Remove'),
          thumbnailWidth: 150,
          maxFilesize: 10,
          filesizeBase: 1024,
          dictFileTooBig: 'File is too big. Maximum file size: {{maxFilesize}}MB',
          autoProcessQueue: true,
          parallelChunkUploads: true,
          withCredentials: true,
          headers: { 'X-CSRFToken': document.querySelector('meta[name="csrf-token"]').getAttribute('content') }
        },
        collections: [],
        meetings: [],
        keywords: [],
        monoNumbers: [],
        notificationMessage: null,
        notificationEnabled: true,
        notificationForced: false,
        userForCommentSubscriptions: [],
        selectedUserForCommentSubscriptions: null,
        comment_subscriptions: [],
        selectedFrequencyForCommentSubscriptions: 'each'
      }
    },
    computed: {
      title () {
        if (this.documentId) {
          return this.$gettext('Modification wizard')
        } else {
          return this.$gettext('Publication wizard')
        }
      },
      subtitle () {
        if (this.documentId) {
          return this.$gettext('Make an update to a published document')
        } else {
          return this.$gettext('Publish a new document to Extranet')
        }
      },
      documents () {
        return this.metadata.filter((item, index) => index > 0)
      },
      editMode () {
        return !!this.documentId
      },
      isAddNotificationDisabled () {
        return (this.selectedUserForCommentSubscriptions === null || this.selectedFrequencyForCommentSubscriptions === null)
      }
    },
    watch: {
      documentForComment () {
        this.defaultPermission.documentForComment = this.documentForComment
      }
    },
    methods: {
      getFrequencyDisplayName (frequency) {
          if (frequency === 'each') return this.$gettext('Each update')
          if (frequency === 'day') return this.$gettext('Once per day')
          if (frequency === 'week') return this.$gettext('Once per week')
          if (frequency === 'month') return this.$gettext('Once per month')
          return frequency
      },
      changeDocumentForCommentState (perm) {
        if (!perm.documentForComment) {
          perm.commentUntil = null
          perm.commentOthersVisible = false
          perm.documentVisibleUntil = null
          perm.commentVisibleAfterClose = false
          perm.commentVisibleUntil = null
        } else {
          perm.commentOthersVisible = true
          perm.commentVisibleAfterClose = true
        }
      },
      openFolderSelection () {
        openCollectionSelector(this.$root, 'id').then(selection => {
          //let values = []
          this.$http.get('/autocomplete/collections/?select=' + selection.join(',')).then(response => {
            response.data.results.forEach(item => {
              this.onSelectCollection({...item, 'selected': true})
              //values.push(item.id)
              if (!this.metadata[this.currentMetadataFile].collections) {
                this.metadata[this.currentMetadataFile].collections = []
              }
              if (this.metadata[this.currentMetadataFile].collections.findIndex(i => i === item.id) < 0) {
                this.metadata[this.currentMetadataFile].collections.push(item.id)
              }
            })
            //this.metadata[this.currentMetadataFile].collections = values
          }).catch(error => {})
        })
      },
      openAgendaSelection () {
        openAgendaSelector(this.$root).then(selection => {
          // let values = []
          this.$http.get('/autocomplete/agenda/?select=' + selection.join(',')).then(response => {
            response.data.results.forEach(item => {
              this.onSelectMeeting({...item, 'selected': true})
              // values.push(item.id)
              if (!this.metadata[this.currentMetadataFile].meetings) {
                this.metadata[this.currentMetadataFile].meetings = []
              }
              if (this.metadata[this.currentMetadataFile].meetings.findIndex(i => i === item.id) < 0) {
                this.metadata[this.currentMetadataFile].meetings.push(item.id)
              }
            })
            //this.metadata[this.currentMetadataFile].meetings = values
          }).catch(error => {})
        })
      },
      loadCollection () {
        return {}
      },
      waitConvertPreview (file_uuid, timeout = 1000, retry = 60) {
        return new Promise((resolve, reject) => {
          let counter = retry
          let intervalID = setInterval(() => {
            counter--
            if (counter < 1) {
              clearInterval(intervalID);
              reject('timeout')
            } else {
              this.$restAPI.head(`converter/${file_uuid}/`).then(response => {
                if (response.status === 200) {
                  console.log('available')
                  clearInterval(intervalID);
                  resolve(response)
                } else {
                  console.log('unavailable retry')
                }
              }).catch(error => {
                if (error.response.status !== 404) {
                  clearInterval(intervalID);
                  reject(error)
                }
              }).finally(function() {
                $('.convert-to-html').removeClass('disabled')
              })
            }
          }, timeout)
        })
      },
      convertToHTML () {
        let supported_files = ['.docx', '.doc']
        let convertToHtmlButton = $('.convert-to-html')
        convertToHtmlButton.addClass('disabled')
        let enableButton = true
        this.metadata.forEach(item => {
          if (item.file_uuid && supported_files.includes(item.filename.substring(item.filename.lastIndexOf('.'), item.filename.length))) {
            enableButton = false
            let data = {
              file_uuid: item.file_uuid,
              format: 'HTML'
            }
            this.errors = {}
            this.$restAPI.post('/converter/', data).then(response => {
              if (response.status === 201) {
                item.origin_file_uuid = item.file_uuid
                item.origin_filename = item.filename
                item.filename = item.filename.slice(0, -5) + '.html'
                item.html_uuid = response.data.file_uuid
                this.fileChanged()
                let index = this.convertFiles.length
                this.convertFiles.push({
                  url: response.data.url,
                  origin: item.origin_filename,
                  filename: item.filename,
                  available: false
                })
                this.waitConvertPreview(item.file_uuid).then(response => {
                  this.$set(this.convertFiles, index, { ...this.convertFiles[index], available: true })
                }).catch(error => {
                  console.log('waitConvertPreview Failed: ', error)
                  convertToHtmlButton.removeClass('disabled')
                })
              }
            }).catch(error => {
              console.log('convertToHTML Failed: ', error)
              convertToHtmlButton.removeClass('disabled')
            })
          } else {
            this.errors = { dropzone: this.$gettext('Conversion to HTML is only available for Word documents (.doc, .docx).') }
          }
        })
        if (enableButton) {
          convertToHtmlButton.removeClass('disabled')
        }
      },
      addUserPermission () {
        accessMembersSelector(this.$root, ALL_USERS, this.permissions, this.defaultPermission).then(selection => {
          this.permissions = selection
        })
      },
      addGroupPermission () {
        accessMembersSelector(this.$root, MY_GROUPS, this.permissions, this.defaultPermission).then(selection => {
          this.permissions = selection
        })
      },
      removePermission (permission) {
        this.permissions = this.permissions.filter(i => !(i.id === permission.id && i.type === permission.type))
      },
      fileChanged () {
        if (this.metadata.length === 2) {
          this.currentMetadataFile = 1
          Object.keys(this.sameAsDefault[this.currentMetadataFile]).forEach(key => {
            if (!key.startsWith('_')) {
              if (!this.metadata[this.currentMetadataFile][key]) {
                this.metadata[this.currentMetadataFile][key] = this.getDefaultValue(key)
              }
            }
          })
          Object.keys(this.sameAsDefault[this.currentMetadataFile]).forEach(key => {
            if (!key.startsWith('_')) {
              this.sameAsDefault[this.currentMetadataFile][key] = false
            }
          })
        } else if (this.metadata.length > 1) {
          this.currentMetadataFile = 0;
          Object.keys(this.sameAsDefault[1]).forEach(key => {
            if (!key.startsWith('_')) {
              this.sameAsDefault[1][key] = true
            }
          })
        }
      },
      initSameAsDefault (value) {
        return {
          englishTitle: value,
          frenchTitle: value,
          latinTitle: value,
          keywords: value,
          monoNumbers: value,
          restricted: value,
          meetings: value,
          collections: value,
          newFilename: value
        }
      },
      doFileProcessing (file) {
        if (file.accepted) {
          this.metadata.push({ filename: file.name, uuid: file.upload.uuid, file_uuid: null })
          this.sameAsDefault.push({ ...this.initSameAsDefault(true), _uuid: file.upload.uuid })
          this.fileChanged()
          Vue.nextTick(() => $('#wizard-select-file').selectpicker('refresh'))
        }
      },
      doFileRemoved (file) {
        this.metadata = this.metadata.filter(item => item.uuid !== file.upload.uuid)
        this.sameAsDefault = this.sameAsDefault.filter(item => item._uuid !== file.upload.uuid)
        this.fileChanged()
        Vue.nextTick(() => {
          $('#wizard-select-file').selectpicker('refresh')
        })
      },
      doFileUploaded (file) {
        const found = this.metadata.find(item => item.uuid === file.upload.uuid && item.file_uuid === null)
        if (found) {
          found.file_uuid = JSON.parse(file.xhr.response).uuid
        }
      },
      getNotificationNameByEmail (email) {
        let item = this.userForCommentSubscriptions.find(item => item.id === email)
        return item ? item.text: email
      },
      addData (documentNumber, data, key, name, allowEmpty = false) {
        let value = this.getMetadataValue(documentNumber, name)
        if (allowEmpty || value !== null) {
          data[key] = value
        }
      },
      addNotification () {
        let item = {
            'email': this.selectedUserForCommentSubscriptions,
            'frequency': this.selectedFrequencyForCommentSubscriptions
          }
        let index = this.comment_subscriptions.findIndex(x => (x.email === item.email && x.frequency === item.frequency))
        if (index === -1) {
          this.comment_subscriptions.push(item)
        } else {
          message.warning(this.$gettext('Email already subscribed'))
        }
        this.selectedUserForCommentSubscriptions = null
        this.selectedFrequencyForCommentSubscriptions = null
      },
      removeNotification (item) {
        this.comment_subscriptions = this.comment_subscriptions.filter(element => element !== item)
      },
      getDocumentData (documentNumber) {
        let data = {
          permissions: []
        }

        this.addData(documentNumber, data, 'title_fr', 'frenchTitle', true)
        this.addData(documentNumber, data, 'title_en', 'englishTitle')
        this.addData(documentNumber, data, 'latin_title', 'latinTitle', true)
        this.addData(documentNumber, data, 'file_uuid', 'file_uuid')
        this.addData(documentNumber, data, 'html_uuid', 'html_uuid')
        this.addData(documentNumber, data, 'filename', 'newFilename', true)
        this.addData(documentNumber, data, 'monograph_numbers', 'monoNumbers')
        this.addData(documentNumber, data, 'keywords', 'keywords')
        this.addData(documentNumber, data, 'is_restricted', 'restricted')
        this.addData(documentNumber, data, 'author_group_id', 'authorGroup')
        this.addData(documentNumber, data, 'author_id', 'author')

        data.meeting_ids = this.sameAsDefault[documentNumber]['meetings'] ? this.metadata[0].meetings : this.metadata[documentNumber].meetings
        data.collection_ids = this.sameAsDefault[documentNumber]['collections'] ? this.metadata[0].collections : this.metadata[documentNumber].collections
        data.is_commentable = this.documentForComment
        data.notification_enabled = this.notificationEnabled
        data.notification_message = this.notificationMessage
        data.notification_urgent = this.notificationForced

        data.comment_subscriptions = this.documentForComment ? this.comment_subscriptions : []

        this.permissions.forEach(perm => {
          let dataPermission = {}
          if (perm.type === 'group') {
            dataPermission.group_id = perm.id
          } else {
            dataPermission.user_id = perm.id
          }
          if (perm.documentVisibleUntil) {
            dataPermission.document_visible_until = dateFormat(perm.documentVisibleUntil.toDate('dd/mm/yyyy'), "yyyy-mm-dd")
          }
          if (perm.commentVisibleUntil) {
            dataPermission.comment_visible_until = dateFormat(perm.commentVisibleUntil.toDate('dd/mm/yyyy'), "yyyy-mm-dd")
          }
          dataPermission.document_for_comment = perm.documentForComment
          if (perm.commentUntil) {
            dataPermission.comment_open_until = dateFormat(perm.commentUntil.toDate('dd/mm/yyyy'), "yyyy-mm-dd")
          }
          dataPermission.comment_visible_for_others = perm.commentOthersVisible
          dataPermission.comment_visible_after_close = perm.commentVisibleAfterClose
          dataPermission.send_notification = perm.send_notification || false

          data.permissions.push(dataPermission)
        })
        return data
      },
      createDocument (documentNumber) {
        let data = this.getDocumentData(documentNumber)
        let toastrTitle = `Document ${data?.filename ? data?.filename : documentNumber}`
        if (!this.editMode) {
          this.$restAPI.post('/documents/', data, { headers: { 'content-type': 'application/json' } }).then(response => {
            message.success(this.$gettext('New document is posted.') + `<br><br><a href="${response.data.direct_link}">${response.data.direct_link}</a>`, toastrTitle, { timeOut: 10000, extendedTimeOut: 0, closeButton: true })
          }).catch(error => {
            console.error(error)
            message.error(this.$gettext('Error: Document not posted'), toastrTitle, { timeOut: 0, extendedTimeOut: 0, closeButton: true })
          })
        } else {
          this.$restAPI.put('/documents/', data, { headers: { 'content-type': 'application/json' } }).then(response => {
            message.success(this.$gettext('Document updated.'), toastrTitle)
          }).catch(error => {
              let options = { timeOut: 0, extendedTimeOut: 0, closeButton: true }
              if (error.response.status === 403) {
                message.error(this.$gettext('Error: You do not have the necessary rights to carry out this action'), toastrTitle, options)
              } else {
                message.error(error, toastrTitle, options)
              }
          })
        }
      },
      updateDocument (documentNumber) {
        let data = this.getDocumentData(documentNumber)
        let toastrTitle = `Document ${data?.filename ? data?.filename : documentNumber}`
        if (this.editMode) {
          this.$restAPI.put(`/documents/${this.documentId}/`, data, { headers: { 'content-type': 'application/json' } }).then(response => {
            this.$root.$emit('document_changed', this.documentId)
            message.success(this.$gettext('Document updated.'), toastrTitle)
          }).catch(error => {
              let title = `Document ${documentNumber}`
              let options = { timeOut: 0, closeButton: true }
              if (error.response.status === 403) {
                message.error(this.$gettext('Error: You do not have the necessary rights to carry out this action'), toastrTitle, options)
              } else {
                message.error(error, toastrTitle, options)
              }
          })
        }
      },
      doComplete: function () {
        if (!this.editMode) {
          for (let documentNumber = 1; documentNumber < this.metadata.length; documentNumber++) {
            this.createDocument(documentNumber)
          }
          this.$router.push({ name: 'home' })
        } else {
          this.updateDocument(1)
          this.$parent.close()
        }
      },
      doLoading: function (value) {

      },
      doValidate: function (isValid, tabIndex) {

      },
      handleErrorMessage: function (errorMessage) {
        if (errorMessage) {
          message.warning(errorMessage)
        }
      },
      getMacroContext (filename) {
        let currentDate = new Date()
        return {
          filename: filename,
          name: filename.replace(/\.[^/.]+$/, '').replace(/_/g, ' '),
          date: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`,
          year: `${currentDate.getFullYear()}`,
          month: `${currentDate.getMonth() + 1}`,
          day: `${currentDate.getDate()}`,
          ext: path.extname(filename)
        }
      },
      getMacroHelp () {
        let examples = []
        let macros = this.getMacroContext('example.docx')
        for (let [key, value] of Object.entries(macros)) {
          examples.push(`{${key}}  ${value}`)
        }
        return examples
      },
      getDefaultValue (name) {
        return this.getDefaultMetadataValue(this.currentMetadataFile, name)
      },
      getDefaultMetadataValue (documentNumber, name) {
        let defaultValues = this.metadata[0]
        let value = defaultValues[name]
        if (documentNumber > 0 && defaultValues[name]) {
          if (typeof value === 'string' || value instanceof String) {
            let filename = this.metadata[documentNumber].filename
            let context = this.getMacroContext(filename)
            return Mustache.render(value, context)
          }
        }
        return value
      },
      getMetadataValue (documentNumber, name, emptyValue = null) {
        let defaultValue = this.getDefaultMetadataValue(documentNumber, name)
        let customValue = this.metadata[documentNumber][name]
        if (customValue === null || customValue === undefined) {
           customValue = emptyValue
        }
        return (this.sameAsDefault[documentNumber][name]) ? defaultValue : customValue
      },
      validateStep1 () {
        return new Promise((resolve, reject) => {
          this.errors = {}
          if (this.metadata.length <= 1 ) {
            this.errors = { dropzone: this.$gettext('A file is required.') }
            reject(this.$gettext('You must add a file.'))
          } else if (this.documentForComment){
            let fileTypeError = false
            this.metadata.forEach((data, index) => {
              if (index !== 0 && data.filename.substring(data.filename.lastIndexOf('.'), data.filename.length).toLowerCase() !== '.html') {
                fileTypeError = true
              }
            })
            if (fileTypeError) {
              this.errors = { dropzone: this.$gettext('Documents for comment must be in HTML format. ' +
                  'Only post HTML documents or Word documents converted to HTML.') }
              reject()
            } else {
              resolve(true)
            }
          } else {
            resolve(true)
          }
        })
      },
      validateStep2 () {
        return new Promise((resolve, reject) => {
           let error = false
           for (let i=1;i < this.metadata.length; i++) {
            if (!this.getMetadataValue(i, 'englishTitle')) {
              reject(this.$gettext('The english title is required for ' + this.metadata[i].filename))
              error = true
              break;
            }
          }
          if (!error) {
            if (this.documentForComment) {
              this.loadUserForCommentSubscriptions()
            }
            this.$forceUpdate()
            resolve(true)
          }
        })
      },
      validateStep3 () {
        return new Promise((resolve, reject) => {
          let restricted = this.metadata.some(item => item.restricted)
          if (!restricted || (restricted && this.permissions.length > 0)) {
            resolve(true)
          } else {
            reject(this.$gettext('You have a restricted document, permission is required'))
          }
        })
      },
      getSelect2Settings (url, tags = false) {
        return {
          allowClear: true,
          placeholder: { id: -1, text: null },
          tags,
          ajax: {
            url: urljoin(this.$http.defaults.baseURL, url),
            delay: 100,
            data: function (params) {
              // Query parameters will be ?search=[term]&page=[page]
              return {
                search: params.term,
                page: params.page || 1
              }
            }
          }
        }
      },
      onSelectCollection (collection) {
        console.log('onSelectCollection', collection)
        if (collection.selected && this.collections.findIndex(item => item.id === collection.id) < 0) {
          this.collections.push({ 'text': collection.text, 'id': collection.id })
        }
      },
      getCollectionName(id) {
        console.log('getCollectionName', id, typeof id)
        console.log(this.collections)
        let item = this.collections.find(i => i.id === id)
        console.log(item)
        if (item) {
          return item.text
        }
        return '#' + id
      },
      getMeetingName(id) {
        let item = this.meetings.find(i => i.id === id)
        if (item) {
          return item.text
        }
        return '#' + id
      },
      onSelectMeeting (meeting) {
        if (meeting.selected && this.meetings.findIndex(item => item.id === meeting.id) < 0) {
          this.meetings.push({ 'text': meeting.text, 'id': meeting.id })
        }
      },
      getMonoNumberSettings () {
        let self = this
        return {
          allowClear: true,
          placeholder: { id: -1, text: null },
          tags: true,
          ajax: {
            url: urljoin(this.$http.defaults.baseURL, '/autocomplete/monoNumbers/'),
            delay: 100,
            data: function (params) {
              // Query parameters will be ?search=[term]&page=[page]
              return {
                search: params.term,
                page: params.page || 1
              }
            }
          },
          createTag: function (params) {
            if (params.term !== '' && !numberRegex.test(params.term)) {
              message.info(self.$gettext('Number must be a positive integer'), '', { "preventDuplicates": true })
              return null; // disable tag creation
            }

            return {
              id: pad(params.term, 4),
              text: pad(params.term, 4)
            }
          }
        }
      },

      load () {
        this.$restAPI.get(`/documents/${this.documentId}/edit/`).then(response => {
          let meetings = [];
          let collections = [];
          (response.data.meetings || []).forEach(item => {
            this.meetings.push({ id: item.id, text: item.label })
            meetings.push(item.id)
          });
          (response.data.collections || []).forEach(item => {
            this.collections.push({ id: item.id, text: item.label })
            collections.push(item.id)
          });

          this.keywords = response.data.keywords || []
          this.monoNumbers = response.data.monograph_numbers || []
          this.documentForComment = response.data.is_commentable
          this.notificationMessage = response.data.notification_message
          this.comment_subscriptions = response.data.comment_subscriptions || []

          this.metadata.push({
            frenchTitle: response.data.title_fr,
            latinTitle: response.data.latin_title,
            englishTitle: response.data.title_en,
            newFilename: response.data.filename,
            restricted: response.data.is_restricted,
            collections,
            meetings,
            filename: response.data.filename,
            keywords: response.data.keywords || [],
            monoNumbers: response.data.monograph_numbers || [],
            author: response.data.author_id,
            authorGroup: response.data.author_group_id
          });
          if (!this.editMode) {
            this.sameAsDefault.push(this.initSameAsDefault(true))
          } else {
            this.sameAsDefault.push(this.initSameAsDefault(false))
          }
          (response.data.permissions || []).forEach(item => {
            this.permissions.push({
              id: item.group_id || item.user_id,
              type: item.type = item.group_id ? 'group' : 'user',
              name: item.name,
              commentOthersVisible: item.comment_visible_for_others,
              commentUntil: item.comment_open_until && dateFormat(item.comment_open_until.toDate('yyyy-mm-dd'), "dd/mm/yyyy"),
              commentVisibleAfterClose: item.comment_visible_after_close,
              commentVisibleUntil: item.comment_visible_until && dateFormat(item.comment_visible_until.toDate('yyyy-mm-dd'), "dd/mm/yyyy"),
              documentForComment: item.document_for_comment,
              documentVisibleUntil: item.document_visible_until && dateFormat(item.document_visible_until.toDate('yyyy-mm-dd'), "dd/mm/yyyy")
            })
          })

        }).catch(error => {
          console.error(error)
          message.error(error, 'Document error', { timeOut: 0, closeButton: true })
        })
      },
      loadUserForCommentSubscriptions() {
        this.$http.get(`/autocomplete/usercommentsubscriptions/?size=99999`).then(response => {
          this.userForCommentSubscriptions = response.data.results
        })
      }
    },
    mounted () {
      if (!this.editMode) {
        this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.$pgettext('breadcrumb', 'New document') }]
      } else {
        this.load()
      }
    }
  }
</script>
<style lang="scss" scoped>

  .notification-users {
    max-height: 130px;
    overflow-y: auto;
    width: 100%;
    padding-left: 20px;

    span {
      white-space: nowrap;
      padding-right: 4px;
      padding-left: 4px;

      label {
        font-weight: normal;
      }
    }
  }

  .wizard-btn {
    background-color: #123751;
    border-color: #123751;
    color: white;
  }

  /*.wizard-btn-finish {*/
  /*    background-color: #1b7eac;*/
  /*    border-color: #1b7eac;*/
  /*}*/

  /*.wizard-btn-prev {*/
  /*    background-color: #aaa;*/
  /*    border-color: #aaa;*/
  /*}*/

  .wizard-note {
    font-size: 10px;
    color: #aaa;
    margin-top: 4px;
    font-style: italic;
  }

  .vue-dropzone {
    border: 3px dotted #ccc;
    font-family: Open sans, sans-serif;
  }

  textarea {
    resize: none;
    min-height: 150px;
  }

  form {
    margin-bottom: 30px;
  }

  .has-error .vue-dropzone {
    border-color: #e74c3c;
  }

  .access-rights thead {
    background-color: #eee;
    color: #444;
  }

  /*table.access-rights {*/
  /*  table-layout:fixed;*/
  /*}*/
  .access-rights th {
    text-align: center;
    vertical-align: middle !important;
    width: 1px;
  }

  .column-for-comment {
    width: 1px;
  }

  .column-comment-until {
    width: 1px;
  }

  .column-others-visible {
    width: 1px;
  }

  .column-doc-until {
    width: 1px;
  }

  .column-after-close {
    width: 1px;
  }

  .column-visible-until {
    width: 1px;
  }

  .column-resource {
    min-width: 200px;
    text-align: left !important;
    padding-left: 4px;

    i {
      width: 20px;
      text-align: center;
    }
  }

  .column-action {
    text-align: center;

    button {

    }
  }

  .column-checkbox {
    text-align: center;
  }

  .column-date {
    width: 1px;

    input {
      width: 100%;
    }
  }

  .btn-add-notification {
    margin-top: 18px;
  }
  .notification-comment-item {
    padding: 8px;
    button {
      margin-left: 4px;
      max-width: 32px;
      min-width: 32px;
      padding: 0;
    }
  }
</style>

<style lang="scss">
  .vue-form-wizard .wizard-tab-content {
    max-height: 520px;
    overflow-y: auto;
    margin-bottom: 24px;
  }

  .vue-form-wizard {
    margin-bottom: 50px;
  }

  .vue-form-wizard .wizard-title {
    text-transform: uppercase;
  }

  /**********************************************************************************************/

  .metadata-file-selector {
    margin-bottom: 20px;
    width: 300px;
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
        width: 100%;
    }
  }

  .input-with-action {
    width: calc(100% - 32px);
    display: inline-block;
  }

  .input-action {
    max-width: 32px;
    padding-left: 4px;
  }

  .metadata-page {
    display: None;
  }

  .metadata-page.active {
    display: block;
  }

  .form-horizontal div.add-clear-span.form-group.has-feedback {
    margin-left: 0;
    margin-right: 0;
  }

  .summary-metadata {
    th {
      font-weight: normal;
      padding-right: 30px;
    }
  }

  .choice-badge {
    color: #555555;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    float: left;
    margin: 2px 6px 2px 0;
    padding: 0 6px;
  }

  .help-inline {
    display: inline-block;
    font-weight: normal;
  }

  .wizard-meta-label {
    .control-label {
      width: 100%;
    }

    .pad-top-0 {
      padding-top: 0;
    }
    .same-label {
      width: 100%;
      text-align: right;
      font-weight: normal;
      font-size: 0.94rem;
      color: #444;
      margin-bottom: 0;

      label: {
        font-weight: normal;
      }

    }
  }

  .checkbox.alert-info {
    padding-bottom: 7px;
  }

</style>
