<template>
  <div class="extranet-viewer">
    <iframe :src="url" @load="documentOpened" frameborder="0" allow="encrypted-media" allowfullscreen="allowfullscreen"></iframe>
  </div>
</template>

<script>
  export default {
    name: 'viewer',
    computed: {
      url () {
        return `/documents/embedded/${this.$route.params.pathMatch}`
      }
    },
    methods: {
      documentOpened () {
        let document = { name: this.$pgettext('breadcrumb', 'Document') }
        if (this.$root.breadcrumb.length > 0 ) {
          this.$root.breadcrumb.push(document)
        } else {
          this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, document]
        }
        this.$root.$emit('document_opened', this.$route.params.pathMatch)
      }
    },
    mounted () {
    }
  }
</script>

<style>
  .extranet-viewer iframe {
    height: calc(100vh - 180px);
    width: 100%;
  }
</style>
