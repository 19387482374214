var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form-wizard",
    {
      ref: "wizard",
      attrs: {
        subtitle: _vm.subtitle,
        title: _vm.title,
        color: "#E7A418",
        errorColor: "#e74c3c",
      },
      on: {
        "on-complete": _vm.doComplete,
        "on-loading": _vm.doLoading,
        "on-validate": _vm.doValidate,
        "on-error": _vm.handleErrorMessage,
      },
      scopedSlots: _vm._u([
        {
          key: "prev",
          fn: function (props) {
            return [
              _c(
                "wizard-button",
                { staticClass: "btn btn-default wizard-btn-prev" },
                [_vm._v(_vm._s(_vm.$pgettext("Wizard button", "Back")))]
              ),
            ]
          },
        },
        {
          key: "next",
          fn: function (props) {
            return [
              _c(
                "wizard-button",
                { staticClass: "btn btn-default wizard-btn-next" },
                [_vm._v(_vm._s(_vm.$pgettext("Wizard button", "Next")))]
              ),
            ]
          },
        },
        {
          key: "finish",
          fn: function (props) {
            return [
              _c(
                "wizard-button",
                { staticClass: "btn btn-primary wizard-btn-finish" },
                [_vm._v(_vm._s(_vm.$pgettext("Wizard button", "Publish")))]
              ),
            ]
          },
        },
      ]),
    },
    [
      !_vm.editMode
        ? _c(
            "tab-content",
            {
              attrs: {
                title: _vm.$pgettext("Wizard tab", "Files"),
                "before-change": _vm.validateStep1,
              },
            },
            [
              _c("label", [
                _vm._v(
                  _vm._s(_vm.$gettext("Choose your file upload type")) + " "
                ),
                _c("span", { staticClass: "help-inline" }, [
                  _vm._v(_vm._s(_vm.$gettext("(maximum file size 10 MB)"))),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "radio" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.documentForComment,
                        expression: "documentForComment",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "optionsRadios",
                      id: "optionsRadios1",
                    },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.documentForComment, false),
                    },
                    on: {
                      change: function ($event) {
                        _vm.documentForComment = false
                      },
                    },
                  }),
                  _vm._v(
                    "\n        " + _vm._s(_vm.$gettext("Document")) + "\n      "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "radio" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.documentForComment,
                        expression: "documentForComment",
                      },
                    ],
                    attrs: {
                      type: "radio",
                      name: "optionsRadios",
                      id: "optionsRadios2",
                    },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.documentForComment, true),
                    },
                    on: {
                      change: function ($event) {
                        _vm.documentForComment = true
                      },
                    },
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$gettext(
                          "Document for comment (You may post an HTML document or a Word document to be converted to HTML)"
                        )
                      ) +
                      "\n      "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-group",
                  class: { "has-error": _vm.errors.dropzone },
                },
                [
                  _c(
                    "label",
                    {
                      staticClass: "control-label",
                      attrs: { for: "dropzone" },
                    },
                    [_vm._v(_vm._s(_vm.$gettext("Upload file(s)")))]
                  ),
                  _vm._v(" "),
                  _c("vue-dropzone", {
                    ref: "myVueDropzone",
                    attrs: { id: "dropzone", options: _vm.dropzoneOptions },
                    on: {
                      "vdropzone-processing": _vm.doFileProcessing,
                      "vdropzone-removed-file": _vm.doFileRemoved,
                      "vdropzone-complete": _vm.doFileUploaded,
                    },
                  }),
                  _vm._v(" "),
                  _vm.errors.dropzone
                    ? _c("span", { staticClass: "help-block" }, [
                        _vm._v(_vm._s(_vm.errors.dropzone)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary convert-to-html",
                  on: {
                    click: function ($event) {
                      return _vm.convertToHTML()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$gettext("Convert to HTML")))]
              ),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.convertFiles, function (item) {
                  return item
                    ? _c("li", [
                        _c("span", [_vm._v(_vm._s(item.origin) + " => ")]),
                        _vm._v(" "),
                        !item.available
                          ? _c("span", [
                              _vm._v(_vm._s(_vm.$gettext("Converting..."))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.available
                          ? _c(
                              "a",
                              { attrs: { target: "_blank", href: item.url } },
                              [_vm._v(_vm._s(item.filename))]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c("br"),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "tab-content",
        {
          attrs: {
            title: _vm.$pgettext("Wizard tab", "Metadata"),
            "before-change": _vm.validateStep2,
          },
        },
        [
          _vm.metadata.length > 2
            ? _c("div", { staticClass: "metadata-file-selector" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentMetadataFile,
                        expression: "currentMetadataFile",
                      },
                    ],
                    staticClass: "selectpicker show-tick",
                    attrs: {
                      id: "wizard-select-file",
                      "data-style": "btn-primary",
                    },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.currentMetadataFile = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.metadata, function (meta, index) {
                    return _c("option", { domProps: { value: index } }, [
                      _vm._v(_vm._s(meta.filename)),
                    ])
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            { staticClass: "form-horizontal" },
            _vm._l(_vm.metadata, function (meta, index) {
              return _c(
                "div",
                {
                  staticClass: "metadata-page",
                  class: { active: _vm.currentMetadataFile === index },
                },
                [
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputEnglishTitle" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("English title")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].englishTitle,
                                  expression:
                                    "sameAsDefault[index].englishTitle",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].englishTitle
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].englishTitle,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].englishTitle,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.sameAsDefault[index].englishTitle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "englishTitle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "englishTitle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "englishTitle",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sameAsDefault[index].englishTitle
                              ? _vm.getDefaultValue("englishTitle")
                              : meta.englishTitle,
                            expression:
                              "sameAsDefault[index].englishTitle ? getDefaultValue('englishTitle') : meta.englishTitle",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputEnglishTitle" + index,
                          readonly: _vm.sameAsDefault[index].englishTitle,
                          type: "text",
                        },
                        domProps: {
                          value: _vm.sameAsDefault[index].englishTitle
                            ? _vm.getDefaultValue("englishTitle")
                            : meta.englishTitle,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.sameAsDefault[index].englishTitle
                                ? _vm.getDefaultValue("englishTitle")
                                : meta,
                              "englishTitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputFrenchTitle" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("French title")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].frenchTitle,
                                  expression:
                                    "sameAsDefault[index].frenchTitle",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].frenchTitle
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].frenchTitle,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].frenchTitle,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.sameAsDefault[index].frenchTitle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "frenchTitle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "frenchTitle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "frenchTitle",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sameAsDefault[index].frenchTitle
                              ? _vm.getDefaultValue("frenchTitle")
                              : meta.frenchTitle,
                            expression:
                              "sameAsDefault[index].frenchTitle ? getDefaultValue('frenchTitle') : meta.frenchTitle",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputFrenchTitle" + index,
                          readonly: _vm.sameAsDefault[index].frenchTitle,
                          type: "text",
                        },
                        domProps: {
                          value: _vm.sameAsDefault[index].frenchTitle
                            ? _vm.getDefaultValue("frenchTitle")
                            : meta.frenchTitle,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.sameAsDefault[index].frenchTitle
                                ? _vm.getDefaultValue("frenchTitle")
                                : meta,
                              "frenchTitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputLatinTitle" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Latin title")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].latinTitle,
                                  expression: "sameAsDefault[index].latinTitle",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].latinTitle
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].latinTitle,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].latinTitle,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.sameAsDefault[index].latinTitle,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "latinTitle",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "latinTitle",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "latinTitle",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sameAsDefault[index].latinTitle
                              ? _vm.getDefaultValue("latinTitle")
                              : meta.latinTitle,
                            expression:
                              "sameAsDefault[index].latinTitle ? getDefaultValue('latinTitle') : meta.latinTitle",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "inputLatinTitle" + index,
                          readonly: _vm.sameAsDefault[index].latinTitle,
                          type: "text",
                        },
                        domProps: {
                          value: _vm.sameAsDefault[index].latinTitle
                            ? _vm.getDefaultValue("latinTitle")
                            : meta.latinTitle,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.sameAsDefault[index].latinTitle
                                ? _vm.getDefaultValue("latinTitle")
                                : meta,
                              "latinTitle",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputKeywords" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Keywords / Topic")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].keywords,
                                  expression: "sameAsDefault[index].keywords",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].keywords
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].keywords,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].keywords,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.sameAsDefault[index].keywords,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "keywords",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "keywords",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "keywords",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c("select2", {
                          staticClass: "form-control",
                          attrs: {
                            id: "inputKeywords" + index,
                            readonly: _vm.sameAsDefault[index].keywords,
                            options: _vm.editMode
                              ? _vm.keywords
                              : _vm.metadata[0].keywords,
                            settings: _vm.getSelect2Settings(
                              "/autocomplete/keywords",
                              true
                            ),
                            multiple: "multiple",
                          },
                          model: {
                            value: _vm.sameAsDefault[index].keywords
                              ? _vm.getDefaultValue("keywords")
                              : meta.keywords,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.sameAsDefault[index].keywords
                                  ? _vm.getDefaultValue("keywords")
                                  : meta,
                                "keywords",
                                $$v
                              )
                            },
                            expression:
                              "sameAsDefault[index].keywords ? getDefaultValue('keywords') : meta.keywords",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputMonoNumbers" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Monograph number(s)")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].monoNumbers,
                                  expression:
                                    "sameAsDefault[index].monoNumbers",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].monoNumbers
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].monoNumbers,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].monoNumbers,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.sameAsDefault[index].monoNumbers,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "monoNumbers",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "monoNumbers",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "monoNumbers",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-9" },
                      [
                        _c("select2", {
                          staticClass: "form-control",
                          attrs: {
                            id: "inputMonoNumbers" + index,
                            readonly: _vm.sameAsDefault[index].monoNumbers,
                            options: _vm.editMode
                              ? _vm.monoNumbers
                              : _vm.metadata[0].monoNumbers,
                            settings: _vm.getMonoNumberSettings(),
                            multiple: "multiple",
                          },
                          model: {
                            value: _vm.sameAsDefault[index].monoNumbers
                              ? _vm.getDefaultValue("monoNumbers")
                              : meta.monoNumbers,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.sameAsDefault[index].monoNumbers
                                  ? _vm.getDefaultValue("monoNumbers")
                                  : meta,
                                "monoNumbers",
                                $$v
                              )
                            },
                            expression:
                              "sameAsDefault[index].monoNumbers ? getDefaultValue('monoNumbers') : meta.monoNumbers",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputRestricted" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Restricted document")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].restricted,
                                  expression: "sameAsDefault[index].restricted",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].restricted
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].restricted,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].restricted,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.sameAsDefault[index].restricted,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "restricted",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "restricted",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "restricted",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("div", { staticClass: "checkbox alert alert-info" }, [
                        _c("label", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sameAsDefault[index].restricted
                                  ? _vm.getDefaultValue("restricted")
                                  : meta.restricted,
                                expression:
                                  "sameAsDefault[index].restricted ? getDefaultValue('restricted') : meta.restricted",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              id: "inputRestricted" + index,
                              disabled: _vm.sameAsDefault[index].restricted,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.sameAsDefault[index].restricted
                                  ? _vm.getDefaultValue("restricted")
                                  : meta.restricted
                              )
                                ? _vm._i(
                                    _vm.sameAsDefault[index].restricted
                                      ? _vm.getDefaultValue("restricted")
                                      : meta.restricted,
                                    null
                                  ) > -1
                                : _vm.sameAsDefault[index].restricted
                                ? _vm.getDefaultValue("restricted")
                                : meta.restricted,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.sameAsDefault[index].restricted
                                    ? _vm.getDefaultValue("restricted")
                                    : meta.restricted,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.sameAsDefault[index].restricted
                                          ? _vm.getDefaultValue("restricted")
                                          : meta,
                                        "restricted",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.sameAsDefault[index].restricted
                                          ? _vm.getDefaultValue("restricted")
                                          : meta,
                                        "restricted",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.sameAsDefault[index].restricted
                                      ? _vm.getDefaultValue("restricted")
                                      : meta,
                                    "restricted",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _c("i", { staticClass: "fa fa-lock text-danger" }),
                            _vm._v(
                              _vm._s(
                                _vm.$gettext(
                                  " Tick this box if your document must not be visible to EDQM users, unless they are given access at the next step under Access rights."
                                )
                              )
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputAgenda" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Meeting(s)")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].meetings,
                                  expression: "sameAsDefault[index].meetings",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].meetings
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].meetings,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].meetings,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.sameAsDefault[index].meetings,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "meetings",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "meetings",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "meetings",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("select2", {
                            staticClass: "form-control",
                            attrs: {
                              id: "inputAgenda" + index,
                              readonly: _vm.sameAsDefault[index].meetings,
                              type: Number,
                              options: _vm.meetings,
                              settings: _vm.getSelect2Settings(
                                "/autocomplete/agenda/"
                              ),
                              multiple: "multiple",
                            },
                            on: { select: _vm.onSelectMeeting },
                            model: {
                              value: _vm.sameAsDefault[index].meetings
                                ? _vm.getDefaultValue("meetings")
                                : meta.meetings,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sameAsDefault[index].meetings
                                    ? _vm.getDefaultValue("meetings")
                                    : meta,
                                  "meetings",
                                  $$v
                                )
                              },
                              expression:
                                "sameAsDefault[index].meetings ? getDefaultValue('meetings') : meta.meetings",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  title: _vm.$gettext(
                                    "Search for all meetings, past and future"
                                  ),
                                  disabled: _vm.sameAsDefault[index].meetings,
                                  type: "button",
                                },
                                on: { click: _vm.openAgendaSelection },
                              },
                              [_vm._v("...")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          class: {
                            "pad-top-0":
                              index !== 0 && _vm.metadata.length !== 2,
                          },
                          attrs: { for: "inputFolder" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Folder(s)")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].collections,
                                  expression:
                                    "sameAsDefault[index].collections",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].collections
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].collections,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].collections,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.sameAsDefault[index].collections,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "collections",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "collections",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "collections",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c(
                        "div",
                        { staticClass: "input-group" },
                        [
                          _c("select2", {
                            staticClass: "form-control",
                            attrs: {
                              id: "inputFolder" + index,
                              readonly: _vm.sameAsDefault[index].collections,
                              type: Number,
                              options: _vm.collections,
                              settings: _vm.getSelect2Settings(
                                "/autocomplete/collections/"
                              ),
                              multiple: "multiple",
                            },
                            on: { select: _vm.onSelectCollection },
                            model: {
                              value: _vm.sameAsDefault[index].collections
                                ? _vm.getDefaultValue("collections")
                                : meta.collections,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.sameAsDefault[index].collections
                                    ? _vm.getDefaultValue("collections")
                                    : meta,
                                  "collections",
                                  $$v
                                )
                              },
                              expression:
                                "sameAsDefault[index].collections ? getDefaultValue('collections') : meta.collections",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-btn" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-default",
                                attrs: {
                                  disabled:
                                    _vm.sameAsDefault[index].collections,
                                  type: "button",
                                },
                                on: { click: _vm.openFolderSelection },
                              },
                              [_vm._v("...")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("div", { staticClass: "col-sm-3 wizard-meta-label" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "inputNewFilename" + index },
                        },
                        [_vm._v(_vm._s(_vm.$gettext("Filename")))]
                      ),
                      _vm._v(" "),
                      index !== 0 && _vm.metadata.length !== 2
                        ? _c("label", { staticClass: "same-label" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Using the default")) + " "
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sameAsDefault[index].newFilename,
                                  expression:
                                    "sameAsDefault[index].newFilename",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.sameAsDefault[index].newFilename
                                )
                                  ? _vm._i(
                                      _vm.sameAsDefault[index].newFilename,
                                      null
                                    ) > -1
                                  : _vm.sameAsDefault[index].newFilename,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.sameAsDefault[index].newFilename,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "newFilename",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.sameAsDefault[index],
                                          "newFilename",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.sameAsDefault[index],
                                      "newFilename",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-sm-9" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.sameAsDefault[index].newFilename
                              ? _vm.getDefaultValue("newFilename")
                              : meta.newFilename,
                            expression:
                              "sameAsDefault[index].newFilename ? getDefaultValue('newFilename') : meta.newFilename",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          type: "text",
                          readonly: _vm.sameAsDefault[index].newFilename,
                          id: "inputNewFilename" + index,
                          disabled: _vm.editMode,
                        },
                        domProps: {
                          value: _vm.sameAsDefault[index].newFilename
                            ? _vm.getDefaultValue("newFilename")
                            : meta.newFilename,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.sameAsDefault[index].newFilename
                                ? _vm.getDefaultValue("newFilename")
                                : meta,
                              "newFilename",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "tab-content",
        {
          attrs: {
            title: _vm.$pgettext("Wizard tab", "Access rights"),
            "before-change": _vm.validateStep3,
          },
        },
        [
          _vm.documentForComment
            ? _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$pgettext(
                      "Wizard rights",
                      "Assign rights and deadlines for comments"
                    )
                  )
                ),
              ])
            : _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.$pgettext(
                      "Wizard rights",
                      "Assign rights and deadlines for document that is not for comment"
                    )
                  )
                ),
              ]),
          _vm._v(" "),
          _vm.permissions.length > 0
            ? _c(
                "table",
                {
                  staticClass:
                    "table table-striped table-condensed access-rights",
                },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "column-resource" }, [
                        _vm._v(_vm._s(_vm.$gettext("User or Group"))),
                      ]),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", { staticClass: "column-for-comment" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Document for comment"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", { staticClass: "column-comment-until" }, [
                            _vm._v(_vm._s(_vm.$gettext("Comment until"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", { staticClass: "column-others-visible" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Comments of others visible"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", { staticClass: "column-doc-until" }, [
                        _vm._v(_vm._s(_vm.$gettext("Document visible until"))),
                      ]),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", { staticClass: "column-after-close" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$gettext(
                                  "Comments visible after closing date"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", { staticClass: "column-visible-until" }, [
                            _vm._v(
                              _vm._s(_vm.$gettext("Comments visible until"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", { staticClass: "column-action" }, [
                        _vm._v(_vm._s(_vm.$gettext("Action"))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.permissions, function (perm) {
                      return _c("tr", [
                        _c("td", { staticClass: "column-resource" }, [
                          _c("i", {
                            staticClass: "fa",
                            class: {
                              "fa-user": perm.type === "user",
                              "fa-users": perm.type === "group",
                            },
                          }),
                          _vm._v(" " + _vm._s(perm.name) + "\n        "),
                        ]),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", { staticClass: "column-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: perm.documentForComment,
                                    expression: "perm.documentForComment",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(
                                    perm.documentForComment
                                  )
                                    ? _vm._i(perm.documentForComment, null) > -1
                                    : perm.documentForComment,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = perm.documentForComment,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              perm,
                                              "documentForComment",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              perm,
                                              "documentForComment",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          perm,
                                          "documentForComment",
                                          $$c
                                        )
                                      }
                                    },
                                    function ($event) {
                                      return _vm.changeDocumentForCommentState(
                                        perm
                                      )
                                    },
                                  ],
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c(
                              "td",
                              { staticClass: "column-date" },
                              [
                                _c("datepicker", {
                                  attrs: {
                                    disabled: !perm.documentForComment,
                                    placeholder: "__/__/____",
                                  },
                                  model: {
                                    value: perm.commentUntil,
                                    callback: function ($$v) {
                                      _vm.$set(perm, "commentUntil", $$v)
                                    },
                                    expression: "perm.commentUntil",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", { staticClass: "column-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: perm.commentOthersVisible,
                                    expression: "perm.commentOthersVisible",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !perm.documentForComment,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    perm.commentOthersVisible
                                  )
                                    ? _vm._i(perm.commentOthersVisible, null) >
                                      -1
                                    : perm.commentOthersVisible,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = perm.commentOthersVisible,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            perm,
                                            "commentOthersVisible",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            perm,
                                            "commentOthersVisible",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        perm,
                                        "commentOthersVisible",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "column-date" },
                          [
                            _c("datepicker", {
                              attrs: { placeholder: "__/__/____" },
                              model: {
                                value: perm.documentVisibleUntil,
                                callback: function ($$v) {
                                  _vm.$set(perm, "documentVisibleUntil", $$v)
                                },
                                expression: "perm.documentVisibleUntil",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", { staticClass: "column-checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: perm.commentVisibleAfterClose,
                                    expression: "perm.commentVisibleAfterClose",
                                  },
                                ],
                                attrs: {
                                  type: "checkbox",
                                  disabled: !perm.documentForComment,
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    perm.commentVisibleAfterClose
                                  )
                                    ? _vm._i(
                                        perm.commentVisibleAfterClose,
                                        null
                                      ) > -1
                                    : perm.commentVisibleAfterClose,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = perm.commentVisibleAfterClose,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            perm,
                                            "commentVisibleAfterClose",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            perm,
                                            "commentVisibleAfterClose",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        perm,
                                        "commentVisibleAfterClose",
                                        $$c
                                      )
                                    }
                                  },
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c(
                              "td",
                              { staticClass: "column-date" },
                              [
                                _c("datepicker", {
                                  attrs: {
                                    disabled: !perm.documentForComment,
                                    placeholder: "__/__/____",
                                  },
                                  model: {
                                    value: perm.commentVisibleUntil,
                                    callback: function ($$v) {
                                      _vm.$set(perm, "commentVisibleUntil", $$v)
                                    },
                                    expression: "perm.commentVisibleUntil",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", { staticClass: "column-action" }, [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.removePermission(perm)
                                },
                              },
                            },
                            [_c("span", { staticClass: "fa fa-trash" })]
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticClass: "alert alert-warning",
                  attrs: { role: "alert" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$gettext("No access rights defined.")) +
                      "\n    "
                  ),
                ]
              ),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.addUserPermission },
              },
              [
                _c("i", { staticClass: "fa fa-user" }),
                _vm._v(" " + _vm._s(_vm.$gettext("Set users"))),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                on: { click: _vm.addGroupPermission },
              },
              [
                _c("i", { staticClass: "fa fa-users" }),
                _vm._v(" " + _vm._s(_vm.$gettext("Set groups"))),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.documentForComment
            ? _c("div", [
                _c("h5", [
                  _vm._v(
                    _vm._s(
                      _vm.$gettext(
                        "Receive notifications when comments are made"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-6" },
                    [
                      _vm._v(_vm._s(_vm.$gettext("Email"))),
                      _c("select2", {
                        attrs: { options: _vm.userForCommentSubscriptions },
                        model: {
                          value: _vm.selectedUserForCommentSubscriptions,
                          callback: function ($$v) {
                            _vm.selectedUserForCommentSubscriptions = $$v
                          },
                          expression: "selectedUserForCommentSubscriptions",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-4" }, [
                    _vm._v(_vm._s(_vm.$gettext("Frequency"))),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedFrequencyForCommentSubscriptions,
                            expression:
                              "selectedFrequencyForCommentSubscriptions",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedFrequencyForCommentSubscriptions =
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "each" } }, [
                          _vm._v(_vm._s(_vm.getFrequencyDisplayName("each"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "day" } }, [
                          _vm._v(_vm._s(_vm.getFrequencyDisplayName("day"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "week" } }, [
                          _vm._v(_vm._s(_vm.getFrequencyDisplayName("week"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "month" } }, [
                          _vm._v(_vm._s(_vm.getFrequencyDisplayName("month"))),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "col-md-2" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-add-notification",
                        attrs: { disabled: _vm.isAddNotificationDisabled },
                        on: { click: _vm.addNotification },
                      },
                      [_vm._v(_vm._s(_vm.$gettext("Add notification")))]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.comment_subscriptions, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.email + item.frequency,
                        staticClass: "col-md-6 notification-comment-item",
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getNotificationNameByEmail(item.email)) +
                              ": " +
                              _vm._s(
                                _vm.getFrequencyDisplayName(item.frequency)
                              )
                          ),
                        ]),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-cancel",
                            attrs: { title: _vm.$gettext("Remove") },
                            on: {
                              click: function ($event) {
                                return _vm.removeNotification(item)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fa fa-close" })]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "tab-content",
        { attrs: { title: _vm.$pgettext("Wizard tab", "Notifications") } },
        [
          _c("form", [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "exampleInputEmail1" } }, [
                _vm._v(
                  _vm._s(_vm.$gettext("Add a message to the notification"))
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "help-block" }, [
                _vm._v(
                  _vm._s(
                    _vm.$gettext(
                      "Warning: if you modify/delete the message below, it will also be modified/deleted for those users who have not yet received a notification."
                    )
                  )
                ),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.notificationMessage,
                    expression: "notificationMessage",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "exampleInputEmail1", placeholder: "" },
                domProps: { value: _vm.notificationMessage },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.notificationMessage = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("label", [_vm._v(_vm._s(_vm.$gettext("Notifications")))]),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.notificationEnabled,
                      expression: "notificationEnabled",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.notificationEnabled)
                      ? _vm._i(_vm.notificationEnabled, null) > -1
                      : _vm.notificationEnabled,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.notificationEnabled,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.notificationEnabled = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.notificationEnabled = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.notificationEnabled = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  _vm._s(_vm.$gettext("Send a notification to recipients")) +
                    " "
                ),
                this.editMode ? _c("span") : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notification-users" },
              _vm._l(_vm.permissions, function (perm) {
                return _c("span", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: perm.send_notification,
                        expression: "perm.send_notification",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      id: perm.name,
                      disabled: !_vm.notificationEnabled,
                    },
                    domProps: {
                      value: perm.send_notification,
                      checked: Array.isArray(perm.send_notification)
                        ? _vm._i(
                            perm.send_notification,
                            perm.send_notification
                          ) > -1
                        : perm.send_notification,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = perm.send_notification,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = perm.send_notification,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                perm,
                                "send_notification",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                perm,
                                "send_notification",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(perm, "send_notification", $$c)
                        }
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: perm.name } }, [
                    _vm._v(_vm._s(perm.name)),
                  ]),
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.notificationForced,
                      expression: "notificationForced",
                    },
                  ],
                  attrs: {
                    disabled: !_vm.notificationEnabled,
                    type: "checkbox",
                  },
                  domProps: {
                    checked: Array.isArray(_vm.notificationForced)
                      ? _vm._i(_vm.notificationForced, null) > -1
                      : _vm.notificationForced,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.notificationForced,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            (_vm.notificationForced = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.notificationForced = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.notificationForced = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  _vm._s(
                    _vm.$gettext(
                      "Urgent document? Send immediate notification to recipients. (Note that this will override the recipients' preferences for notifications.)"
                    )
                  ) + "\n        "
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "tab-content",
        { attrs: { title: _vm.$pgettext("Wizard tab", "Publication") } },
        [
          _c("h3", [_vm._v(_vm._s(_vm.$gettext("Document(s)")))]),
          _vm._v(" "),
          _vm._l(_vm.documents, function (doc, index) {
            return _c("table", { staticClass: "summary-metadata" }, [
              _c("tr", [
                _c("th", [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$gettext("Document")) + " " + _vm._s(index + 1)
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td"),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("English title")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.getMetadataValue(index + 1, "englishTitle"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("French title")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.getMetadataValue(index + 1, "frenchTitle"))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Latin title")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.getMetadataValue(index + 1, "latinTitle"))),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Keywords / Topic")))]),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(
                    _vm.getMetadataValue(index + 1, "keywords", []),
                    function (keyword) {
                      return _c(
                        "span",
                        { key: keyword, staticClass: "choice-badge" },
                        [
                          _vm._v(
                            "\n            " + _vm._s(keyword) + "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Monograph number(s)")))]),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(
                    _vm.getMetadataValue(index + 1, "monoNumbers", []),
                    function (mono_number) {
                      return _c(
                        "span",
                        { key: mono_number, staticClass: "choice-badge" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(mono_number) +
                              "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Restricted document")))]),
                _vm._v(" "),
                _c("td", [
                  _vm.getMetadataValue(index + 1, "restricted")
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-lock" }),
                        _vm._v(" " + _vm._s(_vm.$gettext("yes"))),
                      ])
                    : _c("span", [
                        _c("i", { staticClass: "fa fa-unlock" }),
                        _vm._v(" " + _vm._s(_vm.$gettext("no"))),
                      ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Meetings")))]),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(
                    _vm.getMetadataValue(index + 1, "meetings", []),
                    function (meeting_id) {
                      return _c(
                        "span",
                        { key: meeting_id, staticClass: "choice-badge" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getMeetingName(meeting_id)) +
                              "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Folders")))]),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(
                    _vm.getMetadataValue(index + 1, "collections", []),
                    function (collection_id) {
                      return _c(
                        "span",
                        { key: collection_id, staticClass: "choice-badge" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getCollectionName(collection_id)) +
                              "\n          "
                          ),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$gettext("Filename")))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm.getMetadataValue(index + 1, "newFilename"))
                  ),
                ]),
              ]),
            ])
          }),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$gettext("Access rights")))]),
          _vm._v(" "),
          _vm.permissions.length > 0
            ? _c(
                "table",
                { staticClass: "table table-striped table-condensed" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$gettext("User or Group")))]),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", [
                            _vm._v(
                              _vm._s(_vm.$gettext("Document for comment"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", [
                            _vm._v(_vm._s(_vm.$gettext("Comment until"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", [
                            _vm._v(
                              _vm._s(_vm.$gettext("Comments of others visible"))
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", [
                        _vm._v(_vm._s(_vm.$gettext("Document visible until"))),
                      ]),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm.$gettext(
                                  "Comments visible after closing date"
                                )
                              )
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.documentForComment
                        ? _c("th", [
                            _vm._v(
                              _vm._s(_vm.$gettext("Comments visible until"))
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.permissions, function (perm) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(perm.name))]),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  perm.documentForComment
                                    ? _vm.$gettext("yes")
                                    : _vm.$gettext("no")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", [_vm._v(_vm._s(perm.commentUntil))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  perm.commentOthersVisible
                                    ? _vm.$gettext("yes")
                                    : _vm.$gettext("no")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(perm.documentVisibleUntil))]),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", [
                              _vm._v(
                                _vm._s(
                                  perm.commentVisibleAfterClose
                                    ? _vm.$gettext("yes")
                                    : _vm.$gettext("no")
                                )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.documentForComment
                          ? _c("td", [_vm._v(_vm._s(perm.commentVisibleUntil))])
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _c("p", [
                _vm._v(_vm._s(_vm.$gettext("No access rights defined."))),
              ]),
          _vm._v(" "),
          _vm.documentForComment
            ? _c("div", [
                _c("h3", [
                  _vm._v(
                    _vm._s(
                      _vm.$gettext(
                        "Receive notifications when comments are made"
                      )
                    )
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.comment_subscriptions, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.email + item.frequency,
                        staticClass: "col-md-6",
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.getNotificationNameByEmail(item.email)) +
                              ": " +
                              _vm._s(
                                _vm.getFrequencyDisplayName(item.frequency)
                              )
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.$gettext("Notification")))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.$gettext("Message")))]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.notificationMessage))]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$gettext("Send notification")) +
                " " +
                _vm._s(
                  _vm.notificationEnabled
                    ? _vm.$gettext("yes")
                    : _vm.$gettext("no")
                )
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              _vm._s(_vm.$gettext("Send immediately")) +
                " " +
                _vm._s(
                  _vm.notificationForced
                    ? _vm.$gettext("yes")
                    : _vm.$gettext("no")
                )
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }