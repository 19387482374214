<template>
  <select :data-value-touched="touched"></select>
</template>

<script>
import $ from 'jquery';
import 'select2/dist/js/select2.full';
import 'select2/dist/js/i18n/fr';
import 'select2/dist/js/i18n/en';
import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.css';

const initialSettings = {
  language: document.querySelector('html').getAttribute('lang') || 'en',
  theme: 'bootstrap',
  width: '100%'
}

export default {
  name: 'select2',
  model: {
    event: 'change',
    prop: 'value'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    settings: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: () => false
    },
    value: null,
    type: {
      type: Function,
      required: false
    }
  },
  data: function () {
    return {
      touched: false,
      dropdownParent: null
    }
  },
  watch: {
    options(val) {
      this.setOption(val);
    },
    value(val) {
      this.setValue(val);
    },
    readonly(val) {
      this.setReadonly(val);
    }
  },
  methods: {
    setOption(val = []) {
      this.$select2.empty();
      this.$select2.select2({
        ...initialSettings,
        ...this.settings,
        dropdownParent: this.dropdownParent,
        data: val
      });
      this.setValue(this.value);
    },
    setValue(val) {
      if (val instanceof Array) {
        this.$select2.val([...val]);
      } else {
        this.$select2.val([val]);
      }
      this.$select2.trigger('change');
    },
    setReadonly(val) {
      this.$select2.prop('disabled', val);
    }
  },
  mounted() {
    this.dropdownParent = $(document.body)
    if ($(this.$el).parents('.modal:first').length !== 0)
      this.dropdownParent = $(this.$el).parents('.modal:first');
    this.$select2 = $(this.$el)
      .select2({
        ...initialSettings,
        ...this.settings,
        dropdownParent: this.dropdownParent,
        data: this.options
      })
      .on('select2:select select2:unselect', ev => {
        let val = this.$select2.val()
        if (this.type) {
          if (val instanceof Array) {
            val = val.map(this.type)
          } else {
            val = this.type(val)
          }
        }
        this.$emit('change', val);
        this.$emit('select', ev['params']['data']);
      });
    this.setValue(this.value);
    this.setReadonly(this.readonly);
  },
  beforeDestroy() {
    this.$select2.select2('destroy');
  }
};
</script>

<style>
  .select2-container--bootstrap .select2-selection {
    border-radius: 0;
  }

  .select2-container {
    text-align: left;
  }
</style>
