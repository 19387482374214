window.isIE = !!document.documentMode;  // Internet Explorer 6-11
window.isEdge = !window.isIE && !!window.StyleMedia;  // Edge 20+

(function($, undefined) {
  $(function() {
    // $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function (event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   const $parent = $(this).parent();
    //   $parent.siblings().removeClass('open');
    //   $parent.toggleClass('open');
    // });

    // EDQM Customization
    // const $item = $('.nav li');
    // $item.hover(function(){
    //   $(this).toggleClass('active');
    // });
    // $item.click(function(){
    //   window.location=$(this).find('a').attr('href');
    // });
  });
})(jQuery);
