var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "collection-selector",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$gettext("Select a folder")))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              { staticClass: "container" },
              [
                _c("treeview", {
                  ref: "treeview",
                  attrs: {
                    onload: _vm.$root.loadCollections,
                    checkable: false,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary pull-left",
                attrs: { disabled: _vm.isProcessing, tabindex: "-1" },
                on: { click: _vm.newFolder },
              },
              [_vm._v(_vm._s(_vm.$gettext("New folder")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$gettext("OK")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }