var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-md-8" }, [_c("search")], 1),
    _vm._v(" "),
    _c("div", { staticClass: "col-md-4" }, [_c("agenda")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }