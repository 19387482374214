<template>
  <div>
    <span class="title"><i class="fa fa-users"></i> {{$gettext('My groups')}}</span>
    <ul class="footer-team-list">
      <li v-for="item in items" :title="item.name">
        <a :class="{'private': !item.visible}" @click.prevent="open(item)">{{ item.code }}</a>
      </li>
    </ul>
    <a v-if="moreItems" class="more-items" @click="loadMore()">{{ moreItemsCount }} {{ $gettext('more group(s)...') }}</a>
    <spinner v-if="!loaded"></spinner>
  </div>
</template>

<script>
  import Spinner from './spinner'
  import { openTeamDialog } from './team-panel'
  import {messageBox} from "../utils/dialogs";

  export default {
    name: 'teams',
    components: { Spinner },
    data: function () {
      return {
        items: [],
        loaded: false,
        page: 1,
        moreItems: false,
        count: 0
      }
    },
    computed: {
      moreItemsCount() {
        return this.count - this.items.length
      }
    },
    methods: {
      load () {
        this.$restAPI.get('/user/groups/', {params: {size: 30, ordering: 'code'}}).then(response => {
          this.items = response.data.results
          this.page = response.data.page
          this.moreItems = this.page < response.data.num_pages
          this.count = response.data.count
        }).then(() => {this.loaded = true})
      },
      loadMore () {
        this.loaded = false
        this.$restAPI.get('/user/groups/', {params: {size: 30, ordering: 'code', page: this.page + 1}}).then(response => {
          this.items = [...this.items, ...response.data.results]
          this.page = response.data.page
          this.moreItems = this.page < response.data.num_pages
        }).then(() => {this.loaded = true})
      },
      open (item) {
        if (item.visible)
          openTeamDialog(this.$root, item, false)
        else
          messageBox('', this.$gettext('Group membership information is private'), [this.$gettext('Close')])
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped lang="scss">

  .title {
    font-family: Open Sans Condensed, Open Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    i {
      padding-right: 4px;
    }
  }

  ul {
    margin-top: 12px;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  li {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    margin-bottom: 8px;
    max-width: 50%;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.1);

    a {
      color: white;
      cursor: pointer;
      text-decoration: none;
      padding: 2px 3px;
    }
    a.private {
      font-style: italic;
    }
    a:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  .more-items {
    cursor: pointer;
    color: white;
    font-size: 12px;
    font-style: italic;
  }
</style>
