<template>
  <panel :title="$gettext('My favourites')" icon-class="fa fa-star">
    <ul v-if="loaded">
      <li class="no-item" v-if="items.length === 0">{{$gettext('No favourites')}}</li>
      <draggable v-else v-model="items" @change="doChange">
        <transition-group>
          <li v-for="item in items" :key="item.document_id"><i class="fa fa-file"></i> <a :href="item.url" @click.stop="openItem(item, $event)">{{ item.title }}</a></li>
        </transition-group>
      </draggable>
    </ul>
    <spinner v-else></spinner>
    <span v-if="failed" class="error">{{$gettext('Loading failed.')}} (<a href="#" @click="load(true)">{{$gettext('Retry')}}</a>)</span>
    <template #menu>
      <li><a href="#" @click.prevent="load()"><i class="fa fa-refresh"></i> {{ $gettext('Refresh') }}</a></li>
    </template>
  </panel>
</template>

<script>
  import Panel from './panel'
  import Spinner from './spinner'
  import draggable from 'vuedraggable'
  import message from 'toastr'

  export default {
    name: 'favourites',
    components: { Panel, Spinner, draggable },
    data: function () {
      return {
        items: [],
        loaded: false,
        failed: false
      }
    },
    methods: {
      doChange ({moved}) {
        if (moved) {
          let item = this.items[moved.newIndex]
          this.$restAPI.put(`/user/favorites/${item.document_id}/`, {'order': moved.newIndex}).then(response => {
            message.success(response.data.title, this.$gettext("Favourite moved"), {preventDuplicates: true})
          }).catch(error => {
            message.error(undefined, this.$gettext("Move favourite failed"))
            this.load()
          })
        }
      },
      reset () {
        this.loaded = false
        this.failed = false
        this.items = []
      },
      doLoadFailed () {
        this.failed = true
        this.items = []
      },
      load (reset=false) {
        if (reset) this.reset()
        this.$restAPI.get('/user/favorites/').then(response => {
          this.items = response.data.results
        }).catch(this.doLoadFailed).then(() => { this.loaded = true })
      },
      openItem (item, e) {
        e.preventDefault()
        this.$root.openDocument(item.document_id)
      }
    },
    mounted () {
      this.load()
      this.$root.$on('document_favorites_changed', () => {
        this.load()
      })
      this.$root.$on('document_deleted', () => {
        this.load()
      })
    }
  }
</script>

<style scoped lang="scss">
  a {
      color: #444;
    }
  .title {
    font-family: Open Sans Condensed, Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    text-transform: uppercase;
  }

  ul {
    columns: 1;
    list-style: none;
    padding-left: 10px;
  }

  li {
    font-size: 14px;
  }

  li i {
    color: #ccc;
    margin-right: 4px;
  }

  .error {
    font-style: italic;
    color: #ff2910;
  }
  li.no-item {
    font-style: italic;
    font-size: 0.85em;
    color: #ccc;
    margin-left: 12px;
  }
</style>
