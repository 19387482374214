<template>

  <panel :title="$gettext('Advanced search')" icon-class="fa fa-search">
    <template #actions>
      <button type="button" class="btn btn-my-queries" @click="openQueryManager()">{{$gettext('My queries')}}</button>
    </template>
    <form class="search-form">
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group"><label for="id_full_text" accesskey="t" class="">{{$gettext('Full text')}}</label>
            <input id="id_full_text" name="full_text" type="text" autocomplete="off" v-focus v-clear v-model="$root.filterTerm" class="form-control" placeholder="">
          </div>
          <div class="search-full-text-bottom">
            <label class="radio-inline">
              <input type="radio" v-model="$root.filterSearchType" value="1"> {{$gettext('Standard')}}
            </label>
            <label class="radio-inline">
              <input type="radio" v-model="$root.filterSearchType" value="2"> {{$gettext('Phrase prefix')}}
            </label>
            <a @click.prevent="seeSyntax()" href="#" class="pull-right search-syntax">{{$gettext('Search syntax')}}</a>
          </div>
        </div>
        <div class="search-filters">
          <div class="col-xs-12">
            <div class="form-group">
              <label for="inputTitle">{{$gettext('Title')}}</label>
              <input class="form-control" id="inputTitle" placeholder="" v-model="$root.filterTitle" v-clear>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group">
              <label accesskey="g" for="inputGroupAccess">{{$gettext('Group access')}}</label>
              <div class="input-group">
                <select2 id="inputGroupAccess" class="form-control" @select="onSelectGroupAccess" :options="$root.filterGroupAccessItems" :settings="getSelect2Settings('/autocomplete/groups/')" multiple="multiple" v-model="$root.filterGroupAccessTags"></select2>
                <span class="input-group-btn">
                  <button :title="$gettext('Search for all access groups')" @click="openGroupAccessSelection" class="btn btn-default"  type="button">...</button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group">
              <label for="inputAuthorGroup" accesskey="a">{{$gettext('Author group')}}</label>
              <div class="input-group">
                <select2 id="inputAuthorGroup" class="form-control" @select="onSelectAuthorGroup" :settings="getSelect2Settings('/autocomplete/groups/')" multiple="multiple" v-model="$root.filterAuthorGroupTags"></select2>
                <span class="input-group-btn">
                  <button :title="$gettext('Search for all author groups')" @click="openAuthorGroupSelection" class="btn btn-default"  type="button">...</button>
                </span>
              </div>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group">
              <label for="inputReference">{{$gettext('PA/PH reference')}}</label>
              <select2 id="inputReference" class="form-control" :settings="getSelect2Settings('/autocomplete/references/', true, 'contains')" multiple="multiple" v-model="$root.filterReferenceTags"></select2>
            </div>
          </div>
          <div class="col-xs-12">
            <div class="form-group">
              <label for="inputMonoNumber">{{$gettext('Mono/GM number')}}</label>
              <select2 id="inputMonoNumber" class="form-control" :settings="getSelect2Settings('/autocomplete/monoNumbers/', true, 'startwith')" multiple="multiple" v-model="$root.filterMonoNumberTags"></select2>
            </div>
          </div>
          <div class="col-xs-12" v-if="$root.is_edqm_ui()">
            <div class="form-group">
              <label for="inputKeywords">{{$gettext('Keywords/section')}}</label>
              <select2 id="inputKeywords" class="form-control" :settings="getSelect2Settings('/autocomplete/keywords/', true, 'contains')" multiple="multiple" v-model="$root.filterKeywordsTags"></select2>
            </div>
          </div>
          <div class="col-xs-12" v-if="$root.is_edqm_ui()">
            <br>
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="$root.includeAllRestricted">{{$gettext('Include restricted documents to which I do not have access')}}
              </label>
              <div class="checkbox-info">{{$gettext('Please note, text extracts will not be available with this option.')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="search-footer">
        <button class="btn btn-primary" @click.prevent="search()"><i class="fa fa-search"></i> {{ $gettext('Search') }}</button>
        <button class="btn btn-secondary" @click.prevent="commentOverview()">{{ $gettext('Comment overview') }}</button>
        <button class="btn btn-secondary" @click.prevent="clear()">{{ $gettext('Clear') }}</button>
      </div>
    </form>
  </panel>
</template>

<script>
  import InputTags from './components/input-tags'
  import { ajaxMessageBox } from './utils/dialogs'
  import urljoin from 'url-join'
  import message from 'toastr'
  import Panel from './components/panel'
  import { openQueryEditor } from './query-manager'
  import Select2 from './components/select2'
  import { openGroupSelector } from './group-selector'

  export default {
    name: 'search',
    components: { Panel, InputTags, Select2 },
    computed: {},
    data: function () {
      return {

      }
    },
    methods: {
      mySelectEvent({id, text}){
          console.log({id, text})
      },
      onSelectGroupAccess({id, text}){
        this.$root.groupAccessNames[id] = text.substr(0,text.lastIndexOf(" ("))
      },
      onSelectAuthorGroup({id, text}){
        this.$root.authorGroupNames[id] = text.substr(0,text.lastIndexOf(" ("))
      },
      getAutoCompletionURL(url) {
        return urljoin(this.$restAPI.defaults.baseURL, url)
      },
      getQueryParams () {
        let query = {}

        if (this.$root.filterTerm) {
          query['q'] = this.$root.filterTerm
        }
        if (this.$root.filterTitle) {
          query['title'] = this.$root.filterTitle
        }

        if (this.$root.filterGroupAccessTags.length > 0) {
          query['accessGroup'] = this.$root.filterGroupAccessTags.join(',')
        }

        if (this.$root.filterAuthorGroupTags.length > 0) {
          query['authorGroup'] = this.$root.filterAuthorGroupTags.join(',')
        }

        if (this.$root.filterReferenceTags.length > 0) {
          query['reference'] = this.$root.filterReferenceTags.join(',')
        }

        if (this.$root.filterMonoNumberTags.length > 0) {
          query['monoNumbers'] = this.$root.filterMonoNumberTags.join(',')
        }

        if (this.$root.filterKeywordsTags.length > 0) {
          query['tags'] = this.$root.filterKeywordsTags.join(',')
        }

        if (this.$root.filterSearchType > 1) {
          query.qm = parseInt(this.$root.filterSearchType || '1')
        }

        let treeAgendaFilter = this.$root.treeAgendaFilter.getCheckedNodes('data')
        let treePostedFilter = this.$root.treePostedFilter.getCheckedNodes('data')
        let treeCommentFilter = this.$root.treeCommentFilter.getCheckedNodes('action')
        let treeFolderFilter = this.$root.treeFolderFilter.getCheckedNodes('id').join(',')

        if (this.$root.run_saved_query) {
          treeAgendaFilter = []
          treePostedFilter = []
          treeCommentFilter = []
          treeFolderFilter = []
        }

        if (treeFolderFilter) {
          query['folders'] = treeFolderFilter
        }

        let meetingFor = []
        let meetings = []
        treeAgendaFilter.forEach(item => {
          if (item) {
            if (item.id) {
              meetings.push(item.id)
            } else {
              let value = String(item.year)
              if (item.month) value += String(item.month).padStart(2, "0");
              meetingFor.push(value)
            }
          }
        })
        meetingFor = meetingFor.join(',')
        meetings = meetings.join(',')
        if (meetingFor) {
          query['meetingFor'] = meetingFor
        }
        if (this.$root.query_meetingFor) {
          query['meetingFor'] = this.$root.query_meetingFor
        }
        if (meetings) {
          query['meetings'] = meetings
        }
        if (this.$root.query_meetings) {
          query['meetings'] = this.$root.query_meetings
        }
        if (this.$root.query_folders) {
          query['folders'] = this.$root.query_folders
        }
        let postingDates = []
        treePostedFilter.forEach(item => {
          if (item) {
            let filter = String(item.year)
            if (item.month) filter += String(item.month).padStart(2, "0");
            postingDates.push(filter)
          }
        })
        if (postingDates.length > 0) {
          query['posted'] = postingDates.join(',')
        }
        if (this.$root.query_posted) {
          query['posted'] = this.$root.query_posted
        }

        if (treeCommentFilter.length > 0) {
          query['forComment'] = treeCommentFilter.join(',')
        }
        if (this.$root.query_forComment) {
          query['forComment'] = this.$root.query_forComment
        }

        if (this.$root.includeAllRestricted) {
          query['allRestricted'] = '1'
        }

        return query
      },
      setQueryParams (query) {
        if (query['q']) this.$root.filterTerm = query['q']
        if (query['title']) this.$root.filterTitle = query['title']
        if (query['accessGroup']) this.$root.filterGroupAccessTags = (query['accessGroup'] || '').split(',')
        if (query['authorGroup']) this.$root.filterAuthorGroupTags = (query['authorGroup'] || '').split(',')
        if (query['reference']) this.$root.filterReferenceTags = (query['reference'] || '').split(',')
        if (query['monoNumbers']) this.$root.filterMonoNumberTags = (query['monoNumbers'] || '').split(',')
        if (query['tags']) this.$root.filterKeywordsTags = (query['tags'] || '').split(',')
        if (query['allRestricted']) this.$root.includeAllRestricted = !!(query['allRestricted'] || '' === '1')
        if (query['meetingFor']) this.$root.query_meetingFor = query['meetingFor']
        if (query['meetings']) this.$root.query_meetings = query['meetings']
        if (query['folders']) this.$root.query_folders = query['folders']
        if (query['forComment']) this.$root.query_forComment = query['forComment']
        if (query['posted']) this.$root.query_posted = query['posted']

        this.$root.filterSearchType = parseInt(query['qm'] || '1')
      },
      openQueryManager (e) {
        openQueryEditor(this, [])
      },
      search () {
        this.$router.push({ name: 'results', query: this.getQueryParams() })
      },
      commentOverview () {
        let query = this.getQueryParams() || {}
        query.overview=1
        this.$router.push({ name: 'results', query })
      },
      clear () {
        this.$root.clearFilters()
      },
      seeSyntax () {
        ajaxMessageBox(this.$gettext('Search syntax'), urljoin(this.$http.defaults.baseURL, '/help/search/'), {
          'cancel': 'OK'
        }, null, null).then(action => {
        }).catch(() => {
          message.warning('Search syntax is not available')
        })
      },
      getSelect2Settings (url, tags = false, widlcard = false) {
        return {
          allowClear: true,
          placeholder: { id: -1, text: null },
          tags,
          ajax: {
            url: urljoin(this.$http.defaults.baseURL, url),
            delay: 100,
            data: function (params) {
              // Query parameters will be ?search=[term]&page=[page]
              return {
                search: params.term,
                page: params.page || 1
              }
            }
          },
          templateResult: function(state) {
            if (state.deprecated) {
              return $(`<s >${state.text}</s>`)
            } else {
              return state.text
            }
          },
          createTag: function (params) {
            let term = params.term
            if (widlcard && term.indexOf('*') === -1) {
              term = term + '*'
              if (widlcard === 'contains') {
                term = '*' + term
              }
            }
            return {
              id: term,
              text: term
            }
          }
        }
      },
      openGroupAccessSelection () {
        openGroupSelector(this.$root, new Set()).then(selection => {
          let $inputFolder = $('#inputGroupAccess')
          this.$http.get('/autocomplete/groups/?select=' + selection.join(',')).then(response => {
            response.data.results.forEach(item => {
              $inputFolder.find("option[value='" + item.id + "']").remove()
              let newOption = new Option(item.text, item.id, true, true)
              $inputFolder.append(newOption).trigger('change')
              $inputFolder.trigger({
                type: 'select2:select',
                params: {
                  data: item
                }
              })
            })
          }).catch(error => {})
        })
      },
      openAuthorGroupSelection () {
        openGroupSelector(this.$root, new Set()).then(selection => {
          let $inputFolder = $('#inputAuthorGroup')
          this.$http.get('/autocomplete/groups/?select=' + selection.join(',')).then(response => {
            response.data.results.forEach(item => {
              $inputFolder.find("option[value='" + item.id + "']").remove()
              let newOption = new Option(item.text, item.id, true, true)
              $inputFolder.append(newOption).trigger('change')
              $inputFolder.trigger({
                type: 'select2:select',
                params: {
                  data: item
                }
              })
            })
          }).catch(error => {})
        })
      },
      setInitialsTags () {
        let groupAccessNames = this.$root.filterGroupAccessTags.map(item=>this.$root.groupAccessNames[item])
        let authorGroupNames = this.$root.filterAuthorGroupTags.map(item=>this.$root.authorGroupNames[item])

        this.initTags('inputGroupAccess', '/autocomplete/groups/', groupAccessNames)
        this.initTags('inputAuthorGroup', '/autocomplete/groups/', authorGroupNames)
        this.initTags('inputReference', '/autocomplete/references/', this.$root.filterReferenceTags)
        this.initTags('inputMonoNumber', '/autocomplete/monoNumbers/', this.$root.filterMonoNumberTags)
        this.initTags('inputKeywords', '/autocomplete/keywords/', this.$root.filterKeywordsTags)
      },
      initTags (id, url, tags) {
        let tagsSelect = $('#' + id)
        tags.forEach((item) => {
          url = urljoin(this.$http.defaults.baseURL, url)
          $.ajax({type: 'GET',url: url + '?search=' + item}).then(function (data) {
            let d=data.results[0];
            let option = new Option(d.text, d.id, true, true);
            tagsSelect.append(option).trigger('change');
            // manually trigger the `select2:select` event
            tagsSelect.trigger({type: 'select2:select', params: {data: d}})
          })
        })
      }
    },
    mounted () {
      this.setInitialsTags()
      // Autocomplete for title field
      $(`#inputTitle`).autocomplete({
        serviceUrl: urljoin(this.$http.defaults.baseURL, 'autocomplete/title/'),
        autoSelectFirst: false,
        paramName: 'search',
        transformResult: function(response) {
            return {
                suggestions: $.map(JSON.parse(response).results, function(dataItem) {
                    return { value: dataItem.id, data: dataItem.text };
                })
            };
        },
        minChars: 1,
        onSelect: suggestion => {
          this.$root.filterTitle = '"' + suggestion.value + '"'
        }
      })
      // Manage user query
      this.$root.$on('manageQuery', (action, query, viewBefore) => {
        console.log('manageQuery', action, query, viewBefore)
        if (action === 'run') {
          this.$root.run_saved_query = true
          this.setQueryParams(query.data)
          if (!viewBefore) {
            this.$router.push({ name: 'results', query: this.getQueryParams() }).catch(() => {})
          }
          this.$root.run_saved_query = false
        }
      })
    }
  }
</script>

<style lang="scss">
  .ti-tag.ti-valid.ti-auth-grp:not(.ti-deletion-mark) {
    background-color: #129FEA !important;
  }
</style>

<style lang="scss" scoped>
  .search-footer {
    margin-top: 40px;
  }

  .search-form {
    .form-group label {
      font-size: 10px;
      color: #222;
      opacity: 0.8;
    }
  }

  .search-filters {
    margin-top: 30px;
    display: inline-block;
  }

  .search-syntax {
    font-size: 10px;
  }

  .checkbox, .form-group, .radio {
    margin-bottom: 4px;
  }

  .checkbox-info {
    color: #777;
    font-style: italic;
    font-size: 0.8em;
  }

  .btn-my-queries {
    background-color: #f9f9f9;
    border-color: #ccc;
    border-radius: 2px!important;
    box-shadow: none;
    color: #777;
    font-family: "Open Sans", "sans-serif";

    &:hover {
      background-color: #194e73;
      border-color: #194e73;
      color: white;
    }
  }

</style>
