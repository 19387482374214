import './scss/main.scss'
import './utils/misc'
import './utils/announcement'
import Vue from 'vue'
import App from './app'
import AppFooter from './footer'
import { loadProgressBar } from 'axios-progress-bar'
import 'axios-progress-bar/dist/nprogress.css'
import axios from 'axios'
import './i18n'
import router from './router'

import './utils/contextmenu'
import 'bootstrap-select'
import 'bootstrap-select/dist/css/bootstrap-select.css'
import vDragDrop from 'v-drag-drop'
import { $pgettext } from './helpers/i18n'
import 'bootstrap-add-clear'
import VueDefaultValue from './vue-default-value';
import VueScrollStop from 'vue-scroll-stop'
import message from 'toastr'
import 'devbridge-autocomplete'
import {messageBox} from "./utils/dialogs";


Vue.use(VueDefaultValue);
Vue.use(VueScrollStop)

window.isIE = !!document.documentMode // Internet Explorer 6-11
window.isEdge = !window.isIE && !!window.StyleMedia // Edge 20+

function getMetaValue(name, default_value) {
  let $meta = document.querySelector(`meta[name="${name}"]`)
  if ($meta) {
    return $meta.getAttribute('content')
  }
  return default_value
}

// Customize defaults headers for ajax requests
const csrftoken = getMetaValue('csrf-token')
const baseURL = getMetaValue('base-url', '/')
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.baseURL = baseURL
if (csrftoken) {
  axios.defaults.headers.post['X-CSRFToken'] = csrftoken
  axios.defaults.headers.put['X-CSRFToken'] = csrftoken
  axios.defaults.headers.patch['X-CSRFToken'] = csrftoken
  axios.defaults.headers.delete['X-CSRFToken'] = csrftoken
}
axios.defaults.withCredentials = true

Vue.use(vDragDrop)


String.prototype.toDate = function(format)
{
  let normalized      = this.replace(/[^a-zA-Z0-9]/g, '-');
  let normalizedFormat= format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');
  let formatItems     = normalizedFormat.split('-');
  let dateItems       = normalized.split('-');

  let monthIndex  = formatItems.indexOf("mm");
  let dayIndex    = formatItems.indexOf("dd");
  let yearIndex   = formatItems.indexOf("yyyy");
  let hourIndex     = formatItems.indexOf("hh");
  let minutesIndex  = formatItems.indexOf("ii");
  let secondsIndex  = formatItems.indexOf("ss");

  let today = new Date();

  let year  = yearIndex>-1  ? dateItems[yearIndex]    : today.getFullYear();
  let month = monthIndex>-1 ? dateItems[monthIndex]-1 : today.getMonth()-1;
  let day   = dayIndex>-1   ? dateItems[dayIndex]     : today.getDate();

  let hour    = hourIndex>-1      ? dateItems[hourIndex]    : today.getHours();
  let minute  = minutesIndex>-1   ? dateItems[minutesIndex] : today.getMinutes();
  let second  = secondsIndex>-1   ? dateItems[secondsIndex] : today.getSeconds();

  return new Date(year,month,day,hour,minute,second);
};

function initialize () {
  let navBar$ = $('#app_navbar')


  $.fn.datepicker.defaults.todayHighlight = true
  $.fn.datepicker.defaults.clearBtn = true
  $.fn.datepicker.defaults.zIndexOffset = 1031
  $.fn.datepicker.dates['en'] = {
      days: [
        $pgettext("Datepicker days", "Sunday"),
        $pgettext("Datepicker days", "Monday"),
        $pgettext("Datepicker days", "Tuesday"),
        $pgettext("Datepicker days", "Wednesday"),
        $pgettext("Datepicker days", "Thursday"),
        $pgettext("Datepicker days", "Friday"),
        $pgettext("Datepicker days", "Saturday")
      ],
      daysShort: [
        $pgettext("Datepicker days short", "Sun"),
        $pgettext("Datepicker days short", "Mon"),
        $pgettext("Datepicker days short", "Tue"),
        $pgettext("Datepicker days short", "Wed"),
        $pgettext("Datepicker days short", "Thu"),
        $pgettext("Datepicker days short", "Fri"),
        $pgettext("Datepicker days short", "Sat")
      ],
      daysMin: [
        $pgettext("Datepicker days min", "Su"),
        $pgettext("Datepicker days min", "Mo"),
        $pgettext("Datepicker days min", "Tu"),
        $pgettext("Datepicker days min", "We"),
        $pgettext("Datepicker days min", "Th"),
        $pgettext("Datepicker days min", "Fr"),
        $pgettext("Datepicker days min", "Sa")
      ],
      months: [
        $pgettext("Datepicker months", "January"),
        $pgettext("Datepicker months", "February"),
        $pgettext("Datepicker months", "March"),
        $pgettext("Datepicker months", "April"),
        $pgettext("Datepicker months", "May"),
        $pgettext("Datepicker months", "June"),
        $pgettext("Datepicker months", "July"),
        $pgettext("Datepicker months", "August"),
        $pgettext("Datepicker months", "September"),
        $pgettext("Datepicker months", "October"),
        $pgettext("Datepicker months", "November"),
        $pgettext("Datepicker months", "December")
      ],
      monthsShort: [
        $pgettext("Datepicker months short", "Jan"),
        $pgettext("Datepicker months short", "Feb"),
        $pgettext("Datepicker months short", "Mar"),
        $pgettext("Datepicker months short", "Apr"),
        $pgettext("Datepicker months short", "May"),
        $pgettext("Datepicker months short", "Jun"),
        $pgettext("Datepicker months short", "Jul"),
        $pgettext("Datepicker months short", "Aug"),
        $pgettext("Datepicker months short", "Sep"),
        $pgettext("Datepicker months short", "Oct"),
        $pgettext("Datepicker months short", "Nov"),
        $pgettext("Datepicker months short", "Dec")
      ],
      today: $pgettext("Datepicker button", "Today"),
      clear: $pgettext("Datepicker button", "Clear"),
      format: $pgettext("Datepicker date format", "dd/mm/yyyy"),
      titleFormat: $pgettext("Datepicker title format", "MM yyyy"),
      weekStart: 1  // Monday
  };

  navBar$.affix({
    offset: 100,
  })
  navBar$.on('affix.bs.affix', e => {
    $('body').addClass('top-fixed')
  })
  navBar$.on('affix-top.bs.affix', e => {
    $('body').removeClass('top-fixed')
  })
}

Vue.directive('focus', {
  inserted: function (el) {
    Vue.nextTick(() => {
      el.focus()
    })
  }
})

$('.has-clear input[type="text"]').on('input propertychange', function () {
  const $this = $(this)
  let visible = Boolean($this.val())
  $this.siblings('.form-control-clear').toggleClass('hidden', !visible)
}).trigger('propertychange')

$('.form-control-clear').click(function () {
  $(this).siblings('input[type="text"]').val('')
    .trigger('propertychange').focus()
})

function createNewEvent (eventName) {
  let event
  if (typeof (window.Event) === 'function') {
    event = new window.Event(eventName)
  } else {
    event = document.createEvent('Event')
    event.initEvent(eventName, true, true)
  }
  return event
}

Vue.directive('clear', {
  unbind: function (el, binding, vnode) {
    let $span = document.querySelector('div.add-clear-span[data-for="' + el.id + '"]')
    if ($span) $span.remove()
  },
  inserted: function (el, binding, vnode) {
    $(el).addClear({
      symbolClass: '',
      closeSymbol: "×",
      showOnLoad: true,
      clearOnEscape: true,
      returnFocus: true,
      onClear: function (el) {
        if (el && el.length > 0) {
          el[0].dispatchEvent(createNewEvent('input'))
          el[0].dispatchEvent(createNewEvent('change'))
        }
      }
    })
    $(el).parent().attr('data-for', el.id)
  }
})

const Resource = {
  install (Vue, {restAPIbaseURL}) {
    Vue.prototype.$restAPI = axios.create({baseURL: restAPIbaseURL})
    Vue.prototype.$http = axios.create({baseURL: baseURL})

    Vue.prototype.$restAPI.interceptors.response.use(undefined, function (error) {
      if (error.response.status === 401) {
        document.location.reload()
      }
      return Promise.reject(error);
    });

    Vue.prototype.$http.interceptors.response.use(undefined, function (error) {
      if(error.response.status === 403) {
        document.location.reload()
      }
      return Promise.reject(error);
    });

    loadProgressBar({ showSpinner: false }, Vue.prototype.$restAPI)
    loadProgressBar({ showSpinner: false }, Vue.prototype.$http)
  }
}


let $app = document.querySelector('#app')
if ($app) {
  // Injecter axios dans vue par l'intermédaire des méthodes $restAPI et $http
  Vue.use(Resource, {restAPIbaseURL: $app.getAttribute('data-url')})

  let appRoot = new Vue({
    el: $app,
    router,
    components: { App },
    data: {
      breadcrumb: [],
      filterTerm: '',
      filterSearchType: 1,
      filterTitle: '',
      filterGroupAccessTags: [],
      groupAccessNames: {},
      filterAuthorGroupTags: [],
      authorGroupNames: {},
      filterReferenceTags: [],
      filterMonoNumberTags: [],
      filterKeywordsTags: [],
      query_meetingFor: null,
      query_meetings: null,
      query_posted: null,
      query_forComment: null,
      query_folders: null,
      viewerVisible: false,
      viewerUrl: '',
      viewerDocument: null,
      viewerDocumentItems: [],
      viewerDocumentIndex: -1,
      viewerDocumentOffset: 0,
      viewerDocumentCount: 0,
      drtEnabled: true,
      comment_readonly: false,
      treeAgendaFilter: null,
      treePostedFilter: null,
      treeCommentFilter: null,
      treeFolderFilter: null,
      includeAllRestricted: false,
      folderItemsToMove: []
    },
    watch: {
      viewerVisible () {
        document.querySelector('body').classList.toggle('viewer-open', this.viewerVisible)
        if (this.viewerVisible) {
          this.$emit('document_opening')
        } else {
          this.$emit('document_close')
        }
      },
      '$route': function() {
        if (this.$route.query.viewDocument) {
          this.openDocumentFromDirectLink()
        } else {
          this.internalCloseDocument()
        }
      }
    },
    created() {
      this.$router.beforeEach((to, from, next) => {
        this.closeAllModals()
        next()
      })
    },
    methods: {
      /**
       * Helper function to close all  modal
       */
      closeAllModals () {
        $('.modal').modal('hide')
      },
      /**
       * Reset all filters, for perform a new search
       */
      clearFilters () {
        this.filterTerm = ''
        this.filterSearchType = 1
        this.filterTitle = ''
        this.filterGroupAccessTags = []
        this.filterAuthorGroupTags = []
        this.filterReferenceTags = []
        this.filterMonoNumberTags = []
        this.filterKeywordsTags = []
        this.includeAllRestricted = false
      },
      checkPostedValues (posted) {
        let postedValues = (posted) ? posted.split(',') : []
        let tree = this.$root.treePostedFilter
        tree.uncheckAllNodes()
        tree.expandNode("0")
        postedValues.forEach(value=>{
          if ( value.length > 4) {
            let year = value.slice(0,4)
            let month = parseInt(value.slice(-2))-1
            let nodeId = year + '-' + month
            setTimeout(function(){tree.expandNode(year)}, 1000)
            setTimeout(function(){tree.checkNode(nodeId)}, 1400)
          } else {
            let nodeId = value
            setTimeout(function(){tree.checkNode(nodeId)}, 1000)
          }
        })
      },
      checkForCommentValues (forComment) {
        let forCommentValues = (forComment) ? forComment.split(',') : []
        let tree = this.$root.treeCommentFilter
        tree.uncheckAllNodes()
        tree.expandNode("0")
        forCommentValues.forEach(value=>{
          let nodesIds = {
            open: 1,
            closed: 2,
            no: 3
          }
          let nodeId = String(nodesIds[value])
          setTimeout(function(){tree.checkNode(nodeId)}, 1000)
        })
      },
      /**
       * Load agenda filters
       */
      loadAgendaFilters (tree, item, callback) {
        if (item.depth === 1) {
          this.$http.get('/app/filters/agenda/').then(response => {
            callback(response.data.childs)
          })
        } else if (item.depth === 2) {
          this.$http.get(`/app/filters/agenda/${item.node.data.year}/`).then(response => {
            callback(response.data.childs)
          })
        } else if (item.depth === 3) {
          this.$http.get(`/app/filters/agenda/${item.node.data.year}/${item.node.data.month}/`).then(response => {
            callback(response.data.childs)
          })
        }
      },
      loadDatePostedFilters (tree, item, callback) {
        if (item.depth === 1) {
          this.$http.get('/app/filters/date-posted/').then(response => {
            callback(response.data.childs)
          })
        } else if (item.depth === 2) {
          this.$http.get(`/app/filters/date-posted/${item.node.data.year}/`).then(response => {
            callback(response.data.childs)
          })
        } else if (item.depth === 3) {
          this.$http.get(`/app/filters/date-posted/${item.node.data.year}/${item.node.data.month}/`).then(response => {
            callback(response.data.childs)
          })
        }
      },
      loadCommentFilters (tree, item, callback) {
        this.$http.get('/app/filters/comment/').then(response => {
            callback(response.data.childs)
        })
      },
      loadCollections (tree, item, callback) {
        let url = '/app/collections/'
        if (item) {
          url = `${url}${item.node.id}/`
        }
        this.$http.get(url).then(response => {
          callback(response.data.childs)
        })
      },
      refreshDocument (id, refreshRoute=true) {
        this.$restAPI.get( `/documents/${id}`).then(response => {
          if (response.status === 200) {
            this.viewerDocument = response.data
            this.viewerUrl = this.viewerDocument.download_url + '?embed=1'
            this.viewerVisible = true
            this.$root.$emit('document_refreshed')
            if (refreshRoute) {
              this.$router.push({ path: this.$route.path, query: { ...this.$route.query, ...{ viewDocument: id } } }).catch(()=>{})
            }
          }
        }).catch(error => {
          if (error.response.status !== 401) {
            // all errors except 401 Unauthorized
            message.error(this.$gettext('This document is no longer available for viewing or you do not have the required permissions'))
          }
        })
      },
      openDocumentFromDirectLink() {
        this.refreshDocument(this.$route.query.viewDocument, false)
      },
      openDocument (id) {
        let index = this.viewerDocumentItems.indexOf(id);
        if (index !== -1) {
          this.viewerDocumentIndex = index
        } else {
          this.clearDocumentItems()
        }
        this.refreshDocument(id)
      },
      openNextDocument () {
        if (this.hasNextDocument()) {
          this.viewerDocumentIndex += 1
          if (this.viewerDocumentIndex > this.viewerDocumentItems.length - 1) {
            this.$root.$emit('openFirstDocumentFromNextPage')
          } else {
            this.refreshDocument(this.viewerDocumentItems[this.viewerDocumentIndex])
          }
        }
      },
      openPreviousDocument () {
        if (this.hasPreviousDocument()) {
          this.viewerDocumentIndex -= 1
          if (this.viewerDocumentIndex < 0) {
            this.$root.$emit('openLastDocumentFromPreviousPage')
          } else {
            this.refreshDocument(this.viewerDocumentItems[this.viewerDocumentIndex])
          }
        }
      },
      hasPreviousDocument () {
        return this.viewerDocumentOffset + this.viewerDocumentIndex > 0;
      },
      hasNextDocument () {
        return this.viewerDocumentOffset + this.viewerDocumentIndex < this.viewerDocumentCount - 1;
      },
      clearDocumentItems () {
        this.viewerDocumentItems = []
        this.viewerDocumentIndex = -1
        this.viewerDocumentOffset = 0
        this.viewerDocumentCount = 0
      },
      loadDocumentItems (items, count = null, offset = 0, open = null) {
        this.viewerDocumentItems = items
        this.viewerDocumentIndex = -1
        this.viewerDocumentOffset = offset
        this.viewerDocumentCount = count || items.length
        if (open === 'first' && this.viewerDocumentItems.length > 0) {
          this.openDocument(this.viewerDocumentItems[0])
        } else if (open === 'last' && this.viewerDocumentItems.length > 0) {
          this.openDocument(this.viewerDocumentItems[this.viewerDocumentItems.length - 1])
        }
      },
      closeDocument () {
        let query = { ...this.$route.query }
        delete query['viewDocument']
        this.$router.push({ path: this.$route.path, query }).catch(()=>{})
      },
      internalCloseDocument () {
        this.viewerVisible = false
        this.viewerDocument = null
        this.viewerDocumentIndex = -1
      },
      viewerIsClosed() {
        return this.viewerVisible === false
      },
      base_url () {
        return '/'
      },
      is_edqm_ui () {
        let $meta = document.querySelector('meta[name="extranet-ui"]')
        return $meta && $meta.getAttribute('content') === 'edqm'
      },
      can_subscribe_all_groups () {
        let $meta = document.querySelector('meta[name="can-subscribe-all-groups"]')
        return $meta && $meta.getAttribute('content') === 'yes'
      }
    },
    render (h) {
      return h('app')
    },
    mounted () {
      let menuItems = document.querySelectorAll('.router-link a')
      for (let i = 0; i < menuItems.length; i++) {
        menuItems[i].addEventListener('click', e => {
          let path = e.target.closest('a').getAttribute('href')
          if (!path.startsWith(this.$route.path)) {
            this.$router.push(path)
          }
          e.preventDefault()
        })
      }
      if (window.isIE) {
        document.querySelector('body').classList.add('browser-ie')
        messageBox(
          this.$gettext('The EDQM pays particular attention to data protection.'),
          this.$gettext('Therefore, we advise you not to use web browsers that are no longer up to date, no longer supported by its developer, have security flaws or are at the end of their life.') +
          '<br><br>' +
          this.$gettext('This is particularly the case with Internet Explorer, which we recommend that you replace and for which our website is not optimised.\n') + '<br>',
          {'accept': this.$gettext('I understand')},
          false)
      } else if (window.isEdge) {
        document.querySelector('body').classList.add('browser-edge')
      }
      this.$root.$on('document_changed', id => {
        if (this.viewerVisible) {
          this.refreshDocument(id)
        }
      })
      if (this.$route.query.viewDocument) {
          this.openDocumentFromDirectLink()
      }
    }
  })

  let appFooter = new Vue({
    ...AppFooter,
    el: '#app-footer',
    parent: appRoot
  })
}

initialize()

// select2 use function toUpperCase and this function fails when it is applied to an array of text
// to fix this error we extend Array object with toUpperCase function
Array.prototype.toUpperCase = function(){return this.map( (el) => (typeof el === "string") ? el.toUpperCase() : el)}
