var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "panel",
    {
      attrs: {
        title: _vm.$gettext("Advanced search"),
        "icon-class": "fa fa-search",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-my-queries",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.openQueryManager()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$gettext("My queries")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("form", { staticClass: "search-form" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-xs-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "id_full_text", accesskey: "t" } }, [
                _vm._v(_vm._s(_vm.$gettext("Full text"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  { name: "focus", rawName: "v-focus" },
                  { name: "clear", rawName: "v-clear" },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$root.filterTerm,
                    expression: "$root.filterTerm",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "id_full_text",
                  name: "full_text",
                  type: "text",
                  autocomplete: "off",
                  placeholder: "",
                },
                domProps: { value: _vm.$root.filterTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$root, "filterTerm", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "search-full-text-bottom" }, [
              _c("label", { staticClass: "radio-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.filterSearchType,
                      expression: "$root.filterSearchType",
                    },
                  ],
                  attrs: { type: "radio", value: "1" },
                  domProps: {
                    checked: _vm._q(_vm.$root.filterSearchType, "1"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.$root, "filterSearchType", "1")
                    },
                  },
                }),
                _vm._v(" " + _vm._s(_vm.$gettext("Standard")) + "\n          "),
              ]),
              _vm._v(" "),
              _c("label", { staticClass: "radio-inline" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.filterSearchType,
                      expression: "$root.filterSearchType",
                    },
                  ],
                  attrs: { type: "radio", value: "2" },
                  domProps: {
                    checked: _vm._q(_vm.$root.filterSearchType, "2"),
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$set(_vm.$root, "filterSearchType", "2")
                    },
                  },
                }),
                _vm._v(
                  " " + _vm._s(_vm.$gettext("Phrase prefix")) + "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "pull-right search-syntax",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.seeSyntax()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$gettext("Search syntax")))]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "search-filters" }, [
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "inputTitle" } }, [
                  _vm._v(_vm._s(_vm.$gettext("Title"))),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.$root.filterTitle,
                      expression: "$root.filterTitle",
                    },
                    { name: "clear", rawName: "v-clear" },
                  ],
                  staticClass: "form-control",
                  attrs: { id: "inputTitle", placeholder: "" },
                  domProps: { value: _vm.$root.filterTitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.$root, "filterTitle", $event.target.value)
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { attrs: { accesskey: "g", for: "inputGroupAccess" } },
                  [_vm._v(_vm._s(_vm.$gettext("Group access")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("select2", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputGroupAccess",
                        options: _vm.$root.filterGroupAccessItems,
                        settings: _vm.getSelect2Settings(
                          "/autocomplete/groups/"
                        ),
                        multiple: "multiple",
                      },
                      on: { select: _vm.onSelectGroupAccess },
                      model: {
                        value: _vm.$root.filterGroupAccessTags,
                        callback: function ($$v) {
                          _vm.$set(_vm.$root, "filterGroupAccessTags", $$v)
                        },
                        expression: "$root.filterGroupAccessTags",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: {
                            title: _vm.$gettext("Search for all access groups"),
                            type: "button",
                          },
                          on: { click: _vm.openGroupAccessSelection },
                        },
                        [_vm._v("...")]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c(
                  "label",
                  { attrs: { for: "inputAuthorGroup", accesskey: "a" } },
                  [_vm._v(_vm._s(_vm.$gettext("Author group")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-group" },
                  [
                    _c("select2", {
                      staticClass: "form-control",
                      attrs: {
                        id: "inputAuthorGroup",
                        settings: _vm.getSelect2Settings(
                          "/autocomplete/groups/"
                        ),
                        multiple: "multiple",
                      },
                      on: { select: _vm.onSelectAuthorGroup },
                      model: {
                        value: _vm.$root.filterAuthorGroupTags,
                        callback: function ($$v) {
                          _vm.$set(_vm.$root, "filterAuthorGroupTags", $$v)
                        },
                        expression: "$root.filterAuthorGroupTags",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "input-group-btn" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: {
                            title: _vm.$gettext("Search for all author groups"),
                            type: "button",
                          },
                          on: { click: _vm.openAuthorGroupSelection },
                        },
                        [_vm._v("...")]
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "inputReference" } }, [
                    _vm._v(_vm._s(_vm.$gettext("PA/PH reference"))),
                  ]),
                  _vm._v(" "),
                  _c("select2", {
                    staticClass: "form-control",
                    attrs: {
                      id: "inputReference",
                      settings: _vm.getSelect2Settings(
                        "/autocomplete/references/",
                        true,
                        "contains"
                      ),
                      multiple: "multiple",
                    },
                    model: {
                      value: _vm.$root.filterReferenceTags,
                      callback: function ($$v) {
                        _vm.$set(_vm.$root, "filterReferenceTags", $$v)
                      },
                      expression: "$root.filterReferenceTags",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "inputMonoNumber" } }, [
                    _vm._v(_vm._s(_vm.$gettext("Mono/GM number"))),
                  ]),
                  _vm._v(" "),
                  _c("select2", {
                    staticClass: "form-control",
                    attrs: {
                      id: "inputMonoNumber",
                      settings: _vm.getSelect2Settings(
                        "/autocomplete/monoNumbers/",
                        true,
                        "startwith"
                      ),
                      multiple: "multiple",
                    },
                    model: {
                      value: _vm.$root.filterMonoNumberTags,
                      callback: function ($$v) {
                        _vm.$set(_vm.$root, "filterMonoNumberTags", $$v)
                      },
                      expression: "$root.filterMonoNumberTags",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.$root.is_edqm_ui()
              ? _c("div", { staticClass: "col-xs-12" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "inputKeywords" } }, [
                        _vm._v(_vm._s(_vm.$gettext("Keywords/section"))),
                      ]),
                      _vm._v(" "),
                      _c("select2", {
                        staticClass: "form-control",
                        attrs: {
                          id: "inputKeywords",
                          settings: _vm.getSelect2Settings(
                            "/autocomplete/keywords/",
                            true,
                            "contains"
                          ),
                          multiple: "multiple",
                        },
                        model: {
                          value: _vm.$root.filterKeywordsTags,
                          callback: function ($$v) {
                            _vm.$set(_vm.$root, "filterKeywordsTags", $$v)
                          },
                          expression: "$root.filterKeywordsTags",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$root.is_edqm_ui()
              ? _c("div", { staticClass: "col-xs-12" }, [
                  _c("br"),
                  _vm._v(" "),
                  _c("div", { staticClass: "checkbox" }, [
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.$root.includeAllRestricted,
                            expression: "$root.includeAllRestricted",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(_vm.$root.includeAllRestricted)
                            ? _vm._i(_vm.$root.includeAllRestricted, null) > -1
                            : _vm.$root.includeAllRestricted,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.$root.includeAllRestricted,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.$root,
                                    "includeAllRestricted",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.$root,
                                    "includeAllRestricted",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.$root, "includeAllRestricted", $$c)
                            }
                          },
                        },
                      }),
                      _vm._v(
                        _vm._s(
                          _vm.$gettext(
                            "Include restricted documents to which I do not have access"
                          )
                        ) + "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox-info" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$gettext(
                            "Please note, text extracts will not be available with this option."
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search-footer" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.search()
                },
              },
            },
            [
              _c("i", { staticClass: "fa fa-search" }),
              _vm._v(" " + _vm._s(_vm.$gettext("Search"))),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.commentOverview()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$gettext("Comment overview")))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-secondary",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.clear()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$gettext("Clear")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }