<template>
  <div>
    <span class="title"><i class="fa fa-external-link"></i> {{$gettext('Links')}}</span>
    <ul v-if="loaded">
      <li v-for="item in links" :key="item.name"><a target="_blank" :href="item.url">{{ item.name }}</a></li>
    </ul>
    <spinner v-else></spinner>
  </div>
</template>

<script>
  import Spinner from './spinner'

  export default {
    name: 'links',
    components: { Spinner },
    data: function () {
      return {
        links: [],
        editMode: false,
        loaded: false
      }
    },
    methods: {
      load () {
        this.$restAPI.get('/user/links/').then(response => {
          this.links = response.data
        }).then(() => { this.loaded = true })
      }
    },
    mounted () {
      this.load()
    }
  }
</script>

<style scoped lang="scss">
  .title {
    font-family: Open Sans Condensed, Open Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    i {
      padding-right: 4px;
    }
  }

  ul {
    margin-top: 8px;
    margin-bottom: 0;
    list-style: none;
    padding-left: 0;
  }

  li {
    font-size: 12px;

    a {
      color: white;
    }
  }

</style>
