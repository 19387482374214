<template>
  <div class="modal fade" tabindex="-1" role="dialog" id="team-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col-sm-6">
            <h2 class="modal-title">{{$gettext('Access for')}}</h2>
          </div>
          <div class="col-sm-6">
            <input tabindex="1" autocomplete="off" :placeholder="$gettext('Filter')" name="filter" class="form-control pull-right"
                   v-model="filter">
          </div>
        </div>
        <div class="modal-body">
          <div class="table-top-panel">
            <label class="radio-inline">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1" v-model="mode"> {{$gettext('My groups')}}
            </label>
            <label class="radio-inline">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2" v-model="mode"> {{$gettext('All groups')}}
            </label>
            <label class="radio-inline">
              <input type="radio" name="inlineRadioOptions" id="inlineRadio3" :value="3" v-model="mode"> {{$gettext('Users')}}
            </label>
          </div>
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="table-col-1"><input id="select-all" type="checkbox" @change="toggleAllItemSelection($event)"></th>
                <th v-if="mode !== 3" class="table-col-3"><a href="#" @click.stop="toggleOrderBy('code')">{{$gettext('Code')}} <i
                  :class="getSortOrderIconClass('code')" class="fa"></i></a></th>
                <th class="table-col-2"><a href="#" @click.stop="toggleOrderBy(mode === 3 ? 'display_name': 'name')">{{$gettext('Name')}} <i
                  :class="getSortOrderIconClass('name')" class="fa"></i></a></th>
                <th v-if="mode === 3 || mode === 4" class="table-col-4"><a href="#" @click.stop="toggleOrderBy('email')">{{$gettext('Email')}} <i
                  :class="getSortOrderIconClass('email')" class="fa"></i></a></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in items" :key="item.code" :data-user-id="item.code" @dblclick="rowSelect(item)">
                <td class="table-col-1"><input type="checkbox" @change="toggleItemSelection(item, $event)" :checked="hasItemSelected(item)"></td>
                <td v-if="mode !== 3" class="table-col-3">{{ item.code }}</td>
                <td class="table-col-2">{{ item.name || item.display_name }}</td>
                <td v-if="mode === 3 || mode === 4" class="table-col-4">{{ item.email }}</td>
              </tr>
            </tbody>
          </table>
          <div v-if="items.length === 0" class="alert alert-info" role="alert">
            {{$gettext('No result found.')}}
          </div>
          <paginator v-if="numPages > 1" :params="params" :num_page="numPages" @change="loadData()"></paginator>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal" @click="submit">{{$gettext('OK')}}</button>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$gettext('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Paginator from './paginator'
  import Spinner from './spinner'
  import InfiniteTable from './infinite-table'
  import debounce from 'lodash.debounce'

  export const MY_GROUPS = 1
  export const ALL_GROUPS = 2
  export const ALL_USERS = 3
  export const ALL_SELECTED = 4

  let groupList = {
    name: 'group-list',
    components: { Spinner, Paginator, InfiniteTable },
    props: {
      selection: {
        type: Array,
        default: []
      },
      initial: {
        type: Object,
        default: {}
      },
      defaultMode: {
        type: Number,
        default: MY_GROUPS
      }
    },
    data: function () {
      return {
        numPages: 1,
        total: 0,
        params: { page: 1 },
        orderBy: null,
        sortOrder: 'asc',
        fieldOrder: null,
        filter: '',
        items: [],
        selectedItems: [...this.selection],
        loading: false,
        mode: this.defaultMode
      }
    },
    computed: {
      pageSize () {
        return Math.trunc(this.total / this.numPages)
      },
      recordNumber () {
        return this.pageSize * (this.params.page - 1) + this.currentIndex + 1
      }
    },
    watch: {
      mode () {
        this.params.page=1
        this.loadData(true)
      },
      filter: debounce(function () {
          this.loadData(true)
        }, 300)
    },
    methods: {
      nextPage () {
        this.params.page++
        this.loadData()
      },
      prevPage () {
        this.params.page--
        if (this.params.page < 1) {
          this.params.page = 1;
          return
        }
        this.loadData()
      },
      selectItem(item) {
        if (!this.hasItemSelected(item)) {
          if (this.mode === 3) {
            this.selectedItems.push({ ...this.initial, name: `${item.display_name}`, id: item.id, type: 'user' })
          } else {
            this.selectedItems.push({ ...this.initial, name: `${item.name} (${item.code})`, id: item.id, type: 'group' })
          }
        }
      },
      rowSelect (item) {
        this.selectItem(item)
        this.submit()
        $(this.$el).modal('hide')
      },
      toggleAllItemSelection (e) {
        if (e.target.checked) {
          this.items.forEach(item => this.selectItem(item))
        } else {
          this.items.forEach(item => {
            if (this.mode === 3) {
              this.selectedItems = this.selectedItems.filter(i => !(i.id === item.id && i.type === 'user'))
            } else {
              this.selectedItems = this.selectedItems.filter(i => !(i.id === item.id && i.type === 'group'))
            }
          })
        }
      },
      updateSelectAll() {
        let type = (this.mode === 3) ? 'user' : 'group';
        let checked = true
        this.items.forEach(item => {
            checked = checked && this.selectedItems.some(i => i.id === item.id && i.type === type)
          }
        )
        document.getElementById('select-all').checked = checked
      },
      toggleItemSelection (item, e) {
        if (e.target.checked) {
          this.selectItem(item)
        } else {

          if (this.mode === 3) {
            this.selectedItems = this.selectedItems.filter(i => !(i.id === item.id && i.type === 'user'))
          } else {
            this.selectedItems = this.selectedItems.filter(i => !(i.id === item.id && i.type === 'group'))
          }
        }
        this.updateSelectAll()
      },
      hasItemSelected (item) {
        if (this.mode === 3) {
          return !!this.selectedItems.find(i => i.id === item.id && i.type === 'user')
        } else {
          return !!this.selectedItems.find(i => i.id === item.id && i.type === 'group')
        }

      },
      submit () {
        this.$emit('selection', this.selectedItems)
      },
      loadData (resetPage=false) {
        if (resetPage) this.params.page = 1
        let ordering = 'name'
        if (this.fieldOrder) {
          ordering = (this.sortOrder === 'desc' ? '-' : '') + this.fieldOrder
        }
        let params = {
          page: this.params.page,
          ordering: ordering,
          size: 10
        }
        if (this.filter) {
          params['search'] = this.filter
        }
        let url = null
        switch(this.mode) {
          case MY_GROUPS:
            url = '/user/groups/'
            break;
          case ALL_GROUPS:
            url = '/groups/'
            break;
          case ALL_USERS:
            url = '/users/'
            break;
        }
        if (url) {
          this.loading=true
          this.$restAPI.get(url, { params }).then(response => {
            this.numPages = response.data.num_pages
            this.items = response.data.results
            this.total = response.data.count
            this.updateSelectAll()
          }).catch(error => { console.error(error)}).then(() => this.loading=false)
        }
      },
      toggleOrderBy (fieldname) {
        if (this.fieldOrder === fieldname) {
          this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc'
        } else {
          this.sortOrder = 'asc'
          this.fieldOrder = fieldname
        }
        this.loadData(true)
      },
      getSortOrderIconClass (fieldname) {
        if (this.fieldOrder === fieldname) {
          return 'fa-sort-' + this.sortOrder
        } else {
          return 'fa-sort'
        }
      }
    },
    mounted () {
      this.loadData()
      Vue.nextTick(() => {
        $(this.$el).on('hidden.bs.modal', e => {
          this.$emit('close')
          this.$destroy()
        })
        $(this.$el).on('shown.bs.modal', e => {
          $(this.$el).find('input[name="filter"]').focus()
        })
        $(this.$el).modal()
      })
    }
  }

  export function accessMembersSelector (root, defaultMode, selection, initial) {
    return new Promise((resolve, reject) => {
      let teamDialog = document.getElementById('team-dialog')
      if (teamDialog === null) {
        teamDialog = document.createElement('div')
        // if document-edit exists in the DOM, add team-dialog after
        let documentEditor = document.getElementById('document-editor')
        if (documentEditor === null){
          let body = document.querySelector('body')
          body.appendChild(teamDialog)
        } else {
          documentEditor.after(teamDialog)
        }
      }
      let vm = new Vue({
        name: 'team-dialog',
        parent: root,
        components: { groupList },
        render: function (h) {
          return h('group-list', { props: { selection, initial, defaultMode }, on: { selection: this.onSelection, close: this.onClose } })
        },
        methods: {
          onSelection (selection) {
            resolve(selection)
          },
          onClose () {
            vm.$destroy()
          }
        },
        mounted () {
          $(this.$el).modal({ backdrop: 'static', show: false })
        }
      }).$mount(teamDialog)
    })
  }

  export default groupList
</script>

<style lang="scss" scoped>

  .modal-header {
    padding: 15px 0;
  }

  .btn-tool {
    max-width: 28px;
    min-width: 28px;
    min-height: 28px;
    max-height: 28px;
    padding-top: 3px;
    padding-left: 6px;
    margin-left: 8px;
    background-color: transparent;
    color: #444;
    border-radius: 3px;
    border: none;

    &:hover {
      background-color: #e9e9e9;
      border: 1px solid #999;
    }
  }

  .navig-updown {
    padding-top: 4px;
    color: #444;
    cursor: pointer;
  }

  .navig-rows {
    margin-right: 12px;
    font-size: 0.9em;
    padding-top: 3px;
    color: #444;
  }

  form label {
    color: #888;
  }

  .user-team-item {
    &.active {
      color: currentColor;
      cursor: default;
      text-decoration: none;
      pointer-events: none;
      display: inline-block;
    }
  }

  .user-team-item:not(:first-child):before {
    content: ",";
    color: #444;
    cursor: default;
    text-decoration: none;
    pointer-events: none;
    display: inline-block;
    padding-right: 4px;
  }

  .form-group.user-teams {
    margin-top: 12px;
  }

  .table-top-panel {
    height: 50px;
    padding: 4px;
  }

  th a:hover {
    text-decoration: none;
  }

  th {
    text-align: left;
  }

  .table-bottom-panel {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
  }
  .table-col-1 {
    width: 30px;
  }
  .table-col-2 {
    width: 370px;
  }
  .table-col-3 {
    width: 100px;
  }
  .body-panel {
    height: 40px;
  }
  .modal-body {
    height: 560px;
  }
</style>
