<template>
  <span :class="{'field-edit': edit}">
    <template v-if="!edit">{{ current }}</template>
    <input v-else v-model="newValue" @input="updateValue()">
  </span>
</template>

<script>
  import InputTags from './input-tags'
  export default {
    name: "meta-field",
    components: { InputTags },
    props: {
      value: {},
      edit: {
        type: Boolean,
        default: false
      },
    },
    watch: {
      edit() {
        if (this.edit) {
          this.newValue = this.current
        }
      }
    },
    computed: {
      current() {
        if (Array.isArray(this.value)) {
          return this.value.join(',')
        } else {
          return this.value
        }
      }
    },
    data() {
      return {
        newValue: null
      }
    },
    methods: {
      updateValue() {
        let newValue = null
        if (Array.isArray(this.value)) {
          newValue = this.newValue && this.newValue.split(',') || []
        } else {
          newValue = this.newValue
        }
        this.$emit('input', newValue)
      }
    }
  }
</script>

<style lang="scss" scoped>
  input {
    background-color: transparent;
    border: none;
    width: 100%;
  }
  span.field-edit {
    border: dotted 1px #777;
    background-color: rgba(128, 128, 128, 0.1);
  }
  span {
    padding: 0;
    margin: 0;
    width: 100%;
  }
</style>
