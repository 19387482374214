var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$parent.enabled
    ? _c("div", { staticClass: "comment" }, [
        _c(
          "span",
          {
            staticClass: "comment-link",
            class: { active: !_vm.$parent.readonly },
            attrs: {
              id: "comment-link-" + _vm.to_html_id(_vm.reference),
              "data-toggle": "tooltip",
              title: !this.$parent.readonly
                ? _vm.$gettext("Add new comment for reference line") +
                  " " +
                  _vm.reference
                : "",
            },
            on: { click: _vm.add },
          },
          [_vm._v(_vm._s(_vm.reference))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "comment-line-container" },
          _vm._l(_vm.$parent.comments[_vm.reference], function (item) {
            return _c("comment-item", {
              key: item.id,
              attrs: { tools: _vm.$parent, item: item },
            })
          }),
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }