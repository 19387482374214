<template>
  <div>
     <div :id="'comment-' + to_html_id(item.line_ref)" class="comment-content"
       :style="{ backgroundColor: item.color }" :class="{ 'comment-hidden': item.hidden, 'comment-deleted': item.state.value === 'deleted' }">
    <div class="comment-title" @dblclick="toggleFolded"><span @click="toggleFolded(item)" class="fa comment-fold-toggle" :class="{'fa-caret-down': !folded, 'fa-caret-right': folded}"></span> {{item.line_ref }}.{{item.number }}: {{ item.type.label }} <span v-if="item.origin"> - {{$gettext('Comment from')}} {{ item.origin.label }}</span></div>
    <div v-show="!folded">
      <div class="comment-text clearfix" v-html="item.content"></div>
      <div v-for="attachFile in item.attachments" :key="attachFile.uuid" class="comment-attachment-item">
        <span class="glyph fa fa-paperclip"/>&nbsp;<a target="_blank" :href="attachFile.url">{{ attachFile.filename }}</a>
      </div>
      <div class="comment-footer clearfix">
        <i class="comment-footer-info" v-for="(info, index) in item.info "><br v-if="index != 0"/>{{ info }}</i>
        <span class="comment-actions hidden-print">
          <template v-if="item.state.value === 'deleted' && !item.readonly && !$parent.$parent.readonly">
            <span class="glyph fa fa-trash"></span><a @click.prevent="restore(item)">{{ $gettext('Restore') }}</a>
          </template>
          <template v-else>
            <template v-if="!item.hidden && !item.readonly">
              <span v-if="item.state.value === 'draft'">
                <span class="glyph fa fa-pencil"></span><a @click.prevent="edit(item)">{{ $gettext('Edit') }}</a>
                <span class="glyph fa fa-trash"></span><a @click.prevent="remove(item)">{{ $gettext('Delete') }}</a>
                <span class="glyph fa fa-check"></span><a @click.prevent="validate(item)">{{ $gettext('Validate') }}</a>
              </span>
              <span v-else>
                <span class="glyph fa fa-lock"></span><a @click.prevent="unlock(item)">{{ $gettext('Unlock') }}</a>
              </span>
            </template>
<!--            <template v-if="$parent.$parent.seeHidden">-->
<!--              <template v-if="!item.hidden">-->
<!--                <span class="glyph fa fa-eye-slash"></span><a @click.prevent="hide(item)">{{ $gettext('Hide') }}</a>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span class="glyph fa fa-eye"></span><a @click.prevent="unHide(item)">{{ $gettext('Unhide') }}</a>-->
<!--              </template>-->
<!--            </template>-->
          </template>
        </span>
     </div>
    </div>
  </div>
    </div>
</template>

<script>
  import { messageBox } from './utils/dialogs'
  import axios from 'axios'
  import urljoin from 'url-join'
  import CommentItem from './comment-item'
  import { openCommentEditor } from './comment-editor.vue'
  import message from 'toastr'

  export default {
    name: "comment-item",
    props: {
      item: Object,
      tools: Object
    },
    data: function () {
      return {
        folded: false
      }
    },
    methods: {
      cancel () {
        this.has_comment = false
      },
      toggleFolded (comment) {
        this.folded = !this.folded
      },
      changeState (comment, state) {
        this.$restAPI.patch(`/comments/${comment.id}/`, {state}).then(response => {
          comment.state.value = response.data.state
          this.tools.$emit('commentRefresh', comment.id)
          // message.success(response.statusText)
        }).catch(error => {
          message.error(error.message)
        })
      },
      changeVisibility (comment, visible) {
        message.info('Not available')
        // let action = visible === true ? 'show' : 'hide'
        // axios.post(urljoin(this.$root.base_url(), 'comments', 'visibility', String(commentId), action)).then(response => {
        //   if (response.status === 200) {
        //     this.$root.loadAllComments()
        //   } else {
        //     this.$root.notifyMessage('warning', response.statusText)
        //   }
        // }).catch(error => {
        //   this.$root.notifyMessage('error', error.message)
        // })
      },
      remove (comment) {
        messageBox(this.$gettext('Delete comment'), this.$gettext('Are you sure you want to delete this comment ?'), {
          'delete': this.$gettext('Delete'),
          'cancel': this.$gettext('Cancel')
        }).then(action => {
          if (action === 'delete') {
            this.changeState(comment, 'deleted')
          }
        })
      },
      edit (comment) {
        openCommentEditor(this.tools, comment.line_ref, comment.id)
      },
      validate (comment) {
        this.changeState(comment, 'validated')
      },
      unlock (comment) {
        this.changeState(comment, 'draft')
      },
      restore (comment) {
        this.changeState(comment, 'draft')
      },
      hide (comment) {
        this.changeVisibility(comment, false)
      },
      unHide (comment) {
        this.changeVisibility(comment, true)
      },
      to_html_id (value) {
        return String(value).replace('.', '-').replace(' ', '')
      }
    }
  }
</script>

<style lang="scss" scoped>

.comment {
  text-indent: 0;
  line-height: 12px;
  font-size: 10px;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
  display: none;
}

.comment-title {
  font-size: 10px;
  font-weight: bold;
  color: black;
  user-select: none;
  line-height: 10px;
}

.comment-text {
  margin-top: 8px;
}

.comment-content {
  margin: 4px 2px 8px 2px;
  background-color: #fdffde;
  padding: 4px;
  border: 1px solid #777;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-align: left;
  overflow-x: auto;
  page-break-inside: avoid;
}

.comment-editor label {
  color: #707070;
  font-weight: normal;
}

.comment-footer {
  margin-top: 10px;
  font-size: 10px;
  color: #333;
  user-select: none;
  line-height: 10px;
}

.comment-footer a {
  cursor: pointer;
}

.comment-actions {
  float: right;
}

.comment-actions .glyph {
  color: #1b6d85;
  margin-right: 2px;
  margin-left: 8px;
}

.comment-attachment-item {
  font-size: 12px;
  display: inline-block;
  margin-left: 4px;
  margin-right: 20px;
}

.comment-attachment-item span.glyph {
  color: #1b6d85;
}

.comment-content.folded .comment-footer,
.comment-content.folded .comment-text,
.comment-content.folded .comment-attachment-item {
  display: none;
}

.comment-fold-toggle {
  cursor: pointer;
}

.comment-hidden {
  background: repeating-linear-gradient(135deg, transparent, transparent 10px, rgba(240, 240, 240, 0.8) 10px, rgba(240, 240, 240, 0.8) 20px);
}

.comment-deleted {
  background: repeating-linear-gradient(45deg, transparent, transparent 5px, rgba(255, 0, 0, 0.1), rgba(255, 0, 0, 0.1) 10px);
}

</style>
