<template>
  <input class="datepicker" :value="value" :disabled="disabled" @input="$emit('input', $event.target.value)">
</template>

<script>
  import $ from 'jquery'

  export default {
    name: 'datepicker',
    props: {
      value: {
        type: String,
      },
      disabled: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default () {
          return {}
        }
      }
    },
    mounted () {
      $(this.$el).datepicker(this.options).on('changeDate', () => {
        this.$emit('input', this.$el.value)
      });
    },
    destroyed () {
      $(this.$el).datepicker('destroy');
    }
  }
</script>

<style scoped>

</style>
