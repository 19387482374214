var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      { staticClass: "timeline" },
      [
        _vm._l(_vm.data, function (item, k, index) {
          return [
            index > 0
              ? _c("li", { staticClass: "timeline-item period" }, [
                  _c("div", { staticClass: "timeline-info" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "timeline-marker" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "timeline-content" }, [
                    _c("h2", { staticClass: "timeline-title" }, [
                      _vm._v(_vm._s(k)),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(item, function (event) {
              return _c(
                "li",
                { staticClass: "timeline-item timeline-item-meeting" },
                [
                  _c("div", { staticClass: "timeline-info" }, [
                    _c("span", [_vm._v(_vm._s(event.start))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "timeline-marker" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "timeline-content" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "timeline-title timeline-action",
                        attrs: { title: _vm.$gettext("Click to see detail") },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openEvent(event, $event)
                          },
                        },
                      },
                      [_vm._v(_vm._s(event.title))]
                    ),
                    _vm._v(" "),
                    _c("p", { domProps: { innerHTML: _vm._s(event.summary) } }),
                  ]),
                ]
              )
            }),
          ]
        }),
        _vm._v(" "),
        _vm._m(0),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "timeline-item period" }, [
      _c("div", { staticClass: "timeline-info" }),
      _vm._v(" "),
      _c("div", { staticClass: "timeline-marker timeline-marker-end" }),
      _vm._v(" "),
      _c("div", { staticClass: "timeline-content" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }