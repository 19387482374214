<template>
  <div class="alert alert-warning" role="alert">Not implemented yet</div>
</template>

<script>
  export default {
    name: "MessagePanel"
  }
</script>

<style scoped>

</style>
