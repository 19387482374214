var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    staticClass: "datepicker",
    attrs: { disabled: _vm.disabled },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }