<template>
  <ul class="treeview">
    <treeview-node :always_expanded="expanded" :depth="1"
        :key="node.id"
        :node="node" class="item"
        v-for="node in nodes"
        v-on:emitNodeChecked="onNodeChecked"
        v-on:emitNodeExpanded="onNodeExpanded"
        v-on:emitNodeClicked="onNodeClicked"
        v-on:emitNodeSelected="onNodeSelected">
    </treeview-node>
    <li class="no-item" v-if="!nodes || nodes.length === 0">{{$gettext('Empty')}}</li>
  </ul>
</template>

<script>
  import TreeviewNode from './treeview-node.vue'

  /**
   * Treeview component description
   * @vuedoc
   * @exports components/Treeview
   */
  export default {
    components: {TreeviewNode},
    props: {
      data: {
        type: Array,
        required: false
      },
      onload: {
        type: Function,
        required: false
      },
      onclick: {
        type: Function,
        required: false
      },
      multiselectable: {
        type: Boolean,
        default: false
      },
      selectable: {
        type: Boolean,
        default: true
      },
      expanded: {
        type: Boolean,
        default: false
      },
      checkable: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        selectedNode: null,
        expandedNodes: {},
        nodes: this.data,
        adding: false
      }
    },
    computed: {
    },
    methods: {
      forceRender (nodes) {
        this.nodes = []
        this.$nextTick(() => {
          this.nodes = nodes
        })
      },
      /**
       * @private
       */
      recFindNodePath (nodeId, nodes, depth, maxDepth) {
        let ret = []
        nodes.forEach(node => {
          let tmp = []
          if (nodeId === node.id && maxDepth >= depth) {
            ret.unshift(node.id)
            return false
          } else if (node.childs && Array.isArray(node.childs) && maxDepth > depth && (tmp = this.recFindNodePath(nodeId, node.childs, depth + 1, maxDepth)) != null) {
            tmp.unshift(node.id)
            ret = tmp
            return false
          }
        })
        if (ret.length === 0) return null
        return ret
      },
      findNodePath (nodeId, maxDepth = 9999) {
        return this.recFindNodePath(nodeId, this.nodes, 1, maxDepth)
      },
      /**
       * @private
       */
      recFindNode (nodeId, nodes, depth, maxDepth) {
        let ret = null
        for (let node of nodes) {
          let tmp = []
          if (String(nodeId) === String(node.id) && maxDepth >= depth) {
            ret = node
            break
          } else if (node.childs && Array.isArray(node.childs) && maxDepth > depth && (tmp = this.recFindNode(nodeId, node.childs, depth + 1, maxDepth)) != null) {
            ret = tmp
            break
          }
        }
        return ret
      },
      findNode (nodeId, maxDepth = 9999) {
        return this.recFindNode(nodeId, this.nodes, 1, maxDepth)
      },
      onNodeClicked (nodeClicked) {
        if (this.onclick) {
          this.onclick(this, nodeClicked)
        }
      },
      onNodeSelected (nodeSelected) {
        if (this.selectedNode == null && nodeSelected.state.selected === true) {
          this.selectedNode = nodeSelected
        } else if (this.selectedNode != null && nodeSelected.state.selected === false) {
          this.selectedNode = null
        } else if (!this.multiselectable && this.selectedNode != null && nodeSelected.state.selected === true) {
          const arrIds = this.findNodePath(this.selectedNode.id, this.selectedNode.depth)
          this.callSpecificChild(arrIds, 'callNodeSelected', {value: false, arrIds: arrIds})
          this.selectedNode = nodeSelected
          this.$nextTick(() => {
            const selectArrIds = this.findNodePath(this.selectedNode.id, this.selectedNode.depth)
            this.callSpecificChild(selectArrIds, 'callNodeSelected', {value: true, arrIds: selectArrIds})
          })
        }
        this.$emit('node-selected', nodeSelected)
      },
      onNodeExpanded (node, state) {
        if (state === false) {
          this.nodeCollapsed(node.id, undefined, node.depth)
        } else {
          this.nodeExpanded(node.id, undefined, node.depth)
        }
        this.$emit('expand', node, state)
      },
      onNodeChecked (node) {
        this.$nextTick(() => this.$emit('checked-nodes-changed', this))
      },
      callSpecificChild (arrIds, fname, args) {
        for (let i = 0; i < this.$children.length; i++) {
          let currentNodeId = this.$children[i].$props.node.id
          if (arrIds.find(x => x === currentNodeId)) {
            this.$children[i][fname](args)
            return false
          }
        }
      },
      doCheckNode (nodeId, depth, state) {
        const arrIds = this.findNodePath(nodeId, depth)
        if (!arrIds) return
        this.callSpecificChild(arrIds, 'callNodeChecked', {
          value: state,
          arrIds: arrIds
        })
      },
      checkNode (nodeId, depth) {
        this.doCheckNode(nodeId, depth, true)
      },
      uncheckNode (nodeId, depth) {
        this.doCheckNode(nodeId, depth, false)
      },
      getSelectedNode () {
        return this.selectedNode
      },
      getCheckedNodes (argWanted, format = 'optimize') {
        return this.getNodesData(argWanted, {checked: true}, format)
      },
      getExpandedNodes (argWanted, format = false) {
        return this.getNodesData(argWanted, {expanded: true}, format)
      },
      checkAllNodes () {
        for (let i = 0; i < this.$children.length; i++) {
          this.$children[i].callNodesChecked(true)
        }
      },
      uncheckAllNodes () {
        for (let i = 0; i < this.$children.length; i++) {
          this.$children[i].callNodesChecked(false)
        }
      },
      /**
       * @private
       */
      recExpandAllNodes (nodes) {
        const openedTmp = {}
        for (let i = 0; i < nodes.length; i++) {
          nodes[i].state.expanded = true
          if (nodes[i].childs) {
            openedTmp[nodes[i].id] = this.recExpandAllNodes(nodes[i].childs)
          }
        }
        return openedTmp
      },
      expandAllNodes () {
        const openedTmp = {}
        for (let i = 0; i < this.nodes.length; i++) {
          this.nodes[i].state.expanded = true
          if (this.nodes[i].childs) {
            openedTmp[this.nodes[i].id] = this.recExpandAllNodes(this.nodes[i].childs)
          }
        }
        this.expandedNodes = openedTmp
        this.forceRender(this.nodes)
      },
      recCollapseAllNodes (hashIds) {
        Object.entries(hashIds).forEach(arr => {
          let node = this.findNode(arr[0])
          if (node) node.state.expanded = false
          if (arr[1] && Object.keys(arr[1]).length > 0) this.recCollapseAllNodes(arr[1])
        })
      },
      collapseAllNodes () {
        Object.entries(this.expandedNodes).forEach(arr => {
          let node = this.findNode(arr[0])
          if (node) node.state.expanded = false
          if (arr[1] && Object.keys(arr[1]).length > 0) this.recCollapseAllNodes(arr[1])
        })
        this.expandedNodes = {}
        this.forceRender(this.nodes)
      },
      deselectAllNodes () {
        this.selectedNode = null
        for (let i = 0; i < this.$children.length; i++) {
          this.$children[i].callNodesDeselect()
        }
      },
      expandNode (nodeId, depth) {
        const arrIds = this.findNodePath(nodeId, depth)
        this.nodeExpanded(nodeId, arrIds)
        this.callSpecificChild(arrIds, 'callNodeExpanded', {
          value: true,
          arrIds: arrIds
        })
      },
      newNode() {
        if (this.selectedNode) {
          this.expandNode(this.selectedNode.id, this.selectedNode.depth)
          this.adding = true
        }
      },
      selectNode (nodeId, depth) {
        const nodeSelected = this.findNode(nodeId, depth)
        if (this.selectedNode) {
          let arrIds = this.findNodePath(this.selectedNode.id, this.selectedNode.depth)
          this.callSpecificChild(arrIds, 'callNodeSelected', {value: false, arrIds: arrIds})
        }
        this.selectedNode = nodeSelected
        if (this.selectedNode) {
          this.$nextTick(() => {
            const selectArrIds = this.findNodePath(this.selectedNode.id, this.selectedNode.depth)
            this.callSpecificChild(selectArrIds, 'callNodeSelected', {value: true, arrIds: selectArrIds})
          })
        }
      },
      nodeExpanded (nodeId, arrIds, depth) {
        if (arrIds === undefined) {
          arrIds = this.findNodePath(nodeId, depth)
        }
        let tmpElem = this.expandedNodes
        arrIds.forEach(id => {
          if (!tmpElem[id]) {
            tmpElem[id] = {}
          }
          tmpElem = tmpElem[id]
        })
      },
      collapseNode (nodeId, depth) {
        const arrIds = this.findNodePath(nodeId, depth)
        this.nodeCollapsed(nodeId, arrIds)
        this.callSpecificChild(arrIds, 'callNodeExpanded', {
          value: false,
          arrIds: arrIds
        })
      },
      nodeCollapsed (nodeId, arrIds, depth) {
        if (arrIds === undefined) {
          arrIds = this.findNodePath(nodeId, depth)
        }
        let tmpElem = this.expandedNodes
        arrIds.forEach((id, i) => {
          if (!tmpElem[id]) {
            return false
          } else if (i === arrIds.length - 1 && tmpElem[id]) {
            delete tmpElem[id]
          }
          tmpElem = tmpElem[id]
        })
      },
      recGetVisibleNodes (arr, elem, fullNode) {
        const node = elem.$props.node
        if (fullNode === true) {
          arr.push(node)
        } else {
          arr.push(node.id)
        }
        elem.$children.forEach(child => {
          arr = this.recGetVisibleNodes(arr, child, fullNode)
        })
        return arr
      },
      getVisibleNodes (fullNode = false) {
        let arr = []
        this.$children.forEach(child => {
          arr = this.recGetVisibleNodes(arr, child, fullNode)
        })
        return arr
      },
      recGetNodesData (argWanted, conditions, nodes, skipChildren = false) {
        let arr = []
        if (!nodes || !Array.isArray(nodes)) return arr
        nodes.forEach(node => {
          let found = false
          if (node.state && Object.keys(node.state).filter(key => conditions[key] === node.state[key]).length === Object.keys(conditions).length) {
            if (Array.isArray(argWanted)) {
              arr.push(Object.keys(node).filter(key => argWanted.includes(key)).reduce((obj, key) => {
                obj[key] = node[key]
                return obj
              }, {}))
            } else {
              arr.push(node[argWanted])
            }
            found = true
          }
          if (!skipChildren || (!found && skipChildren)) {
            arr = arr.concat(this.recGetNodesData(argWanted, conditions, node.childs, skipChildren))
          }
        })
        return arr
      },
      recGetNodesDataWithFormat (argWanted, conditions, nodes) {
        let arr = {}
        if (nodes === undefined || nodes.length === 0 || !Array.isArray(nodes)) return arr
        nodes.forEach(node => {
          if (node.state && Object.keys(node.state).filter(key => conditions[key] === node.state[key]).length === Object.keys(conditions).length) {
            arr[node.id] = this.recGetNodesDataWithFormat(argWanted, conditions, node.childs)
          } else {
            Object.assign(arr, this.recGetNodesDataWithFormat(argWanted, conditions, node.childs))
          }
        })
        return arr
      },
      getNodesData (argWanted, conditions = {}, format = false) {
        let arr = null
        if (format === false || format === 'optimize') {
          arr = this.recGetNodesData(argWanted, conditions, this.nodes, format === 'optimize')
        } else {
          arr = this.recGetNodesDataWithFormat(argWanted, conditions, this.nodes)
        }
        return arr
      },
      loadNode (item, selectNodeId=null) {
        if (!this.onload) {
          console.error('Dynamic node loading, an URL must be set.')
        } else {
          if (item) {
            item.loading=true
          }
          this.onload(this, item, data => {
            if (item) {
              item.updateChilds(data, selectNodeId)
            } else {
              this.nodes = data
              if (selectNodeId !== null) {
                this.selectNode(selectNodeId)
              }
            }
          })
        }
      }
    },
    mounted () {
      this.$root.treeview = this
      if (!this.nodes) {
        this.loadNode(null)
      }
    },
    destroyed () {
      this.$root.treeview = null
    }
  }
</script>

<style scoped>
  .treeview {
    user-select: none;
  }

  ul {
    line-height: 1.5em;
    list-style-type: none;
    padding-left: 12px;
  }
  li.no-item {
    font-style: italic;
    font-size: 0.85em;
    color: #ccc;
    margin-left: 12px;
  }
</style>
