<template>
  <panel :title="currentFolderName" icon-class="fa fa-folder-open">
    <div class="folder-action-bar">
      <div class="folder-action-move-box" v-if="isMoving">{{$gettext('Browse to target folder, then click')}} <a href="#" @click.stop="acceptMoving" v-if="isMoving">{{$gettext('here')}}</a> {{$gettext('to move or')}} <a href="#" @click.stop="cancelMoving">{{$gettext('Cancel')}}</a></div>
    </div>
    <table>
      <thead>
      <tr><th class="column-name">{{$gettext('Name')}}</th><th class="column-date">{{$gettext('Posting date')}}</th></tr>
      </thead>
      <tbody>
      <tr @dblclick="doOpenParentFolder" class="item-folder" v-if="url">
        <td><a :href="url" class="nostyle" @click.stop="e => e.preventDefault()">
        <span class="glyphicon glyphicon-level-up item-icon" aria-hidden="true"></span>
        <span class="item-label">..</span>
        </a></td>
      </tr>
      <tr class="item-folder" v-if="canChangeFolder && editItem && editItem.id === -1">
        <td>
          <span class="glyphicon glyphicon-folder-close item-icon" aria-hidden="true"></span>
          <span class="item-label"><input name="editname" v-model="editName" @blur="saveName" @keydown.enter="saveName" @keydown.esc="cancelName"/></span>
        </td>
      </tr>
      <tr v-for="item in items" @contextmenu.prevent="canManageFolder ? item.folder ? $refs.menufolder.open($event, item) : $refs.menudocument.open($event, item) : null" @dblclick="doOpenItem(item)" :class="{'item-folder': item.folder, 'item-active': !item.folder && selectedItems.includes(item.id)}" :title="item.name">
        <td class="column-name" @dragenter="doDragEnter(item, $event)" :class="{'drag': dragOnItem === item && canChangeFolder}"
         @dragleave="doDragLeave(item, $event)"
         @dragover="e => {e.preventDefault()}"
         @dragstart="doDragStart(item, $event)"
         @drop="doDrop(item, $event)"><a :href="item.url" class="nostyle" @click="e => e.preventDefault()">
        <span class="glyphicon item-icon" :class="{'glyphicon-folder-close': item.folder, 'glyphicon-file': !item.folder}" aria-hidden="true"></span>
        <template v-if="!(editItem && editItem.id === item.id)">
          <span class="item-label">{{ item.name }}</span><span v-if="item.is_restricted" class="badge badge-restricted">Restricted</span>
        </template>
        <span v-else class="item-label"><input name="editname" v-model="editName" @blur="saveName" @keydown.enter="saveName" @keydown.esc="cancelName"/></span>
        </a></td>
        <td class="column-date">{{ item.posted_at|date }}</td>
      </tr>
      </tbody>
    </table>
    <div class="loading" v-if="isProcessing || isLoading"></div>
    <h2 v-if="items.length === 0">{{$gettext('This folder is empty')}}</h2>

    <vue-context ref="menufolder" v-slot="{ data }">
        <li v-if="selectedItems.length < 2 && canChangeFolder">
          <a href="#" @click.prevent="doRenameFolder($event.target.innerText, data)"><i class="fa fa-edit"></i> {{ $gettext('Rename the folder') }}</a>
        </li>
        <li v-if="canChangeFolder">
          <a href="#" @click.prevent="doMoveItemTo($event.target.innerText, data)"><i class="fa fa-paper-plane-o"></i> {{ $gettext('Move to...') }}</a>
        </li>
        <li v-if="canDeleteFolder">
          <a href="#" @click.prevent="doRemoveItem($event.target.innerText, data)"><i class="fa fa-trash"></i> {{ $gettext('Delete the folder...') }}</a>
        </li>
    </vue-context>

    <vue-context ref="menudocument" v-slot="{ data }">
        <li v-if="canChangeFolder">
            <a href="#" @click.prevent="doMoveItemTo($event.target.innerText, data)"><i class="fa fa-paper-plane-o"></i> {{ $gettext('Move to...') }}</a>
        </li>
    </vue-context>

    <template #actions>
      <a v-if="canAddFolder" href="#" @click.prevent="doAddFolder()" :title="$gettext('Create new folder')"><i class="fa fa-plus"></i><span class="action-text">{{ $gettext('New folder') }}</span></a>
    </template>
  </panel>
</template>

<script>
  import Panel from './components/panel'
  import VueContext from './components/vue-context'
  import message from 'toastr'
  import { messageBox } from './utils/dialogs'

  export default {
    name: "folders.vue",
    components: { VueContext, Panel },
    computed: {
      path () {
        return '/' + (this.$route.params.pathMatch || '').replace(/^\/+/, '')
      },
      isMoving () {
        return this.$root.folderItemsToMove.length > 0 && this.canChangeFolder
      },
      canDeleteFolder() {
        return this.flags & 4 === 4
      },
      canChangeFolder() {
        return this.flags & 2 === 2
      },
      canAddFolder() {
        return this.flags & 1 === 1
      },
      canManageFolder() {
        return this.flags > 0
      }
    },
    watch: {
      '$route.query': function () {
        this.loadItems(this.path)
      }
    },
    filters: {
      date: function (str) {
        if (!str) return ''
        let dt = new Date(str)
        return ((dt.getDate() < 10) ? '0' : '') + dt.getDate() + '/' + ((dt.getMonth() < 9) ? '0' : '') + (dt.getMonth() + 1) + '/' + dt.getFullYear()
      }
    },
    data () {
      return {
        items: [],
        currentFolderName: null,
        url: null,
        id: null,
        editName: null,
        editItem: null,
        selectedItems: [],
        dragOnItem: null,
        flags: 0,
        isProcessing: false,
        isLoading: false
      }
    },
    methods: {
      loadItems (path) {
        if (this.$root.viewerIsClosed()) {
          console.log('$route.query', this.$route.query.select)
          let select = this.$route.query.select
          this.selectedItems = select && select.split(',').map(Number) || []

          this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.$pgettext('breadcrumb', 'Folders') }]
          this.isLoading = true
          this.$http.get('/internal/folders' + path).then(response => {
            this.items = response.data.results
            this.$root.loadDocumentItems(this.items.filter(item => !item.folder).map(item => item.id))
            this.currentFolderName = response.data.title
            this.url = response.data.url
            this.id = response.data.id
            this.flags = response.data.flags || 0
            this.$nextTick(() => {
              let $element = document.querySelector('.item-active')
              if ($element) {
                $element.scrollIntoView();
              }
            })
          }).then(() => {this.isLoading = false})
        }
      },
      doMouseOver (item) {
        this.overItem = item
      },
      doMouseLeave (item) {
        // this.overItem = null
      },
      doOpenItem (item) {
        if (item.url) {
          if (item.folder) {
             this.$router.push(item.url)
          } else {
            this.$root.openDocument(item.id)
          }
        }
      },
      doOpenParentFolder() {
        if (this.url) {
          this.$router.push(this.url)
        }
      },
      selectItem (item, append = false) {
        if (append) {
          this.selectedItems.push(item.id)
        } else {
          this.selectedItems = [item.id]
        }
      },
      clearSelection () {
        this.selectedItems = []
      },
      doSelectItem (item, event) {
        this.selectItem(item, event.ctrlKey)
      },
      doAddFolder() {
        this.doRenameFolder('', {'id': -1, 'name': 'New folder'})
      },
      doRenameFolder (text, item) {
        this.clearSelection()
        this.editItem = item
        this.editName = item.name
        this.$nextTick(() => {
          let $el = $('.item-folder input')
          $el.select()
          $el.focus()
        })
      },
      doRemoveItem (text, item) {
        if (item.docs > 0) {
          message.error(this.$gettext('Unable to delete, the folder contains documents.'))
        } else {
          messageBox(this.$gettext('Confirmation'), this.$gettext('Are you sure you want delete this folder and its contents ?'), { 'Y': this.$gettext('Yes'), 'N': this.$gettext('No') }).then(action => {
            if (action === 'Y') {
              this.removeItem(item)
            }
          })
        }
      },
      removeItem (item) {
        this.isProcessing = true
        this.$restAPI.delete('/collections/' + item.id + '/').then(response => {
          message.success(this.$gettext('The folder has been deleted'))
          this.loadItems(this.path)
        }).catch(error => {
          message.error(error.response.data.detail)
          this.$nextTick(() => {
            let $el = $('.item-folder input')
            $el.select()
            $el.focus()
          })
        }).then(() => {this.isProcessing = false})
      },
      resetEditing () {
        this.editName = null
        this.editItem = null
      },
      saveName () {
        if (!this.editItem) {
          return
        }
        if (this.editName) {
          if (this.editItem.id === -1) {
            this.isProcessing = true
            this.$restAPI.post('/collections/path' + this.path + '/' + this.editName + '/').then(response => {
              message.success(this.$gettext('The folder is created'))
              this.loadItems(this.path)
              this.resetEditing()
            }).catch(error => {
              message.error(error.response.data.detail)
              this.$nextTick(() => {
                let $el = $('.item-folder input')
                $el.select()
                $el.focus()
              })
            }).then(() => {this.isProcessing = false})
          } else {
            if (this.editName !== this.editItem.name) {
              this.isProcessing = true
              this.$restAPI.patch('/collections/' + this.editItem.id + '/', {'name': this.editName}).then(response => {
                message.success(this.$gettext('The folder has been renamed'))
                this.loadItems(this.path)
                this.resetEditing()
              }).catch(error => {
                message.error((error.response.data.non_field_errors || '').join(' '))
                this.$nextTick(() => {
                  let $el = $('.item-folder input')
                  $el.select()
                  $el.focus()
                })
              }).then(() => {this.isProcessing = false})
            } else {
              this.resetEditing()
            }
          }
        } else {
          message.info(this.$gettext('Enter the folder name'))
        }
      },
      cancelName() {
        this.resetEditing()
      },
      doDragEnter (item, ev) {
        this.dragOnItem = item
      },
      doDragLeave (item, ev) {
        if (ev.currentTarget.contains(ev.relatedTarget)) {
          return;
        }
        this.dragOnItem = null
      },
      doDragStart(item, ev) {
        ev.dataTransfer.setData('application/json', JSON.stringify({ 'id': item.id, 'type': item.folder ? 'folder' : 'document', 'originId': this.id}));
        console.log('doDragStart', item, ev)
      },
      doDrop (item, ev) {
        ev.preventDefault()
        this.dragOnItem = null
        let data = JSON.parse(ev.dataTransfer.getData('application/json'))
        if (data && data.id && data.type === 'folder') {
          this.moveFolder(data.id, item.id)
        }
        if (data && data.id && data.type === 'document' && item.folder) {
          this.moveDocument(data.id, data.originId, item.id)
        }

        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.items.length; i++) {
            // If dropped items aren't files, reject them
            if (ev.dataTransfer.items[i].kind === 'file') {
              let file = ev.dataTransfer.items[i].getAsFile();
              console.log('... file[' + i + '].name = ' + file.name);
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.files.length; i++) {
            console.log('... file[' + i + '].name = ' + ev.dataTransfer.files[i].name);
          }
        }
      },
      moveFolder (fromId, toId, callback=null) {
        if (fromId === toId) {
          if (callback) {
            callback()
          }
          return
        }
        this.isProcessing = true
        this.$restAPI.patch('/collections/' + fromId + '/', {'parent_id': toId}).then(response => {
          message.success(this.$gettext('The folder has been moved'))
          if (callback) {
            callback()
          }
          this.loadItems(this.path)
        }).catch(error => {
          message.error(error.response.data)
        }).then(() => {this.isProcessing = false})
      },
      moveDocument (documentId, fromId, toId, callback=null) {
        if (fromId === toId) {
          if (callback) {
            callback()
          }
          return
        }
        this.isProcessing = true
        this.$restAPI.put('/collections/' + fromId + '/documents/' + documentId + '/', {'to_collection_id': toId}).then(response => {
          message.success(this.$gettext('The document has been moved'))
          if (callback) {
            callback()
          }
          this.loadItems(this.path)
        }).catch(error => {
          message.error(error.response.data)
        }).then(() => {this.isProcessing = false})
      },
      doMoveItemTo(text, item) {
        this.$root.folderItemsToMove = [{...item, 'originId': this.id}]
      },
      cancelMoving () {
        this.$root.folderItemsToMove = []
      },
      clearMovingItems() {
        this.$root.folderItemsToMove = []
      },
      acceptMoving () {
        [...this.$root.folderItemsToMove].forEach(item => {
          if (item.folder) {
            this.moveFolder(item.id, this.id, this.clearMovingItems)
          } else {
            this.moveDocument(item.id, item.originId, this.id, this.clearMovingItems)
          }
        })
      },
    },
    mounted () {
      this.loadItems(this.path)
    }
  }
</script>

<style lang="scss" scoped>

  table {
    word-wrap: break-word;
    table-layout:fixed;
    width: 100%;
  }
  .column-name {
    width: 100%;
    overflow: hidden;
  }
  .column-date {
    text-align: center;
    white-space: normal;
    width: 100px;
  }
  .item-icon {
    font-size: 32px;
    margin-bottom: 12px;
    color: #aaa;
    margin-top: 4px;
  }

  .item-folder .item-icon  {
    color: #123751;
  }

  .item-folder input {

  }

  tr.item-active {
    background-color: #1B7EAC;
    color: white;
    .item-icon {
      color: white;
    }
  }

  .nostyle {
    white-space: nowrap;
    overflow: hidden;
  }

  tr:not(.item-active):hover {
    background-color: #eee;
    color: #444;
    .item-icon {
      color: #aaa;
    }
    cursor: pointer;
  }

  .item-label {
    display: inline-block;
    vertical-align: middle;
    text-overflow: ellipsis;
    height: 32px;
    padding-left: 8px;
    width: calc(100% - 100px);
    overflow-x: hidden;
  }


  h2 {
    color: #eee;
  }

  .badge {
    margin-left: 8px;
    margin-right: 4px;
    border-radius: 4px;
    padding: 3px;
    font-size: 8px;
    margin-bottom: 10px;
  }

  .badge-light {
    background-color: #aaa;
  }

  .badge-restricted {
    background-color: #a92a2b;
  }

  .item-action {
    position: absolute;
    background: #ccc;
    width: 100px;
    height: 24px;
    right: 0;
    display: none;
  }
  .item-folder {
    position: relative;
  }
  .item-folder:hover .item-action {
    display: inline;
  }
  .drag {
    border: 1px dotted #777;
    margin: -1px;
  }
  .folder-action-move-box {
    padding: 8px;
    background: #eee;
  }
  .folder-action-menu {
    padding-top: 4px;
    padding-bottom: 4px;
    margin-bottom: 8px;
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    a {
      padding: 8px;
      color: #444;
    }
    a:hover {
      text-decoration: none;
      background-color: #eee;
    }
  }

  .loading {
    position: absolute;
    left: 0;
    top: 50px;
    width: 100%;
    height: 20vmin;
    background-image: url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 -20 40 40">\
<style> /* ...YO DAWG... */ circle { animation: 4s a infinite linear, 3s o infinite linear; }\
@keyframes a {from{stroke-dasharray:100 0}50%25{stroke-dasharray:0 100}to{stroke-dasharray:100 0}}\
@keyframes o {from{stroke-dashoffset:75}to{stroke-dashoffset:375}}\
<%2Fstyle><circle r="15.9154943092" stroke-width="7" fill="none" stroke="rgb(192, 192, 192, 0.4)"/>\
</svg>');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 20vmin;
  }
</style>
