<template>
  <div>
    <ul class="comment-tools-actions">
      <li @click.prevent="updateCommentReport(false)" :class="{active: !reportMode}" :title="$gettext('Text with comments')"><i class="fa fa-file-text"></i></li>
      <li @click.prevent="updateCommentReport(true)" :class="{active: reportMode}" :title="$gettext('Reports')"><i class="fa fa-list-ol"></i></li>
      <li @click.prevent="load()" :title="$gettext('Refresh')"><i class="fa fa-refresh"></i></li>
    </ul>
    <div class="filter-panel">
      <div v-if="users.length > 1" class="comment-filter user-filter">
        <h5>{{$pgettext('Comment filter', 'Users')}}<small>({{users.length}})</small></h5>
        <div class="checkbox" v-for="(u, index) in users" v-if="moreUsers || index < defaultFilterListSize">
          <label>
            <input type="checkbox" :checked="isFilterChecked('users', u.value)" @click.prevent="toggleFilter('users', u.value, $event)"> <span class="comment-filter-item-text">{{ u.label }}</span> <small>({{ u.count }})</small>
          </label>
        </div>
        <div v-if="users.length > defaultFilterListSize" class="more-item" @click="() => this.moreUsers=!this.moreUsers">{{ moreUsers ? $gettext('See less') : $gettext('See more')}}</div>
      </div>
      <div v-if="types.length > 1" class="comment-filter type-filter">
        <h5>{{$pgettext('Comment filter', 'Types')}} <small>({{types.length}})</small></h5>
        <div class="checkbox" v-for="u in types">
          <label>
            <input type="checkbox" :checked="isFilterChecked('types', u.value)" @click.prevent="toggleFilter('types', u.value, $event)"> <span class="comment-filter-item-text">{{ u.label }}</span> <small>({{ u.count }})</small>
          </label>
        </div>
      </div>
      <div v-if="origins.length > 1" class="comment-filter origin-filter">
        <h5>{{$pgettext('Comment filter', 'Origins')}} <small>({{origins.length}})</small></h5>
        <div class="checkbox" v-for="(u, index) in origins" v-if="moreOrigins || index < defaultFilterListSize">
          <label>
            <input type="checkbox" :checked="isFilterChecked('origins', u.value)" @click.prevent="toggleFilter('origins', u.value, $event)"> <span class="comment-filter-item-text">{{ u.label }}</span> <small>({{ u.count }})</small>
          </label>
        </div>
        <div v-if="origins.length > defaultFilterListSize" class="more-item" @click="() => this.moreOrigins=!this.moreOrigins">{{ moreOrigins ? $gettext('See less'): $gettext('See more')}}</div>
      </div>
      <div v-if="states.length > 1" class="comment-filter state-filter">
        <h5>{{$pgettext('Comment filter', 'States')}} <small>({{states.length}})</small></h5>
        <div class="checkbox" v-for="u in states">
          <label>
            <input type="checkbox" :checked="isFilterChecked('states', u.value)" @click.prevent="toggleFilter('states', u.value, $event)"> <span class="comment-filter-item-text">{{ u.label }}</span> <small>({{ u.count }})</small>
          </label>
        </div>
      </div>
    </div>
    <div v-if="count > 0">
      <div><span class="clear-filter-action" @click="clearFilters">{{$gettext('Clear filters')}}</span></div>
      <br>
      <div>{{filteredCount}} {{$pgettext('ex: 8 of 25', 'of')}} {{count }}</div>
    </div>
    <div v-else>{{$gettext('No comments posted.')}}</div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import groupBy from 'lodash.groupby'
  import CommentLink from './comment-link'

  export default {
    name: "comment-tools",
    props: {
      document: {
        required: true,
        type: Number
      },
      readonly: {
        required: false,
        type: Boolean,
        default: false
      },
      enabled: {
        required: false,
        type: Boolean,
        default: true
      },
      seeHidden: {
        required: false,
        type: Boolean,
        default: false
      },
      selector: {
        required: false,
        type: String,
        default: null
      },
      defaultFilterListSize: {
        type: Number,
        required: false,
        default: 2
      },
      reportMode: {
        type: Boolean,
        required: false,
        default: false
      },
    },
    data: function () {
      return {
        comments: {},
        users: [],
        states: [],
        types: [],
        origins: [],
        filters: {},
        count: 0,
        filteredCount: 0,
        moreUsers: false,
        moreOrigins: false
      }
    },
    methods: {
      updateCommentReport (visible) {
        this.$emit('reportChanged', visible)
      },
      isFilterChecked (name, value) {
        return (this.filters[name] || []).includes(String(value))
      },
      clearFilters () {
        // Reset all filters
        this.filters = {}
        this.load()
      },
      toggleFilter (name, value, ev) {
        let changed = false
        this.filters[name] = this.filters[name] || []
        if (!ev.ctrlKey && !ev.altKey) {
          // Clic: Toggle current item of filter
          if (this.isFilterChecked(name, value)) {
            this.filters[name] = this.filters[name].filter(v => v !== String(value))
          } else {
            this.filters[name].push(String(value))
          }
          changed = true
        } else if (ev.ctrlKey && !ev.altKey) {
          // CTRL+Clic: Set exclusive current item of filter
          this.filters[name] = [String(value)]
          changed = true
        }
        if (changed) {
          this.load()
        }
      },
      getQueryParams () {
        let query = {}
        // Filters
        Object.keys(this.filters).forEach(name => {
          let value = this.filters[name].join(',')
          if (value !== '') {
            query[name] = value
          }
        })
        // Todo: Ordering
        return query
      },
      load () {
        // Load comments from server (filtered)
        this.comments = []
        this.$restAPI.get(`/documents/${this.document}/comments`, { params: this.getQueryParams() }).then(response => {
          this.comments = groupBy(response.data.results, item => String(item.line_ref))
          this.users = response.data.facets.users
          this.states = response.data.facets.states
          this.origins = response.data.facets.origins
          this.types = response.data.facets.types
          this.count = response.data.count
          this.filteredCount = response.data.results.length
          if (this.reportMode) {
            this.updateCommentReport(true)
          }
        })
      },
      removeAnchors () {
        let body = this.$document.body
        body.classList.remove('comments-ready')
        this.$anchors.forEach(item => {
          try { item.$destroy() } catch(e) {console.log(e)}  // Error on IE
          if (item && item.$el && item.$el.parentNode) {
            try { item.$el.parentNode.removeChild(item.$el) } catch(e) {console.log(e)}
          }
        })
      },
      createAnchors () {
        let body = this.$document.body
        body.classList.toggle('drt-mode-enabled', this.enabled)
        if (!body.classList.contains('comments-ready')) {
          body.classList.add('comments-ready')
          let references = body.querySelectorAll('[data-drt-ref]')
          let style = document.querySelector('meta[name="comments-styles"]').getAttribute('content')
          $(this.$iframe)
            .contents().find("head")
            .append($(`<link rel="stylesheet" type="text/css" href="${style}">`)
            );
          for (let i = 0; i < references.length; ++i) {
            let item = references[i]
            let span = document.createElement('span')
            item.appendChild(span)
            this.$anchors.push(new Vue({
              ...CommentLink, parent: this, dataDrtRef: item.getAttribute('data-drt-ref')
            }).$mount(span))
          }
        }
        this.load()  // Force refresh comments when the document are reloaded
      },
      doIframeLoaded () {
        this.$window = (this.$iframe.contentWindow || this.$iframe.contentDocument)
        this.$document = this.$window.document
        this.createAnchors()
      }
    },
    mounted () {
      this.$anchors = []
      if (this.selector) {
        this.$iframe = document.querySelector(this.selector);
        this.$window = (this.$iframe.contentWindow || this.$iframe.contentDocument)
        this.$document = this.$window.document
        this.createAnchors()
        this.$iframe.addEventListener('load', this.doIframeLoaded)
      }
      this.$on('commentRefresh', item => {
        this.load()
      })
    },
    beforeDestroy () {
      this.$iframe.removeEventListener('load', this.doIframeLoaded)
      // Hide comments and anchors
      let body = this.$document.body
      body.classList.toggle('drt-mode-enabled', false)
      this.removeAnchors()
    }
  }
</script>

<style lang="scss" scoped>
  .clear-filter-action {
    cursor: pointer;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 4px;
    background-color: #122E4A;

    &:hover {
      background-color: rgba(128, 128, 128, 0.6);
    }
  }

  small {
    font-size: 10px;
    color: #ccc;
    margin-left: 4px;
  }

  ul.comment-tools-actions {
    padding-left: 0;
    list-style: none;
    display: flex;
    margin-bottom: 8px;

    li {
      padding: 6px;
      height: 36px;
      width: 36px;
      vertical-align: middle;
      text-align: center;
      font-size: 18px;
      cursor: pointer;
      background-color: #122E4A;
      margin: 2px;

      &:hover {
        background-color: rgba(128, 128, 128, 0.6);
      }

      &.active {
        color: #122E4A;
        background-color: rgba(255, 255, 255, 0.7);
      }

      &.active:hover {
        background-color: rgba(255, 255, 255, 0.9);
      }
    }
  }

  .comment-filter .comment-filter-item-text:hover {
    text-decoration: underline;
  }

  .more-item {
    font-size: 12px;
    padding: 4px;
    margin-top: -12px;
    margin-bottom: 10px;
    cursor: pointer;
    font-style: italic;
    color: #ccc;

    &:hover {
      text-decoration: underline;
    }
  }
  .filter-panel {
    overflow-y: auto;
    max-height: 50vh;
  }
</style>
