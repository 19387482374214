<template>
  <nav class="text-center hidden-print">
    <ul class="pagination">
      <li><span :class="{ link: showFirst, 'link-disabled': !showFirst}" @click.prevent="showFirst ? pageClick(1) : null"><span class="glyphicon glyphicon-fast-backward"></span></span></li>
      <li><span :class="{ link: showFirst, 'link-disabled': !showFirst}" @click.prevent="showFirst ? pageClick(params.page-1) : null"><span class="glyphicon glyphicon-step-backward"></span></span></li>
      <li v-for="num in page_range" v-bind:class="{'active': params.page === num }">
        <span class="link" @click.prevent="pageClick(num)">{{ num }}</span>
      </li>
      <li><span :class="{ link: showLast, 'link-disabled': !showLast}" @click.prevent="showLast ? pageClick(params.page+1) : null"><span class="glyphicon glyphicon-step-forward"></span></span></li>
      <li><span :class="{ link: showLast, 'link-disabled': !showLast}" @click.prevent="showLast ? pageClick(num_page) : null"><span class="glyphicon glyphicon-fast-forward"></span></span></li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'paginator',
    props: {
      params: {
        type: Object,
        require: true
      },
      num_page: {
        type: Number,
        require: true
      },
      callback: {
        type: Function,
        require: true
      }
    },
    computed: {
      page_range: function () {
        let left = 1
        let right = this.num_page
        let ar = []
        if (this.num_page >= 11) {
          if (this.params.page > 5 && this.params.page < this.num_page - 4) {
            left = this.params.page - 5
            right = this.params.page + 4
          } else {
            if (this.params.page <= 5) {
              left = 1
              right = 10
            } else {
              right = this.num_page
              left = this.num_page - 9
            }
          }
        }
        while (left <= right) {
          ar.push(left)
          left++
        }
        return ar
      },
      showLast: function () {
        return this.params.page !== this.num_page
      },
      showFirst: function () {
        return this.params.page !== 1
      }
    },
    methods: {
      pageClick: function (cur_page) {
        if (cur_page !== this.params.page) {
          this.params.page = cur_page
          let params = this.params
          params.page = this.params.page
          this.params = params
          this.$emit('change')
        }
      }
    },
    watch: {
      params: {
        handler: function () {
          if (this.callback) this.callback()
        },
        deep: true
      }
    }
    // ready: function () {
    //     this.callback();
    // }
  }
</script>

<style scoped>
  .link {
    cursor: pointer;
  }
  .link-disabled {
    background-color: #eee;
    border-color: #ddd;
    color: #004c7c;
    cursor: default;
    opacity: .5;
    z-index: 2;
  }
</style>
