var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "table table-condensed table-striped table-hover" },
    [
      _c("thead", [_vm._t("thead")], 2),
      _vm._v(" "),
      _c(
        "tbody",
        {
          style: { height: _vm.bodyHeight + "px" },
          on: { scroll: _vm.handleElementScroll },
        },
        [_vm._t("tbody")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }