import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'

import translations from './translations.json'

Vue.use(GetTextPlugin, {
  translations: translations,
  defaultLanguage: document.querySelector('html').getAttribute('lang') || 'en',
  availableLanguages: { en: 'British English', fr: 'Français', }
})
