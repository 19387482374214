var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "agenda-selector",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$gettext("Select meetings")))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-body" },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "search" } }, [
                      _vm._v(_vm._s(_vm.$gettext("Meeting"))),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter",
                        },
                        { name: "clear", rawName: "v-clear" },
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", id: "search", placeholder: "" },
                      domProps: { value: _vm.filter },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.filter = $event.target.value
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "start_date" } }, [
                        _vm._v(_vm._s(_vm.$gettext("From"))),
                      ]),
                      _vm._v(" "),
                      _c("datepicker", {
                        directives: [{ name: "clear", rawName: "v-clear" }],
                        staticClass: "form-control",
                        attrs: { id: "start_date", placeholder: "__/__/____" },
                        model: {
                          value: _vm.dateFrom,
                          callback: function ($$v) {
                            _vm.dateFrom = $$v
                          },
                          expression: "dateFrom",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", { attrs: { for: "end_date" } }, [
                        _vm._v(_vm._s(_vm.$gettext("To"))),
                      ]),
                      _vm._v(" "),
                      _c("datepicker", {
                        directives: [{ name: "clear", rawName: "v-clear" }],
                        staticClass: "form-control",
                        attrs: { id: "end_date", placeholder: "__/__/____" },
                        model: {
                          value: _vm.dateTo,
                          callback: function ($$v) {
                            _vm.dateTo = $$v
                          },
                          expression: "dateTo",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table table-striped table-meeting" },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.selectAllItems($event)
                            },
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "column-text" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeOrdering("title")
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$gettext("Meeting")) + " "),
                            _vm.sortedColumn === "title"
                              ? _c("i", {
                                  staticClass: "fa",
                                  class: {
                                    "fa-sort-amount-desc":
                                      _vm.sortedOrder === "desc",
                                    "fa-sort-amount-asc":
                                      _vm.sortedOrder === "asc",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("th", { staticClass: "column-date" }, [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeOrdering("start_datetime")
                              },
                            },
                          },
                          [
                            _vm._v(_vm._s(_vm.$gettext("Date")) + " "),
                            _vm.sortedColumn === "start_datetime"
                              ? _c("i", {
                                  staticClass: "fa",
                                  class: {
                                    "fa-sort-amount-desc":
                                      _vm.sortedOrder === "desc",
                                    "fa-sort-amount-asc":
                                      _vm.sortedOrder === "asc",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.meetings, function (item) {
                      return _c("tr", [
                        _c("td", [
                          _c("input", {
                            attrs: { id: "mt_" + item.id, type: "checkbox" },
                            domProps: { checked: _vm.itemSelected(item) },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.toggleItem(item, $event)
                              },
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "column-text" }, [
                          _c("label", { attrs: { for: "mt_" + item.id } }, [
                            _vm._v(_vm._s(item.subject)),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "column-date" }, [
                          _c("label", { attrs: { for: "mt_" + item.id } }, [
                            _vm._v(_vm._s(_vm._f("date")(item.start_datetime))),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.meetings.length === 0
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-info",
                      attrs: { role: "alert" },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$gettext("No result found.")) +
                          "\n        "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.numPages > 1
                ? _c("paginator", {
                    attrs: { params: _vm.params, num_page: _vm.numPages },
                    on: {
                      change: function ($event) {
                        return _vm.load()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.submit },
              },
              [_vm._v(_vm._s(_vm.$gettext("OK")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { "data-dismiss": "modal", tabindex: "-1" },
                on: { click: _vm.cancel },
              },
              [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }