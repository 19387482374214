<template>
  <div>
    <ul class="timeline">
      <template v-for="(item, k, index) in data" >
        <li class="timeline-item period" v-if="index > 0">
          <div class="timeline-info"></div>
          <div class="timeline-marker"></div>
          <div class="timeline-content">
            <h2 class="timeline-title">{{ k }}</h2>
          </div>
        </li>

        <li v-for="event in item" class="timeline-item timeline-item-meeting">
          <div class="timeline-info">
            <span>{{ event.start }}</span>
          </div>
          <div class="timeline-marker"></div>
          <div class="timeline-content">
            <h3 class="timeline-title timeline-action" @click.stop="openEvent(event, $event)" :title="$gettext('Click to see detail')">{{ event.title }}</h3>
            <p v-html="event.summary"></p>
          </div>
        </li>
      </template>
      <li class="timeline-item period">
        <div class="timeline-info"></div>
        <div class="timeline-marker timeline-marker-end"></div>
        <div class="timeline-content"></div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'timeline',
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    methods: {
      openEvent(event, ev) {
        this.$emit('click', event)
      }
    }
  }
</script>

<style lang="scss" scoped>

  $primary-color: #1B7EAC;
  $primary-color-hover: scale-color($primary-color, $lightness: 32%);

  .timeline {
    line-height: 1.4em;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    h2 {
      font-size: 22px;
      color: #666;
    }
    h3 {
      margin-bottom: 2px;
      font-size: 16px;
    }
    h1, h2, h3, h4, h5, h6 {
      line-height: inherit;
    }
  }

  /*----- TIMELINE ITEM -----*/

  .timeline-item {
    padding-left: 40px;
    position: relative;

    &:last-child {
      padding-bottom: 0;
      height: 50px;
    }
  }

  /*----- TIMELINE INFO -----*/

  .timeline-info {
    font-size: 12px;
    font-weight: 700;
    /*letter-spacing: 2px;*/
    margin: 0 0 .5em 0;
    text-transform: uppercase;
    white-space: normal;
  }

  /*----- TIMELINE MARKER -----*/

  .timeline-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;

    &:before {
      background: $primary-color;
      border: 3px solid transparent;
      border-radius: 100%;
      content: "";
      display: block;
      height: 15px;
      position: absolute;
      top: 4px;
      left: 0;
      width: 15px;
      transition: background 0.3s ease-in-out,
      border 0.3s ease-in-out;
    }

    &:after {
      content: "";
      width: 3px;
      background: #CCD5DB;
      display: block;
      position: absolute;
      top: 24px;
      bottom: 0;
      left: 6px;
    }

    .timeline-item:last-child &:after {
      content: none;
    }
  }

  .timeline-item:not(.period):hover .timeline-marker:before {
    background: transparent;
    border: 3px solid $primary-color;
  }

  /*----- TIMELINE CONTENT -----*/

  .timeline-content {
    padding-bottom: 40px;

    p:last-child {
      margin-bottom: 0;
    }
  }

  /*----- TIMELINE PERIOD -----*/

  .period {
    padding: 0;

    .timeline-info {
      display: none;
    }

    .timeline-marker {
      &:before {
        background: transparent;
        content: "";
        width: 15px;
        height: auto;
        border: none;
        border-radius: 0;
        top: 0;
        bottom: 30px;
        position: absolute;
        border-top: 3px solid #CCD5DB;
        border-bottom: 3px solid #CCD5DB;
      }

      &:after {
        content: "";
        height: 32px;
        top: auto;
      }
    }

    .timeline-content {
      padding: 40px 0 70px;
    }

    .timeline-title {
      margin: 0;
    }
  }
  .period .timeline-marker.timeline-marker-end:before {
    border-bottom: none;
  }

  li.period:first-child .timeline-marker:before {
    border-top: none;
  }

  .timeline-action {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  /*
  .timeline-item-meeting .timeline-content:hover {
    background-color: #f7f7f7;
    margin: 4px 32px 4px -16px;
    padding: -4px -32px -4px 16px;
  }*/
</style>
