var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "panel",
    {
      attrs: {
        title: _vm.currentFolderName,
        "icon-class": "fa fa-folder-open",
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _vm.canAddFolder
                ? _c(
                    "a",
                    {
                      attrs: {
                        href: "#",
                        title: _vm.$gettext("Create new folder"),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.doAddFolder()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "fa fa-plus" }),
                      _c("span", { staticClass: "action-text" }, [
                        _vm._v(_vm._s(_vm.$gettext("New folder"))),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { staticClass: "folder-action-bar" }, [
        _vm.isMoving
          ? _c("div", { staticClass: "folder-action-move-box" }, [
              _vm._v(
                _vm._s(_vm.$gettext("Browse to target folder, then click")) +
                  " "
              ),
              _vm.isMoving
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.acceptMoving.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$gettext("here")))]
                  )
                : _vm._e(),
              _vm._v(" " + _vm._s(_vm.$gettext("to move or")) + " "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.cancelMoving.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", { staticClass: "column-name" }, [
              _vm._v(_vm._s(_vm.$gettext("Name"))),
            ]),
            _c("th", { staticClass: "column-date" }, [
              _vm._v(_vm._s(_vm.$gettext("Posting date"))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm.url
              ? _c(
                  "tr",
                  {
                    staticClass: "item-folder",
                    on: { dblclick: _vm.doOpenParentFolder },
                  },
                  [
                    _c("td", [
                      _c(
                        "a",
                        {
                          staticClass: "nostyle",
                          attrs: { href: _vm.url },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return ((e) => e.preventDefault()).apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c("span", {
                            staticClass:
                              "glyphicon glyphicon-level-up item-icon",
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "item-label" }, [
                            _vm._v(".."),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.canChangeFolder && _vm.editItem && _vm.editItem.id === -1
              ? _c("tr", { staticClass: "item-folder" }, [
                  _c("td", [
                    _c("span", {
                      staticClass: "glyphicon glyphicon-folder-close item-icon",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "item-label" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.editName,
                            expression: "editName",
                          },
                        ],
                        attrs: { name: "editname" },
                        domProps: { value: _vm.editName },
                        on: {
                          blur: _vm.saveName,
                          keydown: [
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.saveName.apply(null, arguments)
                            },
                            function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "esc", 27, $event.key, [
                                  "Esc",
                                  "Escape",
                                ])
                              )
                                return null
                              return _vm.cancelName.apply(null, arguments)
                            },
                          ],
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.editName = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.items, function (item) {
              return _c(
                "tr",
                {
                  class: {
                    "item-folder": item.folder,
                    "item-active":
                      !item.folder && _vm.selectedItems.includes(item.id),
                  },
                  attrs: { title: item.name },
                  on: {
                    contextmenu: function ($event) {
                      $event.preventDefault()
                      _vm.canManageFolder
                        ? item.folder
                          ? _vm.$refs.menufolder.open($event, item)
                          : _vm.$refs.menudocument.open($event, item)
                        : null
                    },
                    dblclick: function ($event) {
                      return _vm.doOpenItem(item)
                    },
                  },
                },
                [
                  _c(
                    "td",
                    {
                      staticClass: "column-name",
                      class: {
                        drag: _vm.dragOnItem === item && _vm.canChangeFolder,
                      },
                      on: {
                        dragenter: function ($event) {
                          return _vm.doDragEnter(item, $event)
                        },
                        dragleave: function ($event) {
                          return _vm.doDragLeave(item, $event)
                        },
                        dragover: (e) => {
                          e.preventDefault()
                        },
                        dragstart: function ($event) {
                          return _vm.doDragStart(item, $event)
                        },
                        drop: function ($event) {
                          return _vm.doDrop(item, $event)
                        },
                      },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "nostyle",
                          attrs: { href: item.url },
                          on: { click: (e) => e.preventDefault() },
                        },
                        [
                          _c("span", {
                            staticClass: "glyphicon item-icon",
                            class: {
                              "glyphicon-folder-close": item.folder,
                              "glyphicon-file": !item.folder,
                            },
                            attrs: { "aria-hidden": "true" },
                          }),
                          _vm._v(" "),
                          !(_vm.editItem && _vm.editItem.id === item.id)
                            ? [
                                _c("span", { staticClass: "item-label" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                item.is_restricted
                                  ? _c(
                                      "span",
                                      { staticClass: "badge badge-restricted" },
                                      [_vm._v("Restricted")]
                                    )
                                  : _vm._e(),
                              ]
                            : _c("span", { staticClass: "item-label" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editName,
                                      expression: "editName",
                                    },
                                  ],
                                  attrs: { name: "editname" },
                                  domProps: { value: _vm.editName },
                                  on: {
                                    blur: _vm.saveName,
                                    keydown: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.saveName.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "esc",
                                            27,
                                            $event.key,
                                            ["Esc", "Escape"]
                                          )
                                        )
                                          return null
                                        return _vm.cancelName.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.editName = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "column-date" }, [
                    _vm._v(_vm._s(_vm._f("date")(item.posted_at))),
                  ]),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.isProcessing || _vm.isLoading
        ? _c("div", { staticClass: "loading" })
        : _vm._e(),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c("h2", [_vm._v(_vm._s(_vm.$gettext("This folder is empty")))])
        : _vm._e(),
      _vm._v(" "),
      _c("vue-context", {
        ref: "menufolder",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ data }) {
              return [
                _vm.selectedItems.length < 2 && _vm.canChangeFolder
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doRenameFolder(
                                $event.target.innerText,
                                data
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-edit" }),
                          _vm._v(
                            " " + _vm._s(_vm.$gettext("Rename the folder"))
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canChangeFolder
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doMoveItemTo(
                                $event.target.innerText,
                                data
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-paper-plane-o" }),
                          _vm._v(" " + _vm._s(_vm.$gettext("Move to..."))),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.canDeleteFolder
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doRemoveItem(
                                $event.target.innerText,
                                data
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-trash" }),
                          _vm._v(
                            " " + _vm._s(_vm.$gettext("Delete the folder..."))
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("vue-context", {
        ref: "menudocument",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ data }) {
              return [
                _vm.canChangeFolder
                  ? _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.doMoveItemTo(
                                $event.target.innerText,
                                data
                              )
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "fa fa-paper-plane-o" }),
                          _vm._v(" " + _vm._s(_vm.$gettext("Move to..."))),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }