var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "extranet-application" },
    [
      _c("breadcrumb"),
      _vm._v(" "),
      _c("router-view"),
      _vm._v(" "),
      _vm.$root.viewerVisible
        ? _c("div", { staticClass: "document-lightbox" }, [
            _c("div", { staticClass: "document-lightbox-wrapper" }, [
              _c("div", { staticClass: "document-lightbox-header" }, [
                _vm.meta.comment && _vm.meta.comment.enabled
                  ? _c(
                      "span",
                      {
                        staticClass: "comment-deadline",
                        class: {
                          "comment-open": _vm.meta.comment.writable,
                          "comment-close": !_vm.meta.comment.writable,
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.meta.comment.deadline))]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "document-lightbox-content" }, [
                _vm.commentPanelVisible
                  ? _c(
                      "div",
                      { staticClass: "document-lightbox-filters" },
                      [
                        _c("h3", [
                          _vm._v(_vm._s(_vm.$gettext("Commenting tools"))),
                        ]),
                        _vm._v(" "),
                        _c("comment-tools", {
                          ref: "commentTools",
                          attrs: {
                            document: _vm.meta.id,
                            readonly: !_vm.meta.comment.writable,
                            selector: "iframe[name='document-content']",
                            "report-mode": _vm.commentReportVisible,
                          },
                          on: { reportChanged: _vm.doReportChanged },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.metaPanelVisible
                  ? _c(
                      "div",
                      {
                        staticClass: "document-lightbox-metadata",
                        class: { "edit-mode": _vm.editMode },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "document-lightbox-metadata-content" },
                          [
                            _c("h3", [
                              _vm._v(_vm._s(_vm.$gettext("Metadata")) + " "),
                              _vm.editMode
                                ? _c("small", [
                                    _vm._v(
                                      "[" +
                                        _vm._s(_vm.$gettext("Edit mode")) +
                                        "]"
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("table", [
                              _c(
                                "tbody",
                                [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.$gettext("English title"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "meta-field" },
                                      [
                                        _c("meta-field", {
                                          attrs: { edit: _vm.editMode },
                                          model: {
                                            value: _vm.meta.title_en,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.meta,
                                                "title_en",
                                                $$v
                                              )
                                            },
                                            expression: "meta.title_en",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.$gettext("French title"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "meta-field" },
                                      [
                                        _c("meta-field", {
                                          attrs: { edit: _vm.editMode },
                                          model: {
                                            value: _vm.meta.title_fr,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.meta,
                                                "title_fr",
                                                $$v
                                              )
                                            },
                                            expression: "meta.title_fr",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.$gettext("Latin title"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "meta-field" },
                                      [
                                        _c("meta-field", {
                                          attrs: { edit: _vm.editMode },
                                          model: {
                                            value: _vm.meta.latin_title,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.meta,
                                                "latin_title",
                                                $$v
                                              )
                                            },
                                            expression: "meta.latin_title",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(_vm._s(_vm.$gettext("Reference"))),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "meta-field" },
                                      [
                                        _c("meta-field", {
                                          attrs: { edit: _vm.editMode },
                                          model: {
                                            value: _vm.meta.reference,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.meta,
                                                "reference",
                                                $$v
                                              )
                                            },
                                            expression: "meta.reference",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$gettext("Monograph numbers")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      { staticClass: "meta-field" },
                                      [
                                        _c("meta-field", {
                                          attrs: { edit: _vm.editMode },
                                          model: {
                                            value: _vm.meta.monograph_numbers,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.meta,
                                                "monograph_numbers",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "meta.monograph_numbers",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.$root.is_edqm_ui()
                                    ? _c("tr", [
                                        _c("td", [
                                          _vm._v(
                                            _vm._s(_vm.$gettext("Keywords"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "meta-field" },
                                          [
                                            _c("meta-field", {
                                              attrs: { edit: _vm.editMode },
                                              model: {
                                                value: _vm.meta.keywords,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.meta,
                                                    "keywords",
                                                    $$v
                                                  )
                                                },
                                                expression: "meta.keywords",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(_vm.getMetadata(), function (item) {
                                    return _c("tr", [
                                      _c("td", [_vm._v(_vm._s(item[0]))]),
                                      _vm._v(" "),
                                      _c("td", [_vm._v(_vm._s(item[1]))]),
                                    ])
                                  }),
                                ],
                                2
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "direct-link" }, [
                              _c("i", { staticClass: "fa fa-link" }),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: { href: _vm.meta.direct_link },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.directLinkToClipboard()
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$gettext("Direct link...")))]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "document-lightbox-folders" },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass: "document-lightbox-section",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.toggleFolders.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa",
                                      class: {
                                        "fa-angle-down": _vm.isFolderPanelOpen,
                                        "fa-angle-right":
                                          !_vm.isFolderPanelOpen,
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$gettext("Folders")) +
                                        " "
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.folderCount) + ")"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isFolderPanelOpen
                                  ? _c(
                                      "ul",
                                      [
                                        _vm._l(
                                          _vm.getFolders(),
                                          function (item) {
                                            return _c(
                                              "li",
                                              {
                                                attrs: { title: item.path },
                                                on: {
                                                  dblclick: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.openFolder(item)
                                                  },
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.removeFolder(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "glyphicon glyphicon-folder-close item-icon",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "item-label" },
                                                  [
                                                    _c("ellipsis", {
                                                      attrs: {
                                                        data: item.label,
                                                        "line-clamp": 2,
                                                        "line-height": "12px",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "item-remove",
                                                  },
                                                  [
                                                    _vm.editMode
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fa fa-times",
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._v(" "),
                                        _vm.editMode
                                          ? _c(
                                              "li",
                                              {
                                                staticClass: "new-item",
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.newFolder.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "glyphicon glyphicon-plus item-icon",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                }),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "document-lightbox-events" },
                              [
                                _c(
                                  "h5",
                                  {
                                    staticClass: "document-lightbox-section",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.toggleMeetings.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa",
                                      class: {
                                        "fa-angle-down": _vm.meetingPanelOpen,
                                        "fa-angle-right": !_vm.meetingPanelOpen,
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$gettext("Meetings")) +
                                        " "
                                    ),
                                    _c("small", [
                                      _vm._v(
                                        "(" + _vm._s(_vm.meetingCount) + ")"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.meetingPanelOpen
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        _vm.getMeetings(),
                                        function (item) {
                                          return _c(
                                            "li",
                                            {
                                              on: {
                                                dblclick: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.openMeeting(item)
                                                },
                                              },
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "glyphicon glyphicon-calendar",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(" " + _vm._s(item.label)),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.meta.readonly && _vm.$root.is_edqm_ui()
                              ? _c(
                                  "div",
                                  { staticClass: "document-lightbox-events" },
                                  [
                                    _c(
                                      "h5",
                                      {
                                        staticClass:
                                          "document-lightbox-section",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.toggleNotification.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa",
                                          class: {
                                            "fa-angle-down":
                                              _vm.notificationPanelOpen,
                                            "fa-angle-right":
                                              !_vm.notificationPanelOpen,
                                          },
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$gettext("Notification")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.notificationPanelOpen
                                      ? _c("div", [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.meta.notification_message
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "document-lightbox-extra" },
                              [
                                _vm.meta.permissions !== null &&
                                _vm.$root.is_edqm_ui()
                                  ? _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-text",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return (() =>
                                              (_vm.permissionPanelOpen =
                                                !_vm.permissionPanelOpen)).apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        !_vm.permissionPanelOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$gettext(
                                                    "Show permissions"
                                                  )
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm.permissionPanelOpen
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$gettext("Show document")
                                                )
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "document-lightbox-metadata-footer" },
                          [
                            !_vm.meta.readonly
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "document-lightbox-metadata-action",
                                    class: { active: _vm.editMode },
                                    attrs: { title: "Switch to edit mode" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.toggleEditMode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-pencil" })]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.meta.readonly
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "document-lightbox-metadata-action",
                                    attrs: { title: "Delete document" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.deleteDocument.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-trash" })]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.metaPanelVisible &&
                _vm.permissionPanelOpen &&
                _vm.meta.permissions !== null
                  ? _c("div", { staticClass: "permissions-panel" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$gettext("Permissions")))]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table-permissions-panel" }, [
                        _c("table", { staticClass: "table-permissions" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "cell-text" }, [
                                _vm._v(_vm._s(_vm.$gettext("User or group"))),
                              ]),
                              _vm._v(" "),
                              _vm.isDocumentForComment
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$gettext("Document for comment")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isDocumentForComment
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(_vm.$gettext("Comment until"))
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isDocumentForComment
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$gettext(
                                          "Comments of others visible"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.$gettext("Document visible until"))
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.isDocumentForComment
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$gettext(
                                          "Comments visible after close date"
                                        )
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isDocumentForComment
                                ? _c("th", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$gettext("Comments visible until")
                                      )
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.meta.permissions, function (item) {
                              return _c("tr", [
                                _c("td", { staticClass: "cell-text" }, [
                                  _c("i", {
                                    staticClass: "fa",
                                    class: {
                                      "fa-user": item.type === "user",
                                      "fa-users": item.type === "group",
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(item.name)),
                                ]),
                                _vm._v(" "),
                                _vm.isDocumentForComment
                                  ? _c("td", { staticClass: "cell-bool" }, [
                                      item.document_for_comment
                                        ? _c("i", {
                                            staticClass: "fa fa-check",
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isDocumentForComment
                                  ? _c("td", { staticClass: "cell-date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("date")(
                                            item.comment_open_until
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isDocumentForComment
                                  ? _c("td", { staticClass: "cell-bool" }, [
                                      item.comment_visible_for_others
                                        ? _c("i", {
                                            staticClass: "fa fa-check",
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("td", { staticClass: "cell-date" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("date")(
                                        item.document_visible_until
                                      )
                                    )
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.isDocumentForComment
                                  ? _c("td", { staticClass: "cell-bool" }, [
                                      item.comment_visible_after_close
                                        ? _c("i", {
                                            staticClass: "fa fa-check",
                                          })
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.isDocumentForComment
                                  ? _c("td", { staticClass: "cell-date" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("date")(
                                            item.comment_visible_until
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.permissionPanelOpen || !_vm.metaPanelVisible,
                        expression: "!permissionPanelOpen || !metaPanelVisible",
                      },
                    ],
                    staticClass: "panel-center",
                  },
                  [
                    _c("iframe", {
                      staticClass: "document-lightbox-iframe",
                      attrs: {
                        name: "document-content",
                        src: _vm.url,
                        frameborder: "0",
                        allow: "encrypted-media",
                        allowfullscreen: "allowfullscreen",
                      },
                      on: { load: _vm.documentLoaded },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.historyPanelOpen && _vm.$root.is_edqm_ui()
                  ? _c("div", { staticClass: "document-history-panel" }, [
                      _c("h3", [_vm._v(_vm._s(_vm.$gettext("History")))]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "document-history-panel-content" },
                        _vm._l(_vm.documentHistory, function (item) {
                          return _c(
                            "div",
                            {
                              staticClass: "timeline-item",
                              attrs: {
                                "date-is": new Date(
                                  item.timestamp
                                ).toLocaleString(),
                              },
                            },
                            [
                              _c("h1", [
                                _c("i", { staticClass: "fa fa-user-circle" }),
                                _vm._v(" " + _vm._s(item.username)),
                              ]),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(item.description),
                                },
                              }),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "document-lightbox-footer" }, [
                _vm.$root.viewerDocumentIndex > -1
                  ? _c("h5", { staticClass: "pages-indicator" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$root.viewerDocumentOffset +
                            _vm.$root.viewerDocumentIndex +
                            1
                        ) +
                          " of " +
                          _vm._s(_vm.$root.viewerDocumentCount)
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.zoomAvailable
                  ? _c(
                      "div",
                      {
                        staticClass: "page-scale",
                        attrs: { title: _vm.$gettext("Zoom for document") },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.zoom,
                              expression: "zoom",
                              modifiers: { number: true },
                            },
                          ],
                          staticClass: "slider",
                          attrs: {
                            max: "5",
                            min: "-2",
                            step: "1",
                            type: "range",
                          },
                          domProps: { value: _vm.zoom },
                          on: {
                            __r: function ($event) {
                              _vm.zoom = _vm._n($event.target.value)
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "document-lightbox-close",
                  attrs: { title: _vm.$gettext("Close") },
                  on: { click: _vm.closeLightbox },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "document-lightbox-favorite",
                  class: { active: _vm.meta.favorite },
                  attrs: {
                    title: _vm.meta.favorite
                      ? _vm.$gettext("Remove from my favourite documents")
                      : _vm.$gettext("Add to my favourite documents"),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleFavoriteLightbox.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Favorite")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "document-lightbox-meta",
                  class: { active: _vm.metaPanelActive },
                  attrs: {
                    title: _vm.metaPanelActive
                      ? _vm.$gettext("Hide document's metadata")
                      : _vm.$gettext("Show document's metadata"),
                  },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleMetaLightbox.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Meta")]
              ),
              _vm._v(" "),
              _vm.meta.comment && _vm.meta.comment.enabled
                ? _c(
                    "button",
                    {
                      staticClass: "document-lightbox-comments",
                      attrs: { title: _vm.$gettext("See or make comments") },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleCommentLightbox.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("Comments")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "document-lightbox-print",
                  attrs: { title: _vm.$gettext("Print document") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.print()
                    },
                  },
                },
                [_vm._v("Print")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "document-lightbox-open",
                  attrs: { title: _vm.$gettext("Open in a new window") },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openNewWindow.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Link")]
              ),
              _vm._v(" "),
              _vm.canGotoNext
                ? _c(
                    "button",
                    {
                      staticClass: "document-lightbox-next",
                      attrs: { title: _vm.$gettext("Next document") },
                      on: { click: _vm.nextDocument },
                    },
                    [_vm._v("Next")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canGotoPrevious
                ? _c(
                    "button",
                    {
                      staticClass: "document-lightbox-prev",
                      attrs: { title: _vm.$gettext("Previous document") },
                      on: { click: _vm.previousDocument },
                    },
                    [_vm._v("Previous")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.meta.permissions !== null && _vm.$root.is_edqm_ui()
                ? _c(
                    "button",
                    {
                      staticClass: "document-lightbox-history",
                      class: { active: _vm.historyPanelOpen },
                      attrs: {
                        title: _vm.$gettext("Show history of document"),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleHistory()
                        },
                      },
                    },
                    [_vm._v("History")]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }