<template>
  <div class="extranet-application">
    <breadcrumb></breadcrumb>
    <router-view></router-view>
    <div v-if="$root.viewerVisible" class="document-lightbox">
      <div class="document-lightbox-wrapper">
        <div class="document-lightbox-header">
          <span v-if="meta.comment && meta.comment.enabled" class="comment-deadline" :class="{'comment-open': meta.comment.writable, 'comment-close': !meta.comment.writable}"> {{ meta.comment.deadline }}</span>
        </div>
        <div class="document-lightbox-content">
          <div v-if="commentPanelVisible" class="document-lightbox-filters">
            <h3>{{$gettext('Commenting tools')}}</h3>
            <comment-tools ref="commentTools" :document="meta.id" :readonly="!meta.comment.writable" selector="iframe[name='document-content']" :report-mode="commentReportVisible" @reportChanged="doReportChanged"></comment-tools>
          </div>
          <div v-if="metaPanelVisible" class="document-lightbox-metadata" :class="{'edit-mode': editMode}">
            <div class="document-lightbox-metadata-content">
              <h3>{{$gettext('Metadata')}} <small v-if="editMode">[{{$gettext('Edit mode')}}]</small></h3>
              <table>
                <tbody>
                <tr>
                  <td>{{$gettext('English title')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.title_en"></meta-field>
                  </td>
                </tr>
                <tr>
                  <td>{{$gettext('French title')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.title_fr"></meta-field>
                  </td>
                </tr>
                <tr>
                  <td>{{$gettext('Latin title')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.latin_title"></meta-field>
                  </td>
                </tr>
                <tr>
                  <td>{{$gettext('Reference')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.reference"></meta-field>
                  </td>
                </tr>
                <tr>
                  <td>{{$gettext('Monograph numbers')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.monograph_numbers"></meta-field>
                  </td>
                </tr>
                <tr v-if="$root.is_edqm_ui()">
                  <td>{{$gettext('Keywords')}}</td>
                  <td class="meta-field">
                    <meta-field :edit="editMode" v-model="meta.keywords"></meta-field>
                  </td>
                </tr>
                <tr v-for="item in getMetadata()">
                  <td>{{item[0]}}</td>
                  <td>{{item[1]}}</td>
                </tr>
                </tbody>
              </table>
              <div class="direct-link"><i class="fa fa-link"></i> <a :href="meta.direct_link" @click.prevent="directLinkToClipboard()">{{$gettext('Direct link...')}}</a></div>
              <div class="document-lightbox-folders">
                <h5 class="document-lightbox-section" @click.prevent="toggleFolders"><i class="fa" :class="{'fa-angle-down': isFolderPanelOpen, 'fa-angle-right': !isFolderPanelOpen}"></i> {{$gettext('Folders')}} <small>({{folderCount}})</small></h5>
                <ul v-if="isFolderPanelOpen">
                  <li v-for="item in getFolders()" @dblclick.prevent="openFolder(item)" @click.prevent="removeFolder(item)" :title="item.path">
                    <span class="glyphicon glyphicon-folder-close item-icon" aria-hidden="true"></span>
                    <span class="item-label"><ellipsis :data="item.label" :line-clamp="2" :line-height="'12px'"></ellipsis></span>
                    <span class="item-remove"><i v-if="editMode" class="fa fa-times"></i></span>
                  </li>
                  <li v-if="editMode" class="new-item" @click.prevent="newFolder">
                    <span class="glyphicon glyphicon-plus item-icon" aria-hidden="true"></span>
                  </li>
                </ul>
              </div>
              <div class="document-lightbox-events">
                <h5 class="document-lightbox-section" @click.prevent="toggleMeetings"><i class="fa" :class="{'fa-angle-down': meetingPanelOpen, 'fa-angle-right': !meetingPanelOpen}"></i> {{$gettext('Meetings')}} <small>({{meetingCount}})</small></h5>
                <ul v-if="meetingPanelOpen">
                  <li v-for="item in getMeetings()" @dblclick.prevent="openMeeting(item)"><span class="glyphicon glyphicon-calendar" aria-hidden="true"></span> {{ item.label }}</li>
                </ul>
              </div>
              <div v-if="meta.readonly && $root.is_edqm_ui()" class="document-lightbox-events">
                <h5 class="document-lightbox-section" @click.prevent="toggleNotification"><i class="fa" :class="{'fa-angle-down': notificationPanelOpen, 'fa-angle-right': !notificationPanelOpen}"></i> {{$gettext('Notification')}} </h5>
                <div v-if="notificationPanelOpen">
                  <p>{{ meta.notification_message }}</p>
                </div>
              </div>
              <div class="document-lightbox-extra">
                <button class="btn btn-text" v-if="meta.permissions !== null && $root.is_edqm_ui()" @click.prevent="() => permissionPanelOpen = !permissionPanelOpen"><span v-if="!permissionPanelOpen">{{$gettext('Show permissions')}}</span><span v-if="permissionPanelOpen">{{$gettext('Show document')}}</span></button>
              </div>
            </div>
            <div class="document-lightbox-metadata-footer">

              <div v-if="!meta.readonly" class="document-lightbox-metadata-action" :class="{active: editMode}" @click.prevent="toggleEditMode" title="Switch to edit mode"><i class="fa fa-pencil"></i></div>
              <!--              <div class="document-lightbox-metadata-action" @click.prevent="showHistoric" title="Historic of document"><i class="fa fa-history"></i></div>-->
              <div v-if="!meta.readonly" class="document-lightbox-metadata-action" @click.prevent="deleteDocument" title="Delete document"><i class="fa fa-trash"></i></div>
            </div>
          </div>
          <div class="permissions-panel" v-if="metaPanelVisible && permissionPanelOpen && meta.permissions !== null">
            <h3>{{ $gettext('Permissions')}}</h3>
            <div class="table-permissions-panel">
            <table class="table-permissions">
                <thead>
                  <tr>
                    <th class="cell-text">{{ $gettext('User or group')}}</th>
                    <th v-if="isDocumentForComment">{{ $gettext('Document for comment')}}</th>
                    <th v-if="isDocumentForComment">{{ $gettext('Comment until')}}</th>
                    <th v-if="isDocumentForComment">{{ $gettext('Comments of others visible')}}</th>
                    <th>{{ $gettext('Document visible until')}}</th>
                    <th v-if="isDocumentForComment">{{ $gettext('Comments visible after close date')}}</th>
                    <th v-if="isDocumentForComment">{{ $gettext('Comments visible until')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in meta.permissions">
                    <td class="cell-text"><i class="fa" :class="{'fa-user': item.type === 'user', 'fa-users': item.type === 'group'}"/> {{ item.name }}</td>
                    <td v-if="isDocumentForComment" class="cell-bool"><i v-if="item.document_for_comment" class="fa fa-check"/></td>
                    <td v-if="isDocumentForComment" class="cell-date">{{ item.comment_open_until|date }}</td>
                    <td v-if="isDocumentForComment" class="cell-bool"><i v-if="item.comment_visible_for_others" class="fa fa-check"/></td>
                    <td class="cell-date">{{ item.document_visible_until|date}}</td>
                    <td v-if="isDocumentForComment" class="cell-bool"><i v-if="item.comment_visible_after_close" class="fa fa-check"/></td>
                    <td v-if="isDocumentForComment" class="cell-date">{{ item.comment_visible_until|date }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="panel-center" v-show="!permissionPanelOpen || !metaPanelVisible">
            <iframe class="document-lightbox-iframe" name="document-content" @load="documentLoaded" :src="url" frameborder="0" allow="encrypted-media" allowfullscreen="allowfullscreen">
            </iframe>
          </div>
          <div class="document-history-panel" v-if="historyPanelOpen && $root.is_edqm_ui()">
            <h3>{{$gettext('History')}}</h3>
            <div class="document-history-panel-content">
              <div class="timeline-item" :date-is="new Date(item.timestamp).toLocaleString()" v-for="item in documentHistory">
                <h1><i class="fa fa-user-circle"></i> {{ item.username }}</h1>
                <div v-html="item.description"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="document-lightbox-footer">
          <h5 class="pages-indicator" v-if="$root.viewerDocumentIndex > -1">{{ $root.viewerDocumentOffset + $root.viewerDocumentIndex + 1 }} of {{ $root.viewerDocumentCount }}</h5>
          <div v-if="zoomAvailable" class="page-scale" :title="$gettext('Zoom for document')">
            <input class="slider" max="5" min="-2" step="1" type="range" v-model.number="zoom">
          </div>
        </div>
        <button class="document-lightbox-close" @click="closeLightbox" :title="$gettext('Close')">Close</button>
        <button :class="{active: meta.favorite}" :title="meta.favorite ? $gettext('Remove from my favourite documents') : $gettext('Add to my favourite documents')" @click.prevent="toggleFavoriteLightbox" class="document-lightbox-favorite">Favorite</button>
        <button class="document-lightbox-meta" :class="{active: metaPanelActive}" :title=" metaPanelActive ? $gettext('Hide document\'s metadata') : $gettext('Show document\'s metadata')" @click.prevent="toggleMetaLightbox">Meta</button>
        <button v-if="meta.comment && meta.comment.enabled" class="document-lightbox-comments" :title="$gettext('See or make comments')" @click.prevent="toggleCommentLightbox">Comments</button>
        <button class="document-lightbox-print" :title="$gettext('Print document')" @click.prevent="print()">Print</button>
        <button class="document-lightbox-open" :title="$gettext('Open in a new window')" @click.prevent="openNewWindow">Link</button>
        <button class="document-lightbox-next" v-if="canGotoNext" :title="$gettext('Next document')" @click="nextDocument">Next</button>
        <button class="document-lightbox-prev" v-if="canGotoPrevious" :title="$gettext('Previous document')" @click="previousDocument">Previous</button>
        <button class="document-lightbox-history" v-if="meta.permissions !== null && $root.is_edqm_ui()" :class="{active: historyPanelOpen}" @click.prevent="toggleHistory()" :title="$gettext('Show history of document')">History</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueEllipsis from 'vue-ellipsis'
  Vue.use(VueEllipsis)

  import Breadcrumb from './components/breadcrumb'
  import Viewer from './viewer'
  import { messageBox } from './utils/dialogs'
  import dateFormat from 'dateformat'
  import message from 'toastr'
  import CommentLink from './comment-link'
  import CommentTools from './comment-tools'
  import metaField from './components/meta-field'
  import { openCollectionSelector } from './collection-selector'
  import { openDocumentWizardDialog } from './document-wizard-modal'

  export default Vue.extend({
    components: { Viewer, Breadcrumb, CommentTools, metaField },
    data () {
      return {
        metaPanelActive: (window.localStorage.viewerMetadataEnabled || 'Yes') === 'Yes',
        editMode: false,
        folderPanelOpen: (window.localStorage.viewerMetadataFolderOpen || 'Yes') === 'Yes',
        meetingPanelOpen: (window.localStorage.viewerMetadataMeetingOpen || 'Yes') === 'Yes',
        notificationPanelOpen: (window.localStorage.viewerMetadataNotificationOpen || 'Yes') === 'Yes',
        commentEnabled: false,
        referenceCreated: false,
        zoom: 0,
        commentReportVisible: false,
        metaEnglishTitle: null,
        documentHistory: [],
        historyPanelOpen: (window.localStorage.viewerMetadataHistoryOpen || 'No') === 'Yes',
        permissionPanelOpen: false
      }
    },
    filters: {
      date: function (str) {
        return str ? new Date(str).toLocaleDateString() : ''
      }
    },
    computed: {
      meta: {
        get () {
          return this.$root.viewerDocument || {}
        },
        set (newValue) {
          this.$root.viewerDocument = newValue
        }
      },
      isDocumentForComment () {
        return !!this.meta.comment
      },
      meetingCount () {
        return this.meta.meetings && this.meta.meetings.length
      },
      folderCount () {
        return this.meta.collections && this.meta.collections.length
      },
      isFolderPanelOpen () {
        return this.folderPanelOpen && (this.folderCount > 0 || this.editMode)
      },
      commentPanelVisible () {
        return this.commentEnabled && this.meta.comment && this.meta.comment.enabled
      },
      metaPanelVisible () {
        return this.metaPanelActive && !this.commentPanelVisible
      },
      zoomAvailable () {
        // Show slider for text content only
        return (this.meta.mime_type || '').startsWith('text') && !window.isIE
      },
      url () {
        return !this.commentReportVisible ? this.$root.viewerUrl : `/comments/${this.meta.id}/report`
      },
      canGotoNext () {
        return this.$root.hasNextDocument();
      },
      canGotoPrevious () {
        return this.$root.hasPreviousDocument();
      }

    },
    watch: {
      editMode: function () {
        if (this.editMode && !this.folderPanelOpen) {
          this.folderPanelOpen = true
        }
      },
      historyPanelOpen: function () {
        if (this.historyPanelOpen) {
          this.loadHistory()
        }
      },
      zoom: function () {
        let iframe = document.querySelector('iframe');
        let documentFrame = iframe.contentWindow.document
        let windowFrame = iframe.contentWindow
        let scales = [0.7, 0.8, 1, 1.1, 1.2, 1.3, 1.5, 2.0]
        documentFrame.body.setAttribute('style', `scale: ${scales[this.zoom + 2]}; -webkit-transform: scale(${scales[this.zoom + 2]}); -webkit-transform-origin: 0 0;`);

        /* TODO:
        zoom: 0.15;
        -moz-transform:scale(0.75);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.75);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.75);
        -webkit-transform-origin: 0 0;
         */
      }
    },
    methods: {
      newFolder (e) {
        openCollectionSelector(this.$root, ['id', 'text']).then(selection => {
          selection.forEach(item => {
            this.meta.collections.push({ id: item.id, label: item.text })
          })
        })
      },
      removeFolder (item) {
        if (this.editMode) {
          this.meta.collections = this.meta.collections.filter(e => e.id !== item.id)
        }
      },
      doReportChanged (visible) {
        this.commentReportVisible = visible
      },
      toggleHistory () {
        this.historyPanelOpen = !this.historyPanelOpen
        window.localStorage.viewerMetadataHistoryOpen = this.historyPanelOpen ? 'Yes' : 'No'
      },
      toggleEditMode () {
        openDocumentWizardDialog(this.$root, this.meta.id).then(response => {})
      },
      toggleFolders () {
        this.folderPanelOpen = !this.folderPanelOpen
        window.localStorage.viewerMetadataFolderOpen = this.folderPanelOpen ? 'Yes' : 'No'
      },
      toggleMeetings () {
        this.meetingPanelOpen = !this.meetingPanelOpen
        window.localStorage.viewerMetadataMeetingOpen = this.meetingPanelOpen ? 'Yes' : 'No'
      },
      toggleNotification () {
        this.notificationPanelOpen = !this.notificationPanelOpen
        window.localStorage.viewerMetadataNotificationOpen = this.notificationPanelOpen ? 'Yes' : 'No'
      },
      toggleCommentLightbox () {
        this.commentReportVisible = false
        this.commentEnabled = !this.commentEnabled
      },
      getMetadata () {
        // Readonly metadata
        let metadata = [
          [this.$gettext('Date posted'), new Date(this.meta.posted_at).toLocaleDateString()],
          [this.$gettext('Posted by'), this.meta.posted_by],
          [this.$gettext('Filename'), this.meta.filename],
          [this.$gettext('Content type'), this.meta.mime_type],
        ]
        if (this.meta.readonly && this.$root.is_edqm_ui()) {
          metadata.push([this.$gettext('Restricted document'), this.meta.is_restricted ? this.$gettext('yes') : this.$gettext('no')])
        }
        return metadata
      },
      directLinkToClipboard() {
        let btnText = this.$gettext('Copy to clipboard')
        let message = `<div class="input-group">
            <input type="text" id="cc-url" class="form-control" placeholder="url" value="${this.meta.direct_link}" aria-describedby="basic-addon2">
            <span class="input-group-btn"><button id="clipboard-copy" class="btn btn-default"><i class="fa fa-copy"></i> ${btnText}</button></span>
          </div>`
        $(document).on('click', '#clipboard-copy', () => {
          $("#cc-url").select()
          document.execCommand("copy")
        })
        messageBox(this.$gettext('Get direct link'), message, [this.$gettext('Close')])
      },
      getFolders () {
        return this.meta.collections
      },
      getMeetings () {
        return this.meta.meetings
      },
      openFolder (item) {
        if (!this.editMode) {
          this.$router.push(item.url + `?select=${this.meta.id}`)
        }
      },
      openMeeting (item) {
        this.$router.push(item.url)
      },
      openNewWindow () {
        window.open(this.$root.viewerUrl.split("?")[0])
      },
      close () {
        this.commentReportVisible = false
        this.$root.closeDocument()
      },
      closeLightbox (e) {
        e.preventDefault()
        this.close()
      },
      documentLoaded (e) {
        let commentPopupEnabled = this.meta.comment && this.meta.comment.enabled && !this.meta.comment.writable
        if (commentPopupEnabled) {
          message.error(this.$gettext('Commenting is no longer possible on this document.'), this.$gettext('Deadline passed'),
            {"closeButton": true, "preventDuplicates": true, "positionClass": "toast-top-center", "timeOut": "5000"})
        }
        this.$root.$emit('document_opened', this.$root.viewerUrl)
      },
      nextDocument (e) {
        this.commentReportVisible = false
        this.$root.openNextDocument()
      },
      previousDocument (e) {
        this.commentReportVisible = false
        // this.$root.$emit('document_previous')
        this.$root.openPreviousDocument()
      },
      addFavorite () {
        this.$restAPI.post(`/user/favorites/${this.meta.id}/`).then(response => {
          this.meta.favorite = response.status === 204
          this.$root.$emit('document_favorites_changed')
          message.success(this.$gettext('Document added to your favourites'))
        }).catch(() => message.error(this.$gettext('')))
      },
      removeFavorite () {
        this.$restAPI.delete(`/user/favorites/${this.meta.id}/`).then(response => {
          this.meta.favorite = !(response.status === 204)
          this.$root.$emit('document_favorites_changed')
          message.success(this.$gettext('Document removed from your favourites'))
        }).catch(() => message.error(this.$gettext('')))
      },
      toggleMetaLightbox () {
        this.commentReportVisible = false
        if (this.commentEnabled) {
          this.commentEnabled = false
          this.metaPanelActive = true
        } else {
          this.metaPanelActive = !this.metaPanelActive
        }
        window.localStorage.viewerMetadataEnabled = this.metaPanelActive ? 'Yes' : 'No'
      },
      toggleFavoriteLightbox () {
        this.meta.favorite = !this.meta.favorite
        if (this.meta.favorite) {
          messageBox(this.$gettext('Confirmation'), this.$gettext('Add the document to favourites ?'), { 'Y': this.$gettext('Yes'), 'N': this.$gettext('No') }).then(action => {
            if (action === 'Y') {
              this.addFavorite()
            } else {
              this.meta.favorite = false
            }
          })
        } else {
          messageBox(this.$gettext('Confirmation'), this.$gettext('Remove the document from your favourites ?'), { 'Y': this.$gettext('Yes'), 'N': this.$gettext('No') }).then(action => {
            if (action === 'Y') {
              this.removeFavorite()
            } else {
              this.meta.favorite = true
            }
          })
        }
      },
      deleteDocument () {
        messageBox(this.$gettext('Confirmation'), this.$gettext('Are you sure you want to delete this document?'), { 'Y': this.$gettext('Yes'), 'N': this.$gettext('No') }).then(action => {
          if (action === 'Y') {
            this.$restAPI.delete(`documents/${this.meta.id}/`).then(response => {
              this.$root.$emit('document_deleted', this.meta.id)
              this.close()
              message.success(this.$gettext('Document deleted.'))
            }).catch(error => {
              let title = this.$gettext('Unable to delete document.')
              if (error.response.status === 403) {
                message.error(this.$gettext('Error: You do not have the necessary rights to carry out this action'), title)
              } else {
                message.error(error, title)
              }
            })
          }
        })
      },
      loadHistory () {
        this.$restAPI.get(`/documents/${this.meta.id}/history/`).then(response => {
          this.documentHistory = response.data
        })
      },
      print () {
        let $iframeWindow = document.querySelector('iframe.document-lightbox-iframe');
        if ($iframeWindow) {
          $iframeWindow = $iframeWindow.contentWindow
        }
        if ($iframeWindow) $iframeWindow.print()
      },
      openRequestedPopup () {
        this.externalWindow = window.open(
          this.$root.viewerUrl,
          "DescriptiveWindowName",
          "menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
        );
      },
      saveMeta () {
        message.warning('Not implemented')
      },
      cancelEdit () {
        this.editMode = false
      }
    },
    mounted () {
      this.$root.$on('document_refreshed', () => {
        if (this.historyPanelOpen) {
          this.loadHistory()
        }
      })
    }
  })
</script>

<style lang="scss" scoped>
  .application-body {
    min-height: calc(100vh - 435px);
  }

  .document-lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(14, 42, 71, .98);
    z-index: 1800;
  }

  .document-lightbox-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 56px);
    margin: 24px 76px 32px;
    /*background-color: #fff;*/
    /*border-radius: 6px;*/
    /*padding: 6px;*/
  }

  .document-lightbox-content .submit-panel2 {
    margin-top: 50px;
  }

  .document-lightbox-iframe, .comment-report-container {
    height: 100%;
    width: 100%;
    background-color: #fff;
  }

  .document-lightbox-prev, .document-lightbox-next {
    position: fixed;
    border: none;
    // background: url('images/arrow.svg') center center / 16px 28px no-repeat;
    width: 75px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1801;
    cursor: pointer;
    text-indent: 75px;
    overflow: hidden;
    margin-top: -14px;
    background-color: transparent;
    border: none;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #eee;
      font-size: 44px;
      content: "\f105";
      font-family: "FontAwesome";
      text-indent: 0;
    }
  }

  .document-lightbox-prev:hover,
  .document-lightbox-next:hover,
  .document-lightbox-close:hover,
  .document-lightbox-favorite:hover,
  .document-lightbox-meta:hover,
  .document-lightbox-comments:hover,
  .document-lightbox-print:hover,
  .document-lightbox-open:hover,
  .document-lightbox-history:hover {
    color: #fff;
    background-color: rgba(128, 128, 128, 0.3);
  }

  .document-lightbox-prev {
    transform: rotate(180deg);
    left: 0;
    right: auto;
  }

  .document-lightbox-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .document-lightbox-footer {
    position: fixed;
    bottom: 0;
    left: 75px;
    right: 75px;
    z-index: 1801;
    color: #aaa;
    text-align: center;
  }

  .document-lightbox-header {
    position: fixed;
    top: 0;
    left: 75px;
    right: 75px;
    z-index: 1801;
    color: #fff;
    text-align: right;
    height: 24px;
    padding-top: 3px;
  }

  .document-lightbox-metadata-content {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 64px;
    overflow-y: auto;
  }

  .comment-deadline {
    color: white;
    padding-right: 4px;
    padding-left: 4px;
    font-weight: 800;
  }

  .comment-deadline.comment-open {
    background-color: #27ad27;

    &:after {
      content: "\F044";
      font-family: "FontAwesome";
      padding-left: 4px;
    }
  }

  .comment-deadline.comment-close {
    background-color: #ea0000;

    &:after {
      content: "\F023";
      font-family: "FontAwesome";
      padding-left: 4px;

    }
  }


  .document-lightbox-close, .document-lightbox-favorite, .document-lightbox-meta, .document-lightbox-comments, .document-lightbox-print, .document-lightbox-open, .document-lightbox-history {
    position: fixed;
    top: 0;
    right: 0;
    width: 75px;
    height: 75px;
    overflow: hidden;
    z-index: 1803;
    background-color: transparent;
    border: none;
    text-indent: 75px;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 44px;
      content: "\d7";
      text-indent: 0;
    }
  }

  .document-lightbox-favorite {
    right: auto;
    left: 0;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      content: "\f006";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }

  }

  .btn-text {
    border: 1px solid #eee;
    background-color: transparent;
    margin: 8px;
  }

  .btn-text:hover {
    background-color: rgba(128, 128, 128, 0.3);
  }

  .document-lightbox-meta {
    right: auto;
    left: 0;
    top: 196px;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      // content: "\f02c";
      content: "\f013";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }
  }

  .document-lightbox-meta.active:after {
    color: #fff;
  }

  .document-lightbox-favorite.active:after {
    content: "\f005";
    color: #f7e010;
  }

  .document-lightbox-comments {
    right: auto;
    left: 0;
    top: 256px;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      // content:"\f086";
      // content: "\f0e6";
      content: "\f075";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }
  }

  .document-lightbox-print {
    right: auto;
    left: 0;
    top: 128px;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      content: "\f02f";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }
  }

  .document-lightbox-open {
    right: auto;
    left: 0;
    top: 64px;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      content: "\f08e";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }
  }

  .document-lightbox-history {
    right: 0;
    left: auto;
    bottom: 0;
    top: auto;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      content: "\f1da";
      text-indent: 0;
      font-family: "FontAwesome";
      color: #eee;
    }
  }

  .document-lightbox-history.active {
    color: #fff;
    background-color: rgba(128, 128, 128, 0.5);
  }

  .permissions-panel {
    padding: 8px;
  }

  .table-permissions-panel {
    overflow-y: auto;
    height: calc(100% - 40px);
    width: 100%;
  }

  .table-permissions {
    width: 100%;

    td {
      border: 1px solid rgba(128, 128, 128, 0.5);
      padding: 8px;
    }
    th {
      border: 1px solid rgba(128, 128, 128, 0.5);
      padding: 8px;
      text-align: center;
    }
    .cell-date {
      width: 60px;
      text-align: center;
    }
    .cell-bool {
      width: 60px;
      text-align: center;
    }
    .cell-text {
      text-align: left;
    }
    tbody {
      overflow-y: auto;

    }
  }


</style>

<style lang="scss">

  body.viewer-open {
    overflow: hidden;
  }

  .document-lightbox-content {
    display: flex;
  }

  .document-lightbox-filters {
    position: relative;
    flex: 1 300px;
    background-color: rgba(18, 55, 81, 0.8);
    margin: 8px 24px 8px 8px;
    height: 100%;
    color: #eee;
    padding: 8px;

    h3 {
      margin: 0 0 16px;
      color: #fff;
    }
  }

  .document-lightbox-metadata {
    position: relative;
    flex: 1 33%;
    background-color: rgba(18, 55, 81, 0.8);
    margin: 8px 24px 8px 8px;
    height: 100%;
    color: #eee;
    padding: 8px;

    h3 {
      margin: 0 0 16px;
      color: #fff;
    }

    table {
      width: 100%;
      font-size: 12px;
      user-select: text;
    }

    td, th {
      padding: 4px;
      border: 1px solid rgba(14, 26, 46, 0.6);
      text-align: left;
    }

    p {
      padding: 4px;
      text-align: left;
    }
  }
  .comment-popup {
    cursor: pointer;
  }

  .direct-link {
    padding-top: 8px;
    color: #CCC;
    a:link {
      color: #CCC;
    }
    a:hover, a:focus {
      color: white;
      text-decoration: none;
      background-color: rgba(128, 128, 128, 0.3);
    }
  }

  .document-lightbox-content .panel-center {
    height: 100%;
    width: 100%;
  }

  .document-lightbox-content iframe {
    height: 100%;
    width: 100%;
  }

  .document-lightbox-folders {

    margin-top: 20px;


    ul {
      padding-left: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      position: relative;
      float: left;
      height: 64px;
      padding: 4px;
      line-height: 1.4;
      text-align: center;
      width: 64px;
      max-width: 64px;
      font-size: 9px;
      margin: 4px;
      background-color: rgba(0, 0, 0, 0.1);
      overflow: hidden;
      flex-grow: 1;
    }

    li:hover {
      background-color: rgba(128, 128, 128, 0.4);
    }

    .item-label {
      margin-top: 4px;
      display: block;
      text-align: center;
      word-wrap: break-word;
    }

    .item-icon {
      font-size: 24px;
      margin-bottom: 4px;
      color: #aaa;
    }

    .item-remove {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      font-size: 12px;
      padding-right: 4px;
    }

    .new-item {
      border: dotted 1px #ccc;
      opacity: 0.4;
      line-height: 64px;
      cursor: pointer;
    }
  }

  .document-lightbox-events {

    margin-top: 20px;
    flex-grow: 1;

    ul {
      padding-left: 0;
      list-style: none;
      margin: 8px;
    }

    li {
      background-color: rgba(0, 0, 0, 0.1);
      padding: 4px;

      .glyphicon {
        margin-right: 4px;
      }
    }

    li:hover {
      background-color: rgba(128, 128, 128, 0.4);
    }
  }

  .document-lightbox-extra {
    margin-top: 50px;
  }

  .document-lightbox-section {
    cursor: pointer;

    i {
      width: 10px;
    }
  }

  .document-lightbox-metadata-footer {
    position: absolute;
    bottom: 0;
    height: 64px;

    left: 0;
    right: 0;
    display: flex;
    flex-direction: row-reverse;
  }

  .document-lightbox-metadata-action {
    height: 64px;
    width: 64px;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
    font-size: 24px;
    cursor: pointer;
    color: #eee;

    i {
      line-height: 48px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: white;
    }

    &.active {
      background-color: rgba(255, 255, 255, 0.8);
      color: rgba(18, 55, 81, 0.9);
    }
  }

  .edit-mode .document-lightbox-folders li {
    cursor: pointer;
    border: dotted 1px #777;
  }

  .modal-open.browser-ie iframe {
    display: none;
  }

  .page-scale {
    width: 100px;
    position: absolute;
    bottom: 16px;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    background: #777;
    outline: none;
    opacity: 1;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }

  .meta-field {
    padding: 0;
    margin: 0;
  }


  $border-color: rgba(white, .3);

  .document-history-panel .timeline-item {
    padding: 2em 2em 2em;
    position: relative;
    color: rgba(white, .7);
    border-left: 2px solid $border-color;

    h1 {
      font-size: 16px;
      color: white;
      padding-top: 0;

      i {
        margin-right: 8px;
        //color: rgba(255, 255, 255, 0.7);
      }
    }

    p {
      font-size: 12px;
    }

    &::before {
      content: attr(date-is);
      position: absolute;
      left: 16px;
      font-weight: bold;
      top: 9px;
      display: block;
      color: white;
      font-size: 16px;
    }

    &::after {
      width: 13px;
      height: 13px;
      display: block;
      top: 1em;
      position: absolute;
      left: -7px;
      border-radius: 13px;
      content: '';
      border: 2px solid $border-color;
      background: white;
    }

    &:last-child {
      border-image: linear-gradient(
          to bottom,
          $border-color 60%,
          rgba($border-color, 0)) 1 100%;
    }

    strong {
      font-weight: bold;
      color: rgba(255, 255, 255, 0.8);
    }
  }

  .document-history-panel {
    width: 40%;
    height: 100%;
    background-color: rgba(18, 55, 81, 0.8);

    h3 {
      margin-top: 0;
      margin-left: 20px;
      color: white;
    }
  }

  .document-history-panel-content {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding: 24px;
  }

  .permissions-panel {
    width: 100%;
    height: 100%;
    color: white;
  }

  .pages-indicator {
    color: white;
  }

  #toast-container.toast-top-center>div {
    width: 720px;
  }
</style>
