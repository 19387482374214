<template>
  <div>
    <ol class="breadcrumb">
      <li v-for="item in $root.breadcrumb" :class="{active: !item.route}"><template v-if="!item.route">{{ item.name }}</template><router-link v-else :to="item.route">{{ item.name }}</router-link></li>
    </ol>
  </div>
</template>

<script>
  export default {
    name: 'breadcrumb',
    data: function () {
      return {

      }
    },
    methods: {
      // isActive (item) {
      //   return item.route
      // }
    }
  }
</script>

<style scoped>
.breadcrumb {
  font-family: "Open sans condensed", "Open Sans", "sans-serif";
  font-size: 16px;
  padding: 4px 0;
  margin-bottom: 0;
  list-style: none;
  background: none;
  border-radius: 0;
}

.breadcrumb>li+li:before {
  display: inline-block;
  font: normal normal normal 22px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0,0);
  content: "\f105";
  color: #dcdcdc;
  font-weight: 200;
  padding: 0 10px;
  vertical-align: bottom;
}

</style>
