var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "query-editor",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myModalLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header row" }, [
            _c("div", { staticClass: "col-sm-6" }, [
              _c("h2", [_vm._v(_vm._s(_vm.$gettext("List of queries")))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-6" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filter,
                    expression: "filter",
                  },
                  { name: "clear", rawName: "v-clear" },
                ],
                staticClass: "form-control input-sm pull-right",
                attrs: {
                  id: "query-editor-filter",
                  tabindex: "1",
                  autocomplete: "off",
                  placeholder: _vm.$gettext("Filter"),
                  name: "filter",
                },
                domProps: { value: _vm.filter },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.filter = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "table",
              { staticClass: "table table-condensed table-striped" },
              [
                _c(
                  "tbody",
                  _vm._l(_vm.filteringQueryList, function (q, index) {
                    return _c(
                      "tr",
                      [
                        _c("td"),
                        _vm._v(" "),
                        _vm.editingQuery !== q
                          ? [
                              _c(
                                "td",
                                {
                                  on: {
                                    dblclick: function ($event) {
                                      return _vm.editQuery(q)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(q.name))]
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "clearfix" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-sm pull-right",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "...",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: {
                                          disabled: _vm.editingQuery,
                                          title: _vm.$gettext("Edit"),
                                          tabindex: "-1",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editQuery(q)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-pencil",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          disabled: _vm.editingQuery,
                                          title: _vm.$gettext("Run"),
                                          tabindex: index + 10,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.runQuery(q)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-play",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          : [
                              _c("td", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.editingQueryName,
                                      expression: "editingQueryName",
                                    },
                                  ],
                                  staticClass: "input-sm form-control",
                                  attrs: {
                                    autocomplete: "off",
                                    id: "input-query-name",
                                  },
                                  domProps: { value: _vm.editingQueryName },
                                  on: {
                                    keyup: [
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.renameQuery(q)
                                      },
                                      function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "esc",
                                            27,
                                            $event.key,
                                            ["Esc", "Escape"]
                                          )
                                        )
                                          return null
                                        $event.stopPropagation()
                                        return _vm.cancelEditingQuery()
                                      },
                                    ],
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.editingQueryName = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "clearfix" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "btn-group btn-group-sm pull-right",
                                    attrs: {
                                      role: "group",
                                      "aria-label": "...",
                                    },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-primary",
                                        attrs: {
                                          disabled: _vm.editingQueryName === "",
                                          title: _vm.$gettext("Validate"),
                                          tabindex: "-1",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.renameQuery(q)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon glyphicon-ok",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-secondary",
                                        attrs: {
                                          title: _vm.$gettext("Cancel"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelEditingQuery()
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-remove",
                                          attrs: { tabindex: "-1" },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-danger",
                                        attrs: {
                                          "data-action": "delete",
                                          title: _vm.$gettext("Delete"),
                                          tabindex: "-1",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteQuery(q)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "glyphicon glyphicon-trash",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                      ],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("tfoot"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c("span", { staticClass: "pull-left no-execute-panel" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.viewBefore,
                    expression: "viewBefore",
                  },
                ],
                attrs: { type: "checkbox", tabindex: "-1" },
                domProps: {
                  checked: Array.isArray(_vm.viewBefore)
                    ? _vm._i(_vm.viewBefore, null) > -1
                    : _vm.viewBefore,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.viewBefore,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.viewBefore = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.viewBefore = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.viewBefore = $$c
                    }
                  },
                },
              }),
              _vm._v(
                " " +
                  _vm._s(_vm.$gettext("Edit the query before running it")) +
                  "\n          "
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: {
                  "data-dismiss": "modal",
                  "data-action": "cancel",
                  disabled: _vm.editingQuery,
                  tabindex: "-1",
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Close")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }