var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        "div",
        {
          staticClass: "node-item",
          class: {
            folder: _vm.hasChilds,
            drag: _vm.dragOn,
            "node-item-section": _vm.node.section,
          },
          attrs: { title: _vm.node.text },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doClick(_vm.node, $event)
            },
            dblclick: function ($event) {
              _vm.hasChilds && _vm.toggleExpanded(_vm.node)
            },
            dragenter: _vm.doDragEnter,
            dragleave: _vm.doDragLeave,
            dragover: (e) => {
              e.preventDefault()
            },
            drop: _vm.doDrop,
          },
        },
        [
          !_vm.always_expanded && !_vm.loading
            ? _c("span", {
                staticClass: "treeview-toggle",
                class: _vm.hasChilds
                  ? _vm.expanded
                    ? "glyphicon glyphicon-chevron-down"
                    : "glyphicon glyphicon-chevron-right"
                  : "",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    _vm.hasChilds && _vm.toggleExpanded(_vm.node, $event)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.always_expanded && !_vm.loading
            ? _c("span", { staticClass: "treeview-toggle glyphicon" })
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("span", { staticClass: "lds-ring" }, [
                _c("div"),
                _c("div"),
                _c("div"),
                _c("div"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.treeview.checkable
            ? _c("span", {
                staticClass: "img",
                class: _vm.checked
                  ? "img-checked"
                  : _vm.checked === undefined
                  ? "img-indeterminate"
                  : "img-unchecked",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.toggleChecked(_vm.node, $event)
                  },
                  dblclick: function ($event) {
                    $event.stopPropagation()
                  },
                },
              })
            : _vm._e(),
          _vm.node.badge
            ? _c("span", { staticClass: "badge pull-right" }, [
                _vm._v(_vm._s(_vm.node.badge)),
              ])
            : _vm._e(),
          _c(
            "span",
            { staticClass: "title", class: { active: _vm.selected } },
            [_vm._v(_vm._s(_vm.node.text))]
          ),
          _vm._v(" "),
          _vm.node.info
            ? _c("small", { staticClass: "node-text-info" }, [
                _vm._v(_vm._s(_vm.node.info)),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.adding
        ? _c("div", { staticClass: "new-folder-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.editText,
                  expression: "editText",
                },
              ],
              attrs: { id: "new-folder", type: "text" },
              domProps: { value: _vm.editText },
              on: {
                keydown: [
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.stopPropagation()
                    return _vm.commitEditing.apply(null, arguments)
                  },
                  function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape",
                      ])
                    )
                      return null
                    $event.stopPropagation()
                    return _vm.cancelEditing.apply(null, arguments)
                  },
                ],
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.editText = $event.target.value
                },
              },
            }),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.commitEditing.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("OK")))]
            ),
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.cancelEditing.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Cancel")))]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.expanded || _vm.always_expanded
          ? _c(
              "ul",
              _vm._l(_vm.node.childs, function (child) {
                return _c("treeview-node", {
                  key: child.id,
                  staticClass: "item",
                  attrs: { depth: _vm.depth + 1, node: child },
                  on: {
                    emitNodeChecked: _vm.emitNodeChecked,
                    emitNodeExpanded: _vm.emitNodeExpanded,
                    emitNodeSelected: _vm.emitNodeSelected,
                    emitNodeClicked: _vm.emitNodeClicked,
                  },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }