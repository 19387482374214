<template>
  <vue-tags-input id="inputKeywords" v-model="tag"
              :tags="value" @tags-changed="updateTags"
              :autocomplete-items="autocompleteItems"
              :add-only-from-autocomplete="strict"
              :placeholder="placeholder" autocomplete="off" :allow-edit-tags="!strict">

<!--    <template #tag-left="props" class="my-tag-left">-->
<!--      <span v-if="props.tag.selection" class="glyphicon glyphicon-check"></span>-->
<!--      <span class="glyphicon glyphicon-search" v-else-if="props.tag.query"></span><span v-if="props.tag.mode == 2"> {{$gettext('search:Phrase prefix')|upper}}: </span>-->
<!--      <span v-else-if="props.tag.filter">{{ props.tag.label }}: </span>-->
<!--    </template>-->
  </vue-tags-input>
</template>

<script>
  import { VueTagsInput, createTag } from '@johmun/vue-tags-input';
  import message from 'toastr'

  export default {
    name: 'InputTags',
    components: {VueTagsInput},
    props: {
      apiUrl: {
        type: String
      },
      minChars: {
        type: Number,
        default: 2
      },
      strict: {
        type: Boolean,
        default: false
      },
      value: {
        type: Array
      },
      placeholder: {
        type: String
      },
      tagClasses: {
        type: String
      },
      queryName: {
        type: String,
        default: 'search'
      }
    },
    data: function () {
      return {
        debounce: null,
        tag: '',
        tags: [],
        autocompleteItems: []
      }
    },
    watch: {
      tag: 'initAutocompleteItems',
    },
    methods: {
      initAutocompleteItems () {

        if (!this.apiUrl || this.tag.length < this.minChars) return;

        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          let params = {}
          if (this.queryName) {
            params[this.queryName] = this.tag
          }
          this.$restAPI.get(this.apiUrl, { params }).then(response => {
            this.autocompleteItems = response.data.results.map(a => {
              return createTag({ text: `${a.name} (${a.code})` , value: a.code, classes: this.tagClasses }, []);
            });
          }).catch(() => message.error('Oh. Something went wrong'));
        }, 300);
      },
      updateTags(newTags) {
        this.$emit('input', newTags)
      }
    }
  }
</script>

<style>
  .ti-tag:not(.ti-deletion-mark) {
    background-color: #123751 !important;
  }
</style>
