var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: { tabindex: "-1", role: "dialog", id: "agenda-dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v(_vm._s(_vm.item.title))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("iframe", {
              staticClass: "agenda-iframe",
              attrs: {
                src: _vm.item.url,
                frameborder: "0",
                allow: "encrypted-media",
                allowfullscreen: "allowfullscreen",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-secondary",
                attrs: {
                  href: _vm.item.url,
                  title: _vm.$gettext("Open agenda in new window"),
                  target: "_blank",
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Open")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.print()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$gettext("Print")))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button", "data-dismiss": "modal" },
              },
              [_vm._v(_vm._s(_vm.$gettext("Close")))]
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }