<template>
  <div>
  <div class="contact-list">
    <div class="contact-header">
      <div>
      <button class="btn btn-default" @click.prevent="exportExcel">{{$gettext('Export all')}}</button>
      </div>
      <div class="contact-filter">
        <span><input :placeholder="$gettext('Search...')" autocomplete="off" class="form-control" name="filter" tabindex="1"
             v-model="filter" autofocus v-clear></span>
      </div>
    </div>
    <ul class="list-group" v-scroll-stop>
      <li v-if="members.length === 0">
        <div class="alert alert-info" role="alert">{{$gettext('No result found.')}}</div>
      </li>
      <li v-for="(member, index) in members" :key="member.id" href="#" class="list-group-item">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4">
            <img src="./images/no-user.png" :alt="`${member.first_name}  ${member.last_name}`" class="rounded-circle mx-auto d-block img-fluid">
          </div>
          <div class="col-12 col-sm-6 col-md-8 text-center text-sm-left">
            <label class="name lead">{{member.first_name}} {{member.last_name}}</label>
            <br>
            <span class="text-muted">{{member.organization}}</span>
            <br>
            <span class="fa fa-map-marker fa-fw text-muted"></span>
            <span class="text-muted small">{{member.country && member.country.name}}</span>
            <br>
            <template v-if="member.phone">
              <span class="fa fa-phone fa-fw text-muted"></span>
              <span class="text-muted small">{{member.phone}}</span>
              <br>
            </template>
            <template v-if="member.email">
              <span class="fa fa-envelope fa-fw text-muted"></span>
              <a class="text-muted small text-truncate" :href="'mailto:'+member.email">{{member.email}}</a>
            </template>
          </div>
        </div>
      </li>
      <li v-if="loading"><spinner></spinner></li>
    </ul>
  </div>
    </div>
</template>

<script>
  import Vue from 'vue'
  import Paginator from './paginator'
  import Spinner from './spinner'
  import debounce from 'lodash.debounce'
  import jsPanel from 'jspanel4/dist/jspanel'


  let teamForm = {
    name: 'team-form',
    props: {
      team: {
        type: Object,
        default: null
      },
      selectable: {
        type: Boolean,
        default: false
      }
    },
    components: { Spinner, Paginator },
    data: function () {
      return {
        members: {},
        numPages: 1,
        total: 0,
        params: { page: 1 },
        orderBy: null,
        sortOrder: 'asc',
        fieldOrder: null,
        selected: [],
        filter: '',
        mode: 'list',
        userInfo: {},
        currentIndex: 0,
        loading: false
      }
    },
    watch: {
      filter: debounce(function () {
        this.loadMembers()
      }, 300)
    },
    computed: {
      pageSize () {
        return Math.trunc(this.total / this.numPages)
      },
      recordNumber () {
        return this.pageSize * (this.params.page - 1) + this.currentIndex + 1;
      }
    },
    methods: {
      exportExcel () {
        window.open(`/team/${this.team.id}/members/export`,'_self');
      },
      loadMembers (team = null) {
        if (team !== null) {
          this.team = team
          this.params.page = 1
        }
        let ordering = 'last_name,first_name'
        if (this.fieldOrder) {
          ordering = (this.sortOrder === 'desc' ? '-' : '') + this.fieldOrder
        }
        let params = {
          page: this.params.page,
          ordering: ordering,
          size: 9999
        }
        if (this.filter) {
          params['search'] = this.filter
        }
        this.loading = true
        this.$restAPI.get(`/groups/${this.team.id}/members`, { params }).then(response => {
          this.numPages = response.data.num_pages
          this.members = response.data.results
          this.total = response.data.count
          this.currentIndex = 0
          this.mode = 'list'
        }).catch(error => { console.log(error)}).then(() => {this.loading = false})
      },
      close () {
        // console.log('close', this)
        // Vue.nextTick(() => {
        //   this.$destroy()
        // })
      },
    },
    mounted () {
      jsPanel.create({
        closeOnEscape: true,
        theme: 'bootstrap-primary',
        content: this.$el.querySelector('.contact-list'),
        contentSize: '400 500',
        headerTitle: `${this.$gettext('Group')} ${this.team.code} - ${this.team.name}`,
        headerControls: {
          smallify: 'remove',
          maximize: 'remove'
        },
        resizeit: {
          disable: true
        },
        onclosed: this.close
      })
      this.loadMembers()
    }
  }

  export function openTeamDialog (root, team, selectable) {
    let editor = document.getElementById('team-dialog')
    if (editor === null) {
      editor = document.createElement('div')
      let body = document.querySelector('body')
      body.appendChild(editor)
    }
    new Vue({
      name: 'team-dialog',
      parent: root,
      components: { teamForm },
      render: function (h) {
        return h('team-form', { props: { team, selectable } })
      },
      mounted () {
        $(this.$el).modal({ backdrop: 'static', show: false })
      }
    }).$mount(editor)
  }

  export default teamForm
</script>

<style lang="scss" scoped>

  .navig-updown {
    padding-top: 4px;
    color: #444;
    cursor: pointer;
  }

  .navig-rows {
    margin-right: 12px;
    font-size: 0.9em;
    padding-top: 3px;
    color: #444;
  }

  form label {
    color: #888;
  }

  .user-team-item {
    &.active {
      color: currentColor;
      cursor: default;
      text-decoration: none;
      pointer-events: none;
      display: inline-block;
    }
  }

  a {
    cursor: pointer;
  }

  .user-team-item:not(:first-child):before {
    content: ",";
    color: #444;
    cursor: default;
    text-decoration: none;
    pointer-events: none;
    display: inline-block;
    padding-right: 4px;
  }

  .form-group.user-teams {
    margin-top: 12px;
  }

  .list-group {
    height: 430px;
    margin-bottom: 10px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }


  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important;
    }
  }

  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .d-block {
    display: block !important;
  }

  .rounded-circle {
    border-radius: 50%;
  }

  .img-fluid {
    max-width: 100%;
    height: auto;
    max-height: 100px;
  }

  .lead {
    font-size: 1.7rem;
    font-weight: 300;
  }

  label {
    display: inline-block;
    margin-bottom: 0;
  }

  .text-indent {
    margin-left: 20px;
  }

  input[name="filter"] {
    margin-bottom: 4px;
  }

  h3 {
    margin-top: 8px;
  }

  .contact-list {
    padding: 5px;
    font-size: 12px;
    background-color: #eee;
    height: 100%;
    .list-group-item {
      margin-bottom: 4px;
    }
  }
  .contact-header {
    display: flex;

    .contact-filter {
      flex-grow: 1;
    }
    .btn {
      min-width: auto;
      margin-right: 8px;
    }
  }

  .panel-content {
    background-color: #777 !important;
  }
</style>
