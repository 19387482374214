var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "extranet-agenda-panel" },
    [
      _c(
        "panel",
        {
          attrs: {
            title: _vm.$gettext("My forthcoming events"),
            "icon-class": "fa fa-calendar",
          },
          scopedSlots: _vm._u([
            {
              key: "menu",
              fn: function () {
                return [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "view-agenda" } } },
                        [
                          _c("i", { staticClass: "fa fa-calendar" }),
                          _vm._v(" " + _vm._s(_vm.$gettext("My calendar"))),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("li", {
                    staticClass: "divider",
                    attrs: { role: "separator" },
                  }),
                  _vm._v(" "),
                  _c("li", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.loadData()
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "fa fa-refresh" }),
                        _vm._v(" " + _vm._s(_vm.$gettext("Refresh"))),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.feedLinkVisible
            ? _c("div", { staticClass: "feed-link" }, [
                _c("input", {
                  attrs: { readonly: "" },
                  domProps: { value: _vm.feedLinkURL },
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    on: {
                      click: function ($event) {
                        return _vm.showFeedLink(false)
                      },
                    },
                  },
                  [_c("i", { staticClass: "glyphicon glyphicon-remove" })]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "agenda-content" },
            [
              _c("div", { staticClass: "degrad-white-50" }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "scroll-container" },
                [
                  Object.keys(this.meetings).length === 0
                    ? _c("div", { staticClass: "no-item" }, [
                        _vm._v(_vm._s(_vm.$gettext("No events"))),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("timeline", {
                    attrs: { data: _vm.meetings },
                    on: { click: _vm.openAgenda },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "pull-right agenda-show-link",
                  attrs: { to: { name: "view-agenda" } },
                },
                [
                  _vm._v(_vm._s(_vm.$gettext("My calendar")) + " "),
                  _c("i", { staticClass: "fa fa-arrow-circle-right" }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }