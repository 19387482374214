var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "dropzoneElement",
      class: { "vue-dropzone dropzone": _vm.includeStyling },
      attrs: { id: _vm.id },
    },
    [
      _vm.useCustomSlot
        ? _c(
            "div",
            { staticClass: "dz-message" },
            [
              _vm._t("default", function () {
                return [_vm._v("Drop files here to upload")]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }