var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "h3",
        {
          staticClass: "panel-header",
          on: { dblclick: (e) => _vm.$emit("dblclick", e) },
        },
        [
          _c("i", { class: _vm.iconClass }),
          _vm._v(" " + _vm._s(_vm.title) + " "),
          _vm.hasSlot
            ? _c("span", { staticClass: "pull-right dropdown" }, [
                _c("i", {
                  staticClass: "fa fa-ellipsis-v action",
                  attrs: { "data-toggle": "dropdown" },
                  on: { mouseover: _vm.doPopup },
                }),
                _vm._v(" "),
                _vm.hasSlot
                  ? _c(
                      "ul",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { "aria-labelledby": "dLabel" },
                      },
                      [_vm._t("menu")],
                      2
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "pull-right panel-actions" },
            [_vm._t("actions")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }