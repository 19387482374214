var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "contact-list" }, [
      _c("div", { staticClass: "contact-header" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.exportExcel.apply(null, arguments)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$gettext("Export all")))]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "contact-filter" }, [
          _c("span", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.filter,
                  expression: "filter",
                },
                { name: "clear", rawName: "v-clear" },
              ],
              staticClass: "form-control",
              attrs: {
                placeholder: _vm.$gettext("Search..."),
                autocomplete: "off",
                name: "filter",
                tabindex: "1",
                autofocus: "",
              },
              domProps: { value: _vm.filter },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.filter = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [{ name: "scroll-stop", rawName: "v-scroll-stop" }],
          staticClass: "list-group",
        },
        [
          _vm.members.length === 0
            ? _c("li", [
                _c(
                  "div",
                  { staticClass: "alert alert-info", attrs: { role: "alert" } },
                  [_vm._v(_vm._s(_vm.$gettext("No result found.")))]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.members, function (member, index) {
            return _c(
              "li",
              {
                key: member.id,
                staticClass: "list-group-item",
                attrs: { href: "#" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-sm-6 col-md-4" }, [
                    _c("img", {
                      staticClass: "rounded-circle mx-auto d-block img-fluid",
                      attrs: {
                        src: require("./images/no-user.png"),
                        alt: `${member.first_name}  ${member.last_name}`,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-12 col-sm-6 col-md-8 text-center text-sm-left",
                    },
                    [
                      _c("label", { staticClass: "name lead" }, [
                        _vm._v(
                          _vm._s(member.first_name) +
                            " " +
                            _vm._s(member.last_name)
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(member.organization)),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "fa fa-map-marker fa-fw text-muted",
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-muted small" }, [
                        _vm._v(_vm._s(member.country && member.country.name)),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      member.phone
                        ? [
                            _c("span", {
                              staticClass: "fa fa-phone fa-fw text-muted",
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "text-muted small" }, [
                              _vm._v(_vm._s(member.phone)),
                            ]),
                            _vm._v(" "),
                            _c("br"),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      member.email
                        ? [
                            _c("span", {
                              staticClass: "fa fa-envelope fa-fw text-muted",
                            }),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "text-muted small text-truncate",
                                attrs: { href: "mailto:" + member.email },
                              },
                              [_vm._v(_vm._s(member.email))]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            )
          }),
          _vm._v(" "),
          _vm.loading ? _c("li", [_c("spinner")], 1) : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }