var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vue-cal", {
    staticClass: "agenda",
    attrs: {
      locale: _vm.$language.current,
      "time-from": 6 * 60,
      "time-to": 23 * 60,
      "time-step": 60,
      "disable-views": ["day"],
      "events-count-on-year-view": "",
      selectedDate: _vm.selectedDate,
      "on-event-click": _vm.onEventClick,
      events: _vm.events,
    },
    on: { "view-change": _vm.onViewChanged },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }