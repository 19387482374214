<template>
  <panel :title="this.$pgettext('breadcrumb','My last-viewed documents')" icon-class="fa fa-clock-o">
    <template #menu>
      <li><a href="#" @click.prevent="load()"><i class="fa fa-refresh"></i> {{ $gettext('Refresh') }}</a></li>
    </template>
    <table class="table table-striped">
      <thead>
      <tr>
        <th>#</th>
        <th>{{ $gettext('Document') }}</th>
        <th>{{ $gettext('Last access') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in items" :key="index">
        <td>{{index+1}}</td>
        <td><a :href="item.url" @click.stop="openItem(item, $event)">{{item.title}}</a></td>
        <td>{{new Date(item.last_access).toLocaleDateString()}}</td>
      </tr>
      </tbody>
    </table>
  </panel>
</template>

<script>
  import Panel from './components/panel'
  import groupBy from 'lodash.groupby'

  export default {
    name: "recentDocument",
    components: { Panel },
    data() {
      return {
        items: []
      }
    },
    methods: {
      load() {
        if (this.$root.viewerIsClosed()) {
          this.$restAPI.get('documents/recent/?size=50').then(response => {
            this.items = response.data.results
            this.$root.loadDocumentItems(this.items.map(item => item.id))
          }).catch(error => {})
        }
      },
      openItem (item, e) {
        this.$root.openDocument(item.id)
        e.preventDefault()
      }
    },
    mounted () {
      this.$root.breadcrumb = [{ name: this.$pgettext('breadcrumb', 'Home'), route: { name: 'home' } }, { name: this.$pgettext('breadcrumb', 'My last-viewed documents') }]
      this.load()
      this.$root.$on('document_opened', document => {
        this.load()
      })
    },
    beforeDestroy () {
      this.$root.clearDocumentItems()
    }
  }
</script>

<style scoped>
.content {
  padding-left: 20px;
}
</style>
