<template>
  <section>
    <h3 class="panel-header" @dblclick="e => $emit('dblclick', e)">
      <i :class="iconClass"></i> {{ title }} <span v-if="hasSlot" class="pull-right dropdown"><i class="fa fa-ellipsis-v action" data-toggle="dropdown" @mouseover="doPopup"></i>
      <ul v-if="hasSlot" class="dropdown-menu" aria-labelledby="dLabel">
        <slot name="menu"></slot>
      </ul>
      </span>
      <span class="pull-right panel-actions"><slot name="actions"></slot></span>
    </h3>
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: 'panel',
    props: {
      iconClass: undefined,
      title: {
        type: String
      },
    },
    methods: {
      doPopup(e) {
        $(e.target).dropdown('toggle')
      }
    },
    computed: {
      hasSlot () {
        return !!this.$slots['menu'] || !!this.$scopedSlots['menu']
      }
    }
  }
</script>

<style lang="scss" scoped>

h3.panel-header {
  font-family: "Open sans condensed", "Open Sans", "sans-serif";
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  color: #aaa;

  i {
    padding-right: 4px;
  }
  .dropdown-menu {
    font-size: 12px;
    color: #444;
    font-family: "Open Sans", "sans-serif";
    text-transform: none;
    border-top: 1px solid #1b7eac;
  }
  .panel-actions {
    padding-right: 4px;
    padding-left:  4px;
    a, a:focus {
      color: #1b7eac;
      display: inline-flex;
      padding: 4px;
      margin-top: -4px;
      text-decoration: none;
    }
    a:hover {
      color: white;
      background-color: #1b7eac;
      text-decoration: none;
    }
  }
  .action-text {
    font-family: "Open Sans", "sans-serif";
    font-size: 14px;
    text-transform: none;
    font-weight: normal;
    vertical-align: middle;
    height: 20px;
    line-height: 20px;
  }
  i.fa {
    height: 20px;
    line-height: 20px;
}
}
section {
  margin-bottom: 8px;
}

</style>
