var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "title" }, [
        _c("i", { staticClass: "fa fa-external-link" }),
        _vm._v(" " + _vm._s(_vm.$gettext("Links"))),
      ]),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "ul",
            _vm._l(_vm.links, function (item) {
              return _c("li", { key: item.name }, [
                _c("a", { attrs: { target: "_blank", href: item.url } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
              ])
            }),
            0
          )
        : _c("spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }