var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ol",
      { staticClass: "breadcrumb" },
      _vm._l(_vm.$root.breadcrumb, function (item) {
        return _c(
          "li",
          { class: { active: !item.route } },
          [
            !item.route
              ? [_vm._v(_vm._s(item.name))]
              : _c("router-link", { attrs: { to: item.route } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }