<template>
  <div class="modal fade" id="document-editor" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <h2 class="modal-title">{{$gettext('Edit document')}}</h2>
        </div>
        <div class="modal-body">
          <div class="container">
            <document-wizard :documentId="documentId"></document-wizard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import Vue from 'vue'
  import message from 'toastr'
  import {messageBox} from './utils/dialogs'
  import DocumentWizard from './document-wizard'

  const DocumentWizardDialog = {
    name: 'documentWizardDialog',
    components: {DocumentWizard},
    props: {
      documentId: {
        type: Number
      }
    },
    data: function () {
      return {
        selection: []
      }
    },
    methods: {
      close () {
        $(this.$el).modal('hide')
      },
    },
    mounted () {

    }
  }

  export function openDocumentWizardDialog (root, documentId=null) {
    return new Promise(function(resolve, reject) {
      let documentEditor = document.getElementById('document-editor')
      if (documentEditor === null) {
        documentEditor = document.createElement('div')
        // if team-dialog exists in the DOM, add document-editor before
        let teamDialog = document.getElementById('team-dialog')
        if (teamDialog === null) {
          let body = document.querySelector('body')
          body.appendChild(documentEditor)
        } else {
          teamDialog.before(documentEditor)
        }
      }
      new Vue({
        name: 'document-editor',
        parent: root,
        components: {DocumentWizardDialog},
        render: function (h) {
          return h('documentWizardDialog', {props: {documentId}, ref: 'component'})
        },
        mounted () {
          $(this.$el).modal({backdrop: 'static', show: true})
          $(this.$el).on('hidden.bs.modal', () => {
            resolve(true)
            this.$destroy()
          })
          $(this.$el).on('shown.bs.modal', () => {

          })
        }
      }).$mount(documentEditor)
    })
  }

  export default DocumentWizardDialog
</script>

<style scoped>
  .modal-dialog {
    width: 75vw;
  }

  .container {
    overflow: auto;
    width: 100%;
  }
  .close {
    font-size: 30px;
  }

</style>
