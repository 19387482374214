<template>
  <div class="modal fade" id="collection-selector" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header row">
          <div class="col-sm-12">
            <h2>{{$gettext('Select a folder')}}</h2>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <treeview :onload="$root.loadCollections" :checkable="false" ref="treeview"></treeview>
          </div>
        </div>
        <div class="modal-footer">
          <button @click="newFolder" :disabled="isProcessing" tabindex="-1" class="btn btn-primary pull-left">{{$gettext('New folder')}}</button>
          <button data-dismiss="modal" @click="submit" tabindex="-1" class="btn btn-primary">{{$gettext('OK')}}</button>
          <button data-dismiss="modal" @click="cancel" tabindex="-1" class="btn btn-secondary">{{$gettext('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import $ from 'jquery'
  import Vue from 'vue'
  import message from 'toastr'
  import {messageBox} from './utils/dialogs'
  import Treeview from './components/treeview'

  const collectionSelector = {
    name: 'collectionSelector',
    props: ['args'],
    components: {Treeview},
    data: function () {
      return {
        selection: [],
        isProcessing: false
      }
    },
    methods: {
      close () {
        $(this.$el).modal('hide')
      },
      submit () {
        let node = this.$refs['treeview'].getSelectedNode()
        console.log(node)
        if (node) {
          this.selection = [node.id]
        } else {
          this.cancel()
        }
      },
      cancel () {
        this.selection = []
      },
      newFolder () {
        if (this.$refs['treeview'].selectedNode) {
          this.isProcessing = true
          this.$refs['treeview'].newNode()
          this.$refs['treeview'].$once('newFolder', params => {
            if (params.create) {
              this.$restAPI.post('/collections/',
                {'name': params.text, 'parent_id': params.parent_id},
                { headers: { 'content-type': 'application/json' } }).then(response => {
                message.success(this.$gettext('The folder is created'))
                this.$refs['treeview'].loadNode(params.node, response.data.id)
              }).catch(error => {
                if (error.response.data) {
                  message.error(params.text + ': ' + error.response.data.non_field_errors[0])
                } else {
                  message.error(error)
                }
              })
            }
            this.isProcessing = false
          })
        } else {
          message.info(this.$gettext('Please select a parent folder'))
        }
      }
    },
    mounted () {
    }
  }

  export function openCollectionSelector (root, args='id') {
    return new Promise(function(resolve, reject) {
      let editor = document.getElementById('collection-selector')
      if (editor === null) {
        editor = document.createElement('div')
        let body = document.querySelector('body')
        body.appendChild(editor)
      }
      new Vue({
        name: 'collection-selector',
        parent: root,
        components: {collectionSelector},
        render: function (h) {
          return h('collection-selector', {props: {args}, ref: 'component'})
        },
        mounted () {
          $(this.$el).modal({backdrop: 'static', show: true})
          $(this.$el).on('hidden.bs.modal', () => {
            resolve(this.$refs['component'].selection)
            this.$destroy()
          })
          $(this.$el).on('shown.bs.modal', () => {

          })
        }
      }).$mount(editor)
    })
  }

  export default collectionSelector
</script>

<style scoped>
  .modal-header {
    padding: 15px 0 15px 0;
  }

  .modal-header h2 {
    display: inline-block;
  }

  .modal-header input {
    width: 200px;
    float: right;
  }

  .container {
    height: 50vh;
    max-height: 50vh;
    overflow: auto;
    width: 100%;
  }
</style>
