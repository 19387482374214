<template>
  <div class="row display-flex application-body">
    <div class="col-md-3 hidden-xs">
<!--      <div id="affix-left-panel" :data-spy="getAffix()" data-offset-top="100">-->
        <panel :title="$gettext('Filters')" icon-class="fa fa-filter">
          <div class="panel-container">
            <treeview :data="[{id: '0', text: $gettext('Meetings'), section: true, childs: true}]" :onclick="doAgendaClick" :onload="$root.loadAgendaFilters" :selectable="false" @checked-nodes-changed="onAgendaFilterChecked" ref="treeview-agenda"></treeview>
            <treeview ref="treeview-posted" :onclick="doPostedClick" @checked-nodes-changed="onPostedFilterChecked" :onload="$root.loadDatePostedFilters" :data="[{id: '0', text: $gettext('Date posted'), section: true, childs: true}]" :selectable="false"></treeview>
            <treeview ref="treeview-comment" :onclick="doForCommentClick" @checked-nodes-changed="onCommentFilterChecked" :onload="$root.loadCommentFilters" :data="[{id: '0', text: $gettext('For comment'), section: true, childs: true}]" :selectable="false"></treeview>
          </div>
          <template #menu>
            <li><a href="#" @click.prevent="collapseAllFilters()">
              {{$gettext('Collapse all')}}
            </a></li>
            <li><a href="#" @click.prevent="resetAllFilters()">
              {{$gettext('Reset all filters')}}
            </a></li>
            <li role="separator" class="divider"></li>
            <li><a href="#" @click.prevent="refreshFilters"><i class="fa fa-refresh"></i> {{ $gettext('Refresh') }}</a></li>
          </template>
        </panel>
        <panel v-show="!detached" :title="$gettext('Folders')" :icon-class="{'fa': true, 'fa-folder-open': folderPanelOpen,  'fa-folder': !folderPanelOpen}" @dblclick="toggleFolderPanel()">
          <div class="panel-folder panel-container" v-show="isFolderOpen">
            <div class="panel-folder panel-container-content">
              <treeview :onload="$root.loadCollections" :onclick="doFolderClick" @checked-nodes-changed="onFolderFilterChecked" :selectable="false" ref="folders"></treeview>
            </div>
          </div>
          <template #menu>
            <li><a href="#" @click.prevent="collapseAllFolders()">{{ $gettext('Collapse all') }}</a></li>
            <li><a href="#" @click.prevent="resetAllFolders()">{{ $gettext('Reset all filters') }}</a></li>
             <li><a href="#" @click.prevent="selectAllFolders()">
              {{$gettext('Select all')}}
            </a></li>
            <li role="separator" class="divider"></li>
            <li><a href="#" @click.prevent="$root.loadCollections"><i class="fa fa-refresh"></i> {{ $gettext('Refresh') }}</a></li>
            <li role="separator" class="divider"></li>
            <li><a href="#" @click.prevent="createPanel()">
              {{$gettext('Detach')}} <small>(Experimental)</small>
            </a></li>
          </template>
        </panel>
        <favourites></favourites>
<!--      </div>-->
    </div>
    <div class="col-md-9 col-xs-12">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import Treeview from './components/treeview'
  import Panel from './components/panel'
  import Favourites from './components/favorites'
  import jsPanel from 'jspanel4/dist/jspanel'


  export default {
    name: 'container',
    components: { Favourites, Panel, Treeview },
    data () {
      return {
        folderPanelOpen: true,
        detached: false
      }
    },
    computed: {
      isFolderOpen () {
        return this.folderPanelOpen && !this.detached
      }
    },
    methods: {
      doForCommentClick (sender, node) {
        this.resetAllFilters()
        this.$router.push({ name: 'results', query: { forComment: node.action } }).catch(err => {})
      },
      doFolderClick (sender, node) {
        this.$router.push(node.url).catch(err => {})
      },
      doPostedClick (sender, node) {
        this.resetAllFilters()
        if (node.data) {
          let filter = String(node.data.year)
          if (node.data.month) filter += String(node.data.month).padStart(2, "0");
          this.$router.push({ name: 'results', query: { posted: filter } }).catch(err => {})
        } else {
          this.$router.push({ name: 'results', query: { all: 1 } }).catch(err => {})
        }
      },
      doAgendaClick (sender, node) {
         this.resetAllFilters()
         if (node.data && node.data.year) {
           let filter = String(node.data.year)
           if (node.data.month) filter += String(node.data.month).padStart(2, "0");
           this.$router.push({ name: 'results', query: { meetingFor: filter } }).catch(err => {})
         } else {
           this.$router.push({ name: 'results', query: { meetings: node.id, '_display': node.text } }).catch(err => {})
         }
      },
      getAffix () {
        return this.$router.currentRoute.name === 'results' ? 'affix' : ''
      },
      collapseAllFolders () {
        this.$refs['folders'].collapseAllNodes()
      },
      resetAllFolders () {
        this.$refs['folders'].uncheckAllNodes()
      },
      selectAllFolders () {
        this.$refs['folders'].checkAllNodes()
      },
      collapseAllFilters () {
        this.$refs['treeview-agenda'].collapseAllNodes()
        this.$refs['treeview-posted'].collapseAllNodes()
        this.$refs['treeview-comment'].collapseAllNodes()
      },
      resetAllFilters () {
        this.$refs['treeview-agenda'].uncheckAllNodes()
        this.$refs['treeview-posted'].uncheckAllNodes()
        this.$refs['treeview-comment'].uncheckAllNodes()
      },
      toggleFolderPanel () {
        this.folderPanelOpen = !this.folderPanelOpen
      },
      closeFolderPanel (panel, status, closedByUser) {
        let $target = this.$el.querySelector('.panel-folder.panel-container')
        let $src = document.querySelector('.panel-folder.panel-container-content')
        $target.append($src)
        this.detached = false
        return true
      },
      createPanel () {
        jsPanel.create({
          theme: 'bootstrap-primary',
          headerToolbar: '<span id="bus"><i class="fa fa-cc-discover"></i></span>',
          content: this.$el.querySelector('.panel-folder.panel-container-content'),
          headerTitle: `Folders`,
          position: {
            at: 'left-top',
            my: 'left-top',
          },
          headerControls: {
            // smallify: 'remove',
            maximize: 'remove'
          },
          onbeforeclose: this.closeFolderPanel
        })
        this.detached = true
      },
      refreshFilters () {
        this.$root.loadAgendaFilters()
        this.$root.loadDatePostedFilters()
      },
      onAgendaFilterChecked(node) {
        this.$root.$emit('agendaFilterChecked', node)
      },
      onPostedFilterChecked(node) {
        this.$root.$emit('postedFilterChecked', node)
      },
      onCommentFilterChecked(node) {
        this.$root.$emit('commentFilterChecked', node)
      },
      onFolderFilterChecked(node) {
        this.$root.$emit('folderFilterChecked', node)
      }
    },
    mounted () {

      this.$root.treeAgendaFilter = this.$refs['treeview-agenda']
      this.$root.treePostedFilter = this.$refs['treeview-posted']
      this.$root.treeCommentFilter = this.$refs['treeview-comment']
      this.$root.treeFolderFilter = this.$refs['folders']

      $(document).ready(function () {
        let elem = $('#affix-left-panel');
        let parentPanel = elem.parent()
        let resizeFn = function () {
          let sideBarNavWidth = $(parentPanel).width() - parseInt(elem.css('paddingLeft')) - parseInt(elem.css('paddingRight')) - parseInt(elem.css('marginLeft')) - parseInt(elem.css('marginRight')) - parseInt(elem.css('borderLeftWidth')) - parseInt(elem.css('borderRightWidth'));
          elem.css('width', sideBarNavWidth);
        };
        resizeFn();
        $(window).resize(resizeFn);
      });
    }
  }
</script>

<style scoped>

  .affix {
    top: 40px;
  }

  .favorite-none {
    color: #ccc;
    font-size: 10px;
  }

  .multipane.foo.layout-v .multipane-resizer {
  margin: 0; left: 0; /* reset default styling */
  width: 15px;
  background: blue;
}

.multipane.foo.layout-h .multipane-resizer {
  margin: 0; top: 0; /* reset default styling */
  height: 15px;
  background: blue;
}

  #left-pane {
    width: 33%;
    max-width: 66%;
    margin-right: 15px;
  }

  #main-pane {
    flex-grow: 1;
    margin-left: 15px;
  }

</style>
