<template>
  <div class="modal fade" tabindex="-1" role="dialog" id="agenda-dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2>{{ item.title }}</h2>
        </div>
        <div class="modal-body">
          <iframe class="agenda-iframe" :src="item.url" frameborder="0" allow="encrypted-media" allowfullscreen="allowfullscreen">
          </iframe>
        </div>
        <div class="modal-footer">
          <a :href="item.url" :title="$gettext('Open agenda in new window')" class="btn btn-secondary" target="_blank">{{$gettext('Open')}}</a>
          <button type="button" class="btn btn-secondary" @click.prevent="print()">{{$gettext('Print')}}</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">{{$gettext('Close')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Paginator from './paginator'
  import Spinner from './spinner'
  import debounce from 'lodash.debounce'

  let agendaForm = {
    name: 'agenda-form',
    components: { },
    props: {
      item: {
        type: Object,
        default: null
      },
    },
    data: function () {
      return {

      }
    },
    methods: {
      print () {
        if (this.$window) this.$window.print()
      }
    },
    mounted () {
      this.$iframe = document.querySelector('iframe.agenda-iframe');
      this.$document = this.$iframe.contentWindow.document
      this.$window = this.$iframe.contentWindow
      Vue.nextTick(() => {
        $(this.$el).on('hidden.bs.modal', e => {
          this.$destroy()
        })
        $(this.$el).modal()
      })
    }
  }

  export function openAgendaDialog (root, item) {
    let editor = document.getElementById('agenda-dialog')
    if (editor === null) {
      editor = document.createElement('div')
      let body = document.querySelector('body')
      body.appendChild(editor)
    }
    new Vue({
      name: 'agenda-dialog',
      parent: root,
      components: { agendaForm },
      render: function (h) {
        return h('agenda-form', { props: { item } })
      },
      mounted () {
        $(this.$el).modal({ backdrop: 'static', show: false })
      }
    }).$mount(editor)
  }

  export default agendaForm
</script>

<style lang="scss" scoped>

  .agenda-iframe {
    width: 720px;
    height: 400px;
  }

  .modal-dialog {
    width: 745px;
  }

</style>

<style>
  .agenda-iframe iframe {
    height: 100%;
    width: 100%;
  }
</style>
