var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("ul", { staticClass: "comment-tools-actions" }, [
      _c(
        "li",
        {
          class: { active: !_vm.reportMode },
          attrs: { title: _vm.$gettext("Text with comments") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.updateCommentReport(false)
            },
          },
        },
        [_c("i", { staticClass: "fa fa-file-text" })]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { active: _vm.reportMode },
          attrs: { title: _vm.$gettext("Reports") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.updateCommentReport(true)
            },
          },
        },
        [_c("i", { staticClass: "fa fa-list-ol" })]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          attrs: { title: _vm.$gettext("Refresh") },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.load()
            },
          },
        },
        [_c("i", { staticClass: "fa fa-refresh" })]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "filter-panel" }, [
      _vm.users.length > 1
        ? _c(
            "div",
            { staticClass: "comment-filter user-filter" },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$pgettext("Comment filter", "Users"))),
                _c("small", [_vm._v("(" + _vm._s(_vm.users.length) + ")")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.users, function (u, index) {
                return _vm.moreUsers || index < _vm.defaultFilterListSize
                  ? _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: _vm.isFilterChecked("users", u.value),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleFilter("users", u.value, $event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "comment-filter-item-text" },
                          [_vm._v(_vm._s(u.label))]
                        ),
                        _vm._v(" "),
                        _c("small", [_vm._v("(" + _vm._s(u.count) + ")")]),
                      ]),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.users.length > _vm.defaultFilterListSize
                ? _c(
                    "div",
                    {
                      staticClass: "more-item",
                      on: { click: () => (this.moreUsers = !this.moreUsers) },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.moreUsers
                            ? _vm.$gettext("See less")
                            : _vm.$gettext("See more")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.types.length > 1
        ? _c(
            "div",
            { staticClass: "comment-filter type-filter" },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$pgettext("Comment filter", "Types")) + " "),
                _c("small", [_vm._v("(" + _vm._s(_vm.types.length) + ")")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.types, function (u) {
                return _c("div", { staticClass: "checkbox" }, [
                  _c("label", [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: _vm.isFilterChecked("types", u.value),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleFilter("types", u.value, $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "comment-filter-item-text" }, [
                      _vm._v(_vm._s(u.label)),
                    ]),
                    _vm._v(" "),
                    _c("small", [_vm._v("(" + _vm._s(u.count) + ")")]),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.origins.length > 1
        ? _c(
            "div",
            { staticClass: "comment-filter origin-filter" },
            [
              _c("h5", [
                _vm._v(
                  _vm._s(_vm.$pgettext("Comment filter", "Origins")) + " "
                ),
                _c("small", [_vm._v("(" + _vm._s(_vm.origins.length) + ")")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.origins, function (u, index) {
                return _vm.moreOrigins || index < _vm.defaultFilterListSize
                  ? _c("div", { staticClass: "checkbox" }, [
                      _c("label", [
                        _c("input", {
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: _vm.isFilterChecked("origins", u.value),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleFilter(
                                "origins",
                                u.value,
                                $event
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "comment-filter-item-text" },
                          [_vm._v(_vm._s(u.label))]
                        ),
                        _vm._v(" "),
                        _c("small", [_vm._v("(" + _vm._s(u.count) + ")")]),
                      ]),
                    ])
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm.origins.length > _vm.defaultFilterListSize
                ? _c(
                    "div",
                    {
                      staticClass: "more-item",
                      on: {
                        click: () => (this.moreOrigins = !this.moreOrigins),
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.moreOrigins
                            ? _vm.$gettext("See less")
                            : _vm.$gettext("See more")
                        )
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.states.length > 1
        ? _c(
            "div",
            { staticClass: "comment-filter state-filter" },
            [
              _c("h5", [
                _vm._v(_vm._s(_vm.$pgettext("Comment filter", "States")) + " "),
                _c("small", [_vm._v("(" + _vm._s(_vm.states.length) + ")")]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.states, function (u) {
                return _c("div", { staticClass: "checkbox" }, [
                  _c("label", [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: _vm.isFilterChecked("states", u.value),
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.toggleFilter("states", u.value, $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "comment-filter-item-text" }, [
                      _vm._v(_vm._s(u.label)),
                    ]),
                    _vm._v(" "),
                    _c("small", [_vm._v("(" + _vm._s(u.count) + ")")]),
                  ]),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _vm.count > 0
      ? _c("div", [
          _c("div", [
            _c(
              "span",
              {
                staticClass: "clear-filter-action",
                on: { click: _vm.clearFilters },
              },
              [_vm._v(_vm._s(_vm.$gettext("Clear filters")))]
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("div", [
            _vm._v(
              _vm._s(_vm.filteredCount) +
                " " +
                _vm._s(_vm.$pgettext("ex: 8 of 25", "of")) +
                " " +
                _vm._s(_vm.count)
            ),
          ]),
        ])
      : _c("div", [_vm._v(_vm._s(_vm.$gettext("No comments posted.")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }