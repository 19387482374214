<template>
    <div v-if="$parent.enabled" class="comment">
      <span class="comment-link" :class="{'active': !$parent.readonly}" :id="'comment-link-'+to_html_id(reference)" data-toggle="tooltip" :title="!this.$parent.readonly ? $gettext('Add new comment for reference line') + ' ' + reference : ''"
            @click="add">{{ reference }}</span>
      <div class="comment-line-container">
        <comment-item :tools="$parent" :item="item" v-for="item in $parent.comments[reference]" :key="item.id"></comment-item>
      </div>
    </div>
</template>

<script>
  import { openCommentEditor } from './comment-editor.vue'
  import CommentItem from './comment-item'

  export default {
    name: 'comment-anchor',
    components: { CommentItem },
    data () {
      return {

      }
    },
    computed: {
      reference () {
        return this.$options.dataDrtRef
      }
    },
    methods: {
      add () {
        if (!this.$parent.readonly) {
          openCommentEditor(this.$parent, this.reference)
        }
      },
      to_html_id (value) {
        return String(value).replace('.', '-').replace(' ', '')
      }
    },
    mounted () {

    }
  }
</script>

<style scoped>
 /* See comment-ui.js and comment-ui.scss */
</style>
