var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "extranet-viewer" }, [
    _c("iframe", {
      attrs: {
        src: _vm.url,
        frameborder: "0",
        allow: "encrypted-media",
        allowfullscreen: "allowfullscreen",
      },
      on: { load: _vm.documentOpened },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }